import React, { useState, useEffect, useRef } from "react";

import { Form, Input, Button, message, Space, Tag, Table, Spin } from "antd";

import {
  FormOutlined,
  UnorderedListOutlined,
  SnippetsOutlined,
  PieChartOutlined,
} from "@ant-design/icons";

import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";

import Ky from "ky";
import { Http } from "../../Http";
import queryString from "query-string";

import styles from "./UserSave.module.less";

function UserSave({ location, navigate }) {
  const { state } = location;
  const { data, search, pagination } = state;

  const [loading, setLoading] = useState(true);
  const [userSubmit, setUserSubmit] = useState(false);

  const [orderSelected, setOrderSelected] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderData, setOrderData] = useState({});
  const [orderSearch, setOrderSearch] = useState({});
  const [orderPagination, setOrderPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [rankSelected, setRankSelected] = useState([]);
  const [rankLoading, setRankLoading] = useState(true);
  const [rankData, setRankData] = useState({});
  const [rankSearch, setRankSearch] = useState({});
  const [rankPagination, setRankPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [loginSelected, setLoginSelected] = useState([]);
  const [loginLoading, setLoginLoading] = useState(true);
  const [loginData, setLoginData] = useState({});
  const [loginSearch, setLoginSearch] = useState({});
  const [loginPagination, setLoginPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [userForm] = Form.useForm();
  const [orderSearchForm] = Form.useForm();
  const [rankSearchForm] = Form.useForm();
  const [loginSearchForm] = Form.useForm();
  const Chart = useRef();

  const orderColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
      fixed: "left",
    },
    {
      title: "订单号",
      dataIndex: "serialNo",
      key: "serialNo",
      width: 140,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "产品",
      dataIndex: ["shop", "name"],
      key: ["shop", "name"],
      ellipsis: true,
      width: 100,
      fixed: "left",
    },
    {
      title: "套餐",
      dataIndex: ["edition", "name"],
      key: ["edition", "name"],
      ellipsis: true,
      width: 100,
    },
    {
      title: "价格",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      width: 100,
      render: (value) => {
        return `￥${value}`;
      },
    },
    {
      title: "开始日期",
      dataIndex: "startTime",
      key: "startTime",
      ellipsis: true,
      width: 160,
      sorter: true,
    },
    {
      title: "结束日期",
      dataIndex: "endTime",
      key: "endTime",
      ellipsis: true,
      width: 160,
      sorter: true,
      render: (value) => {
        return value ? value : "无限期";
      },
    },
    {
      title: "交易状态",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 100,
      fixed: "right",
      sorter: true,
      render: (value) => {
        if (value === 1) return <Tag color="red">未支付</Tag>;
        if (value === 0) return <Tag color="green">已支付</Tag>;
        return "未知";
      },
    },
    {
      title: "创建日期",
      dataIndex: "createdTime",
      key: "createdTime",
      ellipsis: true,
      width: 160,
      sorter: true,
      fixed: "right",
    },
  ];

  const rankColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
    },
    {
      title: "关键词",
      dataIndex: "x1",
      key: "x1",
      ellipsis: true,
    },
    {
      title: "搜索引擎",
      dataIndex: "x2",
      key: "x2",
      render: (value) => {
        if (value === 1) return <Tag color="blue">百度</Tag>;
        if (value === 2) return <Tag color="green">360</Tag>;
        if (value === 3) return <Tag color="red">搜狗</Tag>;
        if (value === 4) return <Tag color="orange">神马</Tag>;
        return null;
      },
    },
    {
      title: "当前排名",
      dataIndex: "x3",
      key: "x3",
      ellipsis: true,
      render: (value) => <span>第{value}名</span>,
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, data) => <a>查看排名</a>,
    },
  ];

  const loginColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      ellipsis: true,
    },
    {
      title: "登录地址",
      dataIndex: "ref",
      key: "ref",
    },
    {
      title: "登录时间",
      dataIndex: "createdTime",
      key: "createdTime",
    },
  ];

  const getIp = async (ip) => {
    let para = queryString.stringify({ ip });
    try {
      let parsed = await Ky.get(`https://ip.chinaqyz.com/`, {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        let { country_name, region_name, city_name } = parsed.result;
        return [country_name, region_name, city_name]
          .filter((val) => val.length > 0)
          .join("-");
      } else {
        await message.warn(parsed.message ? parsed.message : "获取失败！");
      }
    } catch (error) {
      await message.error("接口出现错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onBack = () => {
    navigate("../", { state: { search, pagination } });
  };

  const onOrderSearch = (values) => {
    setOrderSearch(values);
    getOrderList({
      orderSearch: values,
      orderPagination: { current: 1, pageSize: 10, total: 0 },
    });
  };

  const onRankSearch = (values) => {
    setRankSearch(values);
    getRankList({
      rankSearch: values,
      rankPagination: { current: 1, pageSize: 10, total: 0 },
    });
  };

  const onLoginSearch = (values) => {
    setLoginSearch(values);
    getLoginList({
      loginSearch: values,
      loginPagination: { current: 1, pageSize: 10, total: 0 },
    });
  };

  const getChart = async () => {
    // let para = queryString.stringify({});
    // let parsed = await Http.get(`hour`, {
    //   searchParams: para,
    // }).json();
    // if (parsed.state === "ok") {

    // }
    let eChartKeywords = echarts.init(Chart.current);
    eChartKeywords.setOption({
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["百度", "百度移动", "360", "搜狗", "头条", "神马"],
      },
      series: [
        {
          name: "流量占比",
          type: "pie",
          radius: "70%",
          center: ["50%", "50%"],
          data: [
            { value: 335, name: "百度" },
            { value: 310, name: "百度移动" },
            { value: 234, name: "360" },
            { value: 135, name: "搜狗" },
            { value: 1548, name: "头条" },
            { value: 848, name: "神马" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getChart();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onUserFinish = async (values) => {
    setUserSubmit(true);
    if (data) {
      values = { ...data, ...values };
    }
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/save`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("提交成功");
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setUserSubmit(false);
    }
  };

  const getOrderList = async (params) => {
    setOrderLoading(true);
    try {
      let para = queryString.stringify({
        ...params.orderSearch,
        userId: data.id,
        pageNumber: params.orderPagination.current,
        pageSize: params.orderPagination.pageSize,
      });
      let parsed = await Http.get(`order`, {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        setOrderData(parsed.result);
        setOrderPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setOrderLoading(false);
    }
  };

  const getRankList = async (params) => {
    // setRankLoading(true);
    // try {
    //   let para = queryString.stringify({
    //     ...params.rankSearch,
    //     userId: data.id,
    //     pageNumber: params.rankPagination.current,
    //     pageSize: params.rankPagination.pageSize,
    //   });
    //   let parsed = await Http.get(`rank`, {
    //     searchParams: para,
    //   }).json();
    //   if (parsed.state === "ok") {
    //     setRankData(parsed.result);
    //     setRankPagination({
    //       current: parsed.result.pageNumber,
    //       pageSize: parsed.result.pageSize,
    //       total: parsed.result.totalRow,
    //     });
    //   }
    // } catch (error) {
    //   await message.error("接口调用错误, 错误原因:" + error.message);
    // } finally {
    //   setRankLoading(false);
    // }
    setRankLoading(false);
    setRankData({
      list: [
        {
          id: 1,
          x1: "世纪东方",
          x2: 1,
          x3: 1,
        },
      ],
    });
  };

  const getLoginList = async (params) => {
    setLoginLoading(true);
    try {
      let para = queryString.stringify({
        ...params.loginSearch,
        userId: data.id,
        pageNumber: params.loginPagination.current,
        pageSize: params.loginPagination.pageSize,
      });
      let parsed = await Http.get(`history`, {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        for (let i = 0; i < parsed.result.list.length; i++) {
          let v = parsed.result.list[i];
          if (v.ip) {
            v.ip = v.ip + " - " + (await getIp(v.ip));
          }
        }
        setLoginData(parsed.result);
        setLoginPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoginLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      onLoading();
      userForm.setFieldsValue(data);
      getOrderList({ orderSearch, orderPagination });
      getRankList({ rankSearch, rankPagination });
      getLoginList({ loginSearch, loginPagination });
    }
  }, [data]);

  return (
    <div className={styles.UserSave}>
      <div className={styles.infos}>
        <div className={styles.user}>
          <div className={styles.header}>
            <FormOutlined style={{ fontSize: 16, color: "#1890ff" }} />
            <i>用户信息</i>
          </div>
          <div className={styles.content}>
            <Spin spinning={loading}>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                form={userForm}
                onFinish={onUserFinish}
              >
                <Form.Item
                  name="username"
                  label="用户名"
                  rules={[{ required: true, message: "请输入用户名!" }]}
                >
                  <Input placeholder="请输入用户名" disabled />
                </Form.Item>
                <Form.Item name="cellphone" label="手机号">
                  <Input placeholder="请输入手机号" />
                </Form.Item>
                <Form.Item name="email" label="邮箱">
                  <Input placeholder="请输入邮箱" />
                </Form.Item>
                <Form.Item
                  wrapperCol={{ offset: 4 }}
                  style={{ marginBottom: 0 }}
                >
                  <Space>
                    <Button
                      type="primary"
                      disabled={userSubmit}
                      loading={userSubmit}
                      htmlType="submit"
                    >
                      提交
                    </Button>
                    <Button htmlType="button" onClick={onBack}>
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
        <div className={styles.count}>
          <div className={styles.col}>
            <div className={styles.tit}>
              <SnippetsOutlined style={{ fontSize: 16, color: "#1890ff" }} />
              <i>发布统计</i>
            </div>
            <div className={styles.box}>
              <h2>
                <span>
                  秒排宝<i>（条）</i>
                </span>
                <span>
                  <i>昨日（9999）</i>
                  <i>今日（9999）</i>
                  <i>总计（9999）</i>
                </span>
              </h2>
              <h2>
                <span>
                  新闻发布<i>（条）</i>
                </span>
                <span>
                  <i>昨日（9999）</i>
                  <i>今日（9999）</i>
                  <i>总计（9999）</i>
                </span>
              </h2>
              <h2>
                <span>
                  贴吧问答<i>（条）</i>
                </span>
                <span>
                  <i>昨日（9999）</i>
                  <i>今日（9999）</i>
                  <i>总计（9999）</i>
                </span>
              </h2>
              <h2>
                <span>
                  企业群站<i>（条）</i>
                </span>
                <span>
                  <i>昨日（9999）</i>
                  <i>今日（9999）</i>
                  <i>总计（9999）</i>
                </span>
              </h2>
              <h2>
                <span>
                  舆情监控<i>（条）</i>
                </span>
                <span>
                  <i>昨日（9999）</i>
                  <i>今日（9999）</i>
                  <i>总计（9999）</i>
                </span>
              </h2>
              <h2>
                <span>
                  已优化<i>（条）</i>
                </span>
                <span>
                  <i>总计（9999）</i>
                </span>
              </h2>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tit}>
              <PieChartOutlined style={{ fontSize: 16, color: "#1890ff" }} />
              <i>流量占比</i>
            </div>
            <div className={styles.box} ref={Chart}></div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.order}>
          <div className={styles.tit}>
            <UnorderedListOutlined style={{ fontSize: 16, color: "#1890ff" }} />
            <i>订单信息</i>
          </div>
          <div className={styles.box}>
            <div className={styles.search}>
              <Form
                form={orderSearchForm}
                layout="inline"
                onFinish={onOrderSearch}
              >
                <Form.Item name="kw" style={{ width: 240 }}>
                  <Input placeholder="请输入订单号" />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="reset"
                    onClick={() => {
                      orderSearchForm.resetFields();
                    }}
                  >
                    重置
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginRight: 0 }}>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Table
              loading={orderLoading}
              rowKey="id"
              scroll={{ x: 1000 }}
              rowSelection={{
                type: "checkbox",
                onChange: (srk) => {
                  setOrderSelected(srk);
                },
              }}
              onChange={(p, f, s) => {
                let sort = null;
                if (s.order) {
                  sort = `${s.field} ${s.order.replace("end", "")}`;
                }
                getOrderList({
                  orderSearch: { ...orderSearch, sort },
                  orderPagination: p,
                });
              }}
              dataSource={orderData.list}
              columns={orderColumns}
              pagination={orderPagination}
            />
          </div>
        </div>
        <div className={styles.rank}>
          <div className={styles.tit}>
            <UnorderedListOutlined style={{ fontSize: 16, color: "#1890ff" }} />
            <i>关键词排名</i>
          </div>
          <div className={styles.box}>
            <div className={styles.search}>
              <Form
                form={rankSearchForm}
                layout="inline"
                initialValues={rankSearch}
                onFinish={onRankSearch}
              >
                <Form.Item name="kw" style={{ width: 240 }}>
                  <Input placeholder="请输入关键词" />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="reset"
                    onClick={() => {
                      rankSearchForm.resetFields();
                    }}
                  >
                    重置
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginRight: 0 }}>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Table
              loading={rankLoading}
              rowKey="id"
              scroll={{ x: 1000 }}
              rowSelection={{
                type: "checkbox",
                onChange: (srk) => {
                  setRankSelected(srk);
                },
              }}
              onChange={(p, f, s) => {
                let sort = null;
                if (s.order) {
                  sort = `${s.field} ${s.order.replace("end", "")}`;
                }
                getRankList({
                  rankSearch: { ...orderSearch, sort },
                  rankPagination: p,
                });
              }}
              dataSource={rankData.list}
              columns={rankColumns}
              pagination={rankPagination}
            />
          </div>
        </div>
        <div className={styles.login}>
          <div className={styles.tit}>
            <UnorderedListOutlined style={{ fontSize: 16, color: "#1890ff" }} />
            <i>登录历史</i>
          </div>
          <div className={styles.box}>
            <div className={styles.search}>
              <Form
                form={loginSearchForm}
                layout="inline"
                onFinish={onLoginSearch}
              >
                <Form.Item name="kw" style={{ width: 240 }}>
                  <Input placeholder="请输入ip" />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="reset"
                    onClick={() => {
                      loginSearchForm.resetFields();
                    }}
                  >
                    重置
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginRight: 0 }}>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Table
              loading={loginLoading}
              rowKey="id"
              scroll={{ x: 1000 }}
              rowSelection={{
                type: "checkbox",
                onChange: (srk) => {
                  setLoginSelected(srk);
                },
              }}
              onChange={(p, f, s) => {
                let sort = null;
                if (s.order) {
                  sort = `${s.field} ${s.order.replace("end", "")}`;
                }
                getLoginList({
                  loginSearch: { ...loginSearch, sort },
                  loginPagination: p,
                });
              }}
              dataSource={loginData.list}
              columns={loginColumns}
              pagination={loginPagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSave;
