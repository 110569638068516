import React, { useState, useEffect } from "react";

import { Router } from "@reach/router";

import { message } from "antd";

import { Http } from "../Http";
import queryString from "query-string";

import Website from "../pages/website/Website";
import Webmaster from "../pages/webmaster/Webmaster";

import styles from "./Container.module.less";

function Container() {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    let para = queryString.stringify({});
    let parsed = await Http.get(`user/self`, {
      searchParams: para,
    }).json();
    if (parsed.state === "ok") {
      setUser(parsed.result);
    }
  };

  const onLoading = async () => {
    try {
      await getUser();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  useEffect(() => {
    onLoading();
  }, []);

  return (
    <div className={styles.Container}>
      <Router>
        <Website path="/*" user={user} />
        <Webmaster path="webmaster/*" user={user} />
      </Router>
    </div>
  );
}

export default Container;
