const area = [
  {
    id: 11,
    pid: 0,
    deep: 0,
    name: "北京",
  },
  {
    id: 12,
    pid: 0,
    deep: 0,
    name: "天津",
  },
  {
    id: 13,
    pid: 0,
    deep: 0,
    name: "河北",
  },
  {
    id: 14,
    pid: 0,
    deep: 0,
    name: "山西",
  },
  {
    id: 15,
    pid: 0,
    deep: 0,
    name: "内蒙古",
  },
  {
    id: 21,
    pid: 0,
    deep: 0,
    name: "辽宁",
  },
  {
    id: 22,
    pid: 0,
    deep: 0,
    name: "吉林",
  },
  {
    id: 23,
    pid: 0,
    deep: 0,
    name: "黑龙江",
  },
  {
    id: 31,
    pid: 0,
    deep: 0,
    name: "上海",
  },
  {
    id: 32,
    pid: 0,
    deep: 0,
    name: "江苏",
  },
  {
    id: 33,
    pid: 0,
    deep: 0,
    name: "浙江",
  },
  {
    id: 34,
    pid: 0,
    deep: 0,
    name: "安徽",
  },
  {
    id: 35,
    pid: 0,
    deep: 0,
    name: "福建",
  },
  {
    id: 36,
    pid: 0,
    deep: 0,
    name: "江西",
  },
  {
    id: 37,
    pid: 0,
    deep: 0,
    name: "山东",
  },
  {
    id: 41,
    pid: 0,
    deep: 0,
    name: "河南",
  },
  {
    id: 42,
    pid: 0,
    deep: 0,
    name: "湖北",
  },
  {
    id: 43,
    pid: 0,
    deep: 0,
    name: "湖南",
  },
  {
    id: 44,
    pid: 0,
    deep: 0,
    name: "广东",
  },
  {
    id: 45,
    pid: 0,
    deep: 0,
    name: "广西",
  },
  {
    id: 46,
    pid: 0,
    deep: 0,
    name: "海南",
  },
  {
    id: 50,
    pid: 0,
    deep: 0,
    name: "重庆",
  },
  {
    id: 51,
    pid: 0,
    deep: 0,
    name: "四川",
  },
  {
    id: 52,
    pid: 0,
    deep: 0,
    name: "贵州",
  },
  {
    id: 53,
    pid: 0,
    deep: 0,
    name: "云南",
  },
  {
    id: 54,
    pid: 0,
    deep: 0,
    name: "西藏",
  },
  {
    id: 61,
    pid: 0,
    deep: 0,
    name: "陕西",
  },
  {
    id: 62,
    pid: 0,
    deep: 0,
    name: "甘肃",
  },
  {
    id: 63,
    pid: 0,
    deep: 0,
    name: "青海",
  },
  {
    id: 64,
    pid: 0,
    deep: 0,
    name: "宁夏",
  },
  {
    id: 65,
    pid: 0,
    deep: 0,
    name: "新疆",
  },
  {
    id: 71,
    pid: 0,
    deep: 0,
    name: "台湾",
  },
  {
    id: 81,
    pid: 0,
    deep: 0,
    name: "香港",
  },
  {
    id: 82,
    pid: 0,
    deep: 0,
    name: "澳门",
  },
  {
    id: 91,
    pid: 0,
    deep: 0,
    name: "国外",
  },
  {
    id: 1101,
    pid: 11,
    deep: 1,
    name: "北京",
  },
  {
    id: 1201,
    pid: 12,
    deep: 1,
    name: "天津",
  },
  {
    id: 1301,
    pid: 13,
    deep: 1,
    name: "石家庄",
  },
  {
    id: 1302,
    pid: 13,
    deep: 1,
    name: "唐山",
  },
  {
    id: 1303,
    pid: 13,
    deep: 1,
    name: "秦皇岛",
  },
  {
    id: 1304,
    pid: 13,
    deep: 1,
    name: "邯郸",
  },
  {
    id: 1305,
    pid: 13,
    deep: 1,
    name: "邢台",
  },
  {
    id: 1306,
    pid: 13,
    deep: 1,
    name: "保定",
  },
  {
    id: 1307,
    pid: 13,
    deep: 1,
    name: "张家口",
  },
  {
    id: 1308,
    pid: 13,
    deep: 1,
    name: "承德",
  },
  {
    id: 1309,
    pid: 13,
    deep: 1,
    name: "沧州",
  },
  {
    id: 1310,
    pid: 13,
    deep: 1,
    name: "廊坊",
  },
  {
    id: 1311,
    pid: 13,
    deep: 1,
    name: "衡水",
  },
  {
    id: 1401,
    pid: 14,
    deep: 1,
    name: "太原",
  },
  {
    id: 1402,
    pid: 14,
    deep: 1,
    name: "大同",
  },
  {
    id: 1403,
    pid: 14,
    deep: 1,
    name: "阳泉",
  },
  {
    id: 1404,
    pid: 14,
    deep: 1,
    name: "长治",
  },
  {
    id: 1405,
    pid: 14,
    deep: 1,
    name: "晋城",
  },
  {
    id: 1406,
    pid: 14,
    deep: 1,
    name: "朔州",
  },
  {
    id: 1407,
    pid: 14,
    deep: 1,
    name: "晋中",
  },
  {
    id: 1408,
    pid: 14,
    deep: 1,
    name: "运城",
  },
  {
    id: 1409,
    pid: 14,
    deep: 1,
    name: "忻州",
  },
  {
    id: 1410,
    pid: 14,
    deep: 1,
    name: "临汾",
  },
  {
    id: 1411,
    pid: 14,
    deep: 1,
    name: "吕梁",
  },
  {
    id: 1501,
    pid: 15,
    deep: 1,
    name: "呼和浩特",
  },
  {
    id: 1502,
    pid: 15,
    deep: 1,
    name: "包头",
  },
  {
    id: 1503,
    pid: 15,
    deep: 1,
    name: "乌海",
  },
  {
    id: 1504,
    pid: 15,
    deep: 1,
    name: "赤峰",
  },
  {
    id: 1505,
    pid: 15,
    deep: 1,
    name: "通辽",
  },
  {
    id: 1506,
    pid: 15,
    deep: 1,
    name: "鄂尔多斯",
  },
  {
    id: 1507,
    pid: 15,
    deep: 1,
    name: "呼伦贝尔",
  },
  {
    id: 1508,
    pid: 15,
    deep: 1,
    name: "巴彦淖尔",
  },
  {
    id: 1509,
    pid: 15,
    deep: 1,
    name: "乌兰察布",
  },
  {
    id: 1522,
    pid: 15,
    deep: 1,
    name: "兴安",
  },
  {
    id: 1525,
    pid: 15,
    deep: 1,
    name: "锡林郭勒",
  },
  {
    id: 1529,
    pid: 15,
    deep: 1,
    name: "阿拉善",
  },
  {
    id: 2101,
    pid: 21,
    deep: 1,
    name: "沈阳",
  },
  {
    id: 2102,
    pid: 21,
    deep: 1,
    name: "大连",
  },
  {
    id: 2103,
    pid: 21,
    deep: 1,
    name: "鞍山",
  },
  {
    id: 2104,
    pid: 21,
    deep: 1,
    name: "抚顺",
  },
  {
    id: 2105,
    pid: 21,
    deep: 1,
    name: "本溪",
  },
  {
    id: 2106,
    pid: 21,
    deep: 1,
    name: "丹东",
  },
  {
    id: 2107,
    pid: 21,
    deep: 1,
    name: "锦州",
  },
  {
    id: 2108,
    pid: 21,
    deep: 1,
    name: "营口",
  },
  {
    id: 2109,
    pid: 21,
    deep: 1,
    name: "阜新",
  },
  {
    id: 2110,
    pid: 21,
    deep: 1,
    name: "辽阳",
  },
  {
    id: 2111,
    pid: 21,
    deep: 1,
    name: "盘锦",
  },
  {
    id: 2112,
    pid: 21,
    deep: 1,
    name: "铁岭",
  },
  {
    id: 2113,
    pid: 21,
    deep: 1,
    name: "朝阳",
  },
  {
    id: 2114,
    pid: 21,
    deep: 1,
    name: "葫芦岛",
  },
  {
    id: 2201,
    pid: 22,
    deep: 1,
    name: "长春",
  },
  {
    id: 2202,
    pid: 22,
    deep: 1,
    name: "吉林市",
  },
  {
    id: 2203,
    pid: 22,
    deep: 1,
    name: "四平",
  },
  {
    id: 2204,
    pid: 22,
    deep: 1,
    name: "辽源",
  },
  {
    id: 2205,
    pid: 22,
    deep: 1,
    name: "通化",
  },
  {
    id: 2206,
    pid: 22,
    deep: 1,
    name: "白山",
  },
  {
    id: 2207,
    pid: 22,
    deep: 1,
    name: "松原",
  },
  {
    id: 2208,
    pid: 22,
    deep: 1,
    name: "白城",
  },
  {
    id: 2224,
    pid: 22,
    deep: 1,
    name: "延边",
  },
  {
    id: 2301,
    pid: 23,
    deep: 1,
    name: "哈尔滨",
  },
  {
    id: 2302,
    pid: 23,
    deep: 1,
    name: "齐齐哈尔",
  },
  {
    id: 2303,
    pid: 23,
    deep: 1,
    name: "鸡西",
  },
  {
    id: 2304,
    pid: 23,
    deep: 1,
    name: "鹤岗",
  },
  {
    id: 2305,
    pid: 23,
    deep: 1,
    name: "双鸭山",
  },
  {
    id: 2306,
    pid: 23,
    deep: 1,
    name: "大庆",
  },
  {
    id: 2307,
    pid: 23,
    deep: 1,
    name: "伊春",
  },
  {
    id: 2308,
    pid: 23,
    deep: 1,
    name: "佳木斯",
  },
  {
    id: 2309,
    pid: 23,
    deep: 1,
    name: "七台河",
  },
  {
    id: 2310,
    pid: 23,
    deep: 1,
    name: "牡丹江",
  },
  {
    id: 2311,
    pid: 23,
    deep: 1,
    name: "黑河",
  },
  {
    id: 2312,
    pid: 23,
    deep: 1,
    name: "绥化",
  },
  {
    id: 2327,
    pid: 23,
    deep: 1,
    name: "大兴安岭",
  },
  {
    id: 3101,
    pid: 31,
    deep: 1,
    name: "上海",
  },
  {
    id: 3201,
    pid: 32,
    deep: 1,
    name: "南京",
  },
  {
    id: 3202,
    pid: 32,
    deep: 1,
    name: "无锡",
  },
  {
    id: 3203,
    pid: 32,
    deep: 1,
    name: "徐州",
  },
  {
    id: 3204,
    pid: 32,
    deep: 1,
    name: "常州",
  },
  {
    id: 3205,
    pid: 32,
    deep: 1,
    name: "苏州",
  },
  {
    id: 3206,
    pid: 32,
    deep: 1,
    name: "南通",
  },
  {
    id: 3207,
    pid: 32,
    deep: 1,
    name: "连云港",
  },
  {
    id: 3208,
    pid: 32,
    deep: 1,
    name: "淮安",
  },
  {
    id: 3209,
    pid: 32,
    deep: 1,
    name: "盐城",
  },
  {
    id: 3210,
    pid: 32,
    deep: 1,
    name: "扬州",
  },
  {
    id: 3211,
    pid: 32,
    deep: 1,
    name: "镇江",
  },
  {
    id: 3212,
    pid: 32,
    deep: 1,
    name: "泰州",
  },
  {
    id: 3213,
    pid: 32,
    deep: 1,
    name: "宿迁",
  },
  {
    id: 3301,
    pid: 33,
    deep: 1,
    name: "杭州",
  },
  {
    id: 3302,
    pid: 33,
    deep: 1,
    name: "宁波",
  },
  {
    id: 3303,
    pid: 33,
    deep: 1,
    name: "温州",
  },
  {
    id: 3304,
    pid: 33,
    deep: 1,
    name: "嘉兴",
  },
  {
    id: 3305,
    pid: 33,
    deep: 1,
    name: "湖州",
  },
  {
    id: 3306,
    pid: 33,
    deep: 1,
    name: "绍兴",
  },
  {
    id: 3307,
    pid: 33,
    deep: 1,
    name: "金华",
  },
  {
    id: 3308,
    pid: 33,
    deep: 1,
    name: "衢州",
  },
  {
    id: 3309,
    pid: 33,
    deep: 1,
    name: "舟山",
  },
  {
    id: 3310,
    pid: 33,
    deep: 1,
    name: "台州",
  },
  {
    id: 3311,
    pid: 33,
    deep: 1,
    name: "丽水",
  },
  {
    id: 3401,
    pid: 34,
    deep: 1,
    name: "合肥",
  },
  {
    id: 3402,
    pid: 34,
    deep: 1,
    name: "芜湖",
  },
  {
    id: 3403,
    pid: 34,
    deep: 1,
    name: "蚌埠",
  },
  {
    id: 3404,
    pid: 34,
    deep: 1,
    name: "淮南",
  },
  {
    id: 3405,
    pid: 34,
    deep: 1,
    name: "马鞍山",
  },
  {
    id: 3406,
    pid: 34,
    deep: 1,
    name: "淮北",
  },
  {
    id: 3407,
    pid: 34,
    deep: 1,
    name: "铜陵",
  },
  {
    id: 3408,
    pid: 34,
    deep: 1,
    name: "安庆",
  },
  {
    id: 3410,
    pid: 34,
    deep: 1,
    name: "黄山",
  },
  {
    id: 3411,
    pid: 34,
    deep: 1,
    name: "滁州",
  },
  {
    id: 3412,
    pid: 34,
    deep: 1,
    name: "阜阳",
  },
  {
    id: 3413,
    pid: 34,
    deep: 1,
    name: "宿州",
  },
  {
    id: 3415,
    pid: 34,
    deep: 1,
    name: "六安",
  },
  {
    id: 3416,
    pid: 34,
    deep: 1,
    name: "亳州",
  },
  {
    id: 3417,
    pid: 34,
    deep: 1,
    name: "池州",
  },
  {
    id: 3418,
    pid: 34,
    deep: 1,
    name: "宣城",
  },
  {
    id: 3501,
    pid: 35,
    deep: 1,
    name: "福州",
  },
  {
    id: 3502,
    pid: 35,
    deep: 1,
    name: "厦门",
  },
  {
    id: 3503,
    pid: 35,
    deep: 1,
    name: "莆田",
  },
  {
    id: 3504,
    pid: 35,
    deep: 1,
    name: "三明",
  },
  {
    id: 3505,
    pid: 35,
    deep: 1,
    name: "泉州",
  },
  {
    id: 3506,
    pid: 35,
    deep: 1,
    name: "漳州",
  },
  {
    id: 3507,
    pid: 35,
    deep: 1,
    name: "南平",
  },
  {
    id: 3508,
    pid: 35,
    deep: 1,
    name: "龙岩",
  },
  {
    id: 3509,
    pid: 35,
    deep: 1,
    name: "宁德",
  },
  {
    id: 3601,
    pid: 36,
    deep: 1,
    name: "南昌",
  },
  {
    id: 3602,
    pid: 36,
    deep: 1,
    name: "景德镇",
  },
  {
    id: 3603,
    pid: 36,
    deep: 1,
    name: "萍乡",
  },
  {
    id: 3604,
    pid: 36,
    deep: 1,
    name: "九江",
  },
  {
    id: 3605,
    pid: 36,
    deep: 1,
    name: "新余",
  },
  {
    id: 3606,
    pid: 36,
    deep: 1,
    name: "鹰潭",
  },
  {
    id: 3607,
    pid: 36,
    deep: 1,
    name: "赣州",
  },
  {
    id: 3608,
    pid: 36,
    deep: 1,
    name: "吉安",
  },
  {
    id: 3609,
    pid: 36,
    deep: 1,
    name: "宜春",
  },
  {
    id: 3610,
    pid: 36,
    deep: 1,
    name: "抚州",
  },
  {
    id: 3611,
    pid: 36,
    deep: 1,
    name: "上饶",
  },
  {
    id: 3701,
    pid: 37,
    deep: 1,
    name: "济南",
  },
  {
    id: 3702,
    pid: 37,
    deep: 1,
    name: "青岛",
  },
  {
    id: 3703,
    pid: 37,
    deep: 1,
    name: "淄博",
  },
  {
    id: 3704,
    pid: 37,
    deep: 1,
    name: "枣庄",
  },
  {
    id: 3705,
    pid: 37,
    deep: 1,
    name: "东营",
  },
  {
    id: 3706,
    pid: 37,
    deep: 1,
    name: "烟台",
  },
  {
    id: 3707,
    pid: 37,
    deep: 1,
    name: "潍坊",
  },
  {
    id: 3708,
    pid: 37,
    deep: 1,
    name: "济宁",
  },
  {
    id: 3709,
    pid: 37,
    deep: 1,
    name: "泰安",
  },
  {
    id: 3710,
    pid: 37,
    deep: 1,
    name: "威海",
  },
  {
    id: 3711,
    pid: 37,
    deep: 1,
    name: "日照",
  },
  {
    id: 3713,
    pid: 37,
    deep: 1,
    name: "临沂",
  },
  {
    id: 3714,
    pid: 37,
    deep: 1,
    name: "德州",
  },
  {
    id: 3715,
    pid: 37,
    deep: 1,
    name: "聊城",
  },
  {
    id: 3716,
    pid: 37,
    deep: 1,
    name: "滨州",
  },
  {
    id: 3717,
    pid: 37,
    deep: 1,
    name: "菏泽",
  },
  {
    id: 4101,
    pid: 41,
    deep: 1,
    name: "郑州",
  },
  {
    id: 4102,
    pid: 41,
    deep: 1,
    name: "开封",
  },
  {
    id: 4103,
    pid: 41,
    deep: 1,
    name: "洛阳",
  },
  {
    id: 4104,
    pid: 41,
    deep: 1,
    name: "平顶山",
  },
  {
    id: 4105,
    pid: 41,
    deep: 1,
    name: "安阳",
  },
  {
    id: 4106,
    pid: 41,
    deep: 1,
    name: "鹤壁",
  },
  {
    id: 4107,
    pid: 41,
    deep: 1,
    name: "新乡",
  },
  {
    id: 4108,
    pid: 41,
    deep: 1,
    name: "焦作",
  },
  {
    id: 4109,
    pid: 41,
    deep: 1,
    name: "濮阳",
  },
  {
    id: 4110,
    pid: 41,
    deep: 1,
    name: "许昌",
  },
  {
    id: 4111,
    pid: 41,
    deep: 1,
    name: "漯河",
  },
  {
    id: 4112,
    pid: 41,
    deep: 1,
    name: "三门峡",
  },
  {
    id: 4113,
    pid: 41,
    deep: 1,
    name: "南阳",
  },
  {
    id: 4114,
    pid: 41,
    deep: 1,
    name: "商丘",
  },
  {
    id: 4115,
    pid: 41,
    deep: 1,
    name: "信阳",
  },
  {
    id: 4116,
    pid: 41,
    deep: 1,
    name: "周口",
  },
  {
    id: 4117,
    pid: 41,
    deep: 1,
    name: "驻马店",
  },
  {
    id: 4201,
    pid: 42,
    deep: 1,
    name: "武汉",
  },
  {
    id: 4202,
    pid: 42,
    deep: 1,
    name: "黄石",
  },
  {
    id: 4203,
    pid: 42,
    deep: 1,
    name: "十堰",
  },
  {
    id: 4205,
    pid: 42,
    deep: 1,
    name: "宜昌",
  },
  {
    id: 4206,
    pid: 42,
    deep: 1,
    name: "襄阳",
  },
  {
    id: 4207,
    pid: 42,
    deep: 1,
    name: "鄂州",
  },
  {
    id: 4208,
    pid: 42,
    deep: 1,
    name: "荆门",
  },
  {
    id: 4209,
    pid: 42,
    deep: 1,
    name: "孝感",
  },
  {
    id: 4210,
    pid: 42,
    deep: 1,
    name: "荆州",
  },
  {
    id: 4211,
    pid: 42,
    deep: 1,
    name: "黄冈",
  },
  {
    id: 4212,
    pid: 42,
    deep: 1,
    name: "咸宁",
  },
  {
    id: 4213,
    pid: 42,
    deep: 1,
    name: "随州",
  },
  {
    id: 4228,
    pid: 42,
    deep: 1,
    name: "恩施",
  },
  {
    id: 4301,
    pid: 43,
    deep: 1,
    name: "长沙",
  },
  {
    id: 4302,
    pid: 43,
    deep: 1,
    name: "株洲",
  },
  {
    id: 4303,
    pid: 43,
    deep: 1,
    name: "湘潭",
  },
  {
    id: 4304,
    pid: 43,
    deep: 1,
    name: "衡阳",
  },
  {
    id: 4305,
    pid: 43,
    deep: 1,
    name: "邵阳",
  },
  {
    id: 4306,
    pid: 43,
    deep: 1,
    name: "岳阳",
  },
  {
    id: 4307,
    pid: 43,
    deep: 1,
    name: "常德",
  },
  {
    id: 4308,
    pid: 43,
    deep: 1,
    name: "张家界",
  },
  {
    id: 4309,
    pid: 43,
    deep: 1,
    name: "益阳",
  },
  {
    id: 4310,
    pid: 43,
    deep: 1,
    name: "郴州",
  },
  {
    id: 4311,
    pid: 43,
    deep: 1,
    name: "永州",
  },
  {
    id: 4312,
    pid: 43,
    deep: 1,
    name: "怀化",
  },
  {
    id: 4313,
    pid: 43,
    deep: 1,
    name: "娄底",
  },
  {
    id: 4331,
    pid: 43,
    deep: 1,
    name: "湘西",
  },
  {
    id: 4401,
    pid: 44,
    deep: 1,
    name: "广州",
  },
  {
    id: 4402,
    pid: 44,
    deep: 1,
    name: "韶关",
  },
  {
    id: 4403,
    pid: 44,
    deep: 1,
    name: "深圳",
  },
  {
    id: 4404,
    pid: 44,
    deep: 1,
    name: "珠海",
  },
  {
    id: 4405,
    pid: 44,
    deep: 1,
    name: "汕头",
  },
  {
    id: 4406,
    pid: 44,
    deep: 1,
    name: "佛山",
  },
  {
    id: 4407,
    pid: 44,
    deep: 1,
    name: "江门",
  },
  {
    id: 4408,
    pid: 44,
    deep: 1,
    name: "湛江",
  },
  {
    id: 4409,
    pid: 44,
    deep: 1,
    name: "茂名",
  },
  {
    id: 4412,
    pid: 44,
    deep: 1,
    name: "肇庆",
  },
  {
    id: 4413,
    pid: 44,
    deep: 1,
    name: "惠州",
  },
  {
    id: 4414,
    pid: 44,
    deep: 1,
    name: "梅州",
  },
  {
    id: 4415,
    pid: 44,
    deep: 1,
    name: "汕尾",
  },
  {
    id: 4416,
    pid: 44,
    deep: 1,
    name: "河源",
  },
  {
    id: 4417,
    pid: 44,
    deep: 1,
    name: "阳江",
  },
  {
    id: 4418,
    pid: 44,
    deep: 1,
    name: "清远",
  },
  {
    id: 4419,
    pid: 44,
    deep: 1,
    name: "东莞",
  },
  {
    id: 4420,
    pid: 44,
    deep: 1,
    name: "中山",
  },
  {
    id: 4451,
    pid: 44,
    deep: 1,
    name: "潮州",
  },
  {
    id: 4452,
    pid: 44,
    deep: 1,
    name: "揭阳",
  },
  {
    id: 4453,
    pid: 44,
    deep: 1,
    name: "云浮",
  },
  {
    id: 4501,
    pid: 45,
    deep: 1,
    name: "南宁",
  },
  {
    id: 4502,
    pid: 45,
    deep: 1,
    name: "柳州",
  },
  {
    id: 4503,
    pid: 45,
    deep: 1,
    name: "桂林",
  },
  {
    id: 4504,
    pid: 45,
    deep: 1,
    name: "梧州",
  },
  {
    id: 4505,
    pid: 45,
    deep: 1,
    name: "北海",
  },
  {
    id: 4506,
    pid: 45,
    deep: 1,
    name: "防城港",
  },
  {
    id: 4507,
    pid: 45,
    deep: 1,
    name: "钦州",
  },
  {
    id: 4508,
    pid: 45,
    deep: 1,
    name: "贵港",
  },
  {
    id: 4509,
    pid: 45,
    deep: 1,
    name: "玉林",
  },
  {
    id: 4510,
    pid: 45,
    deep: 1,
    name: "百色",
  },
  {
    id: 4511,
    pid: 45,
    deep: 1,
    name: "贺州",
  },
  {
    id: 4512,
    pid: 45,
    deep: 1,
    name: "河池",
  },
  {
    id: 4513,
    pid: 45,
    deep: 1,
    name: "来宾",
  },
  {
    id: 4514,
    pid: 45,
    deep: 1,
    name: "崇左",
  },
  {
    id: 4601,
    pid: 46,
    deep: 1,
    name: "海口",
  },
  {
    id: 4602,
    pid: 46,
    deep: 1,
    name: "三亚",
  },
  {
    id: 4603,
    pid: 46,
    deep: 1,
    name: "三沙",
  },
  {
    id: 4604,
    pid: 46,
    deep: 1,
    name: "儋州",
  },
  {
    id: 5001,
    pid: 50,
    deep: 1,
    name: "重庆城区",
  },
  {
    id: 5002,
    pid: 50,
    deep: 1,
    name: "重庆郊县",
  },
  {
    id: 5101,
    pid: 51,
    deep: 1,
    name: "成都",
  },
  {
    id: 5103,
    pid: 51,
    deep: 1,
    name: "自贡",
  },
  {
    id: 5104,
    pid: 51,
    deep: 1,
    name: "攀枝花",
  },
  {
    id: 5105,
    pid: 51,
    deep: 1,
    name: "泸州",
  },
  {
    id: 5106,
    pid: 51,
    deep: 1,
    name: "德阳",
  },
  {
    id: 5107,
    pid: 51,
    deep: 1,
    name: "绵阳",
  },
  {
    id: 5108,
    pid: 51,
    deep: 1,
    name: "广元",
  },
  {
    id: 5109,
    pid: 51,
    deep: 1,
    name: "遂宁",
  },
  {
    id: 5110,
    pid: 51,
    deep: 1,
    name: "内江",
  },
  {
    id: 5111,
    pid: 51,
    deep: 1,
    name: "乐山",
  },
  {
    id: 5113,
    pid: 51,
    deep: 1,
    name: "南充",
  },
  {
    id: 5114,
    pid: 51,
    deep: 1,
    name: "眉山",
  },
  {
    id: 5115,
    pid: 51,
    deep: 1,
    name: "宜宾",
  },
  {
    id: 5116,
    pid: 51,
    deep: 1,
    name: "广安",
  },
  {
    id: 5117,
    pid: 51,
    deep: 1,
    name: "达州",
  },
  {
    id: 5118,
    pid: 51,
    deep: 1,
    name: "雅安",
  },
  {
    id: 5119,
    pid: 51,
    deep: 1,
    name: "巴中",
  },
  {
    id: 5120,
    pid: 51,
    deep: 1,
    name: "资阳",
  },
  {
    id: 5132,
    pid: 51,
    deep: 1,
    name: "阿坝",
  },
  {
    id: 5133,
    pid: 51,
    deep: 1,
    name: "甘孜",
  },
  {
    id: 5134,
    pid: 51,
    deep: 1,
    name: "凉山",
  },
  {
    id: 5201,
    pid: 52,
    deep: 1,
    name: "贵阳",
  },
  {
    id: 5202,
    pid: 52,
    deep: 1,
    name: "六盘水",
  },
  {
    id: 5203,
    pid: 52,
    deep: 1,
    name: "遵义",
  },
  {
    id: 5204,
    pid: 52,
    deep: 1,
    name: "安顺",
  },
  {
    id: 5205,
    pid: 52,
    deep: 1,
    name: "毕节",
  },
  {
    id: 5206,
    pid: 52,
    deep: 1,
    name: "铜仁",
  },
  {
    id: 5223,
    pid: 52,
    deep: 1,
    name: "黔西南",
  },
  {
    id: 5226,
    pid: 52,
    deep: 1,
    name: "黔东南",
  },
  {
    id: 5227,
    pid: 52,
    deep: 1,
    name: "黔南",
  },
  {
    id: 5301,
    pid: 53,
    deep: 1,
    name: "昆明",
  },
  {
    id: 5303,
    pid: 53,
    deep: 1,
    name: "曲靖",
  },
  {
    id: 5304,
    pid: 53,
    deep: 1,
    name: "玉溪",
  },
  {
    id: 5305,
    pid: 53,
    deep: 1,
    name: "保山",
  },
  {
    id: 5306,
    pid: 53,
    deep: 1,
    name: "昭通",
  },
  {
    id: 5307,
    pid: 53,
    deep: 1,
    name: "丽江",
  },
  {
    id: 5308,
    pid: 53,
    deep: 1,
    name: "普洱",
  },
  {
    id: 5309,
    pid: 53,
    deep: 1,
    name: "临沧",
  },
  {
    id: 5323,
    pid: 53,
    deep: 1,
    name: "楚雄",
  },
  {
    id: 5325,
    pid: 53,
    deep: 1,
    name: "红河",
  },
  {
    id: 5326,
    pid: 53,
    deep: 1,
    name: "文山",
  },
  {
    id: 5328,
    pid: 53,
    deep: 1,
    name: "西双版纳",
  },
  {
    id: 5329,
    pid: 53,
    deep: 1,
    name: "大理",
  },
  {
    id: 5331,
    pid: 53,
    deep: 1,
    name: "德宏",
  },
  {
    id: 5333,
    pid: 53,
    deep: 1,
    name: "怒江",
  },
  {
    id: 5334,
    pid: 53,
    deep: 1,
    name: "迪庆",
  },
  {
    id: 5401,
    pid: 54,
    deep: 1,
    name: "拉萨",
  },
  {
    id: 5402,
    pid: 54,
    deep: 1,
    name: "日喀则",
  },
  {
    id: 5403,
    pid: 54,
    deep: 1,
    name: "昌都",
  },
  {
    id: 5404,
    pid: 54,
    deep: 1,
    name: "林芝",
  },
  {
    id: 5405,
    pid: 54,
    deep: 1,
    name: "山南",
  },
  {
    id: 5406,
    pid: 54,
    deep: 1,
    name: "那曲",
  },
  {
    id: 5425,
    pid: 54,
    deep: 1,
    name: "阿里",
  },
  {
    id: 6101,
    pid: 61,
    deep: 1,
    name: "西安",
  },
  {
    id: 6102,
    pid: 61,
    deep: 1,
    name: "铜川",
  },
  {
    id: 6103,
    pid: 61,
    deep: 1,
    name: "宝鸡",
  },
  {
    id: 6104,
    pid: 61,
    deep: 1,
    name: "咸阳",
  },
  {
    id: 6105,
    pid: 61,
    deep: 1,
    name: "渭南",
  },
  {
    id: 6106,
    pid: 61,
    deep: 1,
    name: "延安",
  },
  {
    id: 6107,
    pid: 61,
    deep: 1,
    name: "汉中",
  },
  {
    id: 6108,
    pid: 61,
    deep: 1,
    name: "榆林",
  },
  {
    id: 6109,
    pid: 61,
    deep: 1,
    name: "安康",
  },
  {
    id: 6110,
    pid: 61,
    deep: 1,
    name: "商洛",
  },
  {
    id: 6201,
    pid: 62,
    deep: 1,
    name: "兰州",
  },
  {
    id: 6202,
    pid: 62,
    deep: 1,
    name: "嘉峪关",
  },
  {
    id: 6203,
    pid: 62,
    deep: 1,
    name: "金昌",
  },
  {
    id: 6204,
    pid: 62,
    deep: 1,
    name: "白银",
  },
  {
    id: 6205,
    pid: 62,
    deep: 1,
    name: "天水",
  },
  {
    id: 6206,
    pid: 62,
    deep: 1,
    name: "武威",
  },
  {
    id: 6207,
    pid: 62,
    deep: 1,
    name: "张掖",
  },
  {
    id: 6208,
    pid: 62,
    deep: 1,
    name: "平凉",
  },
  {
    id: 6209,
    pid: 62,
    deep: 1,
    name: "酒泉",
  },
  {
    id: 6210,
    pid: 62,
    deep: 1,
    name: "庆阳",
  },
  {
    id: 6211,
    pid: 62,
    deep: 1,
    name: "定西",
  },
  {
    id: 6212,
    pid: 62,
    deep: 1,
    name: "陇南",
  },
  {
    id: 6229,
    pid: 62,
    deep: 1,
    name: "临夏",
  },
  {
    id: 6230,
    pid: 62,
    deep: 1,
    name: "甘南",
  },
  {
    id: 6301,
    pid: 63,
    deep: 1,
    name: "西宁",
  },
  {
    id: 6302,
    pid: 63,
    deep: 1,
    name: "海东",
  },
  {
    id: 6322,
    pid: 63,
    deep: 1,
    name: "海北",
  },
  {
    id: 6323,
    pid: 63,
    deep: 1,
    name: "黄南",
  },
  {
    id: 6325,
    pid: 63,
    deep: 1,
    name: "海南",
  },
  {
    id: 6326,
    pid: 63,
    deep: 1,
    name: "果洛",
  },
  {
    id: 6327,
    pid: 63,
    deep: 1,
    name: "玉树",
  },
  {
    id: 6328,
    pid: 63,
    deep: 1,
    name: "海西",
  },
  {
    id: 6401,
    pid: 64,
    deep: 1,
    name: "银川",
  },
  {
    id: 6402,
    pid: 64,
    deep: 1,
    name: "石嘴山",
  },
  {
    id: 6403,
    pid: 64,
    deep: 1,
    name: "吴忠",
  },
  {
    id: 6404,
    pid: 64,
    deep: 1,
    name: "固原",
  },
  {
    id: 6405,
    pid: 64,
    deep: 1,
    name: "中卫",
  },
  {
    id: 6501,
    pid: 65,
    deep: 1,
    name: "乌鲁木齐",
  },
  {
    id: 6502,
    pid: 65,
    deep: 1,
    name: "克拉玛依",
  },
  {
    id: 6504,
    pid: 65,
    deep: 1,
    name: "吐鲁番",
  },
  {
    id: 6505,
    pid: 65,
    deep: 1,
    name: "哈密",
  },
  {
    id: 6523,
    pid: 65,
    deep: 1,
    name: "昌吉",
  },
  {
    id: 6527,
    pid: 65,
    deep: 1,
    name: "博尔塔拉",
  },
  {
    id: 6528,
    pid: 65,
    deep: 1,
    name: "巴音郭楞",
  },
  {
    id: 6529,
    pid: 65,
    deep: 1,
    name: "阿克苏",
  },
  {
    id: 6530,
    pid: 65,
    deep: 1,
    name: "克孜勒苏",
  },
  {
    id: 6531,
    pid: 65,
    deep: 1,
    name: "喀什",
  },
  {
    id: 6532,
    pid: 65,
    deep: 1,
    name: "和田",
  },
  {
    id: 6540,
    pid: 65,
    deep: 1,
    name: "伊犁",
  },
  {
    id: 6542,
    pid: 65,
    deep: 1,
    name: "塔城",
  },
  {
    id: 6543,
    pid: 65,
    deep: 1,
    name: "阿勒泰",
  },
  {
    id: 7101,
    pid: 71,
    deep: 1,
    name: "台北",
  },
  {
    id: 7102,
    pid: 71,
    deep: 1,
    name: "高雄",
  },
  {
    id: 7103,
    pid: 71,
    deep: 1,
    name: "台南",
  },
  {
    id: 7104,
    pid: 71,
    deep: 1,
    name: "台中",
  },
  {
    id: 7106,
    pid: 71,
    deep: 1,
    name: "南投",
  },
  {
    id: 7107,
    pid: 71,
    deep: 1,
    name: "基隆",
  },
  {
    id: 7108,
    pid: 71,
    deep: 1,
    name: "新竹市",
  },
  {
    id: 7109,
    pid: 71,
    deep: 1,
    name: "嘉义市",
  },
  {
    id: 7111,
    pid: 71,
    deep: 1,
    name: "新北",
  },
  {
    id: 7112,
    pid: 71,
    deep: 1,
    name: "宜兰",
  },
  {
    id: 7113,
    pid: 71,
    deep: 1,
    name: "新竹县",
  },
  {
    id: 7114,
    pid: 71,
    deep: 1,
    name: "桃园",
  },
  {
    id: 7115,
    pid: 71,
    deep: 1,
    name: "苗栗",
  },
  {
    id: 7117,
    pid: 71,
    deep: 1,
    name: "彰化",
  },
  {
    id: 7119,
    pid: 71,
    deep: 1,
    name: "嘉义县",
  },
  {
    id: 7121,
    pid: 71,
    deep: 1,
    name: "云林",
  },
  {
    id: 7124,
    pid: 71,
    deep: 1,
    name: "屏东",
  },
  {
    id: 7125,
    pid: 71,
    deep: 1,
    name: "台东",
  },
  {
    id: 7126,
    pid: 71,
    deep: 1,
    name: "花莲",
  },
  {
    id: 7127,
    pid: 71,
    deep: 1,
    name: "澎湖",
  },
  {
    id: 8100,
    pid: 81,
    deep: 1,
    name: "香港",
  },
  {
    id: 8200,
    pid: 82,
    deep: 1,
    name: "澳门",
  },
  {
    id: 9100,
    pid: 91,
    deep: 1,
    name: "国外",
  },
  {
    id: 110101,
    pid: 1101,
    deep: 2,
    name: "东城",
  },
  {
    id: 110102,
    pid: 1101,
    deep: 2,
    name: "西城",
  },
  {
    id: 110105,
    pid: 1101,
    deep: 2,
    name: "朝阳",
  },
  {
    id: 110106,
    pid: 1101,
    deep: 2,
    name: "丰台",
  },
  {
    id: 110107,
    pid: 1101,
    deep: 2,
    name: "石景山",
  },
  {
    id: 110108,
    pid: 1101,
    deep: 2,
    name: "海淀",
  },
  {
    id: 110109,
    pid: 1101,
    deep: 2,
    name: "门头沟",
  },
  {
    id: 110111,
    pid: 1101,
    deep: 2,
    name: "房山",
  },
  {
    id: 110112,
    pid: 1101,
    deep: 2,
    name: "通州",
  },
  {
    id: 110113,
    pid: 1101,
    deep: 2,
    name: "顺义",
  },
  {
    id: 110114,
    pid: 1101,
    deep: 2,
    name: "昌平",
  },
  {
    id: 110115,
    pid: 1101,
    deep: 2,
    name: "大兴",
  },
  {
    id: 110116,
    pid: 1101,
    deep: 2,
    name: "怀柔",
  },
  {
    id: 110117,
    pid: 1101,
    deep: 2,
    name: "平谷",
  },
  {
    id: 110118,
    pid: 1101,
    deep: 2,
    name: "密云",
  },
  {
    id: 110119,
    pid: 1101,
    deep: 2,
    name: "延庆",
  },
  {
    id: 120101,
    pid: 1201,
    deep: 2,
    name: "和平",
  },
  {
    id: 120102,
    pid: 1201,
    deep: 2,
    name: "河东",
  },
  {
    id: 120103,
    pid: 1201,
    deep: 2,
    name: "河西",
  },
  {
    id: 120104,
    pid: 1201,
    deep: 2,
    name: "南开",
  },
  {
    id: 120105,
    pid: 1201,
    deep: 2,
    name: "河北",
  },
  {
    id: 120106,
    pid: 1201,
    deep: 2,
    name: "红桥",
  },
  {
    id: 120110,
    pid: 1201,
    deep: 2,
    name: "东丽",
  },
  {
    id: 120111,
    pid: 1201,
    deep: 2,
    name: "西青",
  },
  {
    id: 120112,
    pid: 1201,
    deep: 2,
    name: "津南",
  },
  {
    id: 120113,
    pid: 1201,
    deep: 2,
    name: "北辰",
  },
  {
    id: 120114,
    pid: 1201,
    deep: 2,
    name: "武清",
  },
  {
    id: 120115,
    pid: 1201,
    deep: 2,
    name: "宝坻",
  },
  {
    id: 120116,
    pid: 1201,
    deep: 2,
    name: "滨海新区",
  },
  {
    id: 120117,
    pid: 1201,
    deep: 2,
    name: "宁河",
  },
  {
    id: 120118,
    pid: 1201,
    deep: 2,
    name: "静海",
  },
  {
    id: 120119,
    pid: 1201,
    deep: 2,
    name: "蓟州",
  },
  {
    id: 130102,
    pid: 1301,
    deep: 2,
    name: "长安",
  },
  {
    id: 130104,
    pid: 1301,
    deep: 2,
    name: "桥西",
  },
  {
    id: 130105,
    pid: 1301,
    deep: 2,
    name: "新华",
  },
  {
    id: 130107,
    pid: 1301,
    deep: 2,
    name: "井陉矿区",
  },
  {
    id: 130108,
    pid: 1301,
    deep: 2,
    name: "裕华",
  },
  {
    id: 130109,
    pid: 1301,
    deep: 2,
    name: "藁城",
  },
  {
    id: 130110,
    pid: 1301,
    deep: 2,
    name: "鹿泉",
  },
  {
    id: 130111,
    pid: 1301,
    deep: 2,
    name: "栾城",
  },
  {
    id: 130121,
    pid: 1301,
    deep: 2,
    name: "井陉",
  },
  {
    id: 130123,
    pid: 1301,
    deep: 2,
    name: "正定",
  },
  {
    id: 130125,
    pid: 1301,
    deep: 2,
    name: "行唐",
  },
  {
    id: 130126,
    pid: 1301,
    deep: 2,
    name: "灵寿",
  },
  {
    id: 130127,
    pid: 1301,
    deep: 2,
    name: "高邑",
  },
  {
    id: 130128,
    pid: 1301,
    deep: 2,
    name: "深泽",
  },
  {
    id: 130129,
    pid: 1301,
    deep: 2,
    name: "赞皇",
  },
  {
    id: 130130,
    pid: 1301,
    deep: 2,
    name: "无极",
  },
  {
    id: 130131,
    pid: 1301,
    deep: 2,
    name: "平山",
  },
  {
    id: 130132,
    pid: 1301,
    deep: 2,
    name: "元氏",
  },
  {
    id: 130133,
    pid: 1301,
    deep: 2,
    name: "赵县",
  },
  {
    id: 130181,
    pid: 1301,
    deep: 2,
    name: "辛集",
  },
  {
    id: 130183,
    pid: 1301,
    deep: 2,
    name: "晋州",
  },
  {
    id: 130184,
    pid: 1301,
    deep: 2,
    name: "新乐",
  },
  {
    id: 130202,
    pid: 1302,
    deep: 2,
    name: "路南",
  },
  {
    id: 130203,
    pid: 1302,
    deep: 2,
    name: "路北",
  },
  {
    id: 130204,
    pid: 1302,
    deep: 2,
    name: "古冶",
  },
  {
    id: 130205,
    pid: 1302,
    deep: 2,
    name: "开平",
  },
  {
    id: 130207,
    pid: 1302,
    deep: 2,
    name: "丰南",
  },
  {
    id: 130208,
    pid: 1302,
    deep: 2,
    name: "丰润",
  },
  {
    id: 130209,
    pid: 1302,
    deep: 2,
    name: "曹妃甸",
  },
  {
    id: 130224,
    pid: 1302,
    deep: 2,
    name: "滦南",
  },
  {
    id: 130225,
    pid: 1302,
    deep: 2,
    name: "乐亭",
  },
  {
    id: 130227,
    pid: 1302,
    deep: 2,
    name: "迁西",
  },
  {
    id: 130229,
    pid: 1302,
    deep: 2,
    name: "玉田",
  },
  {
    id: 130281,
    pid: 1302,
    deep: 2,
    name: "遵化",
  },
  {
    id: 130283,
    pid: 1302,
    deep: 2,
    name: "迁安",
  },
  {
    id: 130284,
    pid: 1302,
    deep: 2,
    name: "滦州",
  },
  {
    id: 130302,
    pid: 1303,
    deep: 2,
    name: "海港",
  },
  {
    id: 130303,
    pid: 1303,
    deep: 2,
    name: "山海关",
  },
  {
    id: 130304,
    pid: 1303,
    deep: 2,
    name: "北戴河",
  },
  {
    id: 130306,
    pid: 1303,
    deep: 2,
    name: "抚宁",
  },
  {
    id: 130321,
    pid: 1303,
    deep: 2,
    name: "青龙",
  },
  {
    id: 130322,
    pid: 1303,
    deep: 2,
    name: "昌黎",
  },
  {
    id: 130324,
    pid: 1303,
    deep: 2,
    name: "卢龙",
  },
  {
    id: 130402,
    pid: 1304,
    deep: 2,
    name: "邯山",
  },
  {
    id: 130403,
    pid: 1304,
    deep: 2,
    name: "丛台",
  },
  {
    id: 130404,
    pid: 1304,
    deep: 2,
    name: "复兴",
  },
  {
    id: 130406,
    pid: 1304,
    deep: 2,
    name: "峰峰矿区",
  },
  {
    id: 130407,
    pid: 1304,
    deep: 2,
    name: "肥乡",
  },
  {
    id: 130408,
    pid: 1304,
    deep: 2,
    name: "永年",
  },
  {
    id: 130423,
    pid: 1304,
    deep: 2,
    name: "临漳",
  },
  {
    id: 130424,
    pid: 1304,
    deep: 2,
    name: "成安",
  },
  {
    id: 130425,
    pid: 1304,
    deep: 2,
    name: "大名",
  },
  {
    id: 130426,
    pid: 1304,
    deep: 2,
    name: "涉县",
  },
  {
    id: 130427,
    pid: 1304,
    deep: 2,
    name: "磁县",
  },
  {
    id: 130430,
    pid: 1304,
    deep: 2,
    name: "邱县",
  },
  {
    id: 130431,
    pid: 1304,
    deep: 2,
    name: "鸡泽",
  },
  {
    id: 130432,
    pid: 1304,
    deep: 2,
    name: "广平",
  },
  {
    id: 130433,
    pid: 1304,
    deep: 2,
    name: "馆陶",
  },
  {
    id: 130434,
    pid: 1304,
    deep: 2,
    name: "魏县",
  },
  {
    id: 130435,
    pid: 1304,
    deep: 2,
    name: "曲周",
  },
  {
    id: 130481,
    pid: 1304,
    deep: 2,
    name: "武安",
  },
  {
    id: 130502,
    pid: 1305,
    deep: 2,
    name: "桥东",
  },
  {
    id: 130503,
    pid: 1305,
    deep: 2,
    name: "桥西",
  },
  {
    id: 130521,
    pid: 1305,
    deep: 2,
    name: "邢台县",
  },
  {
    id: 130522,
    pid: 1305,
    deep: 2,
    name: "临城",
  },
  {
    id: 130523,
    pid: 1305,
    deep: 2,
    name: "内丘",
  },
  {
    id: 130524,
    pid: 1305,
    deep: 2,
    name: "柏乡",
  },
  {
    id: 130525,
    pid: 1305,
    deep: 2,
    name: "隆尧",
  },
  {
    id: 130526,
    pid: 1305,
    deep: 2,
    name: "任县",
  },
  {
    id: 130527,
    pid: 1305,
    deep: 2,
    name: "南和",
  },
  {
    id: 130528,
    pid: 1305,
    deep: 2,
    name: "宁晋",
  },
  {
    id: 130529,
    pid: 1305,
    deep: 2,
    name: "巨鹿",
  },
  {
    id: 130530,
    pid: 1305,
    deep: 2,
    name: "新河",
  },
  {
    id: 130531,
    pid: 1305,
    deep: 2,
    name: "广宗",
  },
  {
    id: 130532,
    pid: 1305,
    deep: 2,
    name: "平乡",
  },
  {
    id: 130533,
    pid: 1305,
    deep: 2,
    name: "威县",
  },
  {
    id: 130534,
    pid: 1305,
    deep: 2,
    name: "清河",
  },
  {
    id: 130535,
    pid: 1305,
    deep: 2,
    name: "临西",
  },
  {
    id: 130581,
    pid: 1305,
    deep: 2,
    name: "南宫",
  },
  {
    id: 130582,
    pid: 1305,
    deep: 2,
    name: "沙河",
  },
  {
    id: 130602,
    pid: 1306,
    deep: 2,
    name: "竞秀",
  },
  {
    id: 130606,
    pid: 1306,
    deep: 2,
    name: "莲池",
  },
  {
    id: 130607,
    pid: 1306,
    deep: 2,
    name: "满城",
  },
  {
    id: 130608,
    pid: 1306,
    deep: 2,
    name: "清苑",
  },
  {
    id: 130609,
    pid: 1306,
    deep: 2,
    name: "徐水",
  },
  {
    id: 130623,
    pid: 1306,
    deep: 2,
    name: "涞水",
  },
  {
    id: 130624,
    pid: 1306,
    deep: 2,
    name: "阜平",
  },
  {
    id: 130626,
    pid: 1306,
    deep: 2,
    name: "定兴",
  },
  {
    id: 130627,
    pid: 1306,
    deep: 2,
    name: "唐县",
  },
  {
    id: 130628,
    pid: 1306,
    deep: 2,
    name: "高阳",
  },
  {
    id: 130629,
    pid: 1306,
    deep: 2,
    name: "容城",
  },
  {
    id: 130630,
    pid: 1306,
    deep: 2,
    name: "涞源",
  },
  {
    id: 130631,
    pid: 1306,
    deep: 2,
    name: "望都",
  },
  {
    id: 130632,
    pid: 1306,
    deep: 2,
    name: "安新",
  },
  {
    id: 130633,
    pid: 1306,
    deep: 2,
    name: "易县",
  },
  {
    id: 130634,
    pid: 1306,
    deep: 2,
    name: "曲阳",
  },
  {
    id: 130635,
    pid: 1306,
    deep: 2,
    name: "蠡县",
  },
  {
    id: 130636,
    pid: 1306,
    deep: 2,
    name: "顺平",
  },
  {
    id: 130637,
    pid: 1306,
    deep: 2,
    name: "博野",
  },
  {
    id: 130638,
    pid: 1306,
    deep: 2,
    name: "雄县",
  },
  {
    id: 130681,
    pid: 1306,
    deep: 2,
    name: "涿州",
  },
  {
    id: 130682,
    pid: 1306,
    deep: 2,
    name: "定州",
  },
  {
    id: 130683,
    pid: 1306,
    deep: 2,
    name: "安国",
  },
  {
    id: 130684,
    pid: 1306,
    deep: 2,
    name: "高碑店",
  },
  {
    id: 130702,
    pid: 1307,
    deep: 2,
    name: "桥东",
  },
  {
    id: 130703,
    pid: 1307,
    deep: 2,
    name: "桥西",
  },
  {
    id: 130705,
    pid: 1307,
    deep: 2,
    name: "宣化",
  },
  {
    id: 130706,
    pid: 1307,
    deep: 2,
    name: "下花园",
  },
  {
    id: 130708,
    pid: 1307,
    deep: 2,
    name: "万全",
  },
  {
    id: 130709,
    pid: 1307,
    deep: 2,
    name: "崇礼",
  },
  {
    id: 130722,
    pid: 1307,
    deep: 2,
    name: "张北",
  },
  {
    id: 130723,
    pid: 1307,
    deep: 2,
    name: "康保",
  },
  {
    id: 130724,
    pid: 1307,
    deep: 2,
    name: "沽源",
  },
  {
    id: 130725,
    pid: 1307,
    deep: 2,
    name: "尚义",
  },
  {
    id: 130726,
    pid: 1307,
    deep: 2,
    name: "蔚县",
  },
  {
    id: 130727,
    pid: 1307,
    deep: 2,
    name: "阳原",
  },
  {
    id: 130728,
    pid: 1307,
    deep: 2,
    name: "怀安",
  },
  {
    id: 130730,
    pid: 1307,
    deep: 2,
    name: "怀来",
  },
  {
    id: 130731,
    pid: 1307,
    deep: 2,
    name: "涿鹿",
  },
  {
    id: 130732,
    pid: 1307,
    deep: 2,
    name: "赤城",
  },
  {
    id: 130802,
    pid: 1308,
    deep: 2,
    name: "双桥",
  },
  {
    id: 130803,
    pid: 1308,
    deep: 2,
    name: "双滦",
  },
  {
    id: 130804,
    pid: 1308,
    deep: 2,
    name: "鹰手营子矿区",
  },
  {
    id: 130821,
    pid: 1308,
    deep: 2,
    name: "承德县",
  },
  {
    id: 130822,
    pid: 1308,
    deep: 2,
    name: "兴隆",
  },
  {
    id: 130824,
    pid: 1308,
    deep: 2,
    name: "滦平",
  },
  {
    id: 130825,
    pid: 1308,
    deep: 2,
    name: "隆化",
  },
  {
    id: 130826,
    pid: 1308,
    deep: 2,
    name: "丰宁",
  },
  {
    id: 130827,
    pid: 1308,
    deep: 2,
    name: "宽城",
  },
  {
    id: 130828,
    pid: 1308,
    deep: 2,
    name: "围场",
  },
  {
    id: 130881,
    pid: 1308,
    deep: 2,
    name: "平泉",
  },
  {
    id: 130902,
    pid: 1309,
    deep: 2,
    name: "新华",
  },
  {
    id: 130903,
    pid: 1309,
    deep: 2,
    name: "运河",
  },
  {
    id: 130921,
    pid: 1309,
    deep: 2,
    name: "沧县",
  },
  {
    id: 130922,
    pid: 1309,
    deep: 2,
    name: "青县",
  },
  {
    id: 130923,
    pid: 1309,
    deep: 2,
    name: "东光",
  },
  {
    id: 130924,
    pid: 1309,
    deep: 2,
    name: "海兴",
  },
  {
    id: 130925,
    pid: 1309,
    deep: 2,
    name: "盐山",
  },
  {
    id: 130926,
    pid: 1309,
    deep: 2,
    name: "肃宁",
  },
  {
    id: 130927,
    pid: 1309,
    deep: 2,
    name: "南皮",
  },
  {
    id: 130928,
    pid: 1309,
    deep: 2,
    name: "吴桥",
  },
  {
    id: 130929,
    pid: 1309,
    deep: 2,
    name: "献县",
  },
  {
    id: 130930,
    pid: 1309,
    deep: 2,
    name: "孟村",
  },
  {
    id: 130981,
    pid: 1309,
    deep: 2,
    name: "泊头",
  },
  {
    id: 130982,
    pid: 1309,
    deep: 2,
    name: "任丘",
  },
  {
    id: 130983,
    pid: 1309,
    deep: 2,
    name: "黄骅",
  },
  {
    id: 130984,
    pid: 1309,
    deep: 2,
    name: "河间",
  },
  {
    id: 131002,
    pid: 1310,
    deep: 2,
    name: "安次",
  },
  {
    id: 131003,
    pid: 1310,
    deep: 2,
    name: "广阳",
  },
  {
    id: 131022,
    pid: 1310,
    deep: 2,
    name: "固安",
  },
  {
    id: 131023,
    pid: 1310,
    deep: 2,
    name: "永清",
  },
  {
    id: 131024,
    pid: 1310,
    deep: 2,
    name: "香河",
  },
  {
    id: 131025,
    pid: 1310,
    deep: 2,
    name: "大城",
  },
  {
    id: 131026,
    pid: 1310,
    deep: 2,
    name: "文安",
  },
  {
    id: 131028,
    pid: 1310,
    deep: 2,
    name: "大厂",
  },
  {
    id: 131081,
    pid: 1310,
    deep: 2,
    name: "霸州",
  },
  {
    id: 131082,
    pid: 1310,
    deep: 2,
    name: "三河",
  },
  {
    id: 131102,
    pid: 1311,
    deep: 2,
    name: "桃城",
  },
  {
    id: 131103,
    pid: 1311,
    deep: 2,
    name: "冀州",
  },
  {
    id: 131121,
    pid: 1311,
    deep: 2,
    name: "枣强",
  },
  {
    id: 131122,
    pid: 1311,
    deep: 2,
    name: "武邑",
  },
  {
    id: 131123,
    pid: 1311,
    deep: 2,
    name: "武强",
  },
  {
    id: 131124,
    pid: 1311,
    deep: 2,
    name: "饶阳",
  },
  {
    id: 131125,
    pid: 1311,
    deep: 2,
    name: "安平",
  },
  {
    id: 131126,
    pid: 1311,
    deep: 2,
    name: "故城",
  },
  {
    id: 131127,
    pid: 1311,
    deep: 2,
    name: "景县",
  },
  {
    id: 131128,
    pid: 1311,
    deep: 2,
    name: "阜城",
  },
  {
    id: 131182,
    pid: 1311,
    deep: 2,
    name: "深州",
  },
  {
    id: 140105,
    pid: 1401,
    deep: 2,
    name: "小店",
  },
  {
    id: 140106,
    pid: 1401,
    deep: 2,
    name: "迎泽",
  },
  {
    id: 140107,
    pid: 1401,
    deep: 2,
    name: "杏花岭",
  },
  {
    id: 140108,
    pid: 1401,
    deep: 2,
    name: "尖草坪",
  },
  {
    id: 140109,
    pid: 1401,
    deep: 2,
    name: "万柏林",
  },
  {
    id: 140110,
    pid: 1401,
    deep: 2,
    name: "晋源",
  },
  {
    id: 140121,
    pid: 1401,
    deep: 2,
    name: "清徐",
  },
  {
    id: 140122,
    pid: 1401,
    deep: 2,
    name: "阳曲",
  },
  {
    id: 140123,
    pid: 1401,
    deep: 2,
    name: "娄烦",
  },
  {
    id: 140181,
    pid: 1401,
    deep: 2,
    name: "古交",
  },
  {
    id: 140212,
    pid: 1402,
    deep: 2,
    name: "新荣",
  },
  {
    id: 140213,
    pid: 1402,
    deep: 2,
    name: "平城",
  },
  {
    id: 140214,
    pid: 1402,
    deep: 2,
    name: "云冈",
  },
  {
    id: 140215,
    pid: 1402,
    deep: 2,
    name: "云州",
  },
  {
    id: 140221,
    pid: 1402,
    deep: 2,
    name: "阳高",
  },
  {
    id: 140222,
    pid: 1402,
    deep: 2,
    name: "天镇",
  },
  {
    id: 140223,
    pid: 1402,
    deep: 2,
    name: "广灵",
  },
  {
    id: 140224,
    pid: 1402,
    deep: 2,
    name: "灵丘",
  },
  {
    id: 140225,
    pid: 1402,
    deep: 2,
    name: "浑源",
  },
  {
    id: 140226,
    pid: 1402,
    deep: 2,
    name: "左云",
  },
  {
    id: 140302,
    pid: 1403,
    deep: 2,
    name: "城区",
  },
  {
    id: 140303,
    pid: 1403,
    deep: 2,
    name: "矿区",
  },
  {
    id: 140311,
    pid: 1403,
    deep: 2,
    name: "郊区",
  },
  {
    id: 140321,
    pid: 1403,
    deep: 2,
    name: "平定",
  },
  {
    id: 140322,
    pid: 1403,
    deep: 2,
    name: "盂县",
  },
  {
    id: 140403,
    pid: 1404,
    deep: 2,
    name: "潞州",
  },
  {
    id: 140404,
    pid: 1404,
    deep: 2,
    name: "上党",
  },
  {
    id: 140405,
    pid: 1404,
    deep: 2,
    name: "屯留",
  },
  {
    id: 140406,
    pid: 1404,
    deep: 2,
    name: "潞城",
  },
  {
    id: 140423,
    pid: 1404,
    deep: 2,
    name: "襄垣",
  },
  {
    id: 140425,
    pid: 1404,
    deep: 2,
    name: "平顺",
  },
  {
    id: 140426,
    pid: 1404,
    deep: 2,
    name: "黎城",
  },
  {
    id: 140427,
    pid: 1404,
    deep: 2,
    name: "壶关",
  },
  {
    id: 140428,
    pid: 1404,
    deep: 2,
    name: "长子",
  },
  {
    id: 140429,
    pid: 1404,
    deep: 2,
    name: "武乡",
  },
  {
    id: 140430,
    pid: 1404,
    deep: 2,
    name: "沁县",
  },
  {
    id: 140431,
    pid: 1404,
    deep: 2,
    name: "沁源",
  },
  {
    id: 140502,
    pid: 1405,
    deep: 2,
    name: "城区",
  },
  {
    id: 140521,
    pid: 1405,
    deep: 2,
    name: "沁水",
  },
  {
    id: 140522,
    pid: 1405,
    deep: 2,
    name: "阳城",
  },
  {
    id: 140524,
    pid: 1405,
    deep: 2,
    name: "陵川",
  },
  {
    id: 140525,
    pid: 1405,
    deep: 2,
    name: "泽州",
  },
  {
    id: 140581,
    pid: 1405,
    deep: 2,
    name: "高平",
  },
  {
    id: 140602,
    pid: 1406,
    deep: 2,
    name: "朔城",
  },
  {
    id: 140603,
    pid: 1406,
    deep: 2,
    name: "平鲁",
  },
  {
    id: 140621,
    pid: 1406,
    deep: 2,
    name: "山阴",
  },
  {
    id: 140622,
    pid: 1406,
    deep: 2,
    name: "应县",
  },
  {
    id: 140623,
    pid: 1406,
    deep: 2,
    name: "右玉",
  },
  {
    id: 140681,
    pid: 1406,
    deep: 2,
    name: "怀仁",
  },
  {
    id: 140702,
    pid: 1407,
    deep: 2,
    name: "榆次",
  },
  {
    id: 140721,
    pid: 1407,
    deep: 2,
    name: "榆社",
  },
  {
    id: 140722,
    pid: 1407,
    deep: 2,
    name: "左权",
  },
  {
    id: 140723,
    pid: 1407,
    deep: 2,
    name: "和顺",
  },
  {
    id: 140724,
    pid: 1407,
    deep: 2,
    name: "昔阳",
  },
  {
    id: 140725,
    pid: 1407,
    deep: 2,
    name: "寿阳",
  },
  {
    id: 140726,
    pid: 1407,
    deep: 2,
    name: "太谷",
  },
  {
    id: 140727,
    pid: 1407,
    deep: 2,
    name: "祁县",
  },
  {
    id: 140728,
    pid: 1407,
    deep: 2,
    name: "平遥",
  },
  {
    id: 140729,
    pid: 1407,
    deep: 2,
    name: "灵石",
  },
  {
    id: 140781,
    pid: 1407,
    deep: 2,
    name: "介休",
  },
  {
    id: 140802,
    pid: 1408,
    deep: 2,
    name: "盐湖",
  },
  {
    id: 140821,
    pid: 1408,
    deep: 2,
    name: "临猗",
  },
  {
    id: 140822,
    pid: 1408,
    deep: 2,
    name: "万荣",
  },
  {
    id: 140823,
    pid: 1408,
    deep: 2,
    name: "闻喜",
  },
  {
    id: 140824,
    pid: 1408,
    deep: 2,
    name: "稷山",
  },
  {
    id: 140825,
    pid: 1408,
    deep: 2,
    name: "新绛",
  },
  {
    id: 140826,
    pid: 1408,
    deep: 2,
    name: "绛县",
  },
  {
    id: 140827,
    pid: 1408,
    deep: 2,
    name: "垣曲",
  },
  {
    id: 140828,
    pid: 1408,
    deep: 2,
    name: "夏县",
  },
  {
    id: 140829,
    pid: 1408,
    deep: 2,
    name: "平陆",
  },
  {
    id: 140830,
    pid: 1408,
    deep: 2,
    name: "芮城",
  },
  {
    id: 140881,
    pid: 1408,
    deep: 2,
    name: "永济",
  },
  {
    id: 140882,
    pid: 1408,
    deep: 2,
    name: "河津",
  },
  {
    id: 140902,
    pid: 1409,
    deep: 2,
    name: "忻府",
  },
  {
    id: 140921,
    pid: 1409,
    deep: 2,
    name: "定襄",
  },
  {
    id: 140922,
    pid: 1409,
    deep: 2,
    name: "五台",
  },
  {
    id: 140923,
    pid: 1409,
    deep: 2,
    name: "代县",
  },
  {
    id: 140924,
    pid: 1409,
    deep: 2,
    name: "繁峙",
  },
  {
    id: 140925,
    pid: 1409,
    deep: 2,
    name: "宁武",
  },
  {
    id: 140926,
    pid: 1409,
    deep: 2,
    name: "静乐",
  },
  {
    id: 140927,
    pid: 1409,
    deep: 2,
    name: "神池",
  },
  {
    id: 140928,
    pid: 1409,
    deep: 2,
    name: "五寨",
  },
  {
    id: 140929,
    pid: 1409,
    deep: 2,
    name: "岢岚",
  },
  {
    id: 140930,
    pid: 1409,
    deep: 2,
    name: "河曲",
  },
  {
    id: 140931,
    pid: 1409,
    deep: 2,
    name: "保德",
  },
  {
    id: 140932,
    pid: 1409,
    deep: 2,
    name: "偏关",
  },
  {
    id: 140981,
    pid: 1409,
    deep: 2,
    name: "原平",
  },
  {
    id: 141002,
    pid: 1410,
    deep: 2,
    name: "尧都",
  },
  {
    id: 141021,
    pid: 1410,
    deep: 2,
    name: "曲沃",
  },
  {
    id: 141022,
    pid: 1410,
    deep: 2,
    name: "翼城",
  },
  {
    id: 141023,
    pid: 1410,
    deep: 2,
    name: "襄汾",
  },
  {
    id: 141024,
    pid: 1410,
    deep: 2,
    name: "洪洞",
  },
  {
    id: 141025,
    pid: 1410,
    deep: 2,
    name: "古县",
  },
  {
    id: 141026,
    pid: 1410,
    deep: 2,
    name: "安泽",
  },
  {
    id: 141027,
    pid: 1410,
    deep: 2,
    name: "浮山",
  },
  {
    id: 141028,
    pid: 1410,
    deep: 2,
    name: "吉县",
  },
  {
    id: 141029,
    pid: 1410,
    deep: 2,
    name: "乡宁",
  },
  {
    id: 141030,
    pid: 1410,
    deep: 2,
    name: "大宁",
  },
  {
    id: 141031,
    pid: 1410,
    deep: 2,
    name: "隰县",
  },
  {
    id: 141032,
    pid: 1410,
    deep: 2,
    name: "永和",
  },
  {
    id: 141033,
    pid: 1410,
    deep: 2,
    name: "蒲县",
  },
  {
    id: 141034,
    pid: 1410,
    deep: 2,
    name: "汾西",
  },
  {
    id: 141081,
    pid: 1410,
    deep: 2,
    name: "侯马",
  },
  {
    id: 141082,
    pid: 1410,
    deep: 2,
    name: "霍州",
  },
  {
    id: 141102,
    pid: 1411,
    deep: 2,
    name: "离石",
  },
  {
    id: 141121,
    pid: 1411,
    deep: 2,
    name: "文水",
  },
  {
    id: 141122,
    pid: 1411,
    deep: 2,
    name: "交城",
  },
  {
    id: 141123,
    pid: 1411,
    deep: 2,
    name: "兴县",
  },
  {
    id: 141124,
    pid: 1411,
    deep: 2,
    name: "临县",
  },
  {
    id: 141125,
    pid: 1411,
    deep: 2,
    name: "柳林",
  },
  {
    id: 141126,
    pid: 1411,
    deep: 2,
    name: "石楼",
  },
  {
    id: 141127,
    pid: 1411,
    deep: 2,
    name: "岚县",
  },
  {
    id: 141128,
    pid: 1411,
    deep: 2,
    name: "方山",
  },
  {
    id: 141129,
    pid: 1411,
    deep: 2,
    name: "中阳",
  },
  {
    id: 141130,
    pid: 1411,
    deep: 2,
    name: "交口",
  },
  {
    id: 141181,
    pid: 1411,
    deep: 2,
    name: "孝义",
  },
  {
    id: 141182,
    pid: 1411,
    deep: 2,
    name: "汾阳",
  },
  {
    id: 150102,
    pid: 1501,
    deep: 2,
    name: "新城",
  },
  {
    id: 150103,
    pid: 1501,
    deep: 2,
    name: "回民",
  },
  {
    id: 150104,
    pid: 1501,
    deep: 2,
    name: "玉泉",
  },
  {
    id: 150105,
    pid: 1501,
    deep: 2,
    name: "赛罕",
  },
  {
    id: 150121,
    pid: 1501,
    deep: 2,
    name: "土默特左旗",
  },
  {
    id: 150122,
    pid: 1501,
    deep: 2,
    name: "托克托",
  },
  {
    id: 150123,
    pid: 1501,
    deep: 2,
    name: "和林格尔",
  },
  {
    id: 150124,
    pid: 1501,
    deep: 2,
    name: "清水河",
  },
  {
    id: 150125,
    pid: 1501,
    deep: 2,
    name: "武川",
  },
  {
    id: 150202,
    pid: 1502,
    deep: 2,
    name: "东河",
  },
  {
    id: 150203,
    pid: 1502,
    deep: 2,
    name: "昆都仑",
  },
  {
    id: 150204,
    pid: 1502,
    deep: 2,
    name: "青山",
  },
  {
    id: 150205,
    pid: 1502,
    deep: 2,
    name: "石拐",
  },
  {
    id: 150206,
    pid: 1502,
    deep: 2,
    name: "白云鄂博矿区",
  },
  {
    id: 150207,
    pid: 1502,
    deep: 2,
    name: "九原",
  },
  {
    id: 150221,
    pid: 1502,
    deep: 2,
    name: "土默特右旗",
  },
  {
    id: 150222,
    pid: 1502,
    deep: 2,
    name: "固阳",
  },
  {
    id: 150223,
    pid: 1502,
    deep: 2,
    name: "达尔罕茂明安联合旗",
  },
  {
    id: 150302,
    pid: 1503,
    deep: 2,
    name: "海勃湾",
  },
  {
    id: 150303,
    pid: 1503,
    deep: 2,
    name: "海南",
  },
  {
    id: 150304,
    pid: 1503,
    deep: 2,
    name: "乌达",
  },
  {
    id: 150402,
    pid: 1504,
    deep: 2,
    name: "红山",
  },
  {
    id: 150403,
    pid: 1504,
    deep: 2,
    name: "元宝山",
  },
  {
    id: 150404,
    pid: 1504,
    deep: 2,
    name: "松山",
  },
  {
    id: 150421,
    pid: 1504,
    deep: 2,
    name: "阿鲁科尔沁旗",
  },
  {
    id: 150422,
    pid: 1504,
    deep: 2,
    name: "巴林左旗",
  },
  {
    id: 150423,
    pid: 1504,
    deep: 2,
    name: "巴林右旗",
  },
  {
    id: 150424,
    pid: 1504,
    deep: 2,
    name: "林西",
  },
  {
    id: 150425,
    pid: 1504,
    deep: 2,
    name: "克什克腾旗",
  },
  {
    id: 150426,
    pid: 1504,
    deep: 2,
    name: "翁牛特旗",
  },
  {
    id: 150428,
    pid: 1504,
    deep: 2,
    name: "喀喇沁旗",
  },
  {
    id: 150429,
    pid: 1504,
    deep: 2,
    name: "宁城",
  },
  {
    id: 150430,
    pid: 1504,
    deep: 2,
    name: "敖汉旗",
  },
  {
    id: 150502,
    pid: 1505,
    deep: 2,
    name: "科尔沁",
  },
  {
    id: 150521,
    pid: 1505,
    deep: 2,
    name: "科尔沁左翼中旗",
  },
  {
    id: 150522,
    pid: 1505,
    deep: 2,
    name: "科尔沁左翼后旗",
  },
  {
    id: 150523,
    pid: 1505,
    deep: 2,
    name: "开鲁",
  },
  {
    id: 150524,
    pid: 1505,
    deep: 2,
    name: "库伦旗",
  },
  {
    id: 150525,
    pid: 1505,
    deep: 2,
    name: "奈曼旗",
  },
  {
    id: 150526,
    pid: 1505,
    deep: 2,
    name: "扎鲁特旗",
  },
  {
    id: 150581,
    pid: 1505,
    deep: 2,
    name: "霍林郭勒",
  },
  {
    id: 150602,
    pid: 1506,
    deep: 2,
    name: "东胜",
  },
  {
    id: 150603,
    pid: 1506,
    deep: 2,
    name: "康巴什",
  },
  {
    id: 150621,
    pid: 1506,
    deep: 2,
    name: "达拉特旗",
  },
  {
    id: 150622,
    pid: 1506,
    deep: 2,
    name: "准格尔旗",
  },
  {
    id: 150623,
    pid: 1506,
    deep: 2,
    name: "鄂托克前旗",
  },
  {
    id: 150624,
    pid: 1506,
    deep: 2,
    name: "鄂托克旗",
  },
  {
    id: 150625,
    pid: 1506,
    deep: 2,
    name: "杭锦旗",
  },
  {
    id: 150626,
    pid: 1506,
    deep: 2,
    name: "乌审旗",
  },
  {
    id: 150627,
    pid: 1506,
    deep: 2,
    name: "伊金霍洛旗",
  },
  {
    id: 150702,
    pid: 1507,
    deep: 2,
    name: "海拉尔",
  },
  {
    id: 150703,
    pid: 1507,
    deep: 2,
    name: "扎赉诺尔区",
  },
  {
    id: 150721,
    pid: 1507,
    deep: 2,
    name: "阿荣旗",
  },
  {
    id: 150722,
    pid: 1507,
    deep: 2,
    name: "莫力达瓦",
  },
  {
    id: 150723,
    pid: 1507,
    deep: 2,
    name: "鄂伦春自治旗",
  },
  {
    id: 150724,
    pid: 1507,
    deep: 2,
    name: "鄂温克族自治旗",
  },
  {
    id: 150725,
    pid: 1507,
    deep: 2,
    name: "陈巴尔虎旗",
  },
  {
    id: 150726,
    pid: 1507,
    deep: 2,
    name: "新巴尔虎左旗",
  },
  {
    id: 150727,
    pid: 1507,
    deep: 2,
    name: "新巴尔虎右旗",
  },
  {
    id: 150781,
    pid: 1507,
    deep: 2,
    name: "满洲里",
  },
  {
    id: 150782,
    pid: 1507,
    deep: 2,
    name: "牙克石",
  },
  {
    id: 150783,
    pid: 1507,
    deep: 2,
    name: "扎兰屯",
  },
  {
    id: 150784,
    pid: 1507,
    deep: 2,
    name: "额尔古纳",
  },
  {
    id: 150785,
    pid: 1507,
    deep: 2,
    name: "根河",
  },
  {
    id: 150802,
    pid: 1508,
    deep: 2,
    name: "临河",
  },
  {
    id: 150821,
    pid: 1508,
    deep: 2,
    name: "五原",
  },
  {
    id: 150822,
    pid: 1508,
    deep: 2,
    name: "磴口",
  },
  {
    id: 150823,
    pid: 1508,
    deep: 2,
    name: "乌拉特前旗",
  },
  {
    id: 150824,
    pid: 1508,
    deep: 2,
    name: "乌拉特中旗",
  },
  {
    id: 150825,
    pid: 1508,
    deep: 2,
    name: "乌拉特后旗",
  },
  {
    id: 150826,
    pid: 1508,
    deep: 2,
    name: "杭锦后旗",
  },
  {
    id: 150902,
    pid: 1509,
    deep: 2,
    name: "集宁",
  },
  {
    id: 150921,
    pid: 1509,
    deep: 2,
    name: "卓资",
  },
  {
    id: 150922,
    pid: 1509,
    deep: 2,
    name: "化德",
  },
  {
    id: 150923,
    pid: 1509,
    deep: 2,
    name: "商都",
  },
  {
    id: 150924,
    pid: 1509,
    deep: 2,
    name: "兴和",
  },
  {
    id: 150925,
    pid: 1509,
    deep: 2,
    name: "凉城",
  },
  {
    id: 150926,
    pid: 1509,
    deep: 2,
    name: "察哈尔右翼前旗",
  },
  {
    id: 150927,
    pid: 1509,
    deep: 2,
    name: "察哈尔右翼中旗",
  },
  {
    id: 150928,
    pid: 1509,
    deep: 2,
    name: "察哈尔右翼后旗",
  },
  {
    id: 150929,
    pid: 1509,
    deep: 2,
    name: "四子王旗",
  },
  {
    id: 150981,
    pid: 1509,
    deep: 2,
    name: "丰镇",
  },
  {
    id: 152201,
    pid: 1522,
    deep: 2,
    name: "乌兰浩特",
  },
  {
    id: 152202,
    pid: 1522,
    deep: 2,
    name: "阿尔山",
  },
  {
    id: 152221,
    pid: 1522,
    deep: 2,
    name: "科尔沁右翼前旗",
  },
  {
    id: 152222,
    pid: 1522,
    deep: 2,
    name: "科尔沁右翼中旗",
  },
  {
    id: 152223,
    pid: 1522,
    deep: 2,
    name: "扎赉特旗",
  },
  {
    id: 152224,
    pid: 1522,
    deep: 2,
    name: "突泉",
  },
  {
    id: 152501,
    pid: 1525,
    deep: 2,
    name: "二连浩特",
  },
  {
    id: 152502,
    pid: 1525,
    deep: 2,
    name: "锡林浩特",
  },
  {
    id: 152522,
    pid: 1525,
    deep: 2,
    name: "阿巴嘎旗",
  },
  {
    id: 152523,
    pid: 1525,
    deep: 2,
    name: "苏尼特左旗",
  },
  {
    id: 152524,
    pid: 1525,
    deep: 2,
    name: "苏尼特右旗",
  },
  {
    id: 152525,
    pid: 1525,
    deep: 2,
    name: "东乌珠穆沁旗",
  },
  {
    id: 152526,
    pid: 1525,
    deep: 2,
    name: "西乌珠穆沁旗",
  },
  {
    id: 152527,
    pid: 1525,
    deep: 2,
    name: "太仆寺旗",
  },
  {
    id: 152528,
    pid: 1525,
    deep: 2,
    name: "镶黄旗",
  },
  {
    id: 152529,
    pid: 1525,
    deep: 2,
    name: "正镶白旗",
  },
  {
    id: 152530,
    pid: 1525,
    deep: 2,
    name: "正蓝旗",
  },
  {
    id: 152531,
    pid: 1525,
    deep: 2,
    name: "多伦",
  },
  {
    id: 152921,
    pid: 1529,
    deep: 2,
    name: "阿拉善左旗",
  },
  {
    id: 152922,
    pid: 1529,
    deep: 2,
    name: "阿拉善右旗",
  },
  {
    id: 152923,
    pid: 1529,
    deep: 2,
    name: "额济纳旗",
  },
  {
    id: 210102,
    pid: 2101,
    deep: 2,
    name: "和平",
  },
  {
    id: 210103,
    pid: 2101,
    deep: 2,
    name: "沈河",
  },
  {
    id: 210104,
    pid: 2101,
    deep: 2,
    name: "大东",
  },
  {
    id: 210105,
    pid: 2101,
    deep: 2,
    name: "皇姑",
  },
  {
    id: 210106,
    pid: 2101,
    deep: 2,
    name: "铁西",
  },
  {
    id: 210111,
    pid: 2101,
    deep: 2,
    name: "苏家屯",
  },
  {
    id: 210112,
    pid: 2101,
    deep: 2,
    name: "浑南",
  },
  {
    id: 210113,
    pid: 2101,
    deep: 2,
    name: "沈北新区",
  },
  {
    id: 210114,
    pid: 2101,
    deep: 2,
    name: "于洪",
  },
  {
    id: 210115,
    pid: 2101,
    deep: 2,
    name: "辽中",
  },
  {
    id: 210123,
    pid: 2101,
    deep: 2,
    name: "康平",
  },
  {
    id: 210124,
    pid: 2101,
    deep: 2,
    name: "法库",
  },
  {
    id: 210181,
    pid: 2101,
    deep: 2,
    name: "新民",
  },
  {
    id: 210202,
    pid: 2102,
    deep: 2,
    name: "中山",
  },
  {
    id: 210203,
    pid: 2102,
    deep: 2,
    name: "西岗",
  },
  {
    id: 210204,
    pid: 2102,
    deep: 2,
    name: "沙河口",
  },
  {
    id: 210211,
    pid: 2102,
    deep: 2,
    name: "甘井子",
  },
  {
    id: 210212,
    pid: 2102,
    deep: 2,
    name: "旅顺口",
  },
  {
    id: 210213,
    pid: 2102,
    deep: 2,
    name: "金州",
  },
  {
    id: 210214,
    pid: 2102,
    deep: 2,
    name: "普兰店",
  },
  {
    id: 210224,
    pid: 2102,
    deep: 2,
    name: "长海",
  },
  {
    id: 210281,
    pid: 2102,
    deep: 2,
    name: "瓦房店",
  },
  {
    id: 210283,
    pid: 2102,
    deep: 2,
    name: "庄河",
  },
  {
    id: 210302,
    pid: 2103,
    deep: 2,
    name: "铁东",
  },
  {
    id: 210303,
    pid: 2103,
    deep: 2,
    name: "铁西",
  },
  {
    id: 210304,
    pid: 2103,
    deep: 2,
    name: "立山",
  },
  {
    id: 210311,
    pid: 2103,
    deep: 2,
    name: "千山",
  },
  {
    id: 210321,
    pid: 2103,
    deep: 2,
    name: "台安",
  },
  {
    id: 210323,
    pid: 2103,
    deep: 2,
    name: "岫岩",
  },
  {
    id: 210381,
    pid: 2103,
    deep: 2,
    name: "海城",
  },
  {
    id: 210402,
    pid: 2104,
    deep: 2,
    name: "新抚",
  },
  {
    id: 210403,
    pid: 2104,
    deep: 2,
    name: "东洲",
  },
  {
    id: 210404,
    pid: 2104,
    deep: 2,
    name: "望花",
  },
  {
    id: 210411,
    pid: 2104,
    deep: 2,
    name: "顺城",
  },
  {
    id: 210421,
    pid: 2104,
    deep: 2,
    name: "抚顺县",
  },
  {
    id: 210422,
    pid: 2104,
    deep: 2,
    name: "新宾",
  },
  {
    id: 210423,
    pid: 2104,
    deep: 2,
    name: "清原",
  },
  {
    id: 210502,
    pid: 2105,
    deep: 2,
    name: "平山",
  },
  {
    id: 210503,
    pid: 2105,
    deep: 2,
    name: "溪湖",
  },
  {
    id: 210504,
    pid: 2105,
    deep: 2,
    name: "明山",
  },
  {
    id: 210505,
    pid: 2105,
    deep: 2,
    name: "南芬",
  },
  {
    id: 210521,
    pid: 2105,
    deep: 2,
    name: "本溪满族自治县",
  },
  {
    id: 210522,
    pid: 2105,
    deep: 2,
    name: "桓仁",
  },
  {
    id: 210602,
    pid: 2106,
    deep: 2,
    name: "元宝",
  },
  {
    id: 210603,
    pid: 2106,
    deep: 2,
    name: "振兴",
  },
  {
    id: 210604,
    pid: 2106,
    deep: 2,
    name: "振安",
  },
  {
    id: 210624,
    pid: 2106,
    deep: 2,
    name: "宽甸",
  },
  {
    id: 210681,
    pid: 2106,
    deep: 2,
    name: "东港",
  },
  {
    id: 210682,
    pid: 2106,
    deep: 2,
    name: "凤城",
  },
  {
    id: 210702,
    pid: 2107,
    deep: 2,
    name: "古塔",
  },
  {
    id: 210703,
    pid: 2107,
    deep: 2,
    name: "凌河",
  },
  {
    id: 210711,
    pid: 2107,
    deep: 2,
    name: "太和",
  },
  {
    id: 210726,
    pid: 2107,
    deep: 2,
    name: "黑山",
  },
  {
    id: 210727,
    pid: 2107,
    deep: 2,
    name: "义县",
  },
  {
    id: 210781,
    pid: 2107,
    deep: 2,
    name: "凌海",
  },
  {
    id: 210782,
    pid: 2107,
    deep: 2,
    name: "北镇",
  },
  {
    id: 210802,
    pid: 2108,
    deep: 2,
    name: "站前",
  },
  {
    id: 210803,
    pid: 2108,
    deep: 2,
    name: "西市",
  },
  {
    id: 210804,
    pid: 2108,
    deep: 2,
    name: "鲅鱼圈",
  },
  {
    id: 210811,
    pid: 2108,
    deep: 2,
    name: "老边",
  },
  {
    id: 210881,
    pid: 2108,
    deep: 2,
    name: "盖州",
  },
  {
    id: 210882,
    pid: 2108,
    deep: 2,
    name: "大石桥",
  },
  {
    id: 210902,
    pid: 2109,
    deep: 2,
    name: "海州",
  },
  {
    id: 210903,
    pid: 2109,
    deep: 2,
    name: "新邱",
  },
  {
    id: 210904,
    pid: 2109,
    deep: 2,
    name: "太平",
  },
  {
    id: 210905,
    pid: 2109,
    deep: 2,
    name: "清河门",
  },
  {
    id: 210911,
    pid: 2109,
    deep: 2,
    name: "细河",
  },
  {
    id: 210921,
    pid: 2109,
    deep: 2,
    name: "阜新蒙古族自治县",
  },
  {
    id: 210922,
    pid: 2109,
    deep: 2,
    name: "彰武",
  },
  {
    id: 211002,
    pid: 2110,
    deep: 2,
    name: "白塔",
  },
  {
    id: 211003,
    pid: 2110,
    deep: 2,
    name: "文圣",
  },
  {
    id: 211004,
    pid: 2110,
    deep: 2,
    name: "宏伟",
  },
  {
    id: 211005,
    pid: 2110,
    deep: 2,
    name: "弓长岭",
  },
  {
    id: 211011,
    pid: 2110,
    deep: 2,
    name: "太子河",
  },
  {
    id: 211021,
    pid: 2110,
    deep: 2,
    name: "辽阳县",
  },
  {
    id: 211081,
    pid: 2110,
    deep: 2,
    name: "灯塔",
  },
  {
    id: 211102,
    pid: 2111,
    deep: 2,
    name: "双台子",
  },
  {
    id: 211103,
    pid: 2111,
    deep: 2,
    name: "兴隆台",
  },
  {
    id: 211104,
    pid: 2111,
    deep: 2,
    name: "大洼",
  },
  {
    id: 211122,
    pid: 2111,
    deep: 2,
    name: "盘山",
  },
  {
    id: 211202,
    pid: 2112,
    deep: 2,
    name: "银州",
  },
  {
    id: 211204,
    pid: 2112,
    deep: 2,
    name: "清河",
  },
  {
    id: 211221,
    pid: 2112,
    deep: 2,
    name: "铁岭县",
  },
  {
    id: 211223,
    pid: 2112,
    deep: 2,
    name: "西丰",
  },
  {
    id: 211224,
    pid: 2112,
    deep: 2,
    name: "昌图",
  },
  {
    id: 211281,
    pid: 2112,
    deep: 2,
    name: "调兵山",
  },
  {
    id: 211282,
    pid: 2112,
    deep: 2,
    name: "开原",
  },
  {
    id: 211302,
    pid: 2113,
    deep: 2,
    name: "双塔",
  },
  {
    id: 211303,
    pid: 2113,
    deep: 2,
    name: "龙城",
  },
  {
    id: 211321,
    pid: 2113,
    deep: 2,
    name: "朝阳县",
  },
  {
    id: 211322,
    pid: 2113,
    deep: 2,
    name: "建平",
  },
  {
    id: 211324,
    pid: 2113,
    deep: 2,
    name: "喀喇沁左翼",
  },
  {
    id: 211381,
    pid: 2113,
    deep: 2,
    name: "北票",
  },
  {
    id: 211382,
    pid: 2113,
    deep: 2,
    name: "凌源",
  },
  {
    id: 211402,
    pid: 2114,
    deep: 2,
    name: "连山",
  },
  {
    id: 211403,
    pid: 2114,
    deep: 2,
    name: "龙港",
  },
  {
    id: 211404,
    pid: 2114,
    deep: 2,
    name: "南票",
  },
  {
    id: 211421,
    pid: 2114,
    deep: 2,
    name: "绥中",
  },
  {
    id: 211422,
    pid: 2114,
    deep: 2,
    name: "建昌",
  },
  {
    id: 211481,
    pid: 2114,
    deep: 2,
    name: "兴城",
  },
  {
    id: 220102,
    pid: 2201,
    deep: 2,
    name: "南关",
  },
  {
    id: 220103,
    pid: 2201,
    deep: 2,
    name: "宽城",
  },
  {
    id: 220104,
    pid: 2201,
    deep: 2,
    name: "朝阳",
  },
  {
    id: 220105,
    pid: 2201,
    deep: 2,
    name: "二道",
  },
  {
    id: 220106,
    pid: 2201,
    deep: 2,
    name: "绿园",
  },
  {
    id: 220112,
    pid: 2201,
    deep: 2,
    name: "双阳",
  },
  {
    id: 220113,
    pid: 2201,
    deep: 2,
    name: "九台",
  },
  {
    id: 220122,
    pid: 2201,
    deep: 2,
    name: "农安",
  },
  {
    id: 220182,
    pid: 2201,
    deep: 2,
    name: "榆树",
  },
  {
    id: 220183,
    pid: 2201,
    deep: 2,
    name: "德惠",
  },
  {
    id: 220202,
    pid: 2202,
    deep: 2,
    name: "昌邑",
  },
  {
    id: 220203,
    pid: 2202,
    deep: 2,
    name: "龙潭",
  },
  {
    id: 220204,
    pid: 2202,
    deep: 2,
    name: "船营",
  },
  {
    id: 220211,
    pid: 2202,
    deep: 2,
    name: "丰满",
  },
  {
    id: 220221,
    pid: 2202,
    deep: 2,
    name: "永吉",
  },
  {
    id: 220281,
    pid: 2202,
    deep: 2,
    name: "蛟河",
  },
  {
    id: 220282,
    pid: 2202,
    deep: 2,
    name: "桦甸",
  },
  {
    id: 220283,
    pid: 2202,
    deep: 2,
    name: "舒兰",
  },
  {
    id: 220284,
    pid: 2202,
    deep: 2,
    name: "磐石",
  },
  {
    id: 220302,
    pid: 2203,
    deep: 2,
    name: "铁西",
  },
  {
    id: 220303,
    pid: 2203,
    deep: 2,
    name: "铁东",
  },
  {
    id: 220322,
    pid: 2203,
    deep: 2,
    name: "梨树",
  },
  {
    id: 220323,
    pid: 2203,
    deep: 2,
    name: "伊通",
  },
  {
    id: 220381,
    pid: 2203,
    deep: 2,
    name: "公主岭",
  },
  {
    id: 220382,
    pid: 2203,
    deep: 2,
    name: "双辽",
  },
  {
    id: 220402,
    pid: 2204,
    deep: 2,
    name: "龙山",
  },
  {
    id: 220403,
    pid: 2204,
    deep: 2,
    name: "西安",
  },
  {
    id: 220421,
    pid: 2204,
    deep: 2,
    name: "东丰",
  },
  {
    id: 220422,
    pid: 2204,
    deep: 2,
    name: "东辽",
  },
  {
    id: 220502,
    pid: 2205,
    deep: 2,
    name: "东昌",
  },
  {
    id: 220503,
    pid: 2205,
    deep: 2,
    name: "二道江",
  },
  {
    id: 220521,
    pid: 2205,
    deep: 2,
    name: "通化县",
  },
  {
    id: 220523,
    pid: 2205,
    deep: 2,
    name: "辉南",
  },
  {
    id: 220524,
    pid: 2205,
    deep: 2,
    name: "柳河",
  },
  {
    id: 220581,
    pid: 2205,
    deep: 2,
    name: "梅河口",
  },
  {
    id: 220582,
    pid: 2205,
    deep: 2,
    name: "集安",
  },
  {
    id: 220602,
    pid: 2206,
    deep: 2,
    name: "浑江",
  },
  {
    id: 220605,
    pid: 2206,
    deep: 2,
    name: "江源",
  },
  {
    id: 220621,
    pid: 2206,
    deep: 2,
    name: "抚松",
  },
  {
    id: 220622,
    pid: 2206,
    deep: 2,
    name: "靖宇",
  },
  {
    id: 220623,
    pid: 2206,
    deep: 2,
    name: "长白",
  },
  {
    id: 220681,
    pid: 2206,
    deep: 2,
    name: "临江",
  },
  {
    id: 220702,
    pid: 2207,
    deep: 2,
    name: "宁江",
  },
  {
    id: 220721,
    pid: 2207,
    deep: 2,
    name: "前郭尔罗斯",
  },
  {
    id: 220722,
    pid: 2207,
    deep: 2,
    name: "长岭",
  },
  {
    id: 220723,
    pid: 2207,
    deep: 2,
    name: "乾安",
  },
  {
    id: 220781,
    pid: 2207,
    deep: 2,
    name: "扶余",
  },
  {
    id: 220802,
    pid: 2208,
    deep: 2,
    name: "洮北",
  },
  {
    id: 220821,
    pid: 2208,
    deep: 2,
    name: "镇赉",
  },
  {
    id: 220822,
    pid: 2208,
    deep: 2,
    name: "通榆",
  },
  {
    id: 220881,
    pid: 2208,
    deep: 2,
    name: "洮南",
  },
  {
    id: 220882,
    pid: 2208,
    deep: 2,
    name: "大安",
  },
  {
    id: 222401,
    pid: 2224,
    deep: 2,
    name: "延吉",
  },
  {
    id: 222402,
    pid: 2224,
    deep: 2,
    name: "图们",
  },
  {
    id: 222403,
    pid: 2224,
    deep: 2,
    name: "敦化",
  },
  {
    id: 222404,
    pid: 2224,
    deep: 2,
    name: "珲春",
  },
  {
    id: 222405,
    pid: 2224,
    deep: 2,
    name: "龙井",
  },
  {
    id: 222406,
    pid: 2224,
    deep: 2,
    name: "和龙",
  },
  {
    id: 222424,
    pid: 2224,
    deep: 2,
    name: "汪清",
  },
  {
    id: 222426,
    pid: 2224,
    deep: 2,
    name: "安图",
  },
  {
    id: 230102,
    pid: 2301,
    deep: 2,
    name: "道里",
  },
  {
    id: 230103,
    pid: 2301,
    deep: 2,
    name: "南岗",
  },
  {
    id: 230104,
    pid: 2301,
    deep: 2,
    name: "道外",
  },
  {
    id: 230108,
    pid: 2301,
    deep: 2,
    name: "平房",
  },
  {
    id: 230109,
    pid: 2301,
    deep: 2,
    name: "松北",
  },
  {
    id: 230110,
    pid: 2301,
    deep: 2,
    name: "香坊",
  },
  {
    id: 230111,
    pid: 2301,
    deep: 2,
    name: "呼兰",
  },
  {
    id: 230112,
    pid: 2301,
    deep: 2,
    name: "阿城",
  },
  {
    id: 230113,
    pid: 2301,
    deep: 2,
    name: "双城",
  },
  {
    id: 230123,
    pid: 2301,
    deep: 2,
    name: "依兰",
  },
  {
    id: 230124,
    pid: 2301,
    deep: 2,
    name: "方正",
  },
  {
    id: 230125,
    pid: 2301,
    deep: 2,
    name: "宾县",
  },
  {
    id: 230126,
    pid: 2301,
    deep: 2,
    name: "巴彦",
  },
  {
    id: 230127,
    pid: 2301,
    deep: 2,
    name: "木兰",
  },
  {
    id: 230128,
    pid: 2301,
    deep: 2,
    name: "通河",
  },
  {
    id: 230129,
    pid: 2301,
    deep: 2,
    name: "延寿",
  },
  {
    id: 230183,
    pid: 2301,
    deep: 2,
    name: "尚志",
  },
  {
    id: 230184,
    pid: 2301,
    deep: 2,
    name: "五常",
  },
  {
    id: 230202,
    pid: 2302,
    deep: 2,
    name: "龙沙",
  },
  {
    id: 230203,
    pid: 2302,
    deep: 2,
    name: "建华",
  },
  {
    id: 230204,
    pid: 2302,
    deep: 2,
    name: "铁锋",
  },
  {
    id: 230205,
    pid: 2302,
    deep: 2,
    name: "昂昂溪",
  },
  {
    id: 230206,
    pid: 2302,
    deep: 2,
    name: "富拉尔基区",
  },
  {
    id: 230207,
    pid: 2302,
    deep: 2,
    name: "碾子山",
  },
  {
    id: 230208,
    pid: 2302,
    deep: 2,
    name: "梅里斯达斡尔族区",
  },
  {
    id: 230221,
    pid: 2302,
    deep: 2,
    name: "龙江",
  },
  {
    id: 230223,
    pid: 2302,
    deep: 2,
    name: "依安",
  },
  {
    id: 230224,
    pid: 2302,
    deep: 2,
    name: "泰来",
  },
  {
    id: 230225,
    pid: 2302,
    deep: 2,
    name: "甘南",
  },
  {
    id: 230227,
    pid: 2302,
    deep: 2,
    name: "富裕",
  },
  {
    id: 230229,
    pid: 2302,
    deep: 2,
    name: "克山",
  },
  {
    id: 230230,
    pid: 2302,
    deep: 2,
    name: "克东",
  },
  {
    id: 230231,
    pid: 2302,
    deep: 2,
    name: "拜泉",
  },
  {
    id: 230281,
    pid: 2302,
    deep: 2,
    name: "讷河",
  },
  {
    id: 230302,
    pid: 2303,
    deep: 2,
    name: "鸡冠",
  },
  {
    id: 230303,
    pid: 2303,
    deep: 2,
    name: "恒山",
  },
  {
    id: 230304,
    pid: 2303,
    deep: 2,
    name: "滴道",
  },
  {
    id: 230305,
    pid: 2303,
    deep: 2,
    name: "梨树",
  },
  {
    id: 230306,
    pid: 2303,
    deep: 2,
    name: "城子河",
  },
  {
    id: 230307,
    pid: 2303,
    deep: 2,
    name: "麻山",
  },
  {
    id: 230321,
    pid: 2303,
    deep: 2,
    name: "鸡东",
  },
  {
    id: 230381,
    pid: 2303,
    deep: 2,
    name: "虎林",
  },
  {
    id: 230382,
    pid: 2303,
    deep: 2,
    name: "密山",
  },
  {
    id: 230402,
    pid: 2304,
    deep: 2,
    name: "向阳",
  },
  {
    id: 230403,
    pid: 2304,
    deep: 2,
    name: "工农",
  },
  {
    id: 230404,
    pid: 2304,
    deep: 2,
    name: "南山",
  },
  {
    id: 230405,
    pid: 2304,
    deep: 2,
    name: "兴安",
  },
  {
    id: 230406,
    pid: 2304,
    deep: 2,
    name: "东山",
  },
  {
    id: 230407,
    pid: 2304,
    deep: 2,
    name: "兴山",
  },
  {
    id: 230421,
    pid: 2304,
    deep: 2,
    name: "萝北",
  },
  {
    id: 230422,
    pid: 2304,
    deep: 2,
    name: "绥滨",
  },
  {
    id: 230502,
    pid: 2305,
    deep: 2,
    name: "尖山",
  },
  {
    id: 230503,
    pid: 2305,
    deep: 2,
    name: "岭东",
  },
  {
    id: 230505,
    pid: 2305,
    deep: 2,
    name: "四方台",
  },
  {
    id: 230506,
    pid: 2305,
    deep: 2,
    name: "宝山",
  },
  {
    id: 230521,
    pid: 2305,
    deep: 2,
    name: "集贤",
  },
  {
    id: 230522,
    pid: 2305,
    deep: 2,
    name: "友谊",
  },
  {
    id: 230523,
    pid: 2305,
    deep: 2,
    name: "宝清",
  },
  {
    id: 230524,
    pid: 2305,
    deep: 2,
    name: "饶河",
  },
  {
    id: 230602,
    pid: 2306,
    deep: 2,
    name: "萨尔图",
  },
  {
    id: 230603,
    pid: 2306,
    deep: 2,
    name: "龙凤",
  },
  {
    id: 230604,
    pid: 2306,
    deep: 2,
    name: "让胡路",
  },
  {
    id: 230605,
    pid: 2306,
    deep: 2,
    name: "红岗",
  },
  {
    id: 230606,
    pid: 2306,
    deep: 2,
    name: "大同",
  },
  {
    id: 230621,
    pid: 2306,
    deep: 2,
    name: "肇州",
  },
  {
    id: 230622,
    pid: 2306,
    deep: 2,
    name: "肇源",
  },
  {
    id: 230623,
    pid: 2306,
    deep: 2,
    name: "林甸",
  },
  {
    id: 230624,
    pid: 2306,
    deep: 2,
    name: "杜尔伯特",
  },
  {
    id: 230717,
    pid: 2307,
    deep: 2,
    name: "伊美",
  },
  {
    id: 230718,
    pid: 2307,
    deep: 2,
    name: "乌翠",
  },
  {
    id: 230719,
    pid: 2307,
    deep: 2,
    name: "友好",
  },
  {
    id: 230722,
    pid: 2307,
    deep: 2,
    name: "嘉荫",
  },
  {
    id: 230723,
    pid: 2307,
    deep: 2,
    name: "汤旺",
  },
  {
    id: 230724,
    pid: 2307,
    deep: 2,
    name: "丰林",
  },
  {
    id: 230725,
    pid: 2307,
    deep: 2,
    name: "大箐山",
  },
  {
    id: 230726,
    pid: 2307,
    deep: 2,
    name: "南岔",
  },
  {
    id: 230751,
    pid: 2307,
    deep: 2,
    name: "金林",
  },
  {
    id: 230781,
    pid: 2307,
    deep: 2,
    name: "铁力",
  },
  {
    id: 230803,
    pid: 2308,
    deep: 2,
    name: "向阳",
  },
  {
    id: 230804,
    pid: 2308,
    deep: 2,
    name: "前进",
  },
  {
    id: 230805,
    pid: 2308,
    deep: 2,
    name: "东风",
  },
  {
    id: 230811,
    pid: 2308,
    deep: 2,
    name: "郊区",
  },
  {
    id: 230822,
    pid: 2308,
    deep: 2,
    name: "桦南",
  },
  {
    id: 230826,
    pid: 2308,
    deep: 2,
    name: "桦川",
  },
  {
    id: 230828,
    pid: 2308,
    deep: 2,
    name: "汤原",
  },
  {
    id: 230881,
    pid: 2308,
    deep: 2,
    name: "同江",
  },
  {
    id: 230882,
    pid: 2308,
    deep: 2,
    name: "富锦",
  },
  {
    id: 230883,
    pid: 2308,
    deep: 2,
    name: "抚远",
  },
  {
    id: 230902,
    pid: 2309,
    deep: 2,
    name: "新兴",
  },
  {
    id: 230903,
    pid: 2309,
    deep: 2,
    name: "桃山",
  },
  {
    id: 230904,
    pid: 2309,
    deep: 2,
    name: "茄子河",
  },
  {
    id: 230921,
    pid: 2309,
    deep: 2,
    name: "勃利",
  },
  {
    id: 231002,
    pid: 2310,
    deep: 2,
    name: "东安",
  },
  {
    id: 231003,
    pid: 2310,
    deep: 2,
    name: "阳明",
  },
  {
    id: 231004,
    pid: 2310,
    deep: 2,
    name: "爱民",
  },
  {
    id: 231005,
    pid: 2310,
    deep: 2,
    name: "西安",
  },
  {
    id: 231025,
    pid: 2310,
    deep: 2,
    name: "林口",
  },
  {
    id: 231081,
    pid: 2310,
    deep: 2,
    name: "绥芬河",
  },
  {
    id: 231083,
    pid: 2310,
    deep: 2,
    name: "海林",
  },
  {
    id: 231084,
    pid: 2310,
    deep: 2,
    name: "宁安",
  },
  {
    id: 231085,
    pid: 2310,
    deep: 2,
    name: "穆棱",
  },
  {
    id: 231086,
    pid: 2310,
    deep: 2,
    name: "东宁",
  },
  {
    id: 231102,
    pid: 2311,
    deep: 2,
    name: "爱辉",
  },
  {
    id: 231121,
    pid: 2311,
    deep: 2,
    name: "嫩江",
  },
  {
    id: 231123,
    pid: 2311,
    deep: 2,
    name: "逊克",
  },
  {
    id: 231124,
    pid: 2311,
    deep: 2,
    name: "孙吴",
  },
  {
    id: 231181,
    pid: 2311,
    deep: 2,
    name: "北安",
  },
  {
    id: 231182,
    pid: 2311,
    deep: 2,
    name: "五大连池",
  },
  {
    id: 231202,
    pid: 2312,
    deep: 2,
    name: "北林",
  },
  {
    id: 231221,
    pid: 2312,
    deep: 2,
    name: "望奎",
  },
  {
    id: 231222,
    pid: 2312,
    deep: 2,
    name: "兰西",
  },
  {
    id: 231223,
    pid: 2312,
    deep: 2,
    name: "青冈",
  },
  {
    id: 231224,
    pid: 2312,
    deep: 2,
    name: "庆安",
  },
  {
    id: 231225,
    pid: 2312,
    deep: 2,
    name: "明水",
  },
  {
    id: 231226,
    pid: 2312,
    deep: 2,
    name: "绥棱",
  },
  {
    id: 231281,
    pid: 2312,
    deep: 2,
    name: "安达",
  },
  {
    id: 231282,
    pid: 2312,
    deep: 2,
    name: "肇东",
  },
  {
    id: 231283,
    pid: 2312,
    deep: 2,
    name: "海伦",
  },
  {
    id: 232701,
    pid: 2327,
    deep: 2,
    name: "漠河",
  },
  {
    id: 232721,
    pid: 2327,
    deep: 2,
    name: "呼玛",
  },
  {
    id: 232722,
    pid: 2327,
    deep: 2,
    name: "塔河",
  },
  {
    id: 232761,
    pid: 2327,
    deep: 2,
    name: "加格达奇区",
  },
  {
    id: 310101,
    pid: 3101,
    deep: 2,
    name: "黄浦",
  },
  {
    id: 310104,
    pid: 3101,
    deep: 2,
    name: "徐汇",
  },
  {
    id: 310105,
    pid: 3101,
    deep: 2,
    name: "长宁",
  },
  {
    id: 310106,
    pid: 3101,
    deep: 2,
    name: "静安",
  },
  {
    id: 310107,
    pid: 3101,
    deep: 2,
    name: "普陀",
  },
  {
    id: 310109,
    pid: 3101,
    deep: 2,
    name: "虹口",
  },
  {
    id: 310110,
    pid: 3101,
    deep: 2,
    name: "杨浦",
  },
  {
    id: 310112,
    pid: 3101,
    deep: 2,
    name: "闵行",
  },
  {
    id: 310113,
    pid: 3101,
    deep: 2,
    name: "宝山",
  },
  {
    id: 310114,
    pid: 3101,
    deep: 2,
    name: "嘉定",
  },
  {
    id: 310115,
    pid: 3101,
    deep: 2,
    name: "浦东新区",
  },
  {
    id: 310116,
    pid: 3101,
    deep: 2,
    name: "金山",
  },
  {
    id: 310117,
    pid: 3101,
    deep: 2,
    name: "松江",
  },
  {
    id: 310118,
    pid: 3101,
    deep: 2,
    name: "青浦",
  },
  {
    id: 310120,
    pid: 3101,
    deep: 2,
    name: "奉贤",
  },
  {
    id: 310151,
    pid: 3101,
    deep: 2,
    name: "崇明",
  },
  {
    id: 320102,
    pid: 3201,
    deep: 2,
    name: "玄武",
  },
  {
    id: 320104,
    pid: 3201,
    deep: 2,
    name: "秦淮",
  },
  {
    id: 320105,
    pid: 3201,
    deep: 2,
    name: "建邺",
  },
  {
    id: 320106,
    pid: 3201,
    deep: 2,
    name: "鼓楼",
  },
  {
    id: 320111,
    pid: 3201,
    deep: 2,
    name: "浦口",
  },
  {
    id: 320113,
    pid: 3201,
    deep: 2,
    name: "栖霞",
  },
  {
    id: 320114,
    pid: 3201,
    deep: 2,
    name: "雨花台",
  },
  {
    id: 320115,
    pid: 3201,
    deep: 2,
    name: "江宁",
  },
  {
    id: 320116,
    pid: 3201,
    deep: 2,
    name: "六合",
  },
  {
    id: 320117,
    pid: 3201,
    deep: 2,
    name: "溧水",
  },
  {
    id: 320118,
    pid: 3201,
    deep: 2,
    name: "高淳",
  },
  {
    id: 320205,
    pid: 3202,
    deep: 2,
    name: "锡山",
  },
  {
    id: 320206,
    pid: 3202,
    deep: 2,
    name: "惠山",
  },
  {
    id: 320211,
    pid: 3202,
    deep: 2,
    name: "滨湖",
  },
  {
    id: 320213,
    pid: 3202,
    deep: 2,
    name: "梁溪",
  },
  {
    id: 320214,
    pid: 3202,
    deep: 2,
    name: "新吴",
  },
  {
    id: 320281,
    pid: 3202,
    deep: 2,
    name: "江阴",
  },
  {
    id: 320282,
    pid: 3202,
    deep: 2,
    name: "宜兴",
  },
  {
    id: 320302,
    pid: 3203,
    deep: 2,
    name: "鼓楼",
  },
  {
    id: 320303,
    pid: 3203,
    deep: 2,
    name: "云龙",
  },
  {
    id: 320305,
    pid: 3203,
    deep: 2,
    name: "贾汪",
  },
  {
    id: 320311,
    pid: 3203,
    deep: 2,
    name: "泉山",
  },
  {
    id: 320312,
    pid: 3203,
    deep: 2,
    name: "铜山",
  },
  {
    id: 320321,
    pid: 3203,
    deep: 2,
    name: "丰县",
  },
  {
    id: 320322,
    pid: 3203,
    deep: 2,
    name: "沛县",
  },
  {
    id: 320324,
    pid: 3203,
    deep: 2,
    name: "睢宁",
  },
  {
    id: 320381,
    pid: 3203,
    deep: 2,
    name: "新沂",
  },
  {
    id: 320382,
    pid: 3203,
    deep: 2,
    name: "邳州",
  },
  {
    id: 320402,
    pid: 3204,
    deep: 2,
    name: "天宁",
  },
  {
    id: 320404,
    pid: 3204,
    deep: 2,
    name: "钟楼",
  },
  {
    id: 320411,
    pid: 3204,
    deep: 2,
    name: "新北",
  },
  {
    id: 320412,
    pid: 3204,
    deep: 2,
    name: "武进",
  },
  {
    id: 320413,
    pid: 3204,
    deep: 2,
    name: "金坛",
  },
  {
    id: 320481,
    pid: 3204,
    deep: 2,
    name: "溧阳",
  },
  {
    id: 320505,
    pid: 3205,
    deep: 2,
    name: "虎丘",
  },
  {
    id: 320506,
    pid: 3205,
    deep: 2,
    name: "吴中",
  },
  {
    id: 320507,
    pid: 3205,
    deep: 2,
    name: "相城",
  },
  {
    id: 320508,
    pid: 3205,
    deep: 2,
    name: "姑苏",
  },
  {
    id: 320509,
    pid: 3205,
    deep: 2,
    name: "吴江",
  },
  {
    id: 320571,
    pid: 3205,
    deep: 2,
    name: "工业园区",
  },
  {
    id: 320581,
    pid: 3205,
    deep: 2,
    name: "常熟",
  },
  {
    id: 320582,
    pid: 3205,
    deep: 2,
    name: "张家港",
  },
  {
    id: 320583,
    pid: 3205,
    deep: 2,
    name: "昆山",
  },
  {
    id: 320585,
    pid: 3205,
    deep: 2,
    name: "太仓",
  },
  {
    id: 320602,
    pid: 3206,
    deep: 2,
    name: "崇川",
  },
  {
    id: 320611,
    pid: 3206,
    deep: 2,
    name: "港闸",
  },
  {
    id: 320612,
    pid: 3206,
    deep: 2,
    name: "通州",
  },
  {
    id: 320623,
    pid: 3206,
    deep: 2,
    name: "如东",
  },
  {
    id: 320681,
    pid: 3206,
    deep: 2,
    name: "启东",
  },
  {
    id: 320682,
    pid: 3206,
    deep: 2,
    name: "如皋",
  },
  {
    id: 320684,
    pid: 3206,
    deep: 2,
    name: "海门",
  },
  {
    id: 320685,
    pid: 3206,
    deep: 2,
    name: "海安",
  },
  {
    id: 320703,
    pid: 3207,
    deep: 2,
    name: "连云",
  },
  {
    id: 320706,
    pid: 3207,
    deep: 2,
    name: "海州",
  },
  {
    id: 320707,
    pid: 3207,
    deep: 2,
    name: "赣榆",
  },
  {
    id: 320722,
    pid: 3207,
    deep: 2,
    name: "东海",
  },
  {
    id: 320723,
    pid: 3207,
    deep: 2,
    name: "灌云",
  },
  {
    id: 320724,
    pid: 3207,
    deep: 2,
    name: "灌南",
  },
  {
    id: 320803,
    pid: 3208,
    deep: 2,
    name: "淮安区",
  },
  {
    id: 320804,
    pid: 3208,
    deep: 2,
    name: "淮阴",
  },
  {
    id: 320812,
    pid: 3208,
    deep: 2,
    name: "清江浦",
  },
  {
    id: 320813,
    pid: 3208,
    deep: 2,
    name: "洪泽",
  },
  {
    id: 320826,
    pid: 3208,
    deep: 2,
    name: "涟水",
  },
  {
    id: 320830,
    pid: 3208,
    deep: 2,
    name: "盱眙",
  },
  {
    id: 320831,
    pid: 3208,
    deep: 2,
    name: "金湖",
  },
  {
    id: 320902,
    pid: 3209,
    deep: 2,
    name: "亭湖",
  },
  {
    id: 320903,
    pid: 3209,
    deep: 2,
    name: "盐都",
  },
  {
    id: 320904,
    pid: 3209,
    deep: 2,
    name: "大丰",
  },
  {
    id: 320921,
    pid: 3209,
    deep: 2,
    name: "响水",
  },
  {
    id: 320922,
    pid: 3209,
    deep: 2,
    name: "滨海",
  },
  {
    id: 320923,
    pid: 3209,
    deep: 2,
    name: "阜宁",
  },
  {
    id: 320924,
    pid: 3209,
    deep: 2,
    name: "射阳",
  },
  {
    id: 320925,
    pid: 3209,
    deep: 2,
    name: "建湖",
  },
  {
    id: 320981,
    pid: 3209,
    deep: 2,
    name: "东台",
  },
  {
    id: 321002,
    pid: 3210,
    deep: 2,
    name: "广陵",
  },
  {
    id: 321003,
    pid: 3210,
    deep: 2,
    name: "邗江",
  },
  {
    id: 321012,
    pid: 3210,
    deep: 2,
    name: "江都",
  },
  {
    id: 321023,
    pid: 3210,
    deep: 2,
    name: "宝应",
  },
  {
    id: 321081,
    pid: 3210,
    deep: 2,
    name: "仪征",
  },
  {
    id: 321084,
    pid: 3210,
    deep: 2,
    name: "高邮",
  },
  {
    id: 321102,
    pid: 3211,
    deep: 2,
    name: "京口",
  },
  {
    id: 321111,
    pid: 3211,
    deep: 2,
    name: "润州",
  },
  {
    id: 321112,
    pid: 3211,
    deep: 2,
    name: "丹徒",
  },
  {
    id: 321181,
    pid: 3211,
    deep: 2,
    name: "丹阳",
  },
  {
    id: 321182,
    pid: 3211,
    deep: 2,
    name: "扬中",
  },
  {
    id: 321183,
    pid: 3211,
    deep: 2,
    name: "句容",
  },
  {
    id: 321202,
    pid: 3212,
    deep: 2,
    name: "海陵",
  },
  {
    id: 321203,
    pid: 3212,
    deep: 2,
    name: "高港",
  },
  {
    id: 321204,
    pid: 3212,
    deep: 2,
    name: "姜堰",
  },
  {
    id: 321281,
    pid: 3212,
    deep: 2,
    name: "兴化",
  },
  {
    id: 321282,
    pid: 3212,
    deep: 2,
    name: "靖江",
  },
  {
    id: 321283,
    pid: 3212,
    deep: 2,
    name: "泰兴",
  },
  {
    id: 321302,
    pid: 3213,
    deep: 2,
    name: "宿城",
  },
  {
    id: 321311,
    pid: 3213,
    deep: 2,
    name: "宿豫",
  },
  {
    id: 321322,
    pid: 3213,
    deep: 2,
    name: "沭阳",
  },
  {
    id: 321323,
    pid: 3213,
    deep: 2,
    name: "泗阳",
  },
  {
    id: 321324,
    pid: 3213,
    deep: 2,
    name: "泗洪",
  },
  {
    id: 330102,
    pid: 3301,
    deep: 2,
    name: "上城",
  },
  {
    id: 330103,
    pid: 3301,
    deep: 2,
    name: "下城",
  },
  {
    id: 330104,
    pid: 3301,
    deep: 2,
    name: "江干",
  },
  {
    id: 330105,
    pid: 3301,
    deep: 2,
    name: "拱墅",
  },
  {
    id: 330106,
    pid: 3301,
    deep: 2,
    name: "西湖",
  },
  {
    id: 330108,
    pid: 3301,
    deep: 2,
    name: "滨江",
  },
  {
    id: 330109,
    pid: 3301,
    deep: 2,
    name: "萧山",
  },
  {
    id: 330110,
    pid: 3301,
    deep: 2,
    name: "余杭",
  },
  {
    id: 330111,
    pid: 3301,
    deep: 2,
    name: "富阳",
  },
  {
    id: 330112,
    pid: 3301,
    deep: 2,
    name: "临安",
  },
  {
    id: 330122,
    pid: 3301,
    deep: 2,
    name: "桐庐",
  },
  {
    id: 330127,
    pid: 3301,
    deep: 2,
    name: "淳安",
  },
  {
    id: 330182,
    pid: 3301,
    deep: 2,
    name: "建德",
  },
  {
    id: 330203,
    pid: 3302,
    deep: 2,
    name: "海曙",
  },
  {
    id: 330205,
    pid: 3302,
    deep: 2,
    name: "江北",
  },
  {
    id: 330206,
    pid: 3302,
    deep: 2,
    name: "北仑",
  },
  {
    id: 330211,
    pid: 3302,
    deep: 2,
    name: "镇海",
  },
  {
    id: 330212,
    pid: 3302,
    deep: 2,
    name: "鄞州",
  },
  {
    id: 330213,
    pid: 3302,
    deep: 2,
    name: "奉化",
  },
  {
    id: 330225,
    pid: 3302,
    deep: 2,
    name: "象山",
  },
  {
    id: 330226,
    pid: 3302,
    deep: 2,
    name: "宁海",
  },
  {
    id: 330281,
    pid: 3302,
    deep: 2,
    name: "余姚",
  },
  {
    id: 330282,
    pid: 3302,
    deep: 2,
    name: "慈溪",
  },
  {
    id: 330302,
    pid: 3303,
    deep: 2,
    name: "鹿城",
  },
  {
    id: 330303,
    pid: 3303,
    deep: 2,
    name: "龙湾",
  },
  {
    id: 330304,
    pid: 3303,
    deep: 2,
    name: "瓯海",
  },
  {
    id: 330305,
    pid: 3303,
    deep: 2,
    name: "洞头",
  },
  {
    id: 330324,
    pid: 3303,
    deep: 2,
    name: "永嘉",
  },
  {
    id: 330326,
    pid: 3303,
    deep: 2,
    name: "平阳",
  },
  {
    id: 330327,
    pid: 3303,
    deep: 2,
    name: "苍南",
  },
  {
    id: 330328,
    pid: 3303,
    deep: 2,
    name: "文成",
  },
  {
    id: 330329,
    pid: 3303,
    deep: 2,
    name: "泰顺",
  },
  {
    id: 330381,
    pid: 3303,
    deep: 2,
    name: "瑞安",
  },
  {
    id: 330382,
    pid: 3303,
    deep: 2,
    name: "乐清",
  },
  {
    id: 330383,
    pid: 3303,
    deep: 2,
    name: "龙港",
  },
  {
    id: 330402,
    pid: 3304,
    deep: 2,
    name: "南湖",
  },
  {
    id: 330411,
    pid: 3304,
    deep: 2,
    name: "秀洲",
  },
  {
    id: 330421,
    pid: 3304,
    deep: 2,
    name: "嘉善",
  },
  {
    id: 330424,
    pid: 3304,
    deep: 2,
    name: "海盐",
  },
  {
    id: 330481,
    pid: 3304,
    deep: 2,
    name: "海宁",
  },
  {
    id: 330482,
    pid: 3304,
    deep: 2,
    name: "平湖",
  },
  {
    id: 330483,
    pid: 3304,
    deep: 2,
    name: "桐乡",
  },
  {
    id: 330502,
    pid: 3305,
    deep: 2,
    name: "吴兴",
  },
  {
    id: 330503,
    pid: 3305,
    deep: 2,
    name: "南浔",
  },
  {
    id: 330521,
    pid: 3305,
    deep: 2,
    name: "德清",
  },
  {
    id: 330522,
    pid: 3305,
    deep: 2,
    name: "长兴",
  },
  {
    id: 330523,
    pid: 3305,
    deep: 2,
    name: "安吉",
  },
  {
    id: 330602,
    pid: 3306,
    deep: 2,
    name: "越城",
  },
  {
    id: 330603,
    pid: 3306,
    deep: 2,
    name: "柯桥",
  },
  {
    id: 330604,
    pid: 3306,
    deep: 2,
    name: "上虞",
  },
  {
    id: 330624,
    pid: 3306,
    deep: 2,
    name: "新昌",
  },
  {
    id: 330681,
    pid: 3306,
    deep: 2,
    name: "诸暨",
  },
  {
    id: 330683,
    pid: 3306,
    deep: 2,
    name: "嵊州",
  },
  {
    id: 330702,
    pid: 3307,
    deep: 2,
    name: "婺城",
  },
  {
    id: 330703,
    pid: 3307,
    deep: 2,
    name: "金东",
  },
  {
    id: 330723,
    pid: 3307,
    deep: 2,
    name: "武义",
  },
  {
    id: 330726,
    pid: 3307,
    deep: 2,
    name: "浦江",
  },
  {
    id: 330727,
    pid: 3307,
    deep: 2,
    name: "磐安",
  },
  {
    id: 330781,
    pid: 3307,
    deep: 2,
    name: "兰溪",
  },
  {
    id: 330782,
    pid: 3307,
    deep: 2,
    name: "义乌",
  },
  {
    id: 330783,
    pid: 3307,
    deep: 2,
    name: "东阳",
  },
  {
    id: 330784,
    pid: 3307,
    deep: 2,
    name: "永康",
  },
  {
    id: 330802,
    pid: 3308,
    deep: 2,
    name: "柯城",
  },
  {
    id: 330803,
    pid: 3308,
    deep: 2,
    name: "衢江",
  },
  {
    id: 330822,
    pid: 3308,
    deep: 2,
    name: "常山",
  },
  {
    id: 330824,
    pid: 3308,
    deep: 2,
    name: "开化",
  },
  {
    id: 330825,
    pid: 3308,
    deep: 2,
    name: "龙游",
  },
  {
    id: 330881,
    pid: 3308,
    deep: 2,
    name: "江山",
  },
  {
    id: 330902,
    pid: 3309,
    deep: 2,
    name: "定海",
  },
  {
    id: 330903,
    pid: 3309,
    deep: 2,
    name: "普陀",
  },
  {
    id: 330921,
    pid: 3309,
    deep: 2,
    name: "岱山",
  },
  {
    id: 330922,
    pid: 3309,
    deep: 2,
    name: "嵊泗",
  },
  {
    id: 331002,
    pid: 3310,
    deep: 2,
    name: "椒江",
  },
  {
    id: 331003,
    pid: 3310,
    deep: 2,
    name: "黄岩",
  },
  {
    id: 331004,
    pid: 3310,
    deep: 2,
    name: "路桥",
  },
  {
    id: 331022,
    pid: 3310,
    deep: 2,
    name: "三门",
  },
  {
    id: 331023,
    pid: 3310,
    deep: 2,
    name: "天台",
  },
  {
    id: 331024,
    pid: 3310,
    deep: 2,
    name: "仙居",
  },
  {
    id: 331081,
    pid: 3310,
    deep: 2,
    name: "温岭",
  },
  {
    id: 331082,
    pid: 3310,
    deep: 2,
    name: "临海",
  },
  {
    id: 331083,
    pid: 3310,
    deep: 2,
    name: "玉环",
  },
  {
    id: 331102,
    pid: 3311,
    deep: 2,
    name: "莲都",
  },
  {
    id: 331121,
    pid: 3311,
    deep: 2,
    name: "青田",
  },
  {
    id: 331122,
    pid: 3311,
    deep: 2,
    name: "缙云",
  },
  {
    id: 331123,
    pid: 3311,
    deep: 2,
    name: "遂昌",
  },
  {
    id: 331124,
    pid: 3311,
    deep: 2,
    name: "松阳",
  },
  {
    id: 331125,
    pid: 3311,
    deep: 2,
    name: "云和",
  },
  {
    id: 331126,
    pid: 3311,
    deep: 2,
    name: "庆元",
  },
  {
    id: 331127,
    pid: 3311,
    deep: 2,
    name: "景宁",
  },
  {
    id: 331181,
    pid: 3311,
    deep: 2,
    name: "龙泉",
  },
  {
    id: 340102,
    pid: 3401,
    deep: 2,
    name: "瑶海",
  },
  {
    id: 340103,
    pid: 3401,
    deep: 2,
    name: "庐阳",
  },
  {
    id: 340104,
    pid: 3401,
    deep: 2,
    name: "蜀山",
  },
  {
    id: 340111,
    pid: 3401,
    deep: 2,
    name: "包河",
  },
  {
    id: 340121,
    pid: 3401,
    deep: 2,
    name: "长丰",
  },
  {
    id: 340122,
    pid: 3401,
    deep: 2,
    name: "肥东",
  },
  {
    id: 340123,
    pid: 3401,
    deep: 2,
    name: "肥西",
  },
  {
    id: 340124,
    pid: 3401,
    deep: 2,
    name: "庐江",
  },
  {
    id: 340181,
    pid: 3401,
    deep: 2,
    name: "巢湖",
  },
  {
    id: 340202,
    pid: 3402,
    deep: 2,
    name: "镜湖",
  },
  {
    id: 340203,
    pid: 3402,
    deep: 2,
    name: "弋江",
  },
  {
    id: 340207,
    pid: 3402,
    deep: 2,
    name: "鸠江",
  },
  {
    id: 340208,
    pid: 3402,
    deep: 2,
    name: "三山",
  },
  {
    id: 340221,
    pid: 3402,
    deep: 2,
    name: "芜湖县",
  },
  {
    id: 340222,
    pid: 3402,
    deep: 2,
    name: "繁昌",
  },
  {
    id: 340223,
    pid: 3402,
    deep: 2,
    name: "南陵",
  },
  {
    id: 340225,
    pid: 3402,
    deep: 2,
    name: "无为",
  },
  {
    id: 340302,
    pid: 3403,
    deep: 2,
    name: "龙子湖",
  },
  {
    id: 340303,
    pid: 3403,
    deep: 2,
    name: "蚌山",
  },
  {
    id: 340304,
    pid: 3403,
    deep: 2,
    name: "禹会",
  },
  {
    id: 340311,
    pid: 3403,
    deep: 2,
    name: "淮上",
  },
  {
    id: 340321,
    pid: 3403,
    deep: 2,
    name: "怀远",
  },
  {
    id: 340322,
    pid: 3403,
    deep: 2,
    name: "五河",
  },
  {
    id: 340323,
    pid: 3403,
    deep: 2,
    name: "固镇",
  },
  {
    id: 340402,
    pid: 3404,
    deep: 2,
    name: "大通",
  },
  {
    id: 340403,
    pid: 3404,
    deep: 2,
    name: "田家庵",
  },
  {
    id: 340404,
    pid: 3404,
    deep: 2,
    name: "谢家集",
  },
  {
    id: 340405,
    pid: 3404,
    deep: 2,
    name: "八公山",
  },
  {
    id: 340406,
    pid: 3404,
    deep: 2,
    name: "潘集",
  },
  {
    id: 340421,
    pid: 3404,
    deep: 2,
    name: "凤台",
  },
  {
    id: 340422,
    pid: 3404,
    deep: 2,
    name: "寿县",
  },
  {
    id: 340503,
    pid: 3405,
    deep: 2,
    name: "花山",
  },
  {
    id: 340504,
    pid: 3405,
    deep: 2,
    name: "雨山",
  },
  {
    id: 340506,
    pid: 3405,
    deep: 2,
    name: "博望",
  },
  {
    id: 340521,
    pid: 3405,
    deep: 2,
    name: "当涂",
  },
  {
    id: 340522,
    pid: 3405,
    deep: 2,
    name: "含山",
  },
  {
    id: 340523,
    pid: 3405,
    deep: 2,
    name: "和县",
  },
  {
    id: 340602,
    pid: 3406,
    deep: 2,
    name: "杜集",
  },
  {
    id: 340603,
    pid: 3406,
    deep: 2,
    name: "相山",
  },
  {
    id: 340604,
    pid: 3406,
    deep: 2,
    name: "烈山",
  },
  {
    id: 340621,
    pid: 3406,
    deep: 2,
    name: "濉溪",
  },
  {
    id: 340705,
    pid: 3407,
    deep: 2,
    name: "铜官",
  },
  {
    id: 340706,
    pid: 3407,
    deep: 2,
    name: "义安",
  },
  {
    id: 340711,
    pid: 3407,
    deep: 2,
    name: "郊区",
  },
  {
    id: 340722,
    pid: 3407,
    deep: 2,
    name: "枞阳",
  },
  {
    id: 340802,
    pid: 3408,
    deep: 2,
    name: "迎江",
  },
  {
    id: 340803,
    pid: 3408,
    deep: 2,
    name: "大观",
  },
  {
    id: 340811,
    pid: 3408,
    deep: 2,
    name: "宜秀",
  },
  {
    id: 340822,
    pid: 3408,
    deep: 2,
    name: "怀宁",
  },
  {
    id: 340825,
    pid: 3408,
    deep: 2,
    name: "太湖",
  },
  {
    id: 340826,
    pid: 3408,
    deep: 2,
    name: "宿松",
  },
  {
    id: 340827,
    pid: 3408,
    deep: 2,
    name: "望江",
  },
  {
    id: 340828,
    pid: 3408,
    deep: 2,
    name: "岳西",
  },
  {
    id: 340881,
    pid: 3408,
    deep: 2,
    name: "桐城",
  },
  {
    id: 340882,
    pid: 3408,
    deep: 2,
    name: "潜山",
  },
  {
    id: 341002,
    pid: 3410,
    deep: 2,
    name: "屯溪",
  },
  {
    id: 341003,
    pid: 3410,
    deep: 2,
    name: "黄山区",
  },
  {
    id: 341004,
    pid: 3410,
    deep: 2,
    name: "徽州",
  },
  {
    id: 341021,
    pid: 3410,
    deep: 2,
    name: "歙县",
  },
  {
    id: 341022,
    pid: 3410,
    deep: 2,
    name: "休宁",
  },
  {
    id: 341023,
    pid: 3410,
    deep: 2,
    name: "黟县",
  },
  {
    id: 341024,
    pid: 3410,
    deep: 2,
    name: "祁门",
  },
  {
    id: 341102,
    pid: 3411,
    deep: 2,
    name: "琅琊",
  },
  {
    id: 341103,
    pid: 3411,
    deep: 2,
    name: "南谯",
  },
  {
    id: 341122,
    pid: 3411,
    deep: 2,
    name: "来安",
  },
  {
    id: 341124,
    pid: 3411,
    deep: 2,
    name: "全椒",
  },
  {
    id: 341125,
    pid: 3411,
    deep: 2,
    name: "定远",
  },
  {
    id: 341126,
    pid: 3411,
    deep: 2,
    name: "凤阳",
  },
  {
    id: 341181,
    pid: 3411,
    deep: 2,
    name: "天长",
  },
  {
    id: 341182,
    pid: 3411,
    deep: 2,
    name: "明光",
  },
  {
    id: 341202,
    pid: 3412,
    deep: 2,
    name: "颍州",
  },
  {
    id: 341203,
    pid: 3412,
    deep: 2,
    name: "颍东",
  },
  {
    id: 341204,
    pid: 3412,
    deep: 2,
    name: "颍泉",
  },
  {
    id: 341221,
    pid: 3412,
    deep: 2,
    name: "临泉",
  },
  {
    id: 341222,
    pid: 3412,
    deep: 2,
    name: "太和",
  },
  {
    id: 341225,
    pid: 3412,
    deep: 2,
    name: "阜南",
  },
  {
    id: 341226,
    pid: 3412,
    deep: 2,
    name: "颍上",
  },
  {
    id: 341282,
    pid: 3412,
    deep: 2,
    name: "界首",
  },
  {
    id: 341302,
    pid: 3413,
    deep: 2,
    name: "埇桥",
  },
  {
    id: 341321,
    pid: 3413,
    deep: 2,
    name: "砀山",
  },
  {
    id: 341322,
    pid: 3413,
    deep: 2,
    name: "萧县",
  },
  {
    id: 341323,
    pid: 3413,
    deep: 2,
    name: "灵璧",
  },
  {
    id: 341324,
    pid: 3413,
    deep: 2,
    name: "泗县",
  },
  {
    id: 341502,
    pid: 3415,
    deep: 2,
    name: "金安",
  },
  {
    id: 341503,
    pid: 3415,
    deep: 2,
    name: "裕安",
  },
  {
    id: 341504,
    pid: 3415,
    deep: 2,
    name: "叶集",
  },
  {
    id: 341522,
    pid: 3415,
    deep: 2,
    name: "霍邱",
  },
  {
    id: 341523,
    pid: 3415,
    deep: 2,
    name: "舒城",
  },
  {
    id: 341524,
    pid: 3415,
    deep: 2,
    name: "金寨",
  },
  {
    id: 341525,
    pid: 3415,
    deep: 2,
    name: "霍山",
  },
  {
    id: 341602,
    pid: 3416,
    deep: 2,
    name: "谯城",
  },
  {
    id: 341621,
    pid: 3416,
    deep: 2,
    name: "涡阳",
  },
  {
    id: 341622,
    pid: 3416,
    deep: 2,
    name: "蒙城",
  },
  {
    id: 341623,
    pid: 3416,
    deep: 2,
    name: "利辛",
  },
  {
    id: 341702,
    pid: 3417,
    deep: 2,
    name: "贵池",
  },
  {
    id: 341721,
    pid: 3417,
    deep: 2,
    name: "东至",
  },
  {
    id: 341722,
    pid: 3417,
    deep: 2,
    name: "石台",
  },
  {
    id: 341723,
    pid: 3417,
    deep: 2,
    name: "青阳",
  },
  {
    id: 341802,
    pid: 3418,
    deep: 2,
    name: "宣州",
  },
  {
    id: 341821,
    pid: 3418,
    deep: 2,
    name: "郎溪",
  },
  {
    id: 341822,
    pid: 3418,
    deep: 2,
    name: "广德",
  },
  {
    id: 341823,
    pid: 3418,
    deep: 2,
    name: "泾县",
  },
  {
    id: 341824,
    pid: 3418,
    deep: 2,
    name: "绩溪",
  },
  {
    id: 341825,
    pid: 3418,
    deep: 2,
    name: "旌德",
  },
  {
    id: 341881,
    pid: 3418,
    deep: 2,
    name: "宁国",
  },
  {
    id: 350102,
    pid: 3501,
    deep: 2,
    name: "鼓楼",
  },
  {
    id: 350103,
    pid: 3501,
    deep: 2,
    name: "台江",
  },
  {
    id: 350104,
    pid: 3501,
    deep: 2,
    name: "仓山",
  },
  {
    id: 350105,
    pid: 3501,
    deep: 2,
    name: "马尾",
  },
  {
    id: 350111,
    pid: 3501,
    deep: 2,
    name: "晋安",
  },
  {
    id: 350112,
    pid: 3501,
    deep: 2,
    name: "长乐",
  },
  {
    id: 350121,
    pid: 3501,
    deep: 2,
    name: "闽侯",
  },
  {
    id: 350122,
    pid: 3501,
    deep: 2,
    name: "连江",
  },
  {
    id: 350123,
    pid: 3501,
    deep: 2,
    name: "罗源",
  },
  {
    id: 350124,
    pid: 3501,
    deep: 2,
    name: "闽清",
  },
  {
    id: 350125,
    pid: 3501,
    deep: 2,
    name: "永泰",
  },
  {
    id: 350128,
    pid: 3501,
    deep: 2,
    name: "平潭",
  },
  {
    id: 350181,
    pid: 3501,
    deep: 2,
    name: "福清",
  },
  {
    id: 350203,
    pid: 3502,
    deep: 2,
    name: "思明",
  },
  {
    id: 350205,
    pid: 3502,
    deep: 2,
    name: "海沧",
  },
  {
    id: 350206,
    pid: 3502,
    deep: 2,
    name: "湖里",
  },
  {
    id: 350211,
    pid: 3502,
    deep: 2,
    name: "集美",
  },
  {
    id: 350212,
    pid: 3502,
    deep: 2,
    name: "同安",
  },
  {
    id: 350213,
    pid: 3502,
    deep: 2,
    name: "翔安",
  },
  {
    id: 350302,
    pid: 3503,
    deep: 2,
    name: "城厢",
  },
  {
    id: 350303,
    pid: 3503,
    deep: 2,
    name: "涵江",
  },
  {
    id: 350304,
    pid: 3503,
    deep: 2,
    name: "荔城",
  },
  {
    id: 350305,
    pid: 3503,
    deep: 2,
    name: "秀屿",
  },
  {
    id: 350322,
    pid: 3503,
    deep: 2,
    name: "仙游",
  },
  {
    id: 350402,
    pid: 3504,
    deep: 2,
    name: "梅列",
  },
  {
    id: 350403,
    pid: 3504,
    deep: 2,
    name: "三元",
  },
  {
    id: 350421,
    pid: 3504,
    deep: 2,
    name: "明溪",
  },
  {
    id: 350423,
    pid: 3504,
    deep: 2,
    name: "清流",
  },
  {
    id: 350424,
    pid: 3504,
    deep: 2,
    name: "宁化",
  },
  {
    id: 350425,
    pid: 3504,
    deep: 2,
    name: "大田",
  },
  {
    id: 350426,
    pid: 3504,
    deep: 2,
    name: "尤溪",
  },
  {
    id: 350427,
    pid: 3504,
    deep: 2,
    name: "沙县",
  },
  {
    id: 350428,
    pid: 3504,
    deep: 2,
    name: "将乐",
  },
  {
    id: 350429,
    pid: 3504,
    deep: 2,
    name: "泰宁",
  },
  {
    id: 350430,
    pid: 3504,
    deep: 2,
    name: "建宁",
  },
  {
    id: 350481,
    pid: 3504,
    deep: 2,
    name: "永安",
  },
  {
    id: 350502,
    pid: 3505,
    deep: 2,
    name: "鲤城",
  },
  {
    id: 350503,
    pid: 3505,
    deep: 2,
    name: "丰泽",
  },
  {
    id: 350504,
    pid: 3505,
    deep: 2,
    name: "洛江",
  },
  {
    id: 350505,
    pid: 3505,
    deep: 2,
    name: "泉港",
  },
  {
    id: 350521,
    pid: 3505,
    deep: 2,
    name: "惠安",
  },
  {
    id: 350524,
    pid: 3505,
    deep: 2,
    name: "安溪",
  },
  {
    id: 350525,
    pid: 3505,
    deep: 2,
    name: "永春",
  },
  {
    id: 350526,
    pid: 3505,
    deep: 2,
    name: "德化",
  },
  {
    id: 350527,
    pid: 3505,
    deep: 2,
    name: "金门",
  },
  {
    id: 350581,
    pid: 3505,
    deep: 2,
    name: "石狮",
  },
  {
    id: 350582,
    pid: 3505,
    deep: 2,
    name: "晋江",
  },
  {
    id: 350583,
    pid: 3505,
    deep: 2,
    name: "南安",
  },
  {
    id: 350602,
    pid: 3506,
    deep: 2,
    name: "芗城",
  },
  {
    id: 350603,
    pid: 3506,
    deep: 2,
    name: "龙文",
  },
  {
    id: 350622,
    pid: 3506,
    deep: 2,
    name: "云霄",
  },
  {
    id: 350623,
    pid: 3506,
    deep: 2,
    name: "漳浦",
  },
  {
    id: 350624,
    pid: 3506,
    deep: 2,
    name: "诏安",
  },
  {
    id: 350625,
    pid: 3506,
    deep: 2,
    name: "长泰",
  },
  {
    id: 350626,
    pid: 3506,
    deep: 2,
    name: "东山",
  },
  {
    id: 350627,
    pid: 3506,
    deep: 2,
    name: "南靖",
  },
  {
    id: 350628,
    pid: 3506,
    deep: 2,
    name: "平和",
  },
  {
    id: 350629,
    pid: 3506,
    deep: 2,
    name: "华安",
  },
  {
    id: 350681,
    pid: 3506,
    deep: 2,
    name: "龙海",
  },
  {
    id: 350702,
    pid: 3507,
    deep: 2,
    name: "延平",
  },
  {
    id: 350703,
    pid: 3507,
    deep: 2,
    name: "建阳",
  },
  {
    id: 350721,
    pid: 3507,
    deep: 2,
    name: "顺昌",
  },
  {
    id: 350722,
    pid: 3507,
    deep: 2,
    name: "浦城",
  },
  {
    id: 350723,
    pid: 3507,
    deep: 2,
    name: "光泽",
  },
  {
    id: 350724,
    pid: 3507,
    deep: 2,
    name: "松溪",
  },
  {
    id: 350725,
    pid: 3507,
    deep: 2,
    name: "政和",
  },
  {
    id: 350781,
    pid: 3507,
    deep: 2,
    name: "邵武",
  },
  {
    id: 350782,
    pid: 3507,
    deep: 2,
    name: "武夷山",
  },
  {
    id: 350783,
    pid: 3507,
    deep: 2,
    name: "建瓯",
  },
  {
    id: 350802,
    pid: 3508,
    deep: 2,
    name: "新罗",
  },
  {
    id: 350803,
    pid: 3508,
    deep: 2,
    name: "永定",
  },
  {
    id: 350821,
    pid: 3508,
    deep: 2,
    name: "长汀",
  },
  {
    id: 350823,
    pid: 3508,
    deep: 2,
    name: "上杭",
  },
  {
    id: 350824,
    pid: 3508,
    deep: 2,
    name: "武平",
  },
  {
    id: 350825,
    pid: 3508,
    deep: 2,
    name: "连城",
  },
  {
    id: 350881,
    pid: 3508,
    deep: 2,
    name: "漳平",
  },
  {
    id: 350902,
    pid: 3509,
    deep: 2,
    name: "蕉城",
  },
  {
    id: 350921,
    pid: 3509,
    deep: 2,
    name: "霞浦",
  },
  {
    id: 350922,
    pid: 3509,
    deep: 2,
    name: "古田",
  },
  {
    id: 350923,
    pid: 3509,
    deep: 2,
    name: "屏南",
  },
  {
    id: 350924,
    pid: 3509,
    deep: 2,
    name: "寿宁",
  },
  {
    id: 350925,
    pid: 3509,
    deep: 2,
    name: "周宁",
  },
  {
    id: 350926,
    pid: 3509,
    deep: 2,
    name: "柘荣",
  },
  {
    id: 350981,
    pid: 3509,
    deep: 2,
    name: "福安",
  },
  {
    id: 350982,
    pid: 3509,
    deep: 2,
    name: "福鼎",
  },
  {
    id: 360102,
    pid: 3601,
    deep: 2,
    name: "东湖",
  },
  {
    id: 360103,
    pid: 3601,
    deep: 2,
    name: "西湖",
  },
  {
    id: 360104,
    pid: 3601,
    deep: 2,
    name: "青云谱",
  },
  {
    id: 360105,
    pid: 3601,
    deep: 2,
    name: "湾里",
  },
  {
    id: 360111,
    pid: 3601,
    deep: 2,
    name: "青山湖",
  },
  {
    id: 360112,
    pid: 3601,
    deep: 2,
    name: "新建",
  },
  {
    id: 360121,
    pid: 3601,
    deep: 2,
    name: "南昌县",
  },
  {
    id: 360123,
    pid: 3601,
    deep: 2,
    name: "安义",
  },
  {
    id: 360124,
    pid: 3601,
    deep: 2,
    name: "进贤",
  },
  {
    id: 360202,
    pid: 3602,
    deep: 2,
    name: "昌江",
  },
  {
    id: 360203,
    pid: 3602,
    deep: 2,
    name: "珠山",
  },
  {
    id: 360222,
    pid: 3602,
    deep: 2,
    name: "浮梁",
  },
  {
    id: 360281,
    pid: 3602,
    deep: 2,
    name: "乐平",
  },
  {
    id: 360302,
    pid: 3603,
    deep: 2,
    name: "安源",
  },
  {
    id: 360313,
    pid: 3603,
    deep: 2,
    name: "湘东",
  },
  {
    id: 360321,
    pid: 3603,
    deep: 2,
    name: "莲花",
  },
  {
    id: 360322,
    pid: 3603,
    deep: 2,
    name: "上栗",
  },
  {
    id: 360323,
    pid: 3603,
    deep: 2,
    name: "芦溪",
  },
  {
    id: 360402,
    pid: 3604,
    deep: 2,
    name: "濂溪",
  },
  {
    id: 360403,
    pid: 3604,
    deep: 2,
    name: "浔阳",
  },
  {
    id: 360404,
    pid: 3604,
    deep: 2,
    name: "柴桑",
  },
  {
    id: 360423,
    pid: 3604,
    deep: 2,
    name: "武宁",
  },
  {
    id: 360424,
    pid: 3604,
    deep: 2,
    name: "修水",
  },
  {
    id: 360425,
    pid: 3604,
    deep: 2,
    name: "永修",
  },
  {
    id: 360426,
    pid: 3604,
    deep: 2,
    name: "德安",
  },
  {
    id: 360428,
    pid: 3604,
    deep: 2,
    name: "都昌",
  },
  {
    id: 360429,
    pid: 3604,
    deep: 2,
    name: "湖口",
  },
  {
    id: 360430,
    pid: 3604,
    deep: 2,
    name: "彭泽",
  },
  {
    id: 360481,
    pid: 3604,
    deep: 2,
    name: "瑞昌",
  },
  {
    id: 360482,
    pid: 3604,
    deep: 2,
    name: "共青城",
  },
  {
    id: 360483,
    pid: 3604,
    deep: 2,
    name: "庐山",
  },
  {
    id: 360502,
    pid: 3605,
    deep: 2,
    name: "渝水",
  },
  {
    id: 360521,
    pid: 3605,
    deep: 2,
    name: "分宜",
  },
  {
    id: 360602,
    pid: 3606,
    deep: 2,
    name: "月湖",
  },
  {
    id: 360603,
    pid: 3606,
    deep: 2,
    name: "余江",
  },
  {
    id: 360681,
    pid: 3606,
    deep: 2,
    name: "贵溪",
  },
  {
    id: 360702,
    pid: 3607,
    deep: 2,
    name: "章贡",
  },
  {
    id: 360703,
    pid: 3607,
    deep: 2,
    name: "南康",
  },
  {
    id: 360704,
    pid: 3607,
    deep: 2,
    name: "赣县",
  },
  {
    id: 360722,
    pid: 3607,
    deep: 2,
    name: "信丰",
  },
  {
    id: 360723,
    pid: 3607,
    deep: 2,
    name: "大余",
  },
  {
    id: 360724,
    pid: 3607,
    deep: 2,
    name: "上犹",
  },
  {
    id: 360725,
    pid: 3607,
    deep: 2,
    name: "崇义",
  },
  {
    id: 360726,
    pid: 3607,
    deep: 2,
    name: "安远",
  },
  {
    id: 360727,
    pid: 3607,
    deep: 2,
    name: "龙南",
  },
  {
    id: 360728,
    pid: 3607,
    deep: 2,
    name: "定南",
  },
  {
    id: 360729,
    pid: 3607,
    deep: 2,
    name: "全南",
  },
  {
    id: 360730,
    pid: 3607,
    deep: 2,
    name: "宁都",
  },
  {
    id: 360731,
    pid: 3607,
    deep: 2,
    name: "于都",
  },
  {
    id: 360732,
    pid: 3607,
    deep: 2,
    name: "兴国",
  },
  {
    id: 360733,
    pid: 3607,
    deep: 2,
    name: "会昌",
  },
  {
    id: 360734,
    pid: 3607,
    deep: 2,
    name: "寻乌",
  },
  {
    id: 360735,
    pid: 3607,
    deep: 2,
    name: "石城",
  },
  {
    id: 360781,
    pid: 3607,
    deep: 2,
    name: "瑞金",
  },
  {
    id: 360802,
    pid: 3608,
    deep: 2,
    name: "吉州",
  },
  {
    id: 360803,
    pid: 3608,
    deep: 2,
    name: "青原",
  },
  {
    id: 360821,
    pid: 3608,
    deep: 2,
    name: "吉安县",
  },
  {
    id: 360822,
    pid: 3608,
    deep: 2,
    name: "吉水",
  },
  {
    id: 360823,
    pid: 3608,
    deep: 2,
    name: "峡江",
  },
  {
    id: 360824,
    pid: 3608,
    deep: 2,
    name: "新干",
  },
  {
    id: 360825,
    pid: 3608,
    deep: 2,
    name: "永丰",
  },
  {
    id: 360826,
    pid: 3608,
    deep: 2,
    name: "泰和",
  },
  {
    id: 360827,
    pid: 3608,
    deep: 2,
    name: "遂川",
  },
  {
    id: 360828,
    pid: 3608,
    deep: 2,
    name: "万安",
  },
  {
    id: 360829,
    pid: 3608,
    deep: 2,
    name: "安福",
  },
  {
    id: 360830,
    pid: 3608,
    deep: 2,
    name: "永新",
  },
  {
    id: 360881,
    pid: 3608,
    deep: 2,
    name: "井冈山",
  },
  {
    id: 360902,
    pid: 3609,
    deep: 2,
    name: "袁州",
  },
  {
    id: 360921,
    pid: 3609,
    deep: 2,
    name: "奉新",
  },
  {
    id: 360922,
    pid: 3609,
    deep: 2,
    name: "万载",
  },
  {
    id: 360923,
    pid: 3609,
    deep: 2,
    name: "上高",
  },
  {
    id: 360924,
    pid: 3609,
    deep: 2,
    name: "宜丰",
  },
  {
    id: 360925,
    pid: 3609,
    deep: 2,
    name: "靖安",
  },
  {
    id: 360926,
    pid: 3609,
    deep: 2,
    name: "铜鼓",
  },
  {
    id: 360981,
    pid: 3609,
    deep: 2,
    name: "丰城",
  },
  {
    id: 360982,
    pid: 3609,
    deep: 2,
    name: "樟树",
  },
  {
    id: 360983,
    pid: 3609,
    deep: 2,
    name: "高安",
  },
  {
    id: 361002,
    pid: 3610,
    deep: 2,
    name: "临川",
  },
  {
    id: 361003,
    pid: 3610,
    deep: 2,
    name: "东乡",
  },
  {
    id: 361021,
    pid: 3610,
    deep: 2,
    name: "南城",
  },
  {
    id: 361022,
    pid: 3610,
    deep: 2,
    name: "黎川",
  },
  {
    id: 361023,
    pid: 3610,
    deep: 2,
    name: "南丰",
  },
  {
    id: 361024,
    pid: 3610,
    deep: 2,
    name: "崇仁",
  },
  {
    id: 361025,
    pid: 3610,
    deep: 2,
    name: "乐安",
  },
  {
    id: 361026,
    pid: 3610,
    deep: 2,
    name: "宜黄",
  },
  {
    id: 361027,
    pid: 3610,
    deep: 2,
    name: "金溪",
  },
  {
    id: 361028,
    pid: 3610,
    deep: 2,
    name: "资溪",
  },
  {
    id: 361030,
    pid: 3610,
    deep: 2,
    name: "广昌",
  },
  {
    id: 361102,
    pid: 3611,
    deep: 2,
    name: "信州",
  },
  {
    id: 361103,
    pid: 3611,
    deep: 2,
    name: "广丰",
  },
  {
    id: 361121,
    pid: 3611,
    deep: 2,
    name: "广信",
  },
  {
    id: 361123,
    pid: 3611,
    deep: 2,
    name: "玉山",
  },
  {
    id: 361124,
    pid: 3611,
    deep: 2,
    name: "铅山",
  },
  {
    id: 361125,
    pid: 3611,
    deep: 2,
    name: "横峰",
  },
  {
    id: 361126,
    pid: 3611,
    deep: 2,
    name: "弋阳",
  },
  {
    id: 361127,
    pid: 3611,
    deep: 2,
    name: "余干",
  },
  {
    id: 361128,
    pid: 3611,
    deep: 2,
    name: "鄱阳",
  },
  {
    id: 361129,
    pid: 3611,
    deep: 2,
    name: "万年",
  },
  {
    id: 361130,
    pid: 3611,
    deep: 2,
    name: "婺源",
  },
  {
    id: 361181,
    pid: 3611,
    deep: 2,
    name: "德兴",
  },
  {
    id: 370102,
    pid: 3701,
    deep: 2,
    name: "历下",
  },
  {
    id: 370103,
    pid: 3701,
    deep: 2,
    name: "市中",
  },
  {
    id: 370104,
    pid: 3701,
    deep: 2,
    name: "槐荫",
  },
  {
    id: 370105,
    pid: 3701,
    deep: 2,
    name: "天桥",
  },
  {
    id: 370112,
    pid: 3701,
    deep: 2,
    name: "历城",
  },
  {
    id: 370113,
    pid: 3701,
    deep: 2,
    name: "长清",
  },
  {
    id: 370114,
    pid: 3701,
    deep: 2,
    name: "章丘",
  },
  {
    id: 370115,
    pid: 3701,
    deep: 2,
    name: "济阳",
  },
  {
    id: 370116,
    pid: 3701,
    deep: 2,
    name: "莱芜",
  },
  {
    id: 370117,
    pid: 3701,
    deep: 2,
    name: "钢城",
  },
  {
    id: 370124,
    pid: 3701,
    deep: 2,
    name: "平阴",
  },
  {
    id: 370126,
    pid: 3701,
    deep: 2,
    name: "商河",
  },
  {
    id: 370202,
    pid: 3702,
    deep: 2,
    name: "市南",
  },
  {
    id: 370203,
    pid: 3702,
    deep: 2,
    name: "市北",
  },
  {
    id: 370211,
    pid: 3702,
    deep: 2,
    name: "黄岛",
  },
  {
    id: 370212,
    pid: 3702,
    deep: 2,
    name: "崂山",
  },
  {
    id: 370213,
    pid: 3702,
    deep: 2,
    name: "李沧",
  },
  {
    id: 370214,
    pid: 3702,
    deep: 2,
    name: "城阳",
  },
  {
    id: 370215,
    pid: 3702,
    deep: 2,
    name: "即墨",
  },
  {
    id: 370281,
    pid: 3702,
    deep: 2,
    name: "胶州",
  },
  {
    id: 370283,
    pid: 3702,
    deep: 2,
    name: "平度",
  },
  {
    id: 370285,
    pid: 3702,
    deep: 2,
    name: "莱西",
  },
  {
    id: 370302,
    pid: 3703,
    deep: 2,
    name: "淄川",
  },
  {
    id: 370303,
    pid: 3703,
    deep: 2,
    name: "张店",
  },
  {
    id: 370304,
    pid: 3703,
    deep: 2,
    name: "博山",
  },
  {
    id: 370305,
    pid: 3703,
    deep: 2,
    name: "临淄",
  },
  {
    id: 370306,
    pid: 3703,
    deep: 2,
    name: "周村",
  },
  {
    id: 370321,
    pid: 3703,
    deep: 2,
    name: "桓台",
  },
  {
    id: 370322,
    pid: 3703,
    deep: 2,
    name: "高青",
  },
  {
    id: 370323,
    pid: 3703,
    deep: 2,
    name: "沂源",
  },
  {
    id: 370402,
    pid: 3704,
    deep: 2,
    name: "市中",
  },
  {
    id: 370403,
    pid: 3704,
    deep: 2,
    name: "薛城",
  },
  {
    id: 370404,
    pid: 3704,
    deep: 2,
    name: "峄城",
  },
  {
    id: 370405,
    pid: 3704,
    deep: 2,
    name: "台儿庄",
  },
  {
    id: 370406,
    pid: 3704,
    deep: 2,
    name: "山亭",
  },
  {
    id: 370481,
    pid: 3704,
    deep: 2,
    name: "滕州",
  },
  {
    id: 370502,
    pid: 3705,
    deep: 2,
    name: "东营区",
  },
  {
    id: 370503,
    pid: 3705,
    deep: 2,
    name: "河口",
  },
  {
    id: 370505,
    pid: 3705,
    deep: 2,
    name: "垦利",
  },
  {
    id: 370522,
    pid: 3705,
    deep: 2,
    name: "利津",
  },
  {
    id: 370523,
    pid: 3705,
    deep: 2,
    name: "广饶",
  },
  {
    id: 370602,
    pid: 3706,
    deep: 2,
    name: "芝罘",
  },
  {
    id: 370611,
    pid: 3706,
    deep: 2,
    name: "福山",
  },
  {
    id: 370612,
    pid: 3706,
    deep: 2,
    name: "牟平",
  },
  {
    id: 370613,
    pid: 3706,
    deep: 2,
    name: "莱山",
  },
  {
    id: 370634,
    pid: 3706,
    deep: 2,
    name: "长岛",
  },
  {
    id: 370681,
    pid: 3706,
    deep: 2,
    name: "龙口",
  },
  {
    id: 370682,
    pid: 3706,
    deep: 2,
    name: "莱阳",
  },
  {
    id: 370683,
    pid: 3706,
    deep: 2,
    name: "莱州",
  },
  {
    id: 370684,
    pid: 3706,
    deep: 2,
    name: "蓬莱",
  },
  {
    id: 370685,
    pid: 3706,
    deep: 2,
    name: "招远",
  },
  {
    id: 370686,
    pid: 3706,
    deep: 2,
    name: "栖霞",
  },
  {
    id: 370687,
    pid: 3706,
    deep: 2,
    name: "海阳",
  },
  {
    id: 370702,
    pid: 3707,
    deep: 2,
    name: "潍城",
  },
  {
    id: 370703,
    pid: 3707,
    deep: 2,
    name: "寒亭",
  },
  {
    id: 370704,
    pid: 3707,
    deep: 2,
    name: "坊子",
  },
  {
    id: 370705,
    pid: 3707,
    deep: 2,
    name: "奎文",
  },
  {
    id: 370724,
    pid: 3707,
    deep: 2,
    name: "临朐",
  },
  {
    id: 370725,
    pid: 3707,
    deep: 2,
    name: "昌乐",
  },
  {
    id: 370781,
    pid: 3707,
    deep: 2,
    name: "青州",
  },
  {
    id: 370782,
    pid: 3707,
    deep: 2,
    name: "诸城",
  },
  {
    id: 370783,
    pid: 3707,
    deep: 2,
    name: "寿光",
  },
  {
    id: 370784,
    pid: 3707,
    deep: 2,
    name: "安丘",
  },
  {
    id: 370785,
    pid: 3707,
    deep: 2,
    name: "高密",
  },
  {
    id: 370786,
    pid: 3707,
    deep: 2,
    name: "昌邑",
  },
  {
    id: 370811,
    pid: 3708,
    deep: 2,
    name: "任城",
  },
  {
    id: 370812,
    pid: 3708,
    deep: 2,
    name: "兖州",
  },
  {
    id: 370826,
    pid: 3708,
    deep: 2,
    name: "微山",
  },
  {
    id: 370827,
    pid: 3708,
    deep: 2,
    name: "鱼台",
  },
  {
    id: 370828,
    pid: 3708,
    deep: 2,
    name: "金乡",
  },
  {
    id: 370829,
    pid: 3708,
    deep: 2,
    name: "嘉祥",
  },
  {
    id: 370830,
    pid: 3708,
    deep: 2,
    name: "汶上",
  },
  {
    id: 370831,
    pid: 3708,
    deep: 2,
    name: "泗水",
  },
  {
    id: 370832,
    pid: 3708,
    deep: 2,
    name: "梁山",
  },
  {
    id: 370881,
    pid: 3708,
    deep: 2,
    name: "曲阜",
  },
  {
    id: 370883,
    pid: 3708,
    deep: 2,
    name: "邹城",
  },
  {
    id: 370902,
    pid: 3709,
    deep: 2,
    name: "泰山",
  },
  {
    id: 370911,
    pid: 3709,
    deep: 2,
    name: "岱岳",
  },
  {
    id: 370921,
    pid: 3709,
    deep: 2,
    name: "宁阳",
  },
  {
    id: 370923,
    pid: 3709,
    deep: 2,
    name: "东平",
  },
  {
    id: 370982,
    pid: 3709,
    deep: 2,
    name: "新泰",
  },
  {
    id: 370983,
    pid: 3709,
    deep: 2,
    name: "肥城",
  },
  {
    id: 371002,
    pid: 3710,
    deep: 2,
    name: "环翠",
  },
  {
    id: 371003,
    pid: 3710,
    deep: 2,
    name: "文登",
  },
  {
    id: 371082,
    pid: 3710,
    deep: 2,
    name: "荣成",
  },
  {
    id: 371083,
    pid: 3710,
    deep: 2,
    name: "乳山",
  },
  {
    id: 371102,
    pid: 3711,
    deep: 2,
    name: "东港",
  },
  {
    id: 371103,
    pid: 3711,
    deep: 2,
    name: "岚山",
  },
  {
    id: 371121,
    pid: 3711,
    deep: 2,
    name: "五莲",
  },
  {
    id: 371122,
    pid: 3711,
    deep: 2,
    name: "莒县",
  },
  {
    id: 371302,
    pid: 3713,
    deep: 2,
    name: "兰山",
  },
  {
    id: 371311,
    pid: 3713,
    deep: 2,
    name: "罗庄",
  },
  {
    id: 371312,
    pid: 3713,
    deep: 2,
    name: "河东",
  },
  {
    id: 371321,
    pid: 3713,
    deep: 2,
    name: "沂南",
  },
  {
    id: 371322,
    pid: 3713,
    deep: 2,
    name: "郯城",
  },
  {
    id: 371323,
    pid: 3713,
    deep: 2,
    name: "沂水",
  },
  {
    id: 371324,
    pid: 3713,
    deep: 2,
    name: "兰陵",
  },
  {
    id: 371325,
    pid: 3713,
    deep: 2,
    name: "费县",
  },
  {
    id: 371326,
    pid: 3713,
    deep: 2,
    name: "平邑",
  },
  {
    id: 371327,
    pid: 3713,
    deep: 2,
    name: "莒南",
  },
  {
    id: 371328,
    pid: 3713,
    deep: 2,
    name: "蒙阴",
  },
  {
    id: 371329,
    pid: 3713,
    deep: 2,
    name: "临沭",
  },
  {
    id: 371402,
    pid: 3714,
    deep: 2,
    name: "德城",
  },
  {
    id: 371403,
    pid: 3714,
    deep: 2,
    name: "陵城",
  },
  {
    id: 371422,
    pid: 3714,
    deep: 2,
    name: "宁津",
  },
  {
    id: 371423,
    pid: 3714,
    deep: 2,
    name: "庆云",
  },
  {
    id: 371424,
    pid: 3714,
    deep: 2,
    name: "临邑",
  },
  {
    id: 371425,
    pid: 3714,
    deep: 2,
    name: "齐河",
  },
  {
    id: 371426,
    pid: 3714,
    deep: 2,
    name: "平原",
  },
  {
    id: 371427,
    pid: 3714,
    deep: 2,
    name: "夏津",
  },
  {
    id: 371428,
    pid: 3714,
    deep: 2,
    name: "武城",
  },
  {
    id: 371481,
    pid: 3714,
    deep: 2,
    name: "乐陵",
  },
  {
    id: 371482,
    pid: 3714,
    deep: 2,
    name: "禹城",
  },
  {
    id: 371502,
    pid: 3715,
    deep: 2,
    name: "东昌府",
  },
  {
    id: 371521,
    pid: 3715,
    deep: 2,
    name: "阳谷",
  },
  {
    id: 371522,
    pid: 3715,
    deep: 2,
    name: "莘县",
  },
  {
    id: 371523,
    pid: 3715,
    deep: 2,
    name: "茌平",
  },
  {
    id: 371524,
    pid: 3715,
    deep: 2,
    name: "东阿",
  },
  {
    id: 371525,
    pid: 3715,
    deep: 2,
    name: "冠县",
  },
  {
    id: 371526,
    pid: 3715,
    deep: 2,
    name: "高唐",
  },
  {
    id: 371581,
    pid: 3715,
    deep: 2,
    name: "临清",
  },
  {
    id: 371602,
    pid: 3716,
    deep: 2,
    name: "滨城",
  },
  {
    id: 371603,
    pid: 3716,
    deep: 2,
    name: "沾化",
  },
  {
    id: 371621,
    pid: 3716,
    deep: 2,
    name: "惠民",
  },
  {
    id: 371622,
    pid: 3716,
    deep: 2,
    name: "阳信",
  },
  {
    id: 371623,
    pid: 3716,
    deep: 2,
    name: "无棣",
  },
  {
    id: 371625,
    pid: 3716,
    deep: 2,
    name: "博兴",
  },
  {
    id: 371681,
    pid: 3716,
    deep: 2,
    name: "邹平",
  },
  {
    id: 371702,
    pid: 3717,
    deep: 2,
    name: "牡丹",
  },
  {
    id: 371703,
    pid: 3717,
    deep: 2,
    name: "定陶",
  },
  {
    id: 371721,
    pid: 3717,
    deep: 2,
    name: "曹县",
  },
  {
    id: 371722,
    pid: 3717,
    deep: 2,
    name: "单县",
  },
  {
    id: 371723,
    pid: 3717,
    deep: 2,
    name: "成武",
  },
  {
    id: 371724,
    pid: 3717,
    deep: 2,
    name: "巨野",
  },
  {
    id: 371725,
    pid: 3717,
    deep: 2,
    name: "郓城",
  },
  {
    id: 371726,
    pid: 3717,
    deep: 2,
    name: "鄄城",
  },
  {
    id: 371728,
    pid: 3717,
    deep: 2,
    name: "东明",
  },
  {
    id: 410102,
    pid: 4101,
    deep: 2,
    name: "中原",
  },
  {
    id: 410103,
    pid: 4101,
    deep: 2,
    name: "二七",
  },
  {
    id: 410104,
    pid: 4101,
    deep: 2,
    name: "管城回族区",
  },
  {
    id: 410105,
    pid: 4101,
    deep: 2,
    name: "金水",
  },
  {
    id: 410106,
    pid: 4101,
    deep: 2,
    name: "上街",
  },
  {
    id: 410108,
    pid: 4101,
    deep: 2,
    name: "惠济",
  },
  {
    id: 410122,
    pid: 4101,
    deep: 2,
    name: "中牟",
  },
  {
    id: 410181,
    pid: 4101,
    deep: 2,
    name: "巩义",
  },
  {
    id: 410182,
    pid: 4101,
    deep: 2,
    name: "荥阳",
  },
  {
    id: 410183,
    pid: 4101,
    deep: 2,
    name: "新密",
  },
  {
    id: 410184,
    pid: 4101,
    deep: 2,
    name: "新郑",
  },
  {
    id: 410185,
    pid: 4101,
    deep: 2,
    name: "登封",
  },
  {
    id: 410202,
    pid: 4102,
    deep: 2,
    name: "龙亭",
  },
  {
    id: 410203,
    pid: 4102,
    deep: 2,
    name: "顺河回族区",
  },
  {
    id: 410204,
    pid: 4102,
    deep: 2,
    name: "鼓楼",
  },
  {
    id: 410205,
    pid: 4102,
    deep: 2,
    name: "禹王台",
  },
  {
    id: 410212,
    pid: 4102,
    deep: 2,
    name: "祥符",
  },
  {
    id: 410221,
    pid: 4102,
    deep: 2,
    name: "杞县",
  },
  {
    id: 410222,
    pid: 4102,
    deep: 2,
    name: "通许",
  },
  {
    id: 410223,
    pid: 4102,
    deep: 2,
    name: "尉氏",
  },
  {
    id: 410225,
    pid: 4102,
    deep: 2,
    name: "兰考",
  },
  {
    id: 410302,
    pid: 4103,
    deep: 2,
    name: "老城",
  },
  {
    id: 410303,
    pid: 4103,
    deep: 2,
    name: "西工",
  },
  {
    id: 410304,
    pid: 4103,
    deep: 2,
    name: "瀍河回族区",
  },
  {
    id: 410305,
    pid: 4103,
    deep: 2,
    name: "涧西",
  },
  {
    id: 410306,
    pid: 4103,
    deep: 2,
    name: "吉利",
  },
  {
    id: 410311,
    pid: 4103,
    deep: 2,
    name: "洛龙",
  },
  {
    id: 410322,
    pid: 4103,
    deep: 2,
    name: "孟津",
  },
  {
    id: 410323,
    pid: 4103,
    deep: 2,
    name: "新安",
  },
  {
    id: 410324,
    pid: 4103,
    deep: 2,
    name: "栾川",
  },
  {
    id: 410325,
    pid: 4103,
    deep: 2,
    name: "嵩县",
  },
  {
    id: 410326,
    pid: 4103,
    deep: 2,
    name: "汝阳",
  },
  {
    id: 410327,
    pid: 4103,
    deep: 2,
    name: "宜阳",
  },
  {
    id: 410328,
    pid: 4103,
    deep: 2,
    name: "洛宁",
  },
  {
    id: 410329,
    pid: 4103,
    deep: 2,
    name: "伊川",
  },
  {
    id: 410381,
    pid: 4103,
    deep: 2,
    name: "偃师",
  },
  {
    id: 410402,
    pid: 4104,
    deep: 2,
    name: "新华",
  },
  {
    id: 410403,
    pid: 4104,
    deep: 2,
    name: "卫东",
  },
  {
    id: 410404,
    pid: 4104,
    deep: 2,
    name: "石龙",
  },
  {
    id: 410411,
    pid: 4104,
    deep: 2,
    name: "湛河",
  },
  {
    id: 410421,
    pid: 4104,
    deep: 2,
    name: "宝丰",
  },
  {
    id: 410422,
    pid: 4104,
    deep: 2,
    name: "叶县",
  },
  {
    id: 410423,
    pid: 4104,
    deep: 2,
    name: "鲁山",
  },
  {
    id: 410425,
    pid: 4104,
    deep: 2,
    name: "郏县",
  },
  {
    id: 410481,
    pid: 4104,
    deep: 2,
    name: "舞钢",
  },
  {
    id: 410482,
    pid: 4104,
    deep: 2,
    name: "汝州",
  },
  {
    id: 410502,
    pid: 4105,
    deep: 2,
    name: "文峰",
  },
  {
    id: 410503,
    pid: 4105,
    deep: 2,
    name: "北关",
  },
  {
    id: 410505,
    pid: 4105,
    deep: 2,
    name: "殷都",
  },
  {
    id: 410506,
    pid: 4105,
    deep: 2,
    name: "龙安",
  },
  {
    id: 410522,
    pid: 4105,
    deep: 2,
    name: "安阳县",
  },
  {
    id: 410523,
    pid: 4105,
    deep: 2,
    name: "汤阴",
  },
  {
    id: 410526,
    pid: 4105,
    deep: 2,
    name: "滑县",
  },
  {
    id: 410527,
    pid: 4105,
    deep: 2,
    name: "内黄",
  },
  {
    id: 410581,
    pid: 4105,
    deep: 2,
    name: "林州",
  },
  {
    id: 410602,
    pid: 4106,
    deep: 2,
    name: "鹤山",
  },
  {
    id: 410603,
    pid: 4106,
    deep: 2,
    name: "山城",
  },
  {
    id: 410611,
    pid: 4106,
    deep: 2,
    name: "淇滨",
  },
  {
    id: 410621,
    pid: 4106,
    deep: 2,
    name: "浚县",
  },
  {
    id: 410622,
    pid: 4106,
    deep: 2,
    name: "淇县",
  },
  {
    id: 410702,
    pid: 4107,
    deep: 2,
    name: "红旗",
  },
  {
    id: 410703,
    pid: 4107,
    deep: 2,
    name: "卫滨",
  },
  {
    id: 410704,
    pid: 4107,
    deep: 2,
    name: "凤泉",
  },
  {
    id: 410711,
    pid: 4107,
    deep: 2,
    name: "牧野",
  },
  {
    id: 410721,
    pid: 4107,
    deep: 2,
    name: "新乡县",
  },
  {
    id: 410724,
    pid: 4107,
    deep: 2,
    name: "获嘉",
  },
  {
    id: 410725,
    pid: 4107,
    deep: 2,
    name: "原阳",
  },
  {
    id: 410726,
    pid: 4107,
    deep: 2,
    name: "延津",
  },
  {
    id: 410727,
    pid: 4107,
    deep: 2,
    name: "封丘",
  },
  {
    id: 410728,
    pid: 4107,
    deep: 2,
    name: "长垣",
  },
  {
    id: 410781,
    pid: 4107,
    deep: 2,
    name: "卫辉",
  },
  {
    id: 410782,
    pid: 4107,
    deep: 2,
    name: "辉县",
  },
  {
    id: 410802,
    pid: 4108,
    deep: 2,
    name: "解放",
  },
  {
    id: 410803,
    pid: 4108,
    deep: 2,
    name: "中站",
  },
  {
    id: 410804,
    pid: 4108,
    deep: 2,
    name: "马村",
  },
  {
    id: 410811,
    pid: 4108,
    deep: 2,
    name: "山阳",
  },
  {
    id: 410821,
    pid: 4108,
    deep: 2,
    name: "修武",
  },
  {
    id: 410822,
    pid: 4108,
    deep: 2,
    name: "博爱",
  },
  {
    id: 410823,
    pid: 4108,
    deep: 2,
    name: "武陟",
  },
  {
    id: 410825,
    pid: 4108,
    deep: 2,
    name: "温县",
  },
  {
    id: 410882,
    pid: 4108,
    deep: 2,
    name: "沁阳",
  },
  {
    id: 410883,
    pid: 4108,
    deep: 2,
    name: "孟州",
  },
  {
    id: 410902,
    pid: 4109,
    deep: 2,
    name: "华龙",
  },
  {
    id: 410922,
    pid: 4109,
    deep: 2,
    name: "清丰",
  },
  {
    id: 410923,
    pid: 4109,
    deep: 2,
    name: "南乐",
  },
  {
    id: 410926,
    pid: 4109,
    deep: 2,
    name: "范县",
  },
  {
    id: 410927,
    pid: 4109,
    deep: 2,
    name: "台前",
  },
  {
    id: 410928,
    pid: 4109,
    deep: 2,
    name: "濮阳县",
  },
  {
    id: 411002,
    pid: 4110,
    deep: 2,
    name: "魏都",
  },
  {
    id: 411003,
    pid: 4110,
    deep: 2,
    name: "建安",
  },
  {
    id: 411024,
    pid: 4110,
    deep: 2,
    name: "鄢陵",
  },
  {
    id: 411025,
    pid: 4110,
    deep: 2,
    name: "襄城",
  },
  {
    id: 411081,
    pid: 4110,
    deep: 2,
    name: "禹州",
  },
  {
    id: 411082,
    pid: 4110,
    deep: 2,
    name: "长葛",
  },
  {
    id: 411102,
    pid: 4111,
    deep: 2,
    name: "源汇",
  },
  {
    id: 411103,
    pid: 4111,
    deep: 2,
    name: "郾城",
  },
  {
    id: 411104,
    pid: 4111,
    deep: 2,
    name: "召陵",
  },
  {
    id: 411121,
    pid: 4111,
    deep: 2,
    name: "舞阳",
  },
  {
    id: 411122,
    pid: 4111,
    deep: 2,
    name: "临颍",
  },
  {
    id: 411202,
    pid: 4112,
    deep: 2,
    name: "湖滨",
  },
  {
    id: 411203,
    pid: 4112,
    deep: 2,
    name: "陕州",
  },
  {
    id: 411221,
    pid: 4112,
    deep: 2,
    name: "渑池",
  },
  {
    id: 411224,
    pid: 4112,
    deep: 2,
    name: "卢氏",
  },
  {
    id: 411281,
    pid: 4112,
    deep: 2,
    name: "义马",
  },
  {
    id: 411282,
    pid: 4112,
    deep: 2,
    name: "灵宝",
  },
  {
    id: 411302,
    pid: 4113,
    deep: 2,
    name: "宛城",
  },
  {
    id: 411303,
    pid: 4113,
    deep: 2,
    name: "卧龙",
  },
  {
    id: 411321,
    pid: 4113,
    deep: 2,
    name: "南召",
  },
  {
    id: 411322,
    pid: 4113,
    deep: 2,
    name: "方城",
  },
  {
    id: 411323,
    pid: 4113,
    deep: 2,
    name: "西峡",
  },
  {
    id: 411324,
    pid: 4113,
    deep: 2,
    name: "镇平",
  },
  {
    id: 411325,
    pid: 4113,
    deep: 2,
    name: "内乡",
  },
  {
    id: 411326,
    pid: 4113,
    deep: 2,
    name: "淅川",
  },
  {
    id: 411327,
    pid: 4113,
    deep: 2,
    name: "社旗",
  },
  {
    id: 411328,
    pid: 4113,
    deep: 2,
    name: "唐河",
  },
  {
    id: 411329,
    pid: 4113,
    deep: 2,
    name: "新野",
  },
  {
    id: 411330,
    pid: 4113,
    deep: 2,
    name: "桐柏",
  },
  {
    id: 411381,
    pid: 4113,
    deep: 2,
    name: "邓州",
  },
  {
    id: 411402,
    pid: 4114,
    deep: 2,
    name: "梁园",
  },
  {
    id: 411403,
    pid: 4114,
    deep: 2,
    name: "睢阳",
  },
  {
    id: 411421,
    pid: 4114,
    deep: 2,
    name: "民权",
  },
  {
    id: 411422,
    pid: 4114,
    deep: 2,
    name: "睢县",
  },
  {
    id: 411423,
    pid: 4114,
    deep: 2,
    name: "宁陵",
  },
  {
    id: 411424,
    pid: 4114,
    deep: 2,
    name: "柘城",
  },
  {
    id: 411425,
    pid: 4114,
    deep: 2,
    name: "虞城",
  },
  {
    id: 411426,
    pid: 4114,
    deep: 2,
    name: "夏邑",
  },
  {
    id: 411481,
    pid: 4114,
    deep: 2,
    name: "永城",
  },
  {
    id: 411502,
    pid: 4115,
    deep: 2,
    name: "浉河",
  },
  {
    id: 411503,
    pid: 4115,
    deep: 2,
    name: "平桥",
  },
  {
    id: 411521,
    pid: 4115,
    deep: 2,
    name: "罗山",
  },
  {
    id: 411522,
    pid: 4115,
    deep: 2,
    name: "光山",
  },
  {
    id: 411523,
    pid: 4115,
    deep: 2,
    name: "新县",
  },
  {
    id: 411524,
    pid: 4115,
    deep: 2,
    name: "商城",
  },
  {
    id: 411525,
    pid: 4115,
    deep: 2,
    name: "固始",
  },
  {
    id: 411526,
    pid: 4115,
    deep: 2,
    name: "潢川",
  },
  {
    id: 411527,
    pid: 4115,
    deep: 2,
    name: "淮滨",
  },
  {
    id: 411528,
    pid: 4115,
    deep: 2,
    name: "息县",
  },
  {
    id: 411602,
    pid: 4116,
    deep: 2,
    name: "川汇",
  },
  {
    id: 411621,
    pid: 4116,
    deep: 2,
    name: "扶沟",
  },
  {
    id: 411622,
    pid: 4116,
    deep: 2,
    name: "西华",
  },
  {
    id: 411623,
    pid: 4116,
    deep: 2,
    name: "商水",
  },
  {
    id: 411624,
    pid: 4116,
    deep: 2,
    name: "沈丘",
  },
  {
    id: 411625,
    pid: 4116,
    deep: 2,
    name: "郸城",
  },
  {
    id: 411626,
    pid: 4116,
    deep: 2,
    name: "淮阳",
  },
  {
    id: 411627,
    pid: 4116,
    deep: 2,
    name: "太康",
  },
  {
    id: 411628,
    pid: 4116,
    deep: 2,
    name: "鹿邑",
  },
  {
    id: 411681,
    pid: 4116,
    deep: 2,
    name: "项城",
  },
  {
    id: 411702,
    pid: 4117,
    deep: 2,
    name: "驿城",
  },
  {
    id: 411721,
    pid: 4117,
    deep: 2,
    name: "西平",
  },
  {
    id: 411722,
    pid: 4117,
    deep: 2,
    name: "上蔡",
  },
  {
    id: 411723,
    pid: 4117,
    deep: 2,
    name: "平舆",
  },
  {
    id: 411724,
    pid: 4117,
    deep: 2,
    name: "正阳",
  },
  {
    id: 411725,
    pid: 4117,
    deep: 2,
    name: "确山",
  },
  {
    id: 411726,
    pid: 4117,
    deep: 2,
    name: "泌阳",
  },
  {
    id: 411727,
    pid: 4117,
    deep: 2,
    name: "汝南",
  },
  {
    id: 411728,
    pid: 4117,
    deep: 2,
    name: "遂平",
  },
  {
    id: 411729,
    pid: 4117,
    deep: 2,
    name: "新蔡",
  },
  {
    id: 419001,
    pid: 41,
    deep: 1,
    name: "济源",
  },
  {
    id: 420102,
    pid: 4201,
    deep: 2,
    name: "江岸",
  },
  {
    id: 420103,
    pid: 4201,
    deep: 2,
    name: "江汉",
  },
  {
    id: 420104,
    pid: 4201,
    deep: 2,
    name: "硚口",
  },
  {
    id: 420105,
    pid: 4201,
    deep: 2,
    name: "汉阳",
  },
  {
    id: 420106,
    pid: 4201,
    deep: 2,
    name: "武昌",
  },
  {
    id: 420107,
    pid: 4201,
    deep: 2,
    name: "青山",
  },
  {
    id: 420111,
    pid: 4201,
    deep: 2,
    name: "洪山",
  },
  {
    id: 420112,
    pid: 4201,
    deep: 2,
    name: "东西湖",
  },
  {
    id: 420113,
    pid: 4201,
    deep: 2,
    name: "汉南",
  },
  {
    id: 420114,
    pid: 4201,
    deep: 2,
    name: "蔡甸",
  },
  {
    id: 420115,
    pid: 4201,
    deep: 2,
    name: "江夏",
  },
  {
    id: 420116,
    pid: 4201,
    deep: 2,
    name: "黄陂",
  },
  {
    id: 420117,
    pid: 4201,
    deep: 2,
    name: "新洲",
  },
  {
    id: 420202,
    pid: 4202,
    deep: 2,
    name: "黄石港",
  },
  {
    id: 420203,
    pid: 4202,
    deep: 2,
    name: "西塞山",
  },
  {
    id: 420204,
    pid: 4202,
    deep: 2,
    name: "下陆",
  },
  {
    id: 420205,
    pid: 4202,
    deep: 2,
    name: "铁山",
  },
  {
    id: 420222,
    pid: 4202,
    deep: 2,
    name: "阳新",
  },
  {
    id: 420281,
    pid: 4202,
    deep: 2,
    name: "大冶",
  },
  {
    id: 420302,
    pid: 4203,
    deep: 2,
    name: "茅箭",
  },
  {
    id: 420303,
    pid: 4203,
    deep: 2,
    name: "张湾",
  },
  {
    id: 420304,
    pid: 4203,
    deep: 2,
    name: "郧阳",
  },
  {
    id: 420322,
    pid: 4203,
    deep: 2,
    name: "郧西",
  },
  {
    id: 420323,
    pid: 4203,
    deep: 2,
    name: "竹山",
  },
  {
    id: 420324,
    pid: 4203,
    deep: 2,
    name: "竹溪",
  },
  {
    id: 420325,
    pid: 4203,
    deep: 2,
    name: "房县",
  },
  {
    id: 420381,
    pid: 4203,
    deep: 2,
    name: "丹江口",
  },
  {
    id: 420502,
    pid: 4205,
    deep: 2,
    name: "西陵",
  },
  {
    id: 420503,
    pid: 4205,
    deep: 2,
    name: "伍家岗",
  },
  {
    id: 420504,
    pid: 4205,
    deep: 2,
    name: "点军",
  },
  {
    id: 420505,
    pid: 4205,
    deep: 2,
    name: "猇亭",
  },
  {
    id: 420506,
    pid: 4205,
    deep: 2,
    name: "夷陵",
  },
  {
    id: 420525,
    pid: 4205,
    deep: 2,
    name: "远安",
  },
  {
    id: 420526,
    pid: 4205,
    deep: 2,
    name: "兴山",
  },
  {
    id: 420527,
    pid: 4205,
    deep: 2,
    name: "秭归",
  },
  {
    id: 420528,
    pid: 4205,
    deep: 2,
    name: "长阳",
  },
  {
    id: 420529,
    pid: 4205,
    deep: 2,
    name: "五峰",
  },
  {
    id: 420581,
    pid: 4205,
    deep: 2,
    name: "宜都",
  },
  {
    id: 420582,
    pid: 4205,
    deep: 2,
    name: "当阳",
  },
  {
    id: 420583,
    pid: 4205,
    deep: 2,
    name: "枝江",
  },
  {
    id: 420602,
    pid: 4206,
    deep: 2,
    name: "襄城",
  },
  {
    id: 420606,
    pid: 4206,
    deep: 2,
    name: "樊城",
  },
  {
    id: 420607,
    pid: 4206,
    deep: 2,
    name: "襄州",
  },
  {
    id: 420624,
    pid: 4206,
    deep: 2,
    name: "南漳",
  },
  {
    id: 420625,
    pid: 4206,
    deep: 2,
    name: "谷城",
  },
  {
    id: 420626,
    pid: 4206,
    deep: 2,
    name: "保康",
  },
  {
    id: 420682,
    pid: 4206,
    deep: 2,
    name: "老河口",
  },
  {
    id: 420683,
    pid: 4206,
    deep: 2,
    name: "枣阳",
  },
  {
    id: 420684,
    pid: 4206,
    deep: 2,
    name: "宜城",
  },
  {
    id: 420702,
    pid: 4207,
    deep: 2,
    name: "梁子湖",
  },
  {
    id: 420703,
    pid: 4207,
    deep: 2,
    name: "华容",
  },
  {
    id: 420704,
    pid: 4207,
    deep: 2,
    name: "鄂城",
  },
  {
    id: 420802,
    pid: 4208,
    deep: 2,
    name: "东宝",
  },
  {
    id: 420804,
    pid: 4208,
    deep: 2,
    name: "掇刀",
  },
  {
    id: 420822,
    pid: 4208,
    deep: 2,
    name: "沙洋",
  },
  {
    id: 420881,
    pid: 4208,
    deep: 2,
    name: "钟祥",
  },
  {
    id: 420882,
    pid: 4208,
    deep: 2,
    name: "京山",
  },
  {
    id: 420902,
    pid: 4209,
    deep: 2,
    name: "孝南",
  },
  {
    id: 420921,
    pid: 4209,
    deep: 2,
    name: "孝昌",
  },
  {
    id: 420922,
    pid: 4209,
    deep: 2,
    name: "大悟",
  },
  {
    id: 420923,
    pid: 4209,
    deep: 2,
    name: "云梦",
  },
  {
    id: 420981,
    pid: 4209,
    deep: 2,
    name: "应城",
  },
  {
    id: 420982,
    pid: 4209,
    deep: 2,
    name: "安陆",
  },
  {
    id: 420984,
    pid: 4209,
    deep: 2,
    name: "汉川",
  },
  {
    id: 421002,
    pid: 4210,
    deep: 2,
    name: "沙市",
  },
  {
    id: 421003,
    pid: 4210,
    deep: 2,
    name: "荆州区",
  },
  {
    id: 421022,
    pid: 4210,
    deep: 2,
    name: "公安",
  },
  {
    id: 421023,
    pid: 4210,
    deep: 2,
    name: "监利",
  },
  {
    id: 421024,
    pid: 4210,
    deep: 2,
    name: "江陵",
  },
  {
    id: 421081,
    pid: 4210,
    deep: 2,
    name: "石首",
  },
  {
    id: 421083,
    pid: 4210,
    deep: 2,
    name: "洪湖",
  },
  {
    id: 421087,
    pid: 4210,
    deep: 2,
    name: "松滋",
  },
  {
    id: 421102,
    pid: 4211,
    deep: 2,
    name: "黄州",
  },
  {
    id: 421121,
    pid: 4211,
    deep: 2,
    name: "团风",
  },
  {
    id: 421122,
    pid: 4211,
    deep: 2,
    name: "红安",
  },
  {
    id: 421123,
    pid: 4211,
    deep: 2,
    name: "罗田",
  },
  {
    id: 421124,
    pid: 4211,
    deep: 2,
    name: "英山",
  },
  {
    id: 421125,
    pid: 4211,
    deep: 2,
    name: "浠水",
  },
  {
    id: 421126,
    pid: 4211,
    deep: 2,
    name: "蕲春",
  },
  {
    id: 421127,
    pid: 4211,
    deep: 2,
    name: "黄梅",
  },
  {
    id: 421181,
    pid: 4211,
    deep: 2,
    name: "麻城",
  },
  {
    id: 421182,
    pid: 4211,
    deep: 2,
    name: "武穴",
  },
  {
    id: 421202,
    pid: 4212,
    deep: 2,
    name: "咸安",
  },
  {
    id: 421221,
    pid: 4212,
    deep: 2,
    name: "嘉鱼",
  },
  {
    id: 421222,
    pid: 4212,
    deep: 2,
    name: "通城",
  },
  {
    id: 421223,
    pid: 4212,
    deep: 2,
    name: "崇阳",
  },
  {
    id: 421224,
    pid: 4212,
    deep: 2,
    name: "通山",
  },
  {
    id: 421281,
    pid: 4212,
    deep: 2,
    name: "赤壁",
  },
  {
    id: 421303,
    pid: 4213,
    deep: 2,
    name: "曾都",
  },
  {
    id: 421321,
    pid: 4213,
    deep: 2,
    name: "随县",
  },
  {
    id: 421381,
    pid: 4213,
    deep: 2,
    name: "广水",
  },
  {
    id: 422801,
    pid: 4228,
    deep: 2,
    name: "恩施市",
  },
  {
    id: 422802,
    pid: 4228,
    deep: 2,
    name: "利川",
  },
  {
    id: 422822,
    pid: 4228,
    deep: 2,
    name: "建始",
  },
  {
    id: 422823,
    pid: 4228,
    deep: 2,
    name: "巴东",
  },
  {
    id: 422825,
    pid: 4228,
    deep: 2,
    name: "宣恩",
  },
  {
    id: 422826,
    pid: 4228,
    deep: 2,
    name: "咸丰",
  },
  {
    id: 422827,
    pid: 4228,
    deep: 2,
    name: "来凤",
  },
  {
    id: 422828,
    pid: 4228,
    deep: 2,
    name: "鹤峰",
  },
  {
    id: 429004,
    pid: 42,
    deep: 1,
    name: "仙桃",
  },
  {
    id: 429005,
    pid: 42,
    deep: 1,
    name: "潜江",
  },
  {
    id: 429006,
    pid: 42,
    deep: 1,
    name: "天门",
  },
  {
    id: 429021,
    pid: 42,
    deep: 1,
    name: "神农架",
  },
  {
    id: 430102,
    pid: 4301,
    deep: 2,
    name: "芙蓉",
  },
  {
    id: 430103,
    pid: 4301,
    deep: 2,
    name: "天心",
  },
  {
    id: 430104,
    pid: 4301,
    deep: 2,
    name: "岳麓",
  },
  {
    id: 430105,
    pid: 4301,
    deep: 2,
    name: "开福",
  },
  {
    id: 430111,
    pid: 4301,
    deep: 2,
    name: "雨花",
  },
  {
    id: 430112,
    pid: 4301,
    deep: 2,
    name: "望城",
  },
  {
    id: 430121,
    pid: 4301,
    deep: 2,
    name: "长沙县",
  },
  {
    id: 430181,
    pid: 4301,
    deep: 2,
    name: "浏阳",
  },
  {
    id: 430182,
    pid: 4301,
    deep: 2,
    name: "宁乡",
  },
  {
    id: 430202,
    pid: 4302,
    deep: 2,
    name: "荷塘",
  },
  {
    id: 430203,
    pid: 4302,
    deep: 2,
    name: "芦淞",
  },
  {
    id: 430204,
    pid: 4302,
    deep: 2,
    name: "石峰",
  },
  {
    id: 430211,
    pid: 4302,
    deep: 2,
    name: "天元",
  },
  {
    id: 430212,
    pid: 4302,
    deep: 2,
    name: "渌口",
  },
  {
    id: 430223,
    pid: 4302,
    deep: 2,
    name: "攸县",
  },
  {
    id: 430224,
    pid: 4302,
    deep: 2,
    name: "茶陵",
  },
  {
    id: 430225,
    pid: 4302,
    deep: 2,
    name: "炎陵",
  },
  {
    id: 430281,
    pid: 4302,
    deep: 2,
    name: "醴陵",
  },
  {
    id: 430302,
    pid: 4303,
    deep: 2,
    name: "雨湖",
  },
  {
    id: 430304,
    pid: 4303,
    deep: 2,
    name: "岳塘",
  },
  {
    id: 430321,
    pid: 4303,
    deep: 2,
    name: "湘潭县",
  },
  {
    id: 430381,
    pid: 4303,
    deep: 2,
    name: "湘乡",
  },
  {
    id: 430382,
    pid: 4303,
    deep: 2,
    name: "韶山",
  },
  {
    id: 430405,
    pid: 4304,
    deep: 2,
    name: "珠晖",
  },
  {
    id: 430406,
    pid: 4304,
    deep: 2,
    name: "雁峰",
  },
  {
    id: 430407,
    pid: 4304,
    deep: 2,
    name: "石鼓",
  },
  {
    id: 430408,
    pid: 4304,
    deep: 2,
    name: "蒸湘",
  },
  {
    id: 430412,
    pid: 4304,
    deep: 2,
    name: "南岳",
  },
  {
    id: 430421,
    pid: 4304,
    deep: 2,
    name: "衡阳县",
  },
  {
    id: 430422,
    pid: 4304,
    deep: 2,
    name: "衡南",
  },
  {
    id: 430423,
    pid: 4304,
    deep: 2,
    name: "衡山",
  },
  {
    id: 430424,
    pid: 4304,
    deep: 2,
    name: "衡东",
  },
  {
    id: 430426,
    pid: 4304,
    deep: 2,
    name: "祁东",
  },
  {
    id: 430481,
    pid: 4304,
    deep: 2,
    name: "耒阳",
  },
  {
    id: 430482,
    pid: 4304,
    deep: 2,
    name: "常宁",
  },
  {
    id: 430502,
    pid: 4305,
    deep: 2,
    name: "双清",
  },
  {
    id: 430503,
    pid: 4305,
    deep: 2,
    name: "大祥",
  },
  {
    id: 430511,
    pid: 4305,
    deep: 2,
    name: "北塔",
  },
  {
    id: 430521,
    pid: 4305,
    deep: 2,
    name: "邵东",
  },
  {
    id: 430522,
    pid: 4305,
    deep: 2,
    name: "新邵",
  },
  {
    id: 430523,
    pid: 4305,
    deep: 2,
    name: "邵阳县",
  },
  {
    id: 430524,
    pid: 4305,
    deep: 2,
    name: "隆回",
  },
  {
    id: 430525,
    pid: 4305,
    deep: 2,
    name: "洞口",
  },
  {
    id: 430527,
    pid: 4305,
    deep: 2,
    name: "绥宁",
  },
  {
    id: 430528,
    pid: 4305,
    deep: 2,
    name: "新宁",
  },
  {
    id: 430529,
    pid: 4305,
    deep: 2,
    name: "城步",
  },
  {
    id: 430581,
    pid: 4305,
    deep: 2,
    name: "武冈",
  },
  {
    id: 430602,
    pid: 4306,
    deep: 2,
    name: "岳阳楼",
  },
  {
    id: 430603,
    pid: 4306,
    deep: 2,
    name: "云溪",
  },
  {
    id: 430611,
    pid: 4306,
    deep: 2,
    name: "君山",
  },
  {
    id: 430621,
    pid: 4306,
    deep: 2,
    name: "岳阳县",
  },
  {
    id: 430623,
    pid: 4306,
    deep: 2,
    name: "华容",
  },
  {
    id: 430624,
    pid: 4306,
    deep: 2,
    name: "湘阴",
  },
  {
    id: 430626,
    pid: 4306,
    deep: 2,
    name: "平江",
  },
  {
    id: 430681,
    pid: 4306,
    deep: 2,
    name: "汨罗",
  },
  {
    id: 430682,
    pid: 4306,
    deep: 2,
    name: "临湘",
  },
  {
    id: 430702,
    pid: 4307,
    deep: 2,
    name: "武陵",
  },
  {
    id: 430703,
    pid: 4307,
    deep: 2,
    name: "鼎城",
  },
  {
    id: 430721,
    pid: 4307,
    deep: 2,
    name: "安乡",
  },
  {
    id: 430722,
    pid: 4307,
    deep: 2,
    name: "汉寿",
  },
  {
    id: 430723,
    pid: 4307,
    deep: 2,
    name: "澧县",
  },
  {
    id: 430724,
    pid: 4307,
    deep: 2,
    name: "临澧",
  },
  {
    id: 430725,
    pid: 4307,
    deep: 2,
    name: "桃源",
  },
  {
    id: 430726,
    pid: 4307,
    deep: 2,
    name: "石门",
  },
  {
    id: 430781,
    pid: 4307,
    deep: 2,
    name: "津市",
  },
  {
    id: 430802,
    pid: 4308,
    deep: 2,
    name: "永定",
  },
  {
    id: 430811,
    pid: 4308,
    deep: 2,
    name: "武陵源",
  },
  {
    id: 430821,
    pid: 4308,
    deep: 2,
    name: "慈利",
  },
  {
    id: 430822,
    pid: 4308,
    deep: 2,
    name: "桑植",
  },
  {
    id: 430902,
    pid: 4309,
    deep: 2,
    name: "资阳",
  },
  {
    id: 430903,
    pid: 4309,
    deep: 2,
    name: "赫山",
  },
  {
    id: 430921,
    pid: 4309,
    deep: 2,
    name: "南县",
  },
  {
    id: 430922,
    pid: 4309,
    deep: 2,
    name: "桃江",
  },
  {
    id: 430923,
    pid: 4309,
    deep: 2,
    name: "安化",
  },
  {
    id: 430981,
    pid: 4309,
    deep: 2,
    name: "沅江",
  },
  {
    id: 431002,
    pid: 4310,
    deep: 2,
    name: "北湖",
  },
  {
    id: 431003,
    pid: 4310,
    deep: 2,
    name: "苏仙",
  },
  {
    id: 431021,
    pid: 4310,
    deep: 2,
    name: "桂阳",
  },
  {
    id: 431022,
    pid: 4310,
    deep: 2,
    name: "宜章",
  },
  {
    id: 431023,
    pid: 4310,
    deep: 2,
    name: "永兴",
  },
  {
    id: 431024,
    pid: 4310,
    deep: 2,
    name: "嘉禾",
  },
  {
    id: 431025,
    pid: 4310,
    deep: 2,
    name: "临武",
  },
  {
    id: 431026,
    pid: 4310,
    deep: 2,
    name: "汝城",
  },
  {
    id: 431027,
    pid: 4310,
    deep: 2,
    name: "桂东",
  },
  {
    id: 431028,
    pid: 4310,
    deep: 2,
    name: "安仁",
  },
  {
    id: 431081,
    pid: 4310,
    deep: 2,
    name: "资兴",
  },
  {
    id: 431102,
    pid: 4311,
    deep: 2,
    name: "零陵",
  },
  {
    id: 431103,
    pid: 4311,
    deep: 2,
    name: "冷水滩",
  },
  {
    id: 431121,
    pid: 4311,
    deep: 2,
    name: "祁阳",
  },
  {
    id: 431122,
    pid: 4311,
    deep: 2,
    name: "东安",
  },
  {
    id: 431123,
    pid: 4311,
    deep: 2,
    name: "双牌",
  },
  {
    id: 431124,
    pid: 4311,
    deep: 2,
    name: "道县",
  },
  {
    id: 431125,
    pid: 4311,
    deep: 2,
    name: "江永",
  },
  {
    id: 431126,
    pid: 4311,
    deep: 2,
    name: "宁远",
  },
  {
    id: 431127,
    pid: 4311,
    deep: 2,
    name: "蓝山",
  },
  {
    id: 431128,
    pid: 4311,
    deep: 2,
    name: "新田",
  },
  {
    id: 431129,
    pid: 4311,
    deep: 2,
    name: "江华",
  },
  {
    id: 431202,
    pid: 4312,
    deep: 2,
    name: "鹤城",
  },
  {
    id: 431221,
    pid: 4312,
    deep: 2,
    name: "中方",
  },
  {
    id: 431222,
    pid: 4312,
    deep: 2,
    name: "沅陵",
  },
  {
    id: 431223,
    pid: 4312,
    deep: 2,
    name: "辰溪",
  },
  {
    id: 431224,
    pid: 4312,
    deep: 2,
    name: "溆浦",
  },
  {
    id: 431225,
    pid: 4312,
    deep: 2,
    name: "会同",
  },
  {
    id: 431226,
    pid: 4312,
    deep: 2,
    name: "麻阳",
  },
  {
    id: 431227,
    pid: 4312,
    deep: 2,
    name: "新晃",
  },
  {
    id: 431228,
    pid: 4312,
    deep: 2,
    name: "芷江",
  },
  {
    id: 431229,
    pid: 4312,
    deep: 2,
    name: "靖州",
  },
  {
    id: 431230,
    pid: 4312,
    deep: 2,
    name: "通道",
  },
  {
    id: 431281,
    pid: 4312,
    deep: 2,
    name: "洪江",
  },
  {
    id: 431302,
    pid: 4313,
    deep: 2,
    name: "娄星",
  },
  {
    id: 431321,
    pid: 4313,
    deep: 2,
    name: "双峰",
  },
  {
    id: 431322,
    pid: 4313,
    deep: 2,
    name: "新化",
  },
  {
    id: 431381,
    pid: 4313,
    deep: 2,
    name: "冷水江",
  },
  {
    id: 431382,
    pid: 4313,
    deep: 2,
    name: "涟源",
  },
  {
    id: 433101,
    pid: 4331,
    deep: 2,
    name: "吉首",
  },
  {
    id: 433122,
    pid: 4331,
    deep: 2,
    name: "泸溪",
  },
  {
    id: 433123,
    pid: 4331,
    deep: 2,
    name: "凤凰",
  },
  {
    id: 433124,
    pid: 4331,
    deep: 2,
    name: "花垣",
  },
  {
    id: 433125,
    pid: 4331,
    deep: 2,
    name: "保靖",
  },
  {
    id: 433126,
    pid: 4331,
    deep: 2,
    name: "古丈",
  },
  {
    id: 433127,
    pid: 4331,
    deep: 2,
    name: "永顺",
  },
  {
    id: 433130,
    pid: 4331,
    deep: 2,
    name: "龙山",
  },
  {
    id: 440103,
    pid: 4401,
    deep: 2,
    name: "荔湾",
  },
  {
    id: 440104,
    pid: 4401,
    deep: 2,
    name: "越秀",
  },
  {
    id: 440105,
    pid: 4401,
    deep: 2,
    name: "海珠",
  },
  {
    id: 440106,
    pid: 4401,
    deep: 2,
    name: "天河",
  },
  {
    id: 440111,
    pid: 4401,
    deep: 2,
    name: "白云",
  },
  {
    id: 440112,
    pid: 4401,
    deep: 2,
    name: "黄埔",
  },
  {
    id: 440113,
    pid: 4401,
    deep: 2,
    name: "番禺",
  },
  {
    id: 440114,
    pid: 4401,
    deep: 2,
    name: "花都",
  },
  {
    id: 440115,
    pid: 4401,
    deep: 2,
    name: "南沙",
  },
  {
    id: 440117,
    pid: 4401,
    deep: 2,
    name: "从化",
  },
  {
    id: 440118,
    pid: 4401,
    deep: 2,
    name: "增城",
  },
  {
    id: 440203,
    pid: 4402,
    deep: 2,
    name: "武江",
  },
  {
    id: 440204,
    pid: 4402,
    deep: 2,
    name: "浈江",
  },
  {
    id: 440205,
    pid: 4402,
    deep: 2,
    name: "曲江",
  },
  {
    id: 440222,
    pid: 4402,
    deep: 2,
    name: "始兴",
  },
  {
    id: 440224,
    pid: 4402,
    deep: 2,
    name: "仁化",
  },
  {
    id: 440229,
    pid: 4402,
    deep: 2,
    name: "翁源",
  },
  {
    id: 440232,
    pid: 4402,
    deep: 2,
    name: "乳源",
  },
  {
    id: 440233,
    pid: 4402,
    deep: 2,
    name: "新丰",
  },
  {
    id: 440281,
    pid: 4402,
    deep: 2,
    name: "乐昌",
  },
  {
    id: 440282,
    pid: 4402,
    deep: 2,
    name: "南雄",
  },
  {
    id: 440303,
    pid: 4403,
    deep: 2,
    name: "罗湖",
  },
  {
    id: 440304,
    pid: 4403,
    deep: 2,
    name: "福田",
  },
  {
    id: 440305,
    pid: 4403,
    deep: 2,
    name: "南山",
  },
  {
    id: 440306,
    pid: 4403,
    deep: 2,
    name: "宝安",
  },
  {
    id: 440307,
    pid: 4403,
    deep: 2,
    name: "龙岗",
  },
  {
    id: 440308,
    pid: 4403,
    deep: 2,
    name: "盐田",
  },
  {
    id: 440309,
    pid: 4403,
    deep: 2,
    name: "龙华",
  },
  {
    id: 440310,
    pid: 4403,
    deep: 2,
    name: "坪山",
  },
  {
    id: 440311,
    pid: 4403,
    deep: 2,
    name: "光明",
  },
  {
    id: 440402,
    pid: 4404,
    deep: 2,
    name: "香洲",
  },
  {
    id: 440403,
    pid: 4404,
    deep: 2,
    name: "斗门",
  },
  {
    id: 440404,
    pid: 4404,
    deep: 2,
    name: "金湾",
  },
  {
    id: 440507,
    pid: 4405,
    deep: 2,
    name: "龙湖",
  },
  {
    id: 440511,
    pid: 4405,
    deep: 2,
    name: "金平",
  },
  {
    id: 440512,
    pid: 4405,
    deep: 2,
    name: "濠江",
  },
  {
    id: 440513,
    pid: 4405,
    deep: 2,
    name: "潮阳",
  },
  {
    id: 440514,
    pid: 4405,
    deep: 2,
    name: "潮南",
  },
  {
    id: 440515,
    pid: 4405,
    deep: 2,
    name: "澄海",
  },
  {
    id: 440523,
    pid: 4405,
    deep: 2,
    name: "南澳",
  },
  {
    id: 440604,
    pid: 4406,
    deep: 2,
    name: "禅城",
  },
  {
    id: 440605,
    pid: 4406,
    deep: 2,
    name: "南海",
  },
  {
    id: 440606,
    pid: 4406,
    deep: 2,
    name: "顺德",
  },
  {
    id: 440607,
    pid: 4406,
    deep: 2,
    name: "三水",
  },
  {
    id: 440608,
    pid: 4406,
    deep: 2,
    name: "高明",
  },
  {
    id: 440703,
    pid: 4407,
    deep: 2,
    name: "蓬江",
  },
  {
    id: 440704,
    pid: 4407,
    deep: 2,
    name: "江海",
  },
  {
    id: 440705,
    pid: 4407,
    deep: 2,
    name: "新会",
  },
  {
    id: 440781,
    pid: 4407,
    deep: 2,
    name: "台山",
  },
  {
    id: 440783,
    pid: 4407,
    deep: 2,
    name: "开平",
  },
  {
    id: 440784,
    pid: 4407,
    deep: 2,
    name: "鹤山",
  },
  {
    id: 440785,
    pid: 4407,
    deep: 2,
    name: "恩平",
  },
  {
    id: 440802,
    pid: 4408,
    deep: 2,
    name: "赤坎",
  },
  {
    id: 440803,
    pid: 4408,
    deep: 2,
    name: "霞山",
  },
  {
    id: 440804,
    pid: 4408,
    deep: 2,
    name: "坡头",
  },
  {
    id: 440811,
    pid: 4408,
    deep: 2,
    name: "麻章",
  },
  {
    id: 440823,
    pid: 4408,
    deep: 2,
    name: "遂溪",
  },
  {
    id: 440825,
    pid: 4408,
    deep: 2,
    name: "徐闻",
  },
  {
    id: 440881,
    pid: 4408,
    deep: 2,
    name: "廉江",
  },
  {
    id: 440882,
    pid: 4408,
    deep: 2,
    name: "雷州",
  },
  {
    id: 440883,
    pid: 4408,
    deep: 2,
    name: "吴川",
  },
  {
    id: 440902,
    pid: 4409,
    deep: 2,
    name: "茂南",
  },
  {
    id: 440904,
    pid: 4409,
    deep: 2,
    name: "电白",
  },
  {
    id: 440981,
    pid: 4409,
    deep: 2,
    name: "高州",
  },
  {
    id: 440982,
    pid: 4409,
    deep: 2,
    name: "化州",
  },
  {
    id: 440983,
    pid: 4409,
    deep: 2,
    name: "信宜",
  },
  {
    id: 441202,
    pid: 4412,
    deep: 2,
    name: "端州",
  },
  {
    id: 441203,
    pid: 4412,
    deep: 2,
    name: "鼎湖",
  },
  {
    id: 441204,
    pid: 4412,
    deep: 2,
    name: "高要",
  },
  {
    id: 441223,
    pid: 4412,
    deep: 2,
    name: "广宁",
  },
  {
    id: 441224,
    pid: 4412,
    deep: 2,
    name: "怀集",
  },
  {
    id: 441225,
    pid: 4412,
    deep: 2,
    name: "封开",
  },
  {
    id: 441226,
    pid: 4412,
    deep: 2,
    name: "德庆",
  },
  {
    id: 441284,
    pid: 4412,
    deep: 2,
    name: "四会",
  },
  {
    id: 441302,
    pid: 4413,
    deep: 2,
    name: "惠城",
  },
  {
    id: 441303,
    pid: 4413,
    deep: 2,
    name: "惠阳",
  },
  {
    id: 441322,
    pid: 4413,
    deep: 2,
    name: "博罗",
  },
  {
    id: 441323,
    pid: 4413,
    deep: 2,
    name: "惠东",
  },
  {
    id: 441324,
    pid: 4413,
    deep: 2,
    name: "龙门",
  },
  {
    id: 441402,
    pid: 4414,
    deep: 2,
    name: "梅江",
  },
  {
    id: 441403,
    pid: 4414,
    deep: 2,
    name: "梅县",
  },
  {
    id: 441422,
    pid: 4414,
    deep: 2,
    name: "大埔",
  },
  {
    id: 441423,
    pid: 4414,
    deep: 2,
    name: "丰顺",
  },
  {
    id: 441424,
    pid: 4414,
    deep: 2,
    name: "五华",
  },
  {
    id: 441426,
    pid: 4414,
    deep: 2,
    name: "平远",
  },
  {
    id: 441427,
    pid: 4414,
    deep: 2,
    name: "蕉岭",
  },
  {
    id: 441481,
    pid: 4414,
    deep: 2,
    name: "兴宁",
  },
  {
    id: 441502,
    pid: 4415,
    deep: 2,
    name: "城区",
  },
  {
    id: 441521,
    pid: 4415,
    deep: 2,
    name: "海丰",
  },
  {
    id: 441523,
    pid: 4415,
    deep: 2,
    name: "陆河",
  },
  {
    id: 441581,
    pid: 4415,
    deep: 2,
    name: "陆丰",
  },
  {
    id: 441602,
    pid: 4416,
    deep: 2,
    name: "源城",
  },
  {
    id: 441621,
    pid: 4416,
    deep: 2,
    name: "紫金",
  },
  {
    id: 441622,
    pid: 4416,
    deep: 2,
    name: "龙川",
  },
  {
    id: 441623,
    pid: 4416,
    deep: 2,
    name: "连平",
  },
  {
    id: 441624,
    pid: 4416,
    deep: 2,
    name: "和平",
  },
  {
    id: 441625,
    pid: 4416,
    deep: 2,
    name: "东源",
  },
  {
    id: 441702,
    pid: 4417,
    deep: 2,
    name: "江城",
  },
  {
    id: 441704,
    pid: 4417,
    deep: 2,
    name: "阳东",
  },
  {
    id: 441721,
    pid: 4417,
    deep: 2,
    name: "阳西",
  },
  {
    id: 441781,
    pid: 4417,
    deep: 2,
    name: "阳春",
  },
  {
    id: 441802,
    pid: 4418,
    deep: 2,
    name: "清城",
  },
  {
    id: 441803,
    pid: 4418,
    deep: 2,
    name: "清新区",
  },
  {
    id: 441821,
    pid: 4418,
    deep: 2,
    name: "佛冈",
  },
  {
    id: 441823,
    pid: 4418,
    deep: 2,
    name: "阳山",
  },
  {
    id: 441825,
    pid: 4418,
    deep: 2,
    name: "连山",
  },
  {
    id: 441826,
    pid: 4418,
    deep: 2,
    name: "连南",
  },
  {
    id: 441881,
    pid: 4418,
    deep: 2,
    name: "英德",
  },
  {
    id: 441882,
    pid: 4418,
    deep: 2,
    name: "连州",
  },
  {
    id: 441900,
    pid: 4419,
    deep: 2,
    name: "东莞",
  },
  {
    id: 442000,
    pid: 4420,
    deep: 2,
    name: "中山",
  },
  {
    id: 445102,
    pid: 4451,
    deep: 2,
    name: "湘桥",
  },
  {
    id: 445103,
    pid: 4451,
    deep: 2,
    name: "潮安",
  },
  {
    id: 445122,
    pid: 4451,
    deep: 2,
    name: "饶平",
  },
  {
    id: 445202,
    pid: 4452,
    deep: 2,
    name: "榕城",
  },
  {
    id: 445203,
    pid: 4452,
    deep: 2,
    name: "揭东",
  },
  {
    id: 445222,
    pid: 4452,
    deep: 2,
    name: "揭西",
  },
  {
    id: 445224,
    pid: 4452,
    deep: 2,
    name: "惠来",
  },
  {
    id: 445281,
    pid: 4452,
    deep: 2,
    name: "普宁",
  },
  {
    id: 445302,
    pid: 4453,
    deep: 2,
    name: "云城",
  },
  {
    id: 445303,
    pid: 4453,
    deep: 2,
    name: "云安",
  },
  {
    id: 445321,
    pid: 4453,
    deep: 2,
    name: "新兴",
  },
  {
    id: 445322,
    pid: 4453,
    deep: 2,
    name: "郁南",
  },
  {
    id: 445381,
    pid: 4453,
    deep: 2,
    name: "罗定",
  },
  {
    id: 450102,
    pid: 4501,
    deep: 2,
    name: "兴宁",
  },
  {
    id: 450103,
    pid: 4501,
    deep: 2,
    name: "青秀",
  },
  {
    id: 450105,
    pid: 4501,
    deep: 2,
    name: "江南",
  },
  {
    id: 450107,
    pid: 4501,
    deep: 2,
    name: "西乡塘",
  },
  {
    id: 450108,
    pid: 4501,
    deep: 2,
    name: "良庆",
  },
  {
    id: 450109,
    pid: 4501,
    deep: 2,
    name: "邕宁",
  },
  {
    id: 450110,
    pid: 4501,
    deep: 2,
    name: "武鸣",
  },
  {
    id: 450123,
    pid: 4501,
    deep: 2,
    name: "隆安",
  },
  {
    id: 450124,
    pid: 4501,
    deep: 2,
    name: "马山",
  },
  {
    id: 450125,
    pid: 4501,
    deep: 2,
    name: "上林",
  },
  {
    id: 450126,
    pid: 4501,
    deep: 2,
    name: "宾阳",
  },
  {
    id: 450127,
    pid: 4501,
    deep: 2,
    name: "横县",
  },
  {
    id: 450202,
    pid: 4502,
    deep: 2,
    name: "城中",
  },
  {
    id: 450203,
    pid: 4502,
    deep: 2,
    name: "鱼峰",
  },
  {
    id: 450204,
    pid: 4502,
    deep: 2,
    name: "柳南",
  },
  {
    id: 450205,
    pid: 4502,
    deep: 2,
    name: "柳北",
  },
  {
    id: 450206,
    pid: 4502,
    deep: 2,
    name: "柳江",
  },
  {
    id: 450222,
    pid: 4502,
    deep: 2,
    name: "柳城",
  },
  {
    id: 450223,
    pid: 4502,
    deep: 2,
    name: "鹿寨",
  },
  {
    id: 450224,
    pid: 4502,
    deep: 2,
    name: "融安",
  },
  {
    id: 450225,
    pid: 4502,
    deep: 2,
    name: "融水",
  },
  {
    id: 450226,
    pid: 4502,
    deep: 2,
    name: "三江",
  },
  {
    id: 450302,
    pid: 4503,
    deep: 2,
    name: "秀峰",
  },
  {
    id: 450303,
    pid: 4503,
    deep: 2,
    name: "叠彩",
  },
  {
    id: 450304,
    pid: 4503,
    deep: 2,
    name: "象山",
  },
  {
    id: 450305,
    pid: 4503,
    deep: 2,
    name: "七星",
  },
  {
    id: 450311,
    pid: 4503,
    deep: 2,
    name: "雁山",
  },
  {
    id: 450312,
    pid: 4503,
    deep: 2,
    name: "临桂",
  },
  {
    id: 450321,
    pid: 4503,
    deep: 2,
    name: "阳朔",
  },
  {
    id: 450323,
    pid: 4503,
    deep: 2,
    name: "灵川",
  },
  {
    id: 450324,
    pid: 4503,
    deep: 2,
    name: "全州",
  },
  {
    id: 450325,
    pid: 4503,
    deep: 2,
    name: "兴安",
  },
  {
    id: 450326,
    pid: 4503,
    deep: 2,
    name: "永福",
  },
  {
    id: 450327,
    pid: 4503,
    deep: 2,
    name: "灌阳",
  },
  {
    id: 450328,
    pid: 4503,
    deep: 2,
    name: "龙胜",
  },
  {
    id: 450329,
    pid: 4503,
    deep: 2,
    name: "资源",
  },
  {
    id: 450330,
    pid: 4503,
    deep: 2,
    name: "平乐",
  },
  {
    id: 450332,
    pid: 4503,
    deep: 2,
    name: "恭城",
  },
  {
    id: 450381,
    pid: 4503,
    deep: 2,
    name: "荔浦",
  },
  {
    id: 450403,
    pid: 4504,
    deep: 2,
    name: "万秀",
  },
  {
    id: 450405,
    pid: 4504,
    deep: 2,
    name: "长洲",
  },
  {
    id: 450406,
    pid: 4504,
    deep: 2,
    name: "龙圩",
  },
  {
    id: 450421,
    pid: 4504,
    deep: 2,
    name: "苍梧",
  },
  {
    id: 450422,
    pid: 4504,
    deep: 2,
    name: "藤县",
  },
  {
    id: 450423,
    pid: 4504,
    deep: 2,
    name: "蒙山",
  },
  {
    id: 450481,
    pid: 4504,
    deep: 2,
    name: "岑溪",
  },
  {
    id: 450502,
    pid: 4505,
    deep: 2,
    name: "海城",
  },
  {
    id: 450503,
    pid: 4505,
    deep: 2,
    name: "银海",
  },
  {
    id: 450512,
    pid: 4505,
    deep: 2,
    name: "铁山港",
  },
  {
    id: 450521,
    pid: 4505,
    deep: 2,
    name: "合浦",
  },
  {
    id: 450602,
    pid: 4506,
    deep: 2,
    name: "港口",
  },
  {
    id: 450603,
    pid: 4506,
    deep: 2,
    name: "防城",
  },
  {
    id: 450621,
    pid: 4506,
    deep: 2,
    name: "上思",
  },
  {
    id: 450681,
    pid: 4506,
    deep: 2,
    name: "东兴",
  },
  {
    id: 450702,
    pid: 4507,
    deep: 2,
    name: "钦南",
  },
  {
    id: 450703,
    pid: 4507,
    deep: 2,
    name: "钦北",
  },
  {
    id: 450721,
    pid: 4507,
    deep: 2,
    name: "灵山",
  },
  {
    id: 450722,
    pid: 4507,
    deep: 2,
    name: "浦北",
  },
  {
    id: 450802,
    pid: 4508,
    deep: 2,
    name: "港北",
  },
  {
    id: 450803,
    pid: 4508,
    deep: 2,
    name: "港南",
  },
  {
    id: 450804,
    pid: 4508,
    deep: 2,
    name: "覃塘",
  },
  {
    id: 450821,
    pid: 4508,
    deep: 2,
    name: "平南",
  },
  {
    id: 450881,
    pid: 4508,
    deep: 2,
    name: "桂平",
  },
  {
    id: 450902,
    pid: 4509,
    deep: 2,
    name: "玉州",
  },
  {
    id: 450903,
    pid: 4509,
    deep: 2,
    name: "福绵",
  },
  {
    id: 450921,
    pid: 4509,
    deep: 2,
    name: "容县",
  },
  {
    id: 450922,
    pid: 4509,
    deep: 2,
    name: "陆川",
  },
  {
    id: 450923,
    pid: 4509,
    deep: 2,
    name: "博白",
  },
  {
    id: 450924,
    pid: 4509,
    deep: 2,
    name: "兴业",
  },
  {
    id: 450981,
    pid: 4509,
    deep: 2,
    name: "北流",
  },
  {
    id: 451002,
    pid: 4510,
    deep: 2,
    name: "右江",
  },
  {
    id: 451021,
    pid: 4510,
    deep: 2,
    name: "田阳",
  },
  {
    id: 451022,
    pid: 4510,
    deep: 2,
    name: "田东",
  },
  {
    id: 451023,
    pid: 4510,
    deep: 2,
    name: "平果",
  },
  {
    id: 451024,
    pid: 4510,
    deep: 2,
    name: "德保",
  },
  {
    id: 451026,
    pid: 4510,
    deep: 2,
    name: "那坡",
  },
  {
    id: 451027,
    pid: 4510,
    deep: 2,
    name: "凌云",
  },
  {
    id: 451028,
    pid: 4510,
    deep: 2,
    name: "乐业",
  },
  {
    id: 451029,
    pid: 4510,
    deep: 2,
    name: "田林",
  },
  {
    id: 451030,
    pid: 4510,
    deep: 2,
    name: "西林",
  },
  {
    id: 451031,
    pid: 4510,
    deep: 2,
    name: "隆林",
  },
  {
    id: 451081,
    pid: 4510,
    deep: 2,
    name: "靖西",
  },
  {
    id: 451102,
    pid: 4511,
    deep: 2,
    name: "八步",
  },
  {
    id: 451103,
    pid: 4511,
    deep: 2,
    name: "平桂",
  },
  {
    id: 451121,
    pid: 4511,
    deep: 2,
    name: "昭平",
  },
  {
    id: 451122,
    pid: 4511,
    deep: 2,
    name: "钟山",
  },
  {
    id: 451123,
    pid: 4511,
    deep: 2,
    name: "富川",
  },
  {
    id: 451202,
    pid: 4512,
    deep: 2,
    name: "金城江",
  },
  {
    id: 451203,
    pid: 4512,
    deep: 2,
    name: "宜州",
  },
  {
    id: 451221,
    pid: 4512,
    deep: 2,
    name: "南丹",
  },
  {
    id: 451222,
    pid: 4512,
    deep: 2,
    name: "天峨",
  },
  {
    id: 451223,
    pid: 4512,
    deep: 2,
    name: "凤山",
  },
  {
    id: 451224,
    pid: 4512,
    deep: 2,
    name: "东兰",
  },
  {
    id: 451225,
    pid: 4512,
    deep: 2,
    name: "罗城",
  },
  {
    id: 451226,
    pid: 4512,
    deep: 2,
    name: "环江",
  },
  {
    id: 451227,
    pid: 4512,
    deep: 2,
    name: "巴马",
  },
  {
    id: 451228,
    pid: 4512,
    deep: 2,
    name: "都安",
  },
  {
    id: 451229,
    pid: 4512,
    deep: 2,
    name: "大化",
  },
  {
    id: 451302,
    pid: 4513,
    deep: 2,
    name: "兴宾",
  },
  {
    id: 451321,
    pid: 4513,
    deep: 2,
    name: "忻城",
  },
  {
    id: 451322,
    pid: 4513,
    deep: 2,
    name: "象州",
  },
  {
    id: 451323,
    pid: 4513,
    deep: 2,
    name: "武宣",
  },
  {
    id: 451324,
    pid: 4513,
    deep: 2,
    name: "金秀",
  },
  {
    id: 451381,
    pid: 4513,
    deep: 2,
    name: "合山",
  },
  {
    id: 451402,
    pid: 4514,
    deep: 2,
    name: "江州",
  },
  {
    id: 451421,
    pid: 4514,
    deep: 2,
    name: "扶绥",
  },
  {
    id: 451422,
    pid: 4514,
    deep: 2,
    name: "宁明",
  },
  {
    id: 451423,
    pid: 4514,
    deep: 2,
    name: "龙州",
  },
  {
    id: 451424,
    pid: 4514,
    deep: 2,
    name: "大新",
  },
  {
    id: 451425,
    pid: 4514,
    deep: 2,
    name: "天等",
  },
  {
    id: 451481,
    pid: 4514,
    deep: 2,
    name: "凭祥",
  },
  {
    id: 460105,
    pid: 4601,
    deep: 2,
    name: "秀英",
  },
  {
    id: 460106,
    pid: 4601,
    deep: 2,
    name: "龙华",
  },
  {
    id: 460107,
    pid: 4601,
    deep: 2,
    name: "琼山",
  },
  {
    id: 460108,
    pid: 4601,
    deep: 2,
    name: "美兰",
  },
  {
    id: 460202,
    pid: 4602,
    deep: 2,
    name: "海棠",
  },
  {
    id: 460203,
    pid: 4602,
    deep: 2,
    name: "吉阳",
  },
  {
    id: 460204,
    pid: 4602,
    deep: 2,
    name: "天涯",
  },
  {
    id: 460205,
    pid: 4602,
    deep: 2,
    name: "崖州",
  },
  {
    id: 460321,
    pid: 4603,
    deep: 2,
    name: "西沙群岛",
  },
  {
    id: 460322,
    pid: 4603,
    deep: 2,
    name: "南沙群岛",
  },
  {
    id: 460323,
    pid: 4603,
    deep: 2,
    name: "中沙群岛的岛礁及其海域",
  },
  {
    id: 460400,
    pid: 4604,
    deep: 2,
    name: "儋州",
  },
  {
    id: 469001,
    pid: 46,
    deep: 1,
    name: "五指山",
  },
  {
    id: 469002,
    pid: 46,
    deep: 1,
    name: "琼海",
  },
  {
    id: 469005,
    pid: 46,
    deep: 1,
    name: "文昌",
  },
  {
    id: 469006,
    pid: 46,
    deep: 1,
    name: "万宁",
  },
  {
    id: 469007,
    pid: 46,
    deep: 1,
    name: "东方",
  },
  {
    id: 469021,
    pid: 46,
    deep: 1,
    name: "定安",
  },
  {
    id: 469022,
    pid: 46,
    deep: 1,
    name: "屯昌",
  },
  {
    id: 469023,
    pid: 46,
    deep: 1,
    name: "澄迈",
  },
  {
    id: 469024,
    pid: 46,
    deep: 1,
    name: "临高",
  },
  {
    id: 469025,
    pid: 46,
    deep: 1,
    name: "白沙",
  },
  {
    id: 469026,
    pid: 46,
    deep: 1,
    name: "昌江",
  },
  {
    id: 469027,
    pid: 46,
    deep: 1,
    name: "乐东",
  },
  {
    id: 469028,
    pid: 46,
    deep: 1,
    name: "陵水",
  },
  {
    id: 469029,
    pid: 46,
    deep: 1,
    name: "保亭",
  },
  {
    id: 469030,
    pid: 46,
    deep: 1,
    name: "琼中",
  },
  {
    id: 500101,
    pid: 5001,
    deep: 2,
    name: "万州",
  },
  {
    id: 500102,
    pid: 5001,
    deep: 2,
    name: "涪陵",
  },
  {
    id: 500103,
    pid: 5001,
    deep: 2,
    name: "渝中",
  },
  {
    id: 500104,
    pid: 5001,
    deep: 2,
    name: "大渡口",
  },
  {
    id: 500105,
    pid: 5001,
    deep: 2,
    name: "江北",
  },
  {
    id: 500106,
    pid: 5001,
    deep: 2,
    name: "沙坪坝",
  },
  {
    id: 500107,
    pid: 5001,
    deep: 2,
    name: "九龙坡",
  },
  {
    id: 500108,
    pid: 5001,
    deep: 2,
    name: "南岸",
  },
  {
    id: 500109,
    pid: 5001,
    deep: 2,
    name: "北碚",
  },
  {
    id: 500110,
    pid: 5001,
    deep: 2,
    name: "綦江",
  },
  {
    id: 500111,
    pid: 5001,
    deep: 2,
    name: "大足",
  },
  {
    id: 500112,
    pid: 5001,
    deep: 2,
    name: "渝北",
  },
  {
    id: 500113,
    pid: 5001,
    deep: 2,
    name: "巴南",
  },
  {
    id: 500114,
    pid: 5001,
    deep: 2,
    name: "黔江",
  },
  {
    id: 500115,
    pid: 5001,
    deep: 2,
    name: "长寿",
  },
  {
    id: 500116,
    pid: 5001,
    deep: 2,
    name: "江津",
  },
  {
    id: 500117,
    pid: 5001,
    deep: 2,
    name: "合川",
  },
  {
    id: 500118,
    pid: 5001,
    deep: 2,
    name: "永川",
  },
  {
    id: 500119,
    pid: 5001,
    deep: 2,
    name: "南川",
  },
  {
    id: 500120,
    pid: 5001,
    deep: 2,
    name: "璧山",
  },
  {
    id: 500151,
    pid: 5001,
    deep: 2,
    name: "铜梁",
  },
  {
    id: 500152,
    pid: 5001,
    deep: 2,
    name: "潼南",
  },
  {
    id: 500153,
    pid: 5001,
    deep: 2,
    name: "荣昌",
  },
  {
    id: 500154,
    pid: 5001,
    deep: 2,
    name: "开州",
  },
  {
    id: 500155,
    pid: 5001,
    deep: 2,
    name: "梁平",
  },
  {
    id: 500156,
    pid: 5001,
    deep: 2,
    name: "武隆",
  },
  {
    id: 500229,
    pid: 5002,
    deep: 2,
    name: "城口",
  },
  {
    id: 500230,
    pid: 5002,
    deep: 2,
    name: "丰都",
  },
  {
    id: 500231,
    pid: 5002,
    deep: 2,
    name: "垫江",
  },
  {
    id: 500233,
    pid: 5002,
    deep: 2,
    name: "忠县",
  },
  {
    id: 500235,
    pid: 5002,
    deep: 2,
    name: "云阳",
  },
  {
    id: 500236,
    pid: 5002,
    deep: 2,
    name: "奉节",
  },
  {
    id: 500237,
    pid: 5002,
    deep: 2,
    name: "巫山",
  },
  {
    id: 500238,
    pid: 5002,
    deep: 2,
    name: "巫溪",
  },
  {
    id: 500240,
    pid: 5002,
    deep: 2,
    name: "石柱",
  },
  {
    id: 500241,
    pid: 5002,
    deep: 2,
    name: "秀山",
  },
  {
    id: 500242,
    pid: 5002,
    deep: 2,
    name: "酉阳",
  },
  {
    id: 500243,
    pid: 5002,
    deep: 2,
    name: "彭水",
  },
  {
    id: 510104,
    pid: 5101,
    deep: 2,
    name: "锦江",
  },
  {
    id: 510105,
    pid: 5101,
    deep: 2,
    name: "青羊",
  },
  {
    id: 510106,
    pid: 5101,
    deep: 2,
    name: "金牛",
  },
  {
    id: 510107,
    pid: 5101,
    deep: 2,
    name: "武侯",
  },
  {
    id: 510108,
    pid: 5101,
    deep: 2,
    name: "成华",
  },
  {
    id: 510112,
    pid: 5101,
    deep: 2,
    name: "龙泉驿",
  },
  {
    id: 510113,
    pid: 5101,
    deep: 2,
    name: "青白江",
  },
  {
    id: 510114,
    pid: 5101,
    deep: 2,
    name: "新都",
  },
  {
    id: 510115,
    pid: 5101,
    deep: 2,
    name: "温江",
  },
  {
    id: 510116,
    pid: 5101,
    deep: 2,
    name: "双流",
  },
  {
    id: 510117,
    pid: 5101,
    deep: 2,
    name: "郫都",
  },
  {
    id: 510121,
    pid: 5101,
    deep: 2,
    name: "金堂",
  },
  {
    id: 510129,
    pid: 5101,
    deep: 2,
    name: "大邑",
  },
  {
    id: 510131,
    pid: 5101,
    deep: 2,
    name: "蒲江",
  },
  {
    id: 510132,
    pid: 5101,
    deep: 2,
    name: "新津",
  },
  {
    id: 510181,
    pid: 5101,
    deep: 2,
    name: "都江堰",
  },
  {
    id: 510182,
    pid: 5101,
    deep: 2,
    name: "彭州",
  },
  {
    id: 510183,
    pid: 5101,
    deep: 2,
    name: "邛崃",
  },
  {
    id: 510184,
    pid: 5101,
    deep: 2,
    name: "崇州",
  },
  {
    id: 510185,
    pid: 5101,
    deep: 2,
    name: "简阳",
  },
  {
    id: 510302,
    pid: 5103,
    deep: 2,
    name: "自流井",
  },
  {
    id: 510303,
    pid: 5103,
    deep: 2,
    name: "贡井",
  },
  {
    id: 510304,
    pid: 5103,
    deep: 2,
    name: "大安",
  },
  {
    id: 510311,
    pid: 5103,
    deep: 2,
    name: "沿滩",
  },
  {
    id: 510321,
    pid: 5103,
    deep: 2,
    name: "荣县",
  },
  {
    id: 510322,
    pid: 5103,
    deep: 2,
    name: "富顺",
  },
  {
    id: 510402,
    pid: 5104,
    deep: 2,
    name: "东区",
  },
  {
    id: 510403,
    pid: 5104,
    deep: 2,
    name: "西区",
  },
  {
    id: 510411,
    pid: 5104,
    deep: 2,
    name: "仁和",
  },
  {
    id: 510421,
    pid: 5104,
    deep: 2,
    name: "米易",
  },
  {
    id: 510422,
    pid: 5104,
    deep: 2,
    name: "盐边",
  },
  {
    id: 510502,
    pid: 5105,
    deep: 2,
    name: "江阳",
  },
  {
    id: 510503,
    pid: 5105,
    deep: 2,
    name: "纳溪",
  },
  {
    id: 510504,
    pid: 5105,
    deep: 2,
    name: "龙马潭",
  },
  {
    id: 510521,
    pid: 5105,
    deep: 2,
    name: "泸县",
  },
  {
    id: 510522,
    pid: 5105,
    deep: 2,
    name: "合江",
  },
  {
    id: 510524,
    pid: 5105,
    deep: 2,
    name: "叙永",
  },
  {
    id: 510525,
    pid: 5105,
    deep: 2,
    name: "古蔺",
  },
  {
    id: 510603,
    pid: 5106,
    deep: 2,
    name: "旌阳",
  },
  {
    id: 510604,
    pid: 5106,
    deep: 2,
    name: "罗江",
  },
  {
    id: 510623,
    pid: 5106,
    deep: 2,
    name: "中江",
  },
  {
    id: 510681,
    pid: 5106,
    deep: 2,
    name: "广汉",
  },
  {
    id: 510682,
    pid: 5106,
    deep: 2,
    name: "什邡",
  },
  {
    id: 510683,
    pid: 5106,
    deep: 2,
    name: "绵竹",
  },
  {
    id: 510703,
    pid: 5107,
    deep: 2,
    name: "涪城",
  },
  {
    id: 510704,
    pid: 5107,
    deep: 2,
    name: "游仙",
  },
  {
    id: 510705,
    pid: 5107,
    deep: 2,
    name: "安州",
  },
  {
    id: 510722,
    pid: 5107,
    deep: 2,
    name: "三台",
  },
  {
    id: 510723,
    pid: 5107,
    deep: 2,
    name: "盐亭",
  },
  {
    id: 510725,
    pid: 5107,
    deep: 2,
    name: "梓潼",
  },
  {
    id: 510726,
    pid: 5107,
    deep: 2,
    name: "北川",
  },
  {
    id: 510727,
    pid: 5107,
    deep: 2,
    name: "平武",
  },
  {
    id: 510781,
    pid: 5107,
    deep: 2,
    name: "江油",
  },
  {
    id: 510802,
    pid: 5108,
    deep: 2,
    name: "利州",
  },
  {
    id: 510811,
    pid: 5108,
    deep: 2,
    name: "昭化",
  },
  {
    id: 510812,
    pid: 5108,
    deep: 2,
    name: "朝天",
  },
  {
    id: 510821,
    pid: 5108,
    deep: 2,
    name: "旺苍",
  },
  {
    id: 510822,
    pid: 5108,
    deep: 2,
    name: "青川",
  },
  {
    id: 510823,
    pid: 5108,
    deep: 2,
    name: "剑阁",
  },
  {
    id: 510824,
    pid: 5108,
    deep: 2,
    name: "苍溪",
  },
  {
    id: 510903,
    pid: 5109,
    deep: 2,
    name: "船山",
  },
  {
    id: 510904,
    pid: 5109,
    deep: 2,
    name: "安居",
  },
  {
    id: 510921,
    pid: 5109,
    deep: 2,
    name: "蓬溪",
  },
  {
    id: 510922,
    pid: 5109,
    deep: 2,
    name: "射洪",
  },
  {
    id: 510923,
    pid: 5109,
    deep: 2,
    name: "大英",
  },
  {
    id: 511002,
    pid: 5110,
    deep: 2,
    name: "市中",
  },
  {
    id: 511011,
    pid: 5110,
    deep: 2,
    name: "东兴",
  },
  {
    id: 511024,
    pid: 5110,
    deep: 2,
    name: "威远",
  },
  {
    id: 511025,
    pid: 5110,
    deep: 2,
    name: "资中",
  },
  {
    id: 511083,
    pid: 5110,
    deep: 2,
    name: "隆昌",
  },
  {
    id: 511102,
    pid: 5111,
    deep: 2,
    name: "市中",
  },
  {
    id: 511111,
    pid: 5111,
    deep: 2,
    name: "沙湾",
  },
  {
    id: 511112,
    pid: 5111,
    deep: 2,
    name: "五通桥",
  },
  {
    id: 511113,
    pid: 5111,
    deep: 2,
    name: "金口河",
  },
  {
    id: 511123,
    pid: 5111,
    deep: 2,
    name: "犍为",
  },
  {
    id: 511124,
    pid: 5111,
    deep: 2,
    name: "井研",
  },
  {
    id: 511126,
    pid: 5111,
    deep: 2,
    name: "夹江",
  },
  {
    id: 511129,
    pid: 5111,
    deep: 2,
    name: "沐川",
  },
  {
    id: 511132,
    pid: 5111,
    deep: 2,
    name: "峨边",
  },
  {
    id: 511133,
    pid: 5111,
    deep: 2,
    name: "马边",
  },
  {
    id: 511181,
    pid: 5111,
    deep: 2,
    name: "峨眉山",
  },
  {
    id: 511302,
    pid: 5113,
    deep: 2,
    name: "顺庆",
  },
  {
    id: 511303,
    pid: 5113,
    deep: 2,
    name: "高坪",
  },
  {
    id: 511304,
    pid: 5113,
    deep: 2,
    name: "嘉陵",
  },
  {
    id: 511321,
    pid: 5113,
    deep: 2,
    name: "南部",
  },
  {
    id: 511322,
    pid: 5113,
    deep: 2,
    name: "营山",
  },
  {
    id: 511323,
    pid: 5113,
    deep: 2,
    name: "蓬安",
  },
  {
    id: 511324,
    pid: 5113,
    deep: 2,
    name: "仪陇",
  },
  {
    id: 511325,
    pid: 5113,
    deep: 2,
    name: "西充",
  },
  {
    id: 511381,
    pid: 5113,
    deep: 2,
    name: "阆中",
  },
  {
    id: 511402,
    pid: 5114,
    deep: 2,
    name: "东坡",
  },
  {
    id: 511403,
    pid: 5114,
    deep: 2,
    name: "彭山",
  },
  {
    id: 511421,
    pid: 5114,
    deep: 2,
    name: "仁寿",
  },
  {
    id: 511423,
    pid: 5114,
    deep: 2,
    name: "洪雅",
  },
  {
    id: 511424,
    pid: 5114,
    deep: 2,
    name: "丹棱",
  },
  {
    id: 511425,
    pid: 5114,
    deep: 2,
    name: "青神",
  },
  {
    id: 511502,
    pid: 5115,
    deep: 2,
    name: "翠屏",
  },
  {
    id: 511503,
    pid: 5115,
    deep: 2,
    name: "南溪",
  },
  {
    id: 511504,
    pid: 5115,
    deep: 2,
    name: "叙州",
  },
  {
    id: 511523,
    pid: 5115,
    deep: 2,
    name: "江安",
  },
  {
    id: 511524,
    pid: 5115,
    deep: 2,
    name: "长宁",
  },
  {
    id: 511525,
    pid: 5115,
    deep: 2,
    name: "高县",
  },
  {
    id: 511526,
    pid: 5115,
    deep: 2,
    name: "珙县",
  },
  {
    id: 511527,
    pid: 5115,
    deep: 2,
    name: "筠连",
  },
  {
    id: 511528,
    pid: 5115,
    deep: 2,
    name: "兴文",
  },
  {
    id: 511529,
    pid: 5115,
    deep: 2,
    name: "屏山",
  },
  {
    id: 511602,
    pid: 5116,
    deep: 2,
    name: "广安区",
  },
  {
    id: 511603,
    pid: 5116,
    deep: 2,
    name: "前锋",
  },
  {
    id: 511621,
    pid: 5116,
    deep: 2,
    name: "岳池",
  },
  {
    id: 511622,
    pid: 5116,
    deep: 2,
    name: "武胜",
  },
  {
    id: 511623,
    pid: 5116,
    deep: 2,
    name: "邻水",
  },
  {
    id: 511681,
    pid: 5116,
    deep: 2,
    name: "华蓥",
  },
  {
    id: 511702,
    pid: 5117,
    deep: 2,
    name: "通川",
  },
  {
    id: 511703,
    pid: 5117,
    deep: 2,
    name: "达川",
  },
  {
    id: 511722,
    pid: 5117,
    deep: 2,
    name: "宣汉",
  },
  {
    id: 511723,
    pid: 5117,
    deep: 2,
    name: "开江",
  },
  {
    id: 511724,
    pid: 5117,
    deep: 2,
    name: "大竹",
  },
  {
    id: 511725,
    pid: 5117,
    deep: 2,
    name: "渠县",
  },
  {
    id: 511781,
    pid: 5117,
    deep: 2,
    name: "万源",
  },
  {
    id: 511802,
    pid: 5118,
    deep: 2,
    name: "雨城",
  },
  {
    id: 511803,
    pid: 5118,
    deep: 2,
    name: "名山",
  },
  {
    id: 511822,
    pid: 5118,
    deep: 2,
    name: "荥经",
  },
  {
    id: 511823,
    pid: 5118,
    deep: 2,
    name: "汉源",
  },
  {
    id: 511824,
    pid: 5118,
    deep: 2,
    name: "石棉",
  },
  {
    id: 511825,
    pid: 5118,
    deep: 2,
    name: "天全",
  },
  {
    id: 511826,
    pid: 5118,
    deep: 2,
    name: "芦山",
  },
  {
    id: 511827,
    pid: 5118,
    deep: 2,
    name: "宝兴",
  },
  {
    id: 511902,
    pid: 5119,
    deep: 2,
    name: "巴州",
  },
  {
    id: 511903,
    pid: 5119,
    deep: 2,
    name: "恩阳",
  },
  {
    id: 511921,
    pid: 5119,
    deep: 2,
    name: "通江",
  },
  {
    id: 511922,
    pid: 5119,
    deep: 2,
    name: "南江",
  },
  {
    id: 511923,
    pid: 5119,
    deep: 2,
    name: "平昌",
  },
  {
    id: 512002,
    pid: 5120,
    deep: 2,
    name: "雁江",
  },
  {
    id: 512021,
    pid: 5120,
    deep: 2,
    name: "安岳",
  },
  {
    id: 512022,
    pid: 5120,
    deep: 2,
    name: "乐至",
  },
  {
    id: 513201,
    pid: 5132,
    deep: 2,
    name: "马尔康",
  },
  {
    id: 513221,
    pid: 5132,
    deep: 2,
    name: "汶川",
  },
  {
    id: 513222,
    pid: 5132,
    deep: 2,
    name: "理县",
  },
  {
    id: 513223,
    pid: 5132,
    deep: 2,
    name: "茂县",
  },
  {
    id: 513224,
    pid: 5132,
    deep: 2,
    name: "松潘",
  },
  {
    id: 513225,
    pid: 5132,
    deep: 2,
    name: "九寨沟",
  },
  {
    id: 513226,
    pid: 5132,
    deep: 2,
    name: "金川",
  },
  {
    id: 513227,
    pid: 5132,
    deep: 2,
    name: "小金",
  },
  {
    id: 513228,
    pid: 5132,
    deep: 2,
    name: "黑水",
  },
  {
    id: 513230,
    pid: 5132,
    deep: 2,
    name: "壤塘",
  },
  {
    id: 513231,
    pid: 5132,
    deep: 2,
    name: "阿坝县",
  },
  {
    id: 513232,
    pid: 5132,
    deep: 2,
    name: "若尔盖",
  },
  {
    id: 513233,
    pid: 5132,
    deep: 2,
    name: "红原",
  },
  {
    id: 513301,
    pid: 5133,
    deep: 2,
    name: "康定",
  },
  {
    id: 513322,
    pid: 5133,
    deep: 2,
    name: "泸定",
  },
  {
    id: 513323,
    pid: 5133,
    deep: 2,
    name: "丹巴",
  },
  {
    id: 513324,
    pid: 5133,
    deep: 2,
    name: "九龙",
  },
  {
    id: 513325,
    pid: 5133,
    deep: 2,
    name: "雅江",
  },
  {
    id: 513326,
    pid: 5133,
    deep: 2,
    name: "道孚",
  },
  {
    id: 513327,
    pid: 5133,
    deep: 2,
    name: "炉霍",
  },
  {
    id: 513328,
    pid: 5133,
    deep: 2,
    name: "甘孜县",
  },
  {
    id: 513329,
    pid: 5133,
    deep: 2,
    name: "新龙",
  },
  {
    id: 513330,
    pid: 5133,
    deep: 2,
    name: "德格",
  },
  {
    id: 513331,
    pid: 5133,
    deep: 2,
    name: "白玉",
  },
  {
    id: 513332,
    pid: 5133,
    deep: 2,
    name: "石渠",
  },
  {
    id: 513333,
    pid: 5133,
    deep: 2,
    name: "色达",
  },
  {
    id: 513334,
    pid: 5133,
    deep: 2,
    name: "理塘",
  },
  {
    id: 513335,
    pid: 5133,
    deep: 2,
    name: "巴塘",
  },
  {
    id: 513336,
    pid: 5133,
    deep: 2,
    name: "乡城",
  },
  {
    id: 513337,
    pid: 5133,
    deep: 2,
    name: "稻城",
  },
  {
    id: 513338,
    pid: 5133,
    deep: 2,
    name: "得荣",
  },
  {
    id: 513401,
    pid: 5134,
    deep: 2,
    name: "西昌",
  },
  {
    id: 513422,
    pid: 5134,
    deep: 2,
    name: "木里",
  },
  {
    id: 513423,
    pid: 5134,
    deep: 2,
    name: "盐源",
  },
  {
    id: 513424,
    pid: 5134,
    deep: 2,
    name: "德昌",
  },
  {
    id: 513425,
    pid: 5134,
    deep: 2,
    name: "会理",
  },
  {
    id: 513426,
    pid: 5134,
    deep: 2,
    name: "会东",
  },
  {
    id: 513427,
    pid: 5134,
    deep: 2,
    name: "宁南",
  },
  {
    id: 513428,
    pid: 5134,
    deep: 2,
    name: "普格",
  },
  {
    id: 513429,
    pid: 5134,
    deep: 2,
    name: "布拖",
  },
  {
    id: 513430,
    pid: 5134,
    deep: 2,
    name: "金阳",
  },
  {
    id: 513431,
    pid: 5134,
    deep: 2,
    name: "昭觉",
  },
  {
    id: 513432,
    pid: 5134,
    deep: 2,
    name: "喜德",
  },
  {
    id: 513433,
    pid: 5134,
    deep: 2,
    name: "冕宁",
  },
  {
    id: 513434,
    pid: 5134,
    deep: 2,
    name: "越西",
  },
  {
    id: 513435,
    pid: 5134,
    deep: 2,
    name: "甘洛",
  },
  {
    id: 513436,
    pid: 5134,
    deep: 2,
    name: "美姑",
  },
  {
    id: 513437,
    pid: 5134,
    deep: 2,
    name: "雷波",
  },
  {
    id: 520102,
    pid: 5201,
    deep: 2,
    name: "南明",
  },
  {
    id: 520103,
    pid: 5201,
    deep: 2,
    name: "云岩",
  },
  {
    id: 520111,
    pid: 5201,
    deep: 2,
    name: "花溪",
  },
  {
    id: 520112,
    pid: 5201,
    deep: 2,
    name: "乌当",
  },
  {
    id: 520113,
    pid: 5201,
    deep: 2,
    name: "白云",
  },
  {
    id: 520115,
    pid: 5201,
    deep: 2,
    name: "观山湖",
  },
  {
    id: 520121,
    pid: 5201,
    deep: 2,
    name: "开阳",
  },
  {
    id: 520122,
    pid: 5201,
    deep: 2,
    name: "息烽",
  },
  {
    id: 520123,
    pid: 5201,
    deep: 2,
    name: "修文",
  },
  {
    id: 520181,
    pid: 5201,
    deep: 2,
    name: "清镇",
  },
  {
    id: 520201,
    pid: 5202,
    deep: 2,
    name: "钟山",
  },
  {
    id: 520203,
    pid: 5202,
    deep: 2,
    name: "六枝特",
  },
  {
    id: 520221,
    pid: 5202,
    deep: 2,
    name: "水城",
  },
  {
    id: 520281,
    pid: 5202,
    deep: 2,
    name: "盘州",
  },
  {
    id: 520302,
    pid: 5203,
    deep: 2,
    name: "红花岗",
  },
  {
    id: 520303,
    pid: 5203,
    deep: 2,
    name: "汇川",
  },
  {
    id: 520304,
    pid: 5203,
    deep: 2,
    name: "播州",
  },
  {
    id: 520322,
    pid: 5203,
    deep: 2,
    name: "桐梓",
  },
  {
    id: 520323,
    pid: 5203,
    deep: 2,
    name: "绥阳",
  },
  {
    id: 520324,
    pid: 5203,
    deep: 2,
    name: "正安",
  },
  {
    id: 520325,
    pid: 5203,
    deep: 2,
    name: "道真",
  },
  {
    id: 520326,
    pid: 5203,
    deep: 2,
    name: "务川",
  },
  {
    id: 520327,
    pid: 5203,
    deep: 2,
    name: "凤冈",
  },
  {
    id: 520328,
    pid: 5203,
    deep: 2,
    name: "湄潭",
  },
  {
    id: 520329,
    pid: 5203,
    deep: 2,
    name: "余庆",
  },
  {
    id: 520330,
    pid: 5203,
    deep: 2,
    name: "习水",
  },
  {
    id: 520381,
    pid: 5203,
    deep: 2,
    name: "赤水",
  },
  {
    id: 520382,
    pid: 5203,
    deep: 2,
    name: "仁怀",
  },
  {
    id: 520402,
    pid: 5204,
    deep: 2,
    name: "西秀",
  },
  {
    id: 520403,
    pid: 5204,
    deep: 2,
    name: "平坝",
  },
  {
    id: 520422,
    pid: 5204,
    deep: 2,
    name: "普定",
  },
  {
    id: 520423,
    pid: 5204,
    deep: 2,
    name: "镇宁",
  },
  {
    id: 520424,
    pid: 5204,
    deep: 2,
    name: "关岭",
  },
  {
    id: 520425,
    pid: 5204,
    deep: 2,
    name: "紫云",
  },
  {
    id: 520502,
    pid: 5205,
    deep: 2,
    name: "七星关",
  },
  {
    id: 520521,
    pid: 5205,
    deep: 2,
    name: "大方",
  },
  {
    id: 520522,
    pid: 5205,
    deep: 2,
    name: "黔西",
  },
  {
    id: 520523,
    pid: 5205,
    deep: 2,
    name: "金沙",
  },
  {
    id: 520524,
    pid: 5205,
    deep: 2,
    name: "织金",
  },
  {
    id: 520525,
    pid: 5205,
    deep: 2,
    name: "纳雍",
  },
  {
    id: 520526,
    pid: 5205,
    deep: 2,
    name: "威宁",
  },
  {
    id: 520527,
    pid: 5205,
    deep: 2,
    name: "赫章",
  },
  {
    id: 520602,
    pid: 5206,
    deep: 2,
    name: "碧江",
  },
  {
    id: 520603,
    pid: 5206,
    deep: 2,
    name: "万山",
  },
  {
    id: 520621,
    pid: 5206,
    deep: 2,
    name: "江口",
  },
  {
    id: 520622,
    pid: 5206,
    deep: 2,
    name: "玉屏",
  },
  {
    id: 520623,
    pid: 5206,
    deep: 2,
    name: "石阡",
  },
  {
    id: 520624,
    pid: 5206,
    deep: 2,
    name: "思南",
  },
  {
    id: 520625,
    pid: 5206,
    deep: 2,
    name: "印江",
  },
  {
    id: 520626,
    pid: 5206,
    deep: 2,
    name: "德江",
  },
  {
    id: 520627,
    pid: 5206,
    deep: 2,
    name: "沿河",
  },
  {
    id: 520628,
    pid: 5206,
    deep: 2,
    name: "松桃",
  },
  {
    id: 522301,
    pid: 5223,
    deep: 2,
    name: "兴义",
  },
  {
    id: 522302,
    pid: 5223,
    deep: 2,
    name: "兴仁",
  },
  {
    id: 522323,
    pid: 5223,
    deep: 2,
    name: "普安",
  },
  {
    id: 522324,
    pid: 5223,
    deep: 2,
    name: "晴隆",
  },
  {
    id: 522325,
    pid: 5223,
    deep: 2,
    name: "贞丰",
  },
  {
    id: 522326,
    pid: 5223,
    deep: 2,
    name: "望谟",
  },
  {
    id: 522327,
    pid: 5223,
    deep: 2,
    name: "册亨",
  },
  {
    id: 522328,
    pid: 5223,
    deep: 2,
    name: "安龙",
  },
  {
    id: 522601,
    pid: 5226,
    deep: 2,
    name: "凯里",
  },
  {
    id: 522622,
    pid: 5226,
    deep: 2,
    name: "黄平",
  },
  {
    id: 522623,
    pid: 5226,
    deep: 2,
    name: "施秉",
  },
  {
    id: 522624,
    pid: 5226,
    deep: 2,
    name: "三穗",
  },
  {
    id: 522625,
    pid: 5226,
    deep: 2,
    name: "镇远",
  },
  {
    id: 522626,
    pid: 5226,
    deep: 2,
    name: "岑巩",
  },
  {
    id: 522627,
    pid: 5226,
    deep: 2,
    name: "天柱",
  },
  {
    id: 522628,
    pid: 5226,
    deep: 2,
    name: "锦屏",
  },
  {
    id: 522629,
    pid: 5226,
    deep: 2,
    name: "剑河",
  },
  {
    id: 522630,
    pid: 5226,
    deep: 2,
    name: "台江",
  },
  {
    id: 522631,
    pid: 5226,
    deep: 2,
    name: "黎平",
  },
  {
    id: 522632,
    pid: 5226,
    deep: 2,
    name: "榕江",
  },
  {
    id: 522633,
    pid: 5226,
    deep: 2,
    name: "从江",
  },
  {
    id: 522634,
    pid: 5226,
    deep: 2,
    name: "雷山",
  },
  {
    id: 522635,
    pid: 5226,
    deep: 2,
    name: "麻江",
  },
  {
    id: 522636,
    pid: 5226,
    deep: 2,
    name: "丹寨",
  },
  {
    id: 522701,
    pid: 5227,
    deep: 2,
    name: "都匀",
  },
  {
    id: 522702,
    pid: 5227,
    deep: 2,
    name: "福泉",
  },
  {
    id: 522722,
    pid: 5227,
    deep: 2,
    name: "荔波",
  },
  {
    id: 522723,
    pid: 5227,
    deep: 2,
    name: "贵定",
  },
  {
    id: 522725,
    pid: 5227,
    deep: 2,
    name: "瓮安",
  },
  {
    id: 522726,
    pid: 5227,
    deep: 2,
    name: "独山",
  },
  {
    id: 522727,
    pid: 5227,
    deep: 2,
    name: "平塘",
  },
  {
    id: 522728,
    pid: 5227,
    deep: 2,
    name: "罗甸",
  },
  {
    id: 522729,
    pid: 5227,
    deep: 2,
    name: "长顺",
  },
  {
    id: 522730,
    pid: 5227,
    deep: 2,
    name: "龙里",
  },
  {
    id: 522731,
    pid: 5227,
    deep: 2,
    name: "惠水",
  },
  {
    id: 522732,
    pid: 5227,
    deep: 2,
    name: "三都",
  },
  {
    id: 530102,
    pid: 5301,
    deep: 2,
    name: "五华",
  },
  {
    id: 530103,
    pid: 5301,
    deep: 2,
    name: "盘龙",
  },
  {
    id: 530111,
    pid: 5301,
    deep: 2,
    name: "官渡",
  },
  {
    id: 530112,
    pid: 5301,
    deep: 2,
    name: "西山",
  },
  {
    id: 530113,
    pid: 5301,
    deep: 2,
    name: "东川",
  },
  {
    id: 530114,
    pid: 5301,
    deep: 2,
    name: "呈贡",
  },
  {
    id: 530115,
    pid: 5301,
    deep: 2,
    name: "晋宁",
  },
  {
    id: 530124,
    pid: 5301,
    deep: 2,
    name: "富民",
  },
  {
    id: 530125,
    pid: 5301,
    deep: 2,
    name: "宜良",
  },
  {
    id: 530126,
    pid: 5301,
    deep: 2,
    name: "石林",
  },
  {
    id: 530127,
    pid: 5301,
    deep: 2,
    name: "嵩明",
  },
  {
    id: 530128,
    pid: 5301,
    deep: 2,
    name: "禄劝",
  },
  {
    id: 530129,
    pid: 5301,
    deep: 2,
    name: "寻甸",
  },
  {
    id: 530181,
    pid: 5301,
    deep: 2,
    name: "安宁",
  },
  {
    id: 530302,
    pid: 5303,
    deep: 2,
    name: "麒麟",
  },
  {
    id: 530303,
    pid: 5303,
    deep: 2,
    name: "沾益",
  },
  {
    id: 530304,
    pid: 5303,
    deep: 2,
    name: "马龙",
  },
  {
    id: 530322,
    pid: 5303,
    deep: 2,
    name: "陆良",
  },
  {
    id: 530323,
    pid: 5303,
    deep: 2,
    name: "师宗",
  },
  {
    id: 530324,
    pid: 5303,
    deep: 2,
    name: "罗平",
  },
  {
    id: 530325,
    pid: 5303,
    deep: 2,
    name: "富源",
  },
  {
    id: 530326,
    pid: 5303,
    deep: 2,
    name: "会泽",
  },
  {
    id: 530381,
    pid: 5303,
    deep: 2,
    name: "宣威",
  },
  {
    id: 530402,
    pid: 5304,
    deep: 2,
    name: "红塔",
  },
  {
    id: 530403,
    pid: 5304,
    deep: 2,
    name: "江川",
  },
  {
    id: 530422,
    pid: 5304,
    deep: 2,
    name: "澄江",
  },
  {
    id: 530423,
    pid: 5304,
    deep: 2,
    name: "通海",
  },
  {
    id: 530424,
    pid: 5304,
    deep: 2,
    name: "华宁",
  },
  {
    id: 530425,
    pid: 5304,
    deep: 2,
    name: "易门",
  },
  {
    id: 530426,
    pid: 5304,
    deep: 2,
    name: "峨山",
  },
  {
    id: 530427,
    pid: 5304,
    deep: 2,
    name: "新平",
  },
  {
    id: 530428,
    pid: 5304,
    deep: 2,
    name: "元江",
  },
  {
    id: 530502,
    pid: 5305,
    deep: 2,
    name: "隆阳",
  },
  {
    id: 530521,
    pid: 5305,
    deep: 2,
    name: "施甸",
  },
  {
    id: 530523,
    pid: 5305,
    deep: 2,
    name: "龙陵",
  },
  {
    id: 530524,
    pid: 5305,
    deep: 2,
    name: "昌宁",
  },
  {
    id: 530581,
    pid: 5305,
    deep: 2,
    name: "腾冲",
  },
  {
    id: 530602,
    pid: 5306,
    deep: 2,
    name: "昭阳",
  },
  {
    id: 530621,
    pid: 5306,
    deep: 2,
    name: "鲁甸",
  },
  {
    id: 530622,
    pid: 5306,
    deep: 2,
    name: "巧家",
  },
  {
    id: 530623,
    pid: 5306,
    deep: 2,
    name: "盐津",
  },
  {
    id: 530624,
    pid: 5306,
    deep: 2,
    name: "大关",
  },
  {
    id: 530625,
    pid: 5306,
    deep: 2,
    name: "永善",
  },
  {
    id: 530626,
    pid: 5306,
    deep: 2,
    name: "绥江",
  },
  {
    id: 530627,
    pid: 5306,
    deep: 2,
    name: "镇雄",
  },
  {
    id: 530628,
    pid: 5306,
    deep: 2,
    name: "彝良",
  },
  {
    id: 530629,
    pid: 5306,
    deep: 2,
    name: "威信",
  },
  {
    id: 530681,
    pid: 5306,
    deep: 2,
    name: "水富",
  },
  {
    id: 530702,
    pid: 5307,
    deep: 2,
    name: "古城",
  },
  {
    id: 530721,
    pid: 5307,
    deep: 2,
    name: "玉龙",
  },
  {
    id: 530722,
    pid: 5307,
    deep: 2,
    name: "永胜",
  },
  {
    id: 530723,
    pid: 5307,
    deep: 2,
    name: "华坪",
  },
  {
    id: 530724,
    pid: 5307,
    deep: 2,
    name: "宁蒗",
  },
  {
    id: 530802,
    pid: 5308,
    deep: 2,
    name: "思茅",
  },
  {
    id: 530821,
    pid: 5308,
    deep: 2,
    name: "宁洱",
  },
  {
    id: 530822,
    pid: 5308,
    deep: 2,
    name: "墨江",
  },
  {
    id: 530823,
    pid: 5308,
    deep: 2,
    name: "景东",
  },
  {
    id: 530824,
    pid: 5308,
    deep: 2,
    name: "景谷",
  },
  {
    id: 530825,
    pid: 5308,
    deep: 2,
    name: "镇沅",
  },
  {
    id: 530826,
    pid: 5308,
    deep: 2,
    name: "江城",
  },
  {
    id: 530827,
    pid: 5308,
    deep: 2,
    name: "孟连",
  },
  {
    id: 530828,
    pid: 5308,
    deep: 2,
    name: "澜沧",
  },
  {
    id: 530829,
    pid: 5308,
    deep: 2,
    name: "西盟",
  },
  {
    id: 530902,
    pid: 5309,
    deep: 2,
    name: "临翔",
  },
  {
    id: 530921,
    pid: 5309,
    deep: 2,
    name: "凤庆",
  },
  {
    id: 530922,
    pid: 5309,
    deep: 2,
    name: "云县",
  },
  {
    id: 530923,
    pid: 5309,
    deep: 2,
    name: "永德",
  },
  {
    id: 530924,
    pid: 5309,
    deep: 2,
    name: "镇康",
  },
  {
    id: 530925,
    pid: 5309,
    deep: 2,
    name: "双江",
  },
  {
    id: 530926,
    pid: 5309,
    deep: 2,
    name: "耿马",
  },
  {
    id: 530927,
    pid: 5309,
    deep: 2,
    name: "沧源",
  },
  {
    id: 532301,
    pid: 5323,
    deep: 2,
    name: "楚雄市",
  },
  {
    id: 532322,
    pid: 5323,
    deep: 2,
    name: "双柏",
  },
  {
    id: 532323,
    pid: 5323,
    deep: 2,
    name: "牟定",
  },
  {
    id: 532324,
    pid: 5323,
    deep: 2,
    name: "南华",
  },
  {
    id: 532325,
    pid: 5323,
    deep: 2,
    name: "姚安",
  },
  {
    id: 532326,
    pid: 5323,
    deep: 2,
    name: "大姚",
  },
  {
    id: 532327,
    pid: 5323,
    deep: 2,
    name: "永仁",
  },
  {
    id: 532328,
    pid: 5323,
    deep: 2,
    name: "元谋",
  },
  {
    id: 532329,
    pid: 5323,
    deep: 2,
    name: "武定",
  },
  {
    id: 532331,
    pid: 5323,
    deep: 2,
    name: "禄丰",
  },
  {
    id: 532501,
    pid: 5325,
    deep: 2,
    name: "个旧",
  },
  {
    id: 532502,
    pid: 5325,
    deep: 2,
    name: "开远",
  },
  {
    id: 532503,
    pid: 5325,
    deep: 2,
    name: "蒙自",
  },
  {
    id: 532504,
    pid: 5325,
    deep: 2,
    name: "弥勒",
  },
  {
    id: 532523,
    pid: 5325,
    deep: 2,
    name: "屏边",
  },
  {
    id: 532524,
    pid: 5325,
    deep: 2,
    name: "建水",
  },
  {
    id: 532525,
    pid: 5325,
    deep: 2,
    name: "石屏",
  },
  {
    id: 532527,
    pid: 5325,
    deep: 2,
    name: "泸西",
  },
  {
    id: 532528,
    pid: 5325,
    deep: 2,
    name: "元阳",
  },
  {
    id: 532529,
    pid: 5325,
    deep: 2,
    name: "红河县",
  },
  {
    id: 532530,
    pid: 5325,
    deep: 2,
    name: "金平",
  },
  {
    id: 532531,
    pid: 5325,
    deep: 2,
    name: "绿春",
  },
  {
    id: 532532,
    pid: 5325,
    deep: 2,
    name: "河口",
  },
  {
    id: 532601,
    pid: 5326,
    deep: 2,
    name: "文山市",
  },
  {
    id: 532622,
    pid: 5326,
    deep: 2,
    name: "砚山",
  },
  {
    id: 532623,
    pid: 5326,
    deep: 2,
    name: "西畴",
  },
  {
    id: 532624,
    pid: 5326,
    deep: 2,
    name: "麻栗坡",
  },
  {
    id: 532625,
    pid: 5326,
    deep: 2,
    name: "马关",
  },
  {
    id: 532626,
    pid: 5326,
    deep: 2,
    name: "丘北",
  },
  {
    id: 532627,
    pid: 5326,
    deep: 2,
    name: "广南",
  },
  {
    id: 532628,
    pid: 5326,
    deep: 2,
    name: "富宁",
  },
  {
    id: 532801,
    pid: 5328,
    deep: 2,
    name: "景洪",
  },
  {
    id: 532822,
    pid: 5328,
    deep: 2,
    name: "勐海",
  },
  {
    id: 532823,
    pid: 5328,
    deep: 2,
    name: "勐腊",
  },
  {
    id: 532901,
    pid: 5329,
    deep: 2,
    name: "大理市",
  },
  {
    id: 532922,
    pid: 5329,
    deep: 2,
    name: "漾濞",
  },
  {
    id: 532923,
    pid: 5329,
    deep: 2,
    name: "祥云",
  },
  {
    id: 532924,
    pid: 5329,
    deep: 2,
    name: "宾川",
  },
  {
    id: 532925,
    pid: 5329,
    deep: 2,
    name: "弥渡",
  },
  {
    id: 532926,
    pid: 5329,
    deep: 2,
    name: "南涧",
  },
  {
    id: 532927,
    pid: 5329,
    deep: 2,
    name: "巍山",
  },
  {
    id: 532928,
    pid: 5329,
    deep: 2,
    name: "永平",
  },
  {
    id: 532929,
    pid: 5329,
    deep: 2,
    name: "云龙",
  },
  {
    id: 532930,
    pid: 5329,
    deep: 2,
    name: "洱源",
  },
  {
    id: 532931,
    pid: 5329,
    deep: 2,
    name: "剑川",
  },
  {
    id: 532932,
    pid: 5329,
    deep: 2,
    name: "鹤庆",
  },
  {
    id: 533102,
    pid: 5331,
    deep: 2,
    name: "瑞丽",
  },
  {
    id: 533103,
    pid: 5331,
    deep: 2,
    name: "芒市",
  },
  {
    id: 533122,
    pid: 5331,
    deep: 2,
    name: "梁河",
  },
  {
    id: 533123,
    pid: 5331,
    deep: 2,
    name: "盈江",
  },
  {
    id: 533124,
    pid: 5331,
    deep: 2,
    name: "陇川",
  },
  {
    id: 533301,
    pid: 5333,
    deep: 2,
    name: "泸水",
  },
  {
    id: 533323,
    pid: 5333,
    deep: 2,
    name: "福贡",
  },
  {
    id: 533324,
    pid: 5333,
    deep: 2,
    name: "贡山",
  },
  {
    id: 533325,
    pid: 5333,
    deep: 2,
    name: "兰坪",
  },
  {
    id: 533401,
    pid: 5334,
    deep: 2,
    name: "香格里拉",
  },
  {
    id: 533422,
    pid: 5334,
    deep: 2,
    name: "德钦",
  },
  {
    id: 533423,
    pid: 5334,
    deep: 2,
    name: "维西",
  },
  {
    id: 540102,
    pid: 5401,
    deep: 2,
    name: "城关",
  },
  {
    id: 540103,
    pid: 5401,
    deep: 2,
    name: "堆龙德庆区",
  },
  {
    id: 540104,
    pid: 5401,
    deep: 2,
    name: "达孜",
  },
  {
    id: 540121,
    pid: 5401,
    deep: 2,
    name: "林周",
  },
  {
    id: 540122,
    pid: 5401,
    deep: 2,
    name: "当雄",
  },
  {
    id: 540123,
    pid: 5401,
    deep: 2,
    name: "尼木",
  },
  {
    id: 540124,
    pid: 5401,
    deep: 2,
    name: "曲水",
  },
  {
    id: 540127,
    pid: 5401,
    deep: 2,
    name: "墨竹工卡",
  },
  {
    id: 540202,
    pid: 5402,
    deep: 2,
    name: "桑珠孜",
  },
  {
    id: 540221,
    pid: 5402,
    deep: 2,
    name: "南木林",
  },
  {
    id: 540222,
    pid: 5402,
    deep: 2,
    name: "江孜",
  },
  {
    id: 540223,
    pid: 5402,
    deep: 2,
    name: "定日",
  },
  {
    id: 540224,
    pid: 5402,
    deep: 2,
    name: "萨迦",
  },
  {
    id: 540225,
    pid: 5402,
    deep: 2,
    name: "拉孜",
  },
  {
    id: 540226,
    pid: 5402,
    deep: 2,
    name: "昂仁",
  },
  {
    id: 540227,
    pid: 5402,
    deep: 2,
    name: "谢通门",
  },
  {
    id: 540228,
    pid: 5402,
    deep: 2,
    name: "白朗",
  },
  {
    id: 540229,
    pid: 5402,
    deep: 2,
    name: "仁布",
  },
  {
    id: 540230,
    pid: 5402,
    deep: 2,
    name: "康马",
  },
  {
    id: 540231,
    pid: 5402,
    deep: 2,
    name: "定结",
  },
  {
    id: 540232,
    pid: 5402,
    deep: 2,
    name: "仲巴",
  },
  {
    id: 540233,
    pid: 5402,
    deep: 2,
    name: "亚东",
  },
  {
    id: 540234,
    pid: 5402,
    deep: 2,
    name: "吉隆",
  },
  {
    id: 540235,
    pid: 5402,
    deep: 2,
    name: "聂拉木",
  },
  {
    id: 540236,
    pid: 5402,
    deep: 2,
    name: "萨嘎",
  },
  {
    id: 540237,
    pid: 5402,
    deep: 2,
    name: "岗巴",
  },
  {
    id: 540302,
    pid: 5403,
    deep: 2,
    name: "卡若",
  },
  {
    id: 540321,
    pid: 5403,
    deep: 2,
    name: "江达",
  },
  {
    id: 540322,
    pid: 5403,
    deep: 2,
    name: "贡觉",
  },
  {
    id: 540323,
    pid: 5403,
    deep: 2,
    name: "类乌齐",
  },
  {
    id: 540324,
    pid: 5403,
    deep: 2,
    name: "丁青",
  },
  {
    id: 540325,
    pid: 5403,
    deep: 2,
    name: "察雅",
  },
  {
    id: 540326,
    pid: 5403,
    deep: 2,
    name: "八宿",
  },
  {
    id: 540327,
    pid: 5403,
    deep: 2,
    name: "左贡",
  },
  {
    id: 540328,
    pid: 5403,
    deep: 2,
    name: "芒康",
  },
  {
    id: 540329,
    pid: 5403,
    deep: 2,
    name: "洛隆",
  },
  {
    id: 540330,
    pid: 5403,
    deep: 2,
    name: "边坝",
  },
  {
    id: 540402,
    pid: 5404,
    deep: 2,
    name: "巴宜",
  },
  {
    id: 540421,
    pid: 5404,
    deep: 2,
    name: "工布江达",
  },
  {
    id: 540422,
    pid: 5404,
    deep: 2,
    name: "米林",
  },
  {
    id: 540423,
    pid: 5404,
    deep: 2,
    name: "墨脱",
  },
  {
    id: 540424,
    pid: 5404,
    deep: 2,
    name: "波密",
  },
  {
    id: 540425,
    pid: 5404,
    deep: 2,
    name: "察隅",
  },
  {
    id: 540426,
    pid: 5404,
    deep: 2,
    name: "朗县",
  },
  {
    id: 540502,
    pid: 5405,
    deep: 2,
    name: "乃东",
  },
  {
    id: 540521,
    pid: 5405,
    deep: 2,
    name: "扎囊",
  },
  {
    id: 540522,
    pid: 5405,
    deep: 2,
    name: "贡嘎",
  },
  {
    id: 540523,
    pid: 5405,
    deep: 2,
    name: "桑日",
  },
  {
    id: 540524,
    pid: 5405,
    deep: 2,
    name: "琼结",
  },
  {
    id: 540525,
    pid: 5405,
    deep: 2,
    name: "曲松",
  },
  {
    id: 540526,
    pid: 5405,
    deep: 2,
    name: "措美",
  },
  {
    id: 540527,
    pid: 5405,
    deep: 2,
    name: "洛扎",
  },
  {
    id: 540528,
    pid: 5405,
    deep: 2,
    name: "加查",
  },
  {
    id: 540529,
    pid: 5405,
    deep: 2,
    name: "隆子",
  },
  {
    id: 540530,
    pid: 5405,
    deep: 2,
    name: "错那",
  },
  {
    id: 540531,
    pid: 5405,
    deep: 2,
    name: "浪卡子",
  },
  {
    id: 540602,
    pid: 5406,
    deep: 2,
    name: "色尼",
  },
  {
    id: 540621,
    pid: 5406,
    deep: 2,
    name: "嘉黎",
  },
  {
    id: 540622,
    pid: 5406,
    deep: 2,
    name: "比如",
  },
  {
    id: 540623,
    pid: 5406,
    deep: 2,
    name: "聂荣",
  },
  {
    id: 540624,
    pid: 5406,
    deep: 2,
    name: "安多",
  },
  {
    id: 540625,
    pid: 5406,
    deep: 2,
    name: "申扎",
  },
  {
    id: 540626,
    pid: 5406,
    deep: 2,
    name: "索县",
  },
  {
    id: 540627,
    pid: 5406,
    deep: 2,
    name: "班戈",
  },
  {
    id: 540628,
    pid: 5406,
    deep: 2,
    name: "巴青",
  },
  {
    id: 540629,
    pid: 5406,
    deep: 2,
    name: "尼玛",
  },
  {
    id: 540630,
    pid: 5406,
    deep: 2,
    name: "双湖",
  },
  {
    id: 542521,
    pid: 5425,
    deep: 2,
    name: "普兰",
  },
  {
    id: 542522,
    pid: 5425,
    deep: 2,
    name: "札达",
  },
  {
    id: 542523,
    pid: 5425,
    deep: 2,
    name: "噶尔",
  },
  {
    id: 542524,
    pid: 5425,
    deep: 2,
    name: "日土",
  },
  {
    id: 542525,
    pid: 5425,
    deep: 2,
    name: "革吉",
  },
  {
    id: 542526,
    pid: 5425,
    deep: 2,
    name: "改则",
  },
  {
    id: 542527,
    pid: 5425,
    deep: 2,
    name: "措勤",
  },
  {
    id: 610102,
    pid: 6101,
    deep: 2,
    name: "新城",
  },
  {
    id: 610103,
    pid: 6101,
    deep: 2,
    name: "碑林",
  },
  {
    id: 610104,
    pid: 6101,
    deep: 2,
    name: "莲湖",
  },
  {
    id: 610111,
    pid: 6101,
    deep: 2,
    name: "灞桥",
  },
  {
    id: 610112,
    pid: 6101,
    deep: 2,
    name: "未央",
  },
  {
    id: 610113,
    pid: 6101,
    deep: 2,
    name: "雁塔",
  },
  {
    id: 610114,
    pid: 6101,
    deep: 2,
    name: "阎良",
  },
  {
    id: 610115,
    pid: 6101,
    deep: 2,
    name: "临潼",
  },
  {
    id: 610116,
    pid: 6101,
    deep: 2,
    name: "长安",
  },
  {
    id: 610117,
    pid: 6101,
    deep: 2,
    name: "高陵",
  },
  {
    id: 610118,
    pid: 6101,
    deep: 2,
    name: "鄠邑",
  },
  {
    id: 610122,
    pid: 6101,
    deep: 2,
    name: "蓝田",
  },
  {
    id: 610124,
    pid: 6101,
    deep: 2,
    name: "周至",
  },
  {
    id: 610202,
    pid: 6102,
    deep: 2,
    name: "王益",
  },
  {
    id: 610203,
    pid: 6102,
    deep: 2,
    name: "印台",
  },
  {
    id: 610204,
    pid: 6102,
    deep: 2,
    name: "耀州",
  },
  {
    id: 610222,
    pid: 6102,
    deep: 2,
    name: "宜君",
  },
  {
    id: 610302,
    pid: 6103,
    deep: 2,
    name: "渭滨",
  },
  {
    id: 610303,
    pid: 6103,
    deep: 2,
    name: "金台",
  },
  {
    id: 610304,
    pid: 6103,
    deep: 2,
    name: "陈仓",
  },
  {
    id: 610322,
    pid: 6103,
    deep: 2,
    name: "凤翔",
  },
  {
    id: 610323,
    pid: 6103,
    deep: 2,
    name: "岐山",
  },
  {
    id: 610324,
    pid: 6103,
    deep: 2,
    name: "扶风",
  },
  {
    id: 610326,
    pid: 6103,
    deep: 2,
    name: "眉县",
  },
  {
    id: 610327,
    pid: 6103,
    deep: 2,
    name: "陇县",
  },
  {
    id: 610328,
    pid: 6103,
    deep: 2,
    name: "千阳",
  },
  {
    id: 610329,
    pid: 6103,
    deep: 2,
    name: "麟游",
  },
  {
    id: 610330,
    pid: 6103,
    deep: 2,
    name: "凤县",
  },
  {
    id: 610331,
    pid: 6103,
    deep: 2,
    name: "太白",
  },
  {
    id: 610402,
    pid: 6104,
    deep: 2,
    name: "秦都",
  },
  {
    id: 610403,
    pid: 6104,
    deep: 2,
    name: "杨陵",
  },
  {
    id: 610404,
    pid: 6104,
    deep: 2,
    name: "渭城",
  },
  {
    id: 610422,
    pid: 6104,
    deep: 2,
    name: "三原",
  },
  {
    id: 610423,
    pid: 6104,
    deep: 2,
    name: "泾阳",
  },
  {
    id: 610424,
    pid: 6104,
    deep: 2,
    name: "乾县",
  },
  {
    id: 610425,
    pid: 6104,
    deep: 2,
    name: "礼泉",
  },
  {
    id: 610426,
    pid: 6104,
    deep: 2,
    name: "永寿",
  },
  {
    id: 610428,
    pid: 6104,
    deep: 2,
    name: "长武",
  },
  {
    id: 610429,
    pid: 6104,
    deep: 2,
    name: "旬邑",
  },
  {
    id: 610430,
    pid: 6104,
    deep: 2,
    name: "淳化",
  },
  {
    id: 610431,
    pid: 6104,
    deep: 2,
    name: "武功",
  },
  {
    id: 610481,
    pid: 6104,
    deep: 2,
    name: "兴平",
  },
  {
    id: 610482,
    pid: 6104,
    deep: 2,
    name: "彬州",
  },
  {
    id: 610502,
    pid: 6105,
    deep: 2,
    name: "临渭",
  },
  {
    id: 610503,
    pid: 6105,
    deep: 2,
    name: "华州",
  },
  {
    id: 610522,
    pid: 6105,
    deep: 2,
    name: "潼关",
  },
  {
    id: 610523,
    pid: 6105,
    deep: 2,
    name: "大荔",
  },
  {
    id: 610524,
    pid: 6105,
    deep: 2,
    name: "合阳",
  },
  {
    id: 610525,
    pid: 6105,
    deep: 2,
    name: "澄城",
  },
  {
    id: 610526,
    pid: 6105,
    deep: 2,
    name: "蒲城",
  },
  {
    id: 610527,
    pid: 6105,
    deep: 2,
    name: "白水",
  },
  {
    id: 610528,
    pid: 6105,
    deep: 2,
    name: "富平",
  },
  {
    id: 610581,
    pid: 6105,
    deep: 2,
    name: "韩城",
  },
  {
    id: 610582,
    pid: 6105,
    deep: 2,
    name: "华阴",
  },
  {
    id: 610602,
    pid: 6106,
    deep: 2,
    name: "宝塔",
  },
  {
    id: 610603,
    pid: 6106,
    deep: 2,
    name: "安塞",
  },
  {
    id: 610621,
    pid: 6106,
    deep: 2,
    name: "延长",
  },
  {
    id: 610622,
    pid: 6106,
    deep: 2,
    name: "延川",
  },
  {
    id: 610623,
    pid: 6106,
    deep: 2,
    name: "子长",
  },
  {
    id: 610625,
    pid: 6106,
    deep: 2,
    name: "志丹",
  },
  {
    id: 610626,
    pid: 6106,
    deep: 2,
    name: "吴起",
  },
  {
    id: 610627,
    pid: 6106,
    deep: 2,
    name: "甘泉",
  },
  {
    id: 610628,
    pid: 6106,
    deep: 2,
    name: "富县",
  },
  {
    id: 610629,
    pid: 6106,
    deep: 2,
    name: "洛川",
  },
  {
    id: 610630,
    pid: 6106,
    deep: 2,
    name: "宜川",
  },
  {
    id: 610631,
    pid: 6106,
    deep: 2,
    name: "黄龙",
  },
  {
    id: 610632,
    pid: 6106,
    deep: 2,
    name: "黄陵",
  },
  {
    id: 610702,
    pid: 6107,
    deep: 2,
    name: "汉台",
  },
  {
    id: 610703,
    pid: 6107,
    deep: 2,
    name: "南郑",
  },
  {
    id: 610722,
    pid: 6107,
    deep: 2,
    name: "城固",
  },
  {
    id: 610723,
    pid: 6107,
    deep: 2,
    name: "洋县",
  },
  {
    id: 610724,
    pid: 6107,
    deep: 2,
    name: "西乡",
  },
  {
    id: 610725,
    pid: 6107,
    deep: 2,
    name: "勉县",
  },
  {
    id: 610726,
    pid: 6107,
    deep: 2,
    name: "宁强",
  },
  {
    id: 610727,
    pid: 6107,
    deep: 2,
    name: "略阳",
  },
  {
    id: 610728,
    pid: 6107,
    deep: 2,
    name: "镇巴",
  },
  {
    id: 610729,
    pid: 6107,
    deep: 2,
    name: "留坝",
  },
  {
    id: 610730,
    pid: 6107,
    deep: 2,
    name: "佛坪",
  },
  {
    id: 610802,
    pid: 6108,
    deep: 2,
    name: "榆阳",
  },
  {
    id: 610803,
    pid: 6108,
    deep: 2,
    name: "横山",
  },
  {
    id: 610822,
    pid: 6108,
    deep: 2,
    name: "府谷",
  },
  {
    id: 610824,
    pid: 6108,
    deep: 2,
    name: "靖边",
  },
  {
    id: 610825,
    pid: 6108,
    deep: 2,
    name: "定边",
  },
  {
    id: 610826,
    pid: 6108,
    deep: 2,
    name: "绥德",
  },
  {
    id: 610827,
    pid: 6108,
    deep: 2,
    name: "米脂",
  },
  {
    id: 610828,
    pid: 6108,
    deep: 2,
    name: "佳县",
  },
  {
    id: 610829,
    pid: 6108,
    deep: 2,
    name: "吴堡",
  },
  {
    id: 610830,
    pid: 6108,
    deep: 2,
    name: "清涧",
  },
  {
    id: 610831,
    pid: 6108,
    deep: 2,
    name: "子洲",
  },
  {
    id: 610881,
    pid: 6108,
    deep: 2,
    name: "神木",
  },
  {
    id: 610902,
    pid: 6109,
    deep: 2,
    name: "汉滨",
  },
  {
    id: 610921,
    pid: 6109,
    deep: 2,
    name: "汉阴",
  },
  {
    id: 610922,
    pid: 6109,
    deep: 2,
    name: "石泉",
  },
  {
    id: 610923,
    pid: 6109,
    deep: 2,
    name: "宁陕",
  },
  {
    id: 610924,
    pid: 6109,
    deep: 2,
    name: "紫阳",
  },
  {
    id: 610925,
    pid: 6109,
    deep: 2,
    name: "岚皋",
  },
  {
    id: 610926,
    pid: 6109,
    deep: 2,
    name: "平利",
  },
  {
    id: 610927,
    pid: 6109,
    deep: 2,
    name: "镇坪",
  },
  {
    id: 610928,
    pid: 6109,
    deep: 2,
    name: "旬阳",
  },
  {
    id: 610929,
    pid: 6109,
    deep: 2,
    name: "白河",
  },
  {
    id: 611002,
    pid: 6110,
    deep: 2,
    name: "商州",
  },
  {
    id: 611021,
    pid: 6110,
    deep: 2,
    name: "洛南",
  },
  {
    id: 611022,
    pid: 6110,
    deep: 2,
    name: "丹凤",
  },
  {
    id: 611023,
    pid: 6110,
    deep: 2,
    name: "商南",
  },
  {
    id: 611024,
    pid: 6110,
    deep: 2,
    name: "山阳",
  },
  {
    id: 611025,
    pid: 6110,
    deep: 2,
    name: "镇安",
  },
  {
    id: 611026,
    pid: 6110,
    deep: 2,
    name: "柞水",
  },
  {
    id: 620102,
    pid: 6201,
    deep: 2,
    name: "城关",
  },
  {
    id: 620103,
    pid: 6201,
    deep: 2,
    name: "七里河",
  },
  {
    id: 620104,
    pid: 6201,
    deep: 2,
    name: "西固",
  },
  {
    id: 620105,
    pid: 6201,
    deep: 2,
    name: "安宁",
  },
  {
    id: 620111,
    pid: 6201,
    deep: 2,
    name: "红古",
  },
  {
    id: 620121,
    pid: 6201,
    deep: 2,
    name: "永登",
  },
  {
    id: 620122,
    pid: 6201,
    deep: 2,
    name: "皋兰",
  },
  {
    id: 620123,
    pid: 6201,
    deep: 2,
    name: "榆中",
  },
  {
    id: 620201,
    pid: 6202,
    deep: 2,
    name: "嘉峪关",
  },
  {
    id: 620302,
    pid: 6203,
    deep: 2,
    name: "金川",
  },
  {
    id: 620321,
    pid: 6203,
    deep: 2,
    name: "永昌",
  },
  {
    id: 620402,
    pid: 6204,
    deep: 2,
    name: "白银区",
  },
  {
    id: 620403,
    pid: 6204,
    deep: 2,
    name: "平川",
  },
  {
    id: 620421,
    pid: 6204,
    deep: 2,
    name: "靖远",
  },
  {
    id: 620422,
    pid: 6204,
    deep: 2,
    name: "会宁",
  },
  {
    id: 620423,
    pid: 6204,
    deep: 2,
    name: "景泰",
  },
  {
    id: 620502,
    pid: 6205,
    deep: 2,
    name: "秦州",
  },
  {
    id: 620503,
    pid: 6205,
    deep: 2,
    name: "麦积",
  },
  {
    id: 620521,
    pid: 6205,
    deep: 2,
    name: "清水",
  },
  {
    id: 620522,
    pid: 6205,
    deep: 2,
    name: "秦安",
  },
  {
    id: 620523,
    pid: 6205,
    deep: 2,
    name: "甘谷",
  },
  {
    id: 620524,
    pid: 6205,
    deep: 2,
    name: "武山",
  },
  {
    id: 620525,
    pid: 6205,
    deep: 2,
    name: "张家川",
  },
  {
    id: 620602,
    pid: 6206,
    deep: 2,
    name: "凉州",
  },
  {
    id: 620621,
    pid: 6206,
    deep: 2,
    name: "民勤",
  },
  {
    id: 620622,
    pid: 6206,
    deep: 2,
    name: "古浪",
  },
  {
    id: 620623,
    pid: 6206,
    deep: 2,
    name: "天祝",
  },
  {
    id: 620702,
    pid: 6207,
    deep: 2,
    name: "甘州",
  },
  {
    id: 620721,
    pid: 6207,
    deep: 2,
    name: "肃南",
  },
  {
    id: 620722,
    pid: 6207,
    deep: 2,
    name: "民乐",
  },
  {
    id: 620723,
    pid: 6207,
    deep: 2,
    name: "临泽",
  },
  {
    id: 620724,
    pid: 6207,
    deep: 2,
    name: "高台",
  },
  {
    id: 620725,
    pid: 6207,
    deep: 2,
    name: "山丹",
  },
  {
    id: 620802,
    pid: 6208,
    deep: 2,
    name: "崆峒",
  },
  {
    id: 620821,
    pid: 6208,
    deep: 2,
    name: "泾川",
  },
  {
    id: 620822,
    pid: 6208,
    deep: 2,
    name: "灵台",
  },
  {
    id: 620823,
    pid: 6208,
    deep: 2,
    name: "崇信",
  },
  {
    id: 620825,
    pid: 6208,
    deep: 2,
    name: "庄浪",
  },
  {
    id: 620826,
    pid: 6208,
    deep: 2,
    name: "静宁",
  },
  {
    id: 620881,
    pid: 6208,
    deep: 2,
    name: "华亭",
  },
  {
    id: 620902,
    pid: 6209,
    deep: 2,
    name: "肃州",
  },
  {
    id: 620921,
    pid: 6209,
    deep: 2,
    name: "金塔",
  },
  {
    id: 620922,
    pid: 6209,
    deep: 2,
    name: "瓜州",
  },
  {
    id: 620923,
    pid: 6209,
    deep: 2,
    name: "肃北",
  },
  {
    id: 620924,
    pid: 6209,
    deep: 2,
    name: "阿克塞",
  },
  {
    id: 620981,
    pid: 6209,
    deep: 2,
    name: "玉门",
  },
  {
    id: 620982,
    pid: 6209,
    deep: 2,
    name: "敦煌",
  },
  {
    id: 621002,
    pid: 6210,
    deep: 2,
    name: "西峰",
  },
  {
    id: 621021,
    pid: 6210,
    deep: 2,
    name: "庆城",
  },
  {
    id: 621022,
    pid: 6210,
    deep: 2,
    name: "环县",
  },
  {
    id: 621023,
    pid: 6210,
    deep: 2,
    name: "华池",
  },
  {
    id: 621024,
    pid: 6210,
    deep: 2,
    name: "合水",
  },
  {
    id: 621025,
    pid: 6210,
    deep: 2,
    name: "正宁",
  },
  {
    id: 621026,
    pid: 6210,
    deep: 2,
    name: "宁县",
  },
  {
    id: 621027,
    pid: 6210,
    deep: 2,
    name: "镇原",
  },
  {
    id: 621102,
    pid: 6211,
    deep: 2,
    name: "安定",
  },
  {
    id: 621121,
    pid: 6211,
    deep: 2,
    name: "通渭",
  },
  {
    id: 621122,
    pid: 6211,
    deep: 2,
    name: "陇西",
  },
  {
    id: 621123,
    pid: 6211,
    deep: 2,
    name: "渭源",
  },
  {
    id: 621124,
    pid: 6211,
    deep: 2,
    name: "临洮",
  },
  {
    id: 621125,
    pid: 6211,
    deep: 2,
    name: "漳县",
  },
  {
    id: 621126,
    pid: 6211,
    deep: 2,
    name: "岷县",
  },
  {
    id: 621202,
    pid: 6212,
    deep: 2,
    name: "武都",
  },
  {
    id: 621221,
    pid: 6212,
    deep: 2,
    name: "成县",
  },
  {
    id: 621222,
    pid: 6212,
    deep: 2,
    name: "文县",
  },
  {
    id: 621223,
    pid: 6212,
    deep: 2,
    name: "宕昌",
  },
  {
    id: 621224,
    pid: 6212,
    deep: 2,
    name: "康县",
  },
  {
    id: 621225,
    pid: 6212,
    deep: 2,
    name: "西和",
  },
  {
    id: 621226,
    pid: 6212,
    deep: 2,
    name: "礼县",
  },
  {
    id: 621227,
    pid: 6212,
    deep: 2,
    name: "徽县",
  },
  {
    id: 621228,
    pid: 6212,
    deep: 2,
    name: "两当",
  },
  {
    id: 622901,
    pid: 6229,
    deep: 2,
    name: "临夏市",
  },
  {
    id: 622921,
    pid: 6229,
    deep: 2,
    name: "临夏县",
  },
  {
    id: 622922,
    pid: 6229,
    deep: 2,
    name: "康乐",
  },
  {
    id: 622923,
    pid: 6229,
    deep: 2,
    name: "永靖",
  },
  {
    id: 622924,
    pid: 6229,
    deep: 2,
    name: "广河",
  },
  {
    id: 622925,
    pid: 6229,
    deep: 2,
    name: "和政",
  },
  {
    id: 622926,
    pid: 6229,
    deep: 2,
    name: "东乡族自治县",
  },
  {
    id: 622927,
    pid: 6229,
    deep: 2,
    name: "积石山",
  },
  {
    id: 623001,
    pid: 6230,
    deep: 2,
    name: "合作",
  },
  {
    id: 623021,
    pid: 6230,
    deep: 2,
    name: "临潭",
  },
  {
    id: 623022,
    pid: 6230,
    deep: 2,
    name: "卓尼",
  },
  {
    id: 623023,
    pid: 6230,
    deep: 2,
    name: "舟曲",
  },
  {
    id: 623024,
    pid: 6230,
    deep: 2,
    name: "迭部",
  },
  {
    id: 623025,
    pid: 6230,
    deep: 2,
    name: "玛曲",
  },
  {
    id: 623026,
    pid: 6230,
    deep: 2,
    name: "碌曲",
  },
  {
    id: 623027,
    pid: 6230,
    deep: 2,
    name: "夏河",
  },
  {
    id: 630102,
    pid: 6301,
    deep: 2,
    name: "城东",
  },
  {
    id: 630103,
    pid: 6301,
    deep: 2,
    name: "城中",
  },
  {
    id: 630104,
    pid: 6301,
    deep: 2,
    name: "城西",
  },
  {
    id: 630105,
    pid: 6301,
    deep: 2,
    name: "城北",
  },
  {
    id: 630121,
    pid: 6301,
    deep: 2,
    name: "大通",
  },
  {
    id: 630122,
    pid: 6301,
    deep: 2,
    name: "湟中",
  },
  {
    id: 630123,
    pid: 6301,
    deep: 2,
    name: "湟源",
  },
  {
    id: 630202,
    pid: 6302,
    deep: 2,
    name: "乐都",
  },
  {
    id: 630203,
    pid: 6302,
    deep: 2,
    name: "平安",
  },
  {
    id: 630222,
    pid: 6302,
    deep: 2,
    name: "民和",
  },
  {
    id: 630223,
    pid: 6302,
    deep: 2,
    name: "互助",
  },
  {
    id: 630224,
    pid: 6302,
    deep: 2,
    name: "化隆",
  },
  {
    id: 630225,
    pid: 6302,
    deep: 2,
    name: "循化",
  },
  {
    id: 632221,
    pid: 6322,
    deep: 2,
    name: "门源",
  },
  {
    id: 632222,
    pid: 6322,
    deep: 2,
    name: "祁连",
  },
  {
    id: 632223,
    pid: 6322,
    deep: 2,
    name: "海晏",
  },
  {
    id: 632224,
    pid: 6322,
    deep: 2,
    name: "刚察",
  },
  {
    id: 632321,
    pid: 6323,
    deep: 2,
    name: "同仁",
  },
  {
    id: 632322,
    pid: 6323,
    deep: 2,
    name: "尖扎",
  },
  {
    id: 632323,
    pid: 6323,
    deep: 2,
    name: "泽库",
  },
  {
    id: 632324,
    pid: 6323,
    deep: 2,
    name: "河南",
  },
  {
    id: 632521,
    pid: 6325,
    deep: 2,
    name: "共和",
  },
  {
    id: 632522,
    pid: 6325,
    deep: 2,
    name: "同德",
  },
  {
    id: 632523,
    pid: 6325,
    deep: 2,
    name: "贵德",
  },
  {
    id: 632524,
    pid: 6325,
    deep: 2,
    name: "兴海",
  },
  {
    id: 632525,
    pid: 6325,
    deep: 2,
    name: "贵南",
  },
  {
    id: 632621,
    pid: 6326,
    deep: 2,
    name: "玛沁",
  },
  {
    id: 632622,
    pid: 6326,
    deep: 2,
    name: "班玛",
  },
  {
    id: 632623,
    pid: 6326,
    deep: 2,
    name: "甘德",
  },
  {
    id: 632624,
    pid: 6326,
    deep: 2,
    name: "达日",
  },
  {
    id: 632625,
    pid: 6326,
    deep: 2,
    name: "久治",
  },
  {
    id: 632626,
    pid: 6326,
    deep: 2,
    name: "玛多",
  },
  {
    id: 632701,
    pid: 6327,
    deep: 2,
    name: "玉树市",
  },
  {
    id: 632722,
    pid: 6327,
    deep: 2,
    name: "杂多",
  },
  {
    id: 632723,
    pid: 6327,
    deep: 2,
    name: "称多",
  },
  {
    id: 632724,
    pid: 6327,
    deep: 2,
    name: "治多",
  },
  {
    id: 632725,
    pid: 6327,
    deep: 2,
    name: "囊谦",
  },
  {
    id: 632726,
    pid: 6327,
    deep: 2,
    name: "曲麻莱",
  },
  {
    id: 632801,
    pid: 6328,
    deep: 2,
    name: "格尔木",
  },
  {
    id: 632802,
    pid: 6328,
    deep: 2,
    name: "德令哈",
  },
  {
    id: 632803,
    pid: 6328,
    deep: 2,
    name: "茫崖",
  },
  {
    id: 632821,
    pid: 6328,
    deep: 2,
    name: "乌兰",
  },
  {
    id: 632822,
    pid: 6328,
    deep: 2,
    name: "都兰",
  },
  {
    id: 632823,
    pid: 6328,
    deep: 2,
    name: "天峻",
  },
  {
    id: 632857,
    pid: 6328,
    deep: 2,
    name: "大柴旦行政委员会",
  },
  {
    id: 640104,
    pid: 6401,
    deep: 2,
    name: "兴庆",
  },
  {
    id: 640105,
    pid: 6401,
    deep: 2,
    name: "西夏",
  },
  {
    id: 640106,
    pid: 6401,
    deep: 2,
    name: "金凤",
  },
  {
    id: 640121,
    pid: 6401,
    deep: 2,
    name: "永宁",
  },
  {
    id: 640122,
    pid: 6401,
    deep: 2,
    name: "贺兰",
  },
  {
    id: 640181,
    pid: 6401,
    deep: 2,
    name: "灵武",
  },
  {
    id: 640202,
    pid: 6402,
    deep: 2,
    name: "大武口",
  },
  {
    id: 640205,
    pid: 6402,
    deep: 2,
    name: "惠农",
  },
  {
    id: 640221,
    pid: 6402,
    deep: 2,
    name: "平罗",
  },
  {
    id: 640302,
    pid: 6403,
    deep: 2,
    name: "利通",
  },
  {
    id: 640303,
    pid: 6403,
    deep: 2,
    name: "红寺堡",
  },
  {
    id: 640323,
    pid: 6403,
    deep: 2,
    name: "盐池",
  },
  {
    id: 640324,
    pid: 6403,
    deep: 2,
    name: "同心",
  },
  {
    id: 640381,
    pid: 6403,
    deep: 2,
    name: "青铜峡",
  },
  {
    id: 640402,
    pid: 6404,
    deep: 2,
    name: "原州",
  },
  {
    id: 640422,
    pid: 6404,
    deep: 2,
    name: "西吉",
  },
  {
    id: 640423,
    pid: 6404,
    deep: 2,
    name: "隆德",
  },
  {
    id: 640424,
    pid: 6404,
    deep: 2,
    name: "泾源",
  },
  {
    id: 640425,
    pid: 6404,
    deep: 2,
    name: "彭阳",
  },
  {
    id: 640502,
    pid: 6405,
    deep: 2,
    name: "沙坡头",
  },
  {
    id: 640521,
    pid: 6405,
    deep: 2,
    name: "中宁",
  },
  {
    id: 640522,
    pid: 6405,
    deep: 2,
    name: "海原",
  },
  {
    id: 650102,
    pid: 6501,
    deep: 2,
    name: "天山",
  },
  {
    id: 650103,
    pid: 6501,
    deep: 2,
    name: "沙依巴克区",
  },
  {
    id: 650104,
    pid: 6501,
    deep: 2,
    name: "新市",
  },
  {
    id: 650105,
    pid: 6501,
    deep: 2,
    name: "水磨沟",
  },
  {
    id: 650106,
    pid: 6501,
    deep: 2,
    name: "头屯河",
  },
  {
    id: 650107,
    pid: 6501,
    deep: 2,
    name: "达坂城",
  },
  {
    id: 650109,
    pid: 6501,
    deep: 2,
    name: "米东",
  },
  {
    id: 650121,
    pid: 6501,
    deep: 2,
    name: "乌鲁木齐县",
  },
  {
    id: 650202,
    pid: 6502,
    deep: 2,
    name: "独山子",
  },
  {
    id: 650203,
    pid: 6502,
    deep: 2,
    name: "克拉玛依区",
  },
  {
    id: 650204,
    pid: 6502,
    deep: 2,
    name: "白碱滩",
  },
  {
    id: 650205,
    pid: 6502,
    deep: 2,
    name: "乌尔禾",
  },
  {
    id: 650402,
    pid: 6504,
    deep: 2,
    name: "高昌",
  },
  {
    id: 650421,
    pid: 6504,
    deep: 2,
    name: "鄯善",
  },
  {
    id: 650422,
    pid: 6504,
    deep: 2,
    name: "托克逊",
  },
  {
    id: 650502,
    pid: 6505,
    deep: 2,
    name: "伊州",
  },
  {
    id: 650521,
    pid: 6505,
    deep: 2,
    name: "巴里坤",
  },
  {
    id: 650522,
    pid: 6505,
    deep: 2,
    name: "伊吾",
  },
  {
    id: 652301,
    pid: 6523,
    deep: 2,
    name: "昌吉市",
  },
  {
    id: 652302,
    pid: 6523,
    deep: 2,
    name: "阜康",
  },
  {
    id: 652323,
    pid: 6523,
    deep: 2,
    name: "呼图壁",
  },
  {
    id: 652324,
    pid: 6523,
    deep: 2,
    name: "玛纳斯",
  },
  {
    id: 652325,
    pid: 6523,
    deep: 2,
    name: "奇台",
  },
  {
    id: 652327,
    pid: 6523,
    deep: 2,
    name: "吉木萨尔",
  },
  {
    id: 652328,
    pid: 6523,
    deep: 2,
    name: "木垒",
  },
  {
    id: 652701,
    pid: 6527,
    deep: 2,
    name: "博乐",
  },
  {
    id: 652702,
    pid: 6527,
    deep: 2,
    name: "阿拉山口",
  },
  {
    id: 652722,
    pid: 6527,
    deep: 2,
    name: "精河",
  },
  {
    id: 652723,
    pid: 6527,
    deep: 2,
    name: "温泉",
  },
  {
    id: 652801,
    pid: 6528,
    deep: 2,
    name: "库尔勒",
  },
  {
    id: 652822,
    pid: 6528,
    deep: 2,
    name: "轮台",
  },
  {
    id: 652823,
    pid: 6528,
    deep: 2,
    name: "尉犁",
  },
  {
    id: 652824,
    pid: 6528,
    deep: 2,
    name: "若羌",
  },
  {
    id: 652825,
    pid: 6528,
    deep: 2,
    name: "且末",
  },
  {
    id: 652826,
    pid: 6528,
    deep: 2,
    name: "焉耆",
  },
  {
    id: 652827,
    pid: 6528,
    deep: 2,
    name: "和静",
  },
  {
    id: 652828,
    pid: 6528,
    deep: 2,
    name: "和硕",
  },
  {
    id: 652829,
    pid: 6528,
    deep: 2,
    name: "博湖",
  },
  {
    id: 652901,
    pid: 6529,
    deep: 2,
    name: "阿克苏市",
  },
  {
    id: 652922,
    pid: 6529,
    deep: 2,
    name: "温宿",
  },
  {
    id: 652923,
    pid: 6529,
    deep: 2,
    name: "库车",
  },
  {
    id: 652924,
    pid: 6529,
    deep: 2,
    name: "沙雅",
  },
  {
    id: 652925,
    pid: 6529,
    deep: 2,
    name: "新和",
  },
  {
    id: 652926,
    pid: 6529,
    deep: 2,
    name: "拜城",
  },
  {
    id: 652927,
    pid: 6529,
    deep: 2,
    name: "乌什",
  },
  {
    id: 652928,
    pid: 6529,
    deep: 2,
    name: "阿瓦提",
  },
  {
    id: 652929,
    pid: 6529,
    deep: 2,
    name: "柯坪",
  },
  {
    id: 653001,
    pid: 6530,
    deep: 2,
    name: "阿图什",
  },
  {
    id: 653022,
    pid: 6530,
    deep: 2,
    name: "阿克陶",
  },
  {
    id: 653023,
    pid: 6530,
    deep: 2,
    name: "阿合奇",
  },
  {
    id: 653024,
    pid: 6530,
    deep: 2,
    name: "乌恰",
  },
  {
    id: 653101,
    pid: 6531,
    deep: 2,
    name: "喀什市",
  },
  {
    id: 653121,
    pid: 6531,
    deep: 2,
    name: "疏附",
  },
  {
    id: 653122,
    pid: 6531,
    deep: 2,
    name: "疏勒",
  },
  {
    id: 653123,
    pid: 6531,
    deep: 2,
    name: "英吉沙",
  },
  {
    id: 653124,
    pid: 6531,
    deep: 2,
    name: "泽普",
  },
  {
    id: 653125,
    pid: 6531,
    deep: 2,
    name: "莎车",
  },
  {
    id: 653126,
    pid: 6531,
    deep: 2,
    name: "叶城",
  },
  {
    id: 653127,
    pid: 6531,
    deep: 2,
    name: "麦盖提",
  },
  {
    id: 653128,
    pid: 6531,
    deep: 2,
    name: "岳普湖",
  },
  {
    id: 653129,
    pid: 6531,
    deep: 2,
    name: "伽师",
  },
  {
    id: 653130,
    pid: 6531,
    deep: 2,
    name: "巴楚",
  },
  {
    id: 653131,
    pid: 6531,
    deep: 2,
    name: "塔什库尔干",
  },
  {
    id: 653201,
    pid: 6532,
    deep: 2,
    name: "和田市",
  },
  {
    id: 653221,
    pid: 6532,
    deep: 2,
    name: "和田县",
  },
  {
    id: 653222,
    pid: 6532,
    deep: 2,
    name: "墨玉",
  },
  {
    id: 653223,
    pid: 6532,
    deep: 2,
    name: "皮山",
  },
  {
    id: 653224,
    pid: 6532,
    deep: 2,
    name: "洛浦",
  },
  {
    id: 653225,
    pid: 6532,
    deep: 2,
    name: "策勒",
  },
  {
    id: 653226,
    pid: 6532,
    deep: 2,
    name: "于田",
  },
  {
    id: 653227,
    pid: 6532,
    deep: 2,
    name: "民丰",
  },
  {
    id: 654002,
    pid: 6540,
    deep: 2,
    name: "伊宁市",
  },
  {
    id: 654003,
    pid: 6540,
    deep: 2,
    name: "奎屯",
  },
  {
    id: 654004,
    pid: 6540,
    deep: 2,
    name: "霍尔果斯",
  },
  {
    id: 654021,
    pid: 6540,
    deep: 2,
    name: "伊宁县",
  },
  {
    id: 654022,
    pid: 6540,
    deep: 2,
    name: "察布查尔",
  },
  {
    id: 654023,
    pid: 6540,
    deep: 2,
    name: "霍城",
  },
  {
    id: 654024,
    pid: 6540,
    deep: 2,
    name: "巩留",
  },
  {
    id: 654025,
    pid: 6540,
    deep: 2,
    name: "新源",
  },
  {
    id: 654026,
    pid: 6540,
    deep: 2,
    name: "昭苏",
  },
  {
    id: 654027,
    pid: 6540,
    deep: 2,
    name: "特克斯",
  },
  {
    id: 654028,
    pid: 6540,
    deep: 2,
    name: "尼勒克",
  },
  {
    id: 654201,
    pid: 6542,
    deep: 2,
    name: "塔城市",
  },
  {
    id: 654202,
    pid: 6542,
    deep: 2,
    name: "乌苏",
  },
  {
    id: 654221,
    pid: 6542,
    deep: 2,
    name: "额敏",
  },
  {
    id: 654223,
    pid: 6542,
    deep: 2,
    name: "沙湾",
  },
  {
    id: 654224,
    pid: 6542,
    deep: 2,
    name: "托里",
  },
  {
    id: 654225,
    pid: 6542,
    deep: 2,
    name: "裕民",
  },
  {
    id: 654226,
    pid: 6542,
    deep: 2,
    name: "和布克赛尔",
  },
  {
    id: 654301,
    pid: 6543,
    deep: 2,
    name: "阿勒泰市",
  },
  {
    id: 654321,
    pid: 6543,
    deep: 2,
    name: "布尔津",
  },
  {
    id: 654322,
    pid: 6543,
    deep: 2,
    name: "富蕴",
  },
  {
    id: 654323,
    pid: 6543,
    deep: 2,
    name: "福海",
  },
  {
    id: 654324,
    pid: 6543,
    deep: 2,
    name: "哈巴河",
  },
  {
    id: 654325,
    pid: 6543,
    deep: 2,
    name: "青河",
  },
  {
    id: 654326,
    pid: 6543,
    deep: 2,
    name: "吉木乃",
  },
  {
    id: 659001,
    pid: 65,
    deep: 1,
    name: "石河子",
  },
  {
    id: 659002,
    pid: 65,
    deep: 1,
    name: "阿拉尔",
  },
  {
    id: 659003,
    pid: 65,
    deep: 1,
    name: "图木舒克",
  },
  {
    id: 659004,
    pid: 65,
    deep: 1,
    name: "五家渠",
  },
  {
    id: 659005,
    pid: 65,
    deep: 1,
    name: "北屯",
  },
  {
    id: 659006,
    pid: 65,
    deep: 1,
    name: "铁门关",
  },
  {
    id: 659007,
    pid: 65,
    deep: 1,
    name: "双河",
  },
  {
    id: 659008,
    pid: 65,
    deep: 1,
    name: "可克达拉",
  },
  {
    id: 659009,
    pid: 65,
    deep: 1,
    name: "昆玉",
  },
  {
    id: 710101,
    pid: 7101,
    deep: 2,
    name: "中正",
  },
  {
    id: 710102,
    pid: 7101,
    deep: 2,
    name: "大同",
  },
  {
    id: 710103,
    pid: 7101,
    deep: 2,
    name: "中山",
  },
  {
    id: 710104,
    pid: 7101,
    deep: 2,
    name: "松山",
  },
  {
    id: 710105,
    pid: 7101,
    deep: 2,
    name: "大安",
  },
  {
    id: 710106,
    pid: 7101,
    deep: 2,
    name: "万华",
  },
  {
    id: 710107,
    pid: 7101,
    deep: 2,
    name: "信义",
  },
  {
    id: 710108,
    pid: 7101,
    deep: 2,
    name: "士林",
  },
  {
    id: 710109,
    pid: 7101,
    deep: 2,
    name: "北投",
  },
  {
    id: 710110,
    pid: 7101,
    deep: 2,
    name: "内湖",
  },
  {
    id: 710111,
    pid: 7101,
    deep: 2,
    name: "南港",
  },
  {
    id: 710112,
    pid: 7101,
    deep: 2,
    name: "文山",
  },
  {
    id: 710201,
    pid: 7102,
    deep: 2,
    name: "新兴",
  },
  {
    id: 710202,
    pid: 7102,
    deep: 2,
    name: "前金",
  },
  {
    id: 710203,
    pid: 7102,
    deep: 2,
    name: "苓雅",
  },
  {
    id: 710204,
    pid: 7102,
    deep: 2,
    name: "盐埕",
  },
  {
    id: 710205,
    pid: 7102,
    deep: 2,
    name: "鼓山",
  },
  {
    id: 710206,
    pid: 7102,
    deep: 2,
    name: "旗津",
  },
  {
    id: 710207,
    pid: 7102,
    deep: 2,
    name: "前镇",
  },
  {
    id: 710208,
    pid: 7102,
    deep: 2,
    name: "三民",
  },
  {
    id: 710209,
    pid: 7102,
    deep: 2,
    name: "左营",
  },
  {
    id: 710210,
    pid: 7102,
    deep: 2,
    name: "楠梓",
  },
  {
    id: 710211,
    pid: 7102,
    deep: 2,
    name: "小港",
  },
  {
    id: 710242,
    pid: 7102,
    deep: 2,
    name: "仁武",
  },
  {
    id: 710243,
    pid: 7102,
    deep: 2,
    name: "大社",
  },
  {
    id: 710244,
    pid: 7102,
    deep: 2,
    name: "冈山",
  },
  {
    id: 710245,
    pid: 7102,
    deep: 2,
    name: "路竹",
  },
  {
    id: 710246,
    pid: 7102,
    deep: 2,
    name: "阿莲",
  },
  {
    id: 710247,
    pid: 7102,
    deep: 2,
    name: "田寮",
  },
  {
    id: 710248,
    pid: 7102,
    deep: 2,
    name: "燕巢",
  },
  {
    id: 710249,
    pid: 7102,
    deep: 2,
    name: "桥头",
  },
  {
    id: 710250,
    pid: 7102,
    deep: 2,
    name: "梓官",
  },
  {
    id: 710251,
    pid: 7102,
    deep: 2,
    name: "弥陀",
  },
  {
    id: 710252,
    pid: 7102,
    deep: 2,
    name: "永安",
  },
  {
    id: 710253,
    pid: 7102,
    deep: 2,
    name: "湖内",
  },
  {
    id: 710254,
    pid: 7102,
    deep: 2,
    name: "凤山",
  },
  {
    id: 710255,
    pid: 7102,
    deep: 2,
    name: "大寮",
  },
  {
    id: 710256,
    pid: 7102,
    deep: 2,
    name: "林园",
  },
  {
    id: 710257,
    pid: 7102,
    deep: 2,
    name: "鸟松",
  },
  {
    id: 710258,
    pid: 7102,
    deep: 2,
    name: "大树",
  },
  {
    id: 710259,
    pid: 7102,
    deep: 2,
    name: "旗山",
  },
  {
    id: 710260,
    pid: 7102,
    deep: 2,
    name: "美浓",
  },
  {
    id: 710261,
    pid: 7102,
    deep: 2,
    name: "六龟",
  },
  {
    id: 710262,
    pid: 7102,
    deep: 2,
    name: "内门",
  },
  {
    id: 710263,
    pid: 7102,
    deep: 2,
    name: "杉林",
  },
  {
    id: 710264,
    pid: 7102,
    deep: 2,
    name: "甲仙",
  },
  {
    id: 710265,
    pid: 7102,
    deep: 2,
    name: "桃源",
  },
  {
    id: 710266,
    pid: 7102,
    deep: 2,
    name: "那玛夏",
  },
  {
    id: 710267,
    pid: 7102,
    deep: 2,
    name: "茂林",
  },
  {
    id: 710268,
    pid: 7102,
    deep: 2,
    name: "茄萣",
  },
  {
    id: 710301,
    pid: 7103,
    deep: 2,
    name: "中西",
  },
  {
    id: 710302,
    pid: 7103,
    deep: 2,
    name: "东区",
  },
  {
    id: 710303,
    pid: 7103,
    deep: 2,
    name: "南区",
  },
  {
    id: 710304,
    pid: 7103,
    deep: 2,
    name: "北区",
  },
  {
    id: 710305,
    pid: 7103,
    deep: 2,
    name: "安平",
  },
  {
    id: 710306,
    pid: 7103,
    deep: 2,
    name: "安南",
  },
  {
    id: 710339,
    pid: 7103,
    deep: 2,
    name: "永康",
  },
  {
    id: 710340,
    pid: 7103,
    deep: 2,
    name: "归仁",
  },
  {
    id: 710341,
    pid: 7103,
    deep: 2,
    name: "新化",
  },
  {
    id: 710342,
    pid: 7103,
    deep: 2,
    name: "左镇",
  },
  {
    id: 710343,
    pid: 7103,
    deep: 2,
    name: "玉井",
  },
  {
    id: 710344,
    pid: 7103,
    deep: 2,
    name: "楠西",
  },
  {
    id: 710345,
    pid: 7103,
    deep: 2,
    name: "南化",
  },
  {
    id: 710346,
    pid: 7103,
    deep: 2,
    name: "仁德",
  },
  {
    id: 710347,
    pid: 7103,
    deep: 2,
    name: "关庙",
  },
  {
    id: 710348,
    pid: 7103,
    deep: 2,
    name: "龙崎",
  },
  {
    id: 710349,
    pid: 7103,
    deep: 2,
    name: "官田",
  },
  {
    id: 710350,
    pid: 7103,
    deep: 2,
    name: "麻豆",
  },
  {
    id: 710351,
    pid: 7103,
    deep: 2,
    name: "佳里",
  },
  {
    id: 710352,
    pid: 7103,
    deep: 2,
    name: "西港",
  },
  {
    id: 710353,
    pid: 7103,
    deep: 2,
    name: "七股",
  },
  {
    id: 710354,
    pid: 7103,
    deep: 2,
    name: "将军",
  },
  {
    id: 710355,
    pid: 7103,
    deep: 2,
    name: "学甲",
  },
  {
    id: 710356,
    pid: 7103,
    deep: 2,
    name: "北门",
  },
  {
    id: 710357,
    pid: 7103,
    deep: 2,
    name: "新营",
  },
  {
    id: 710358,
    pid: 7103,
    deep: 2,
    name: "后壁",
  },
  {
    id: 710359,
    pid: 7103,
    deep: 2,
    name: "白河",
  },
  {
    id: 710360,
    pid: 7103,
    deep: 2,
    name: "东山",
  },
  {
    id: 710361,
    pid: 7103,
    deep: 2,
    name: "六甲",
  },
  {
    id: 710362,
    pid: 7103,
    deep: 2,
    name: "下营",
  },
  {
    id: 710363,
    pid: 7103,
    deep: 2,
    name: "柳营",
  },
  {
    id: 710364,
    pid: 7103,
    deep: 2,
    name: "盐水",
  },
  {
    id: 710365,
    pid: 7103,
    deep: 2,
    name: "善化",
  },
  {
    id: 710366,
    pid: 7103,
    deep: 2,
    name: "大内",
  },
  {
    id: 710367,
    pid: 7103,
    deep: 2,
    name: "山上",
  },
  {
    id: 710368,
    pid: 7103,
    deep: 2,
    name: "新市",
  },
  {
    id: 710369,
    pid: 7103,
    deep: 2,
    name: "安定",
  },
  {
    id: 710401,
    pid: 7104,
    deep: 2,
    name: "中区",
  },
  {
    id: 710402,
    pid: 7104,
    deep: 2,
    name: "东区",
  },
  {
    id: 710403,
    pid: 7104,
    deep: 2,
    name: "南区",
  },
  {
    id: 710404,
    pid: 7104,
    deep: 2,
    name: "西区",
  },
  {
    id: 710405,
    pid: 7104,
    deep: 2,
    name: "北区",
  },
  {
    id: 710406,
    pid: 7104,
    deep: 2,
    name: "北屯",
  },
  {
    id: 710407,
    pid: 7104,
    deep: 2,
    name: "西屯",
  },
  {
    id: 710408,
    pid: 7104,
    deep: 2,
    name: "南屯",
  },
  {
    id: 710431,
    pid: 7104,
    deep: 2,
    name: "太平",
  },
  {
    id: 710432,
    pid: 7104,
    deep: 2,
    name: "大里",
  },
  {
    id: 710433,
    pid: 7104,
    deep: 2,
    name: "雾峰",
  },
  {
    id: 710434,
    pid: 7104,
    deep: 2,
    name: "乌日",
  },
  {
    id: 710435,
    pid: 7104,
    deep: 2,
    name: "丰原",
  },
  {
    id: 710436,
    pid: 7104,
    deep: 2,
    name: "后里",
  },
  {
    id: 710437,
    pid: 7104,
    deep: 2,
    name: "石冈",
  },
  {
    id: 710438,
    pid: 7104,
    deep: 2,
    name: "东势",
  },
  {
    id: 710439,
    pid: 7104,
    deep: 2,
    name: "和平",
  },
  {
    id: 710440,
    pid: 7104,
    deep: 2,
    name: "新社",
  },
  {
    id: 710441,
    pid: 7104,
    deep: 2,
    name: "潭子",
  },
  {
    id: 710442,
    pid: 7104,
    deep: 2,
    name: "大雅",
  },
  {
    id: 710443,
    pid: 7104,
    deep: 2,
    name: "神冈",
  },
  {
    id: 710444,
    pid: 7104,
    deep: 2,
    name: "大肚",
  },
  {
    id: 710445,
    pid: 7104,
    deep: 2,
    name: "沙鹿",
  },
  {
    id: 710446,
    pid: 7104,
    deep: 2,
    name: "龙井",
  },
  {
    id: 710447,
    pid: 7104,
    deep: 2,
    name: "梧栖",
  },
  {
    id: 710448,
    pid: 7104,
    deep: 2,
    name: "清水",
  },
  {
    id: 710449,
    pid: 7104,
    deep: 2,
    name: "大甲",
  },
  {
    id: 710450,
    pid: 7104,
    deep: 2,
    name: "外埔",
  },
  {
    id: 710451,
    pid: 7104,
    deep: 2,
    name: "大安",
  },
  {
    id: 710614,
    pid: 7106,
    deep: 2,
    name: "南投市",
  },
  {
    id: 710615,
    pid: 7106,
    deep: 2,
    name: "中寮",
  },
  {
    id: 710616,
    pid: 7106,
    deep: 2,
    name: "草屯",
  },
  {
    id: 710617,
    pid: 7106,
    deep: 2,
    name: "国姓",
  },
  {
    id: 710618,
    pid: 7106,
    deep: 2,
    name: "埔里",
  },
  {
    id: 710619,
    pid: 7106,
    deep: 2,
    name: "仁爱",
  },
  {
    id: 710620,
    pid: 7106,
    deep: 2,
    name: "名间",
  },
  {
    id: 710621,
    pid: 7106,
    deep: 2,
    name: "集集",
  },
  {
    id: 710622,
    pid: 7106,
    deep: 2,
    name: "水里",
  },
  {
    id: 710623,
    pid: 7106,
    deep: 2,
    name: "鱼池",
  },
  {
    id: 710624,
    pid: 7106,
    deep: 2,
    name: "信义",
  },
  {
    id: 710625,
    pid: 7106,
    deep: 2,
    name: "竹山",
  },
  {
    id: 710626,
    pid: 7106,
    deep: 2,
    name: "鹿谷",
  },
  {
    id: 710701,
    pid: 7107,
    deep: 2,
    name: "仁爱",
  },
  {
    id: 710702,
    pid: 7107,
    deep: 2,
    name: "信义",
  },
  {
    id: 710703,
    pid: 7107,
    deep: 2,
    name: "中正",
  },
  {
    id: 710704,
    pid: 7107,
    deep: 2,
    name: "中山",
  },
  {
    id: 710705,
    pid: 7107,
    deep: 2,
    name: "安乐",
  },
  {
    id: 710706,
    pid: 7107,
    deep: 2,
    name: "暖暖",
  },
  {
    id: 710707,
    pid: 7107,
    deep: 2,
    name: "七堵",
  },
  {
    id: 710801,
    pid: 7108,
    deep: 2,
    name: "东区",
  },
  {
    id: 710802,
    pid: 7108,
    deep: 2,
    name: "北区",
  },
  {
    id: 710803,
    pid: 7108,
    deep: 2,
    name: "香山",
  },
  {
    id: 710901,
    pid: 7109,
    deep: 2,
    name: "东区",
  },
  {
    id: 710902,
    pid: 7109,
    deep: 2,
    name: "西区",
  },
  {
    id: 711130,
    pid: 7111,
    deep: 2,
    name: "万里",
  },
  {
    id: 711131,
    pid: 7111,
    deep: 2,
    name: "金山",
  },
  {
    id: 711132,
    pid: 7111,
    deep: 2,
    name: "板桥",
  },
  {
    id: 711133,
    pid: 7111,
    deep: 2,
    name: "汐止",
  },
  {
    id: 711134,
    pid: 7111,
    deep: 2,
    name: "深坑",
  },
  {
    id: 711135,
    pid: 7111,
    deep: 2,
    name: "石碇",
  },
  {
    id: 711136,
    pid: 7111,
    deep: 2,
    name: "瑞芳",
  },
  {
    id: 711137,
    pid: 7111,
    deep: 2,
    name: "平溪",
  },
  {
    id: 711138,
    pid: 7111,
    deep: 2,
    name: "双溪",
  },
  {
    id: 711139,
    pid: 7111,
    deep: 2,
    name: "贡寮",
  },
  {
    id: 711140,
    pid: 7111,
    deep: 2,
    name: "新店",
  },
  {
    id: 711141,
    pid: 7111,
    deep: 2,
    name: "坪林",
  },
  {
    id: 711142,
    pid: 7111,
    deep: 2,
    name: "乌来",
  },
  {
    id: 711143,
    pid: 7111,
    deep: 2,
    name: "永和",
  },
  {
    id: 711144,
    pid: 7111,
    deep: 2,
    name: "中和",
  },
  {
    id: 711145,
    pid: 7111,
    deep: 2,
    name: "土城",
  },
  {
    id: 711146,
    pid: 7111,
    deep: 2,
    name: "三峡",
  },
  {
    id: 711147,
    pid: 7111,
    deep: 2,
    name: "树林",
  },
  {
    id: 711148,
    pid: 7111,
    deep: 2,
    name: "莺歌",
  },
  {
    id: 711149,
    pid: 7111,
    deep: 2,
    name: "三重",
  },
  {
    id: 711150,
    pid: 7111,
    deep: 2,
    name: "新庄",
  },
  {
    id: 711151,
    pid: 7111,
    deep: 2,
    name: "泰山",
  },
  {
    id: 711152,
    pid: 7111,
    deep: 2,
    name: "林口",
  },
  {
    id: 711153,
    pid: 7111,
    deep: 2,
    name: "芦洲",
  },
  {
    id: 711154,
    pid: 7111,
    deep: 2,
    name: "五股",
  },
  {
    id: 711155,
    pid: 7111,
    deep: 2,
    name: "八里",
  },
  {
    id: 711156,
    pid: 7111,
    deep: 2,
    name: "淡水",
  },
  {
    id: 711157,
    pid: 7111,
    deep: 2,
    name: "三芝",
  },
  {
    id: 711158,
    pid: 7111,
    deep: 2,
    name: "石门",
  },
  {
    id: 711214,
    pid: 7112,
    deep: 2,
    name: "宜兰市",
  },
  {
    id: 711215,
    pid: 7112,
    deep: 2,
    name: "头城",
  },
  {
    id: 711216,
    pid: 7112,
    deep: 2,
    name: "礁溪",
  },
  {
    id: 711217,
    pid: 7112,
    deep: 2,
    name: "壮围",
  },
  {
    id: 711218,
    pid: 7112,
    deep: 2,
    name: "员山",
  },
  {
    id: 711219,
    pid: 7112,
    deep: 2,
    name: "罗东",
  },
  {
    id: 711220,
    pid: 7112,
    deep: 2,
    name: "三星",
  },
  {
    id: 711221,
    pid: 7112,
    deep: 2,
    name: "大同",
  },
  {
    id: 711222,
    pid: 7112,
    deep: 2,
    name: "五结",
  },
  {
    id: 711223,
    pid: 7112,
    deep: 2,
    name: "冬山",
  },
  {
    id: 711224,
    pid: 7112,
    deep: 2,
    name: "苏澳",
  },
  {
    id: 711225,
    pid: 7112,
    deep: 2,
    name: "南澳",
  },
  {
    id: 711314,
    pid: 7113,
    deep: 2,
    name: "竹北",
  },
  {
    id: 711315,
    pid: 7113,
    deep: 2,
    name: "湖口",
  },
  {
    id: 711316,
    pid: 7113,
    deep: 2,
    name: "新丰",
  },
  {
    id: 711317,
    pid: 7113,
    deep: 2,
    name: "新埔",
  },
  {
    id: 711318,
    pid: 7113,
    deep: 2,
    name: "关西",
  },
  {
    id: 711319,
    pid: 7113,
    deep: 2,
    name: "芎林",
  },
  {
    id: 711320,
    pid: 7113,
    deep: 2,
    name: "宝山",
  },
  {
    id: 711321,
    pid: 7113,
    deep: 2,
    name: "竹东",
  },
  {
    id: 711322,
    pid: 7113,
    deep: 2,
    name: "五峰",
  },
  {
    id: 711323,
    pid: 7113,
    deep: 2,
    name: "横山",
  },
  {
    id: 711324,
    pid: 7113,
    deep: 2,
    name: "尖石",
  },
  {
    id: 711325,
    pid: 7113,
    deep: 2,
    name: "北埔",
  },
  {
    id: 711326,
    pid: 7113,
    deep: 2,
    name: "峨眉",
  },
  {
    id: 711414,
    pid: 7114,
    deep: 2,
    name: "中坜",
  },
  {
    id: 711415,
    pid: 7114,
    deep: 2,
    name: "平镇",
  },
  {
    id: 711416,
    pid: 7114,
    deep: 2,
    name: "龙潭",
  },
  {
    id: 711417,
    pid: 7114,
    deep: 2,
    name: "杨梅",
  },
  {
    id: 711418,
    pid: 7114,
    deep: 2,
    name: "新屋",
  },
  {
    id: 711419,
    pid: 7114,
    deep: 2,
    name: "观音",
  },
  {
    id: 711420,
    pid: 7114,
    deep: 2,
    name: "桃园区",
  },
  {
    id: 711421,
    pid: 7114,
    deep: 2,
    name: "龟山",
  },
  {
    id: 711422,
    pid: 7114,
    deep: 2,
    name: "八德",
  },
  {
    id: 711423,
    pid: 7114,
    deep: 2,
    name: "大溪",
  },
  {
    id: 711424,
    pid: 7114,
    deep: 2,
    name: "复兴",
  },
  {
    id: 711425,
    pid: 7114,
    deep: 2,
    name: "大园",
  },
  {
    id: 711426,
    pid: 7114,
    deep: 2,
    name: "芦竹",
  },
  {
    id: 711519,
    pid: 7115,
    deep: 2,
    name: "竹南",
  },
  {
    id: 711520,
    pid: 7115,
    deep: 2,
    name: "头份",
  },
  {
    id: 711521,
    pid: 7115,
    deep: 2,
    name: "三湾",
  },
  {
    id: 711522,
    pid: 7115,
    deep: 2,
    name: "南庄",
  },
  {
    id: 711523,
    pid: 7115,
    deep: 2,
    name: "狮潭",
  },
  {
    id: 711524,
    pid: 7115,
    deep: 2,
    name: "后龙",
  },
  {
    id: 711525,
    pid: 7115,
    deep: 2,
    name: "通霄",
  },
  {
    id: 711526,
    pid: 7115,
    deep: 2,
    name: "苑里",
  },
  {
    id: 711527,
    pid: 7115,
    deep: 2,
    name: "苗栗市",
  },
  {
    id: 711528,
    pid: 7115,
    deep: 2,
    name: "造桥",
  },
  {
    id: 711529,
    pid: 7115,
    deep: 2,
    name: "头屋",
  },
  {
    id: 711530,
    pid: 7115,
    deep: 2,
    name: "公馆",
  },
  {
    id: 711531,
    pid: 7115,
    deep: 2,
    name: "大湖",
  },
  {
    id: 711532,
    pid: 7115,
    deep: 2,
    name: "泰安",
  },
  {
    id: 711533,
    pid: 7115,
    deep: 2,
    name: "铜锣",
  },
  {
    id: 711534,
    pid: 7115,
    deep: 2,
    name: "三义",
  },
  {
    id: 711535,
    pid: 7115,
    deep: 2,
    name: "西湖",
  },
  {
    id: 711536,
    pid: 7115,
    deep: 2,
    name: "卓兰",
  },
  {
    id: 711727,
    pid: 7117,
    deep: 2,
    name: "彰化市",
  },
  {
    id: 711728,
    pid: 7117,
    deep: 2,
    name: "芬园",
  },
  {
    id: 711729,
    pid: 7117,
    deep: 2,
    name: "花坛",
  },
  {
    id: 711730,
    pid: 7117,
    deep: 2,
    name: "秀水",
  },
  {
    id: 711731,
    pid: 7117,
    deep: 2,
    name: "鹿港",
  },
  {
    id: 711732,
    pid: 7117,
    deep: 2,
    name: "福兴",
  },
  {
    id: 711733,
    pid: 7117,
    deep: 2,
    name: "线西",
  },
  {
    id: 711734,
    pid: 7117,
    deep: 2,
    name: "和美",
  },
  {
    id: 711735,
    pid: 7117,
    deep: 2,
    name: "伸港",
  },
  {
    id: 711736,
    pid: 7117,
    deep: 2,
    name: "员林",
  },
  {
    id: 711737,
    pid: 7117,
    deep: 2,
    name: "社头",
  },
  {
    id: 711738,
    pid: 7117,
    deep: 2,
    name: "永靖",
  },
  {
    id: 711739,
    pid: 7117,
    deep: 2,
    name: "埔心",
  },
  {
    id: 711740,
    pid: 7117,
    deep: 2,
    name: "溪湖",
  },
  {
    id: 711741,
    pid: 7117,
    deep: 2,
    name: "大村",
  },
  {
    id: 711742,
    pid: 7117,
    deep: 2,
    name: "埔盐",
  },
  {
    id: 711743,
    pid: 7117,
    deep: 2,
    name: "田中",
  },
  {
    id: 711744,
    pid: 7117,
    deep: 2,
    name: "北斗",
  },
  {
    id: 711745,
    pid: 7117,
    deep: 2,
    name: "田尾",
  },
  {
    id: 711746,
    pid: 7117,
    deep: 2,
    name: "埤头",
  },
  {
    id: 711747,
    pid: 7117,
    deep: 2,
    name: "溪州",
  },
  {
    id: 711748,
    pid: 7117,
    deep: 2,
    name: "竹塘",
  },
  {
    id: 711749,
    pid: 7117,
    deep: 2,
    name: "二林",
  },
  {
    id: 711750,
    pid: 7117,
    deep: 2,
    name: "大城",
  },
  {
    id: 711751,
    pid: 7117,
    deep: 2,
    name: "芳苑",
  },
  {
    id: 711752,
    pid: 7117,
    deep: 2,
    name: "二水",
  },
  {
    id: 711919,
    pid: 7119,
    deep: 2,
    name: "番路",
  },
  {
    id: 711920,
    pid: 7119,
    deep: 2,
    name: "梅山",
  },
  {
    id: 711921,
    pid: 7119,
    deep: 2,
    name: "竹崎",
  },
  {
    id: 711922,
    pid: 7119,
    deep: 2,
    name: "阿里山",
  },
  {
    id: 711923,
    pid: 7119,
    deep: 2,
    name: "中埔",
  },
  {
    id: 711924,
    pid: 7119,
    deep: 2,
    name: "大埔",
  },
  {
    id: 711925,
    pid: 7119,
    deep: 2,
    name: "水上",
  },
  {
    id: 711926,
    pid: 7119,
    deep: 2,
    name: "鹿草",
  },
  {
    id: 711927,
    pid: 7119,
    deep: 2,
    name: "太保",
  },
  {
    id: 711928,
    pid: 7119,
    deep: 2,
    name: "朴子",
  },
  {
    id: 711929,
    pid: 7119,
    deep: 2,
    name: "东石",
  },
  {
    id: 711930,
    pid: 7119,
    deep: 2,
    name: "六脚",
  },
  {
    id: 711931,
    pid: 7119,
    deep: 2,
    name: "新港",
  },
  {
    id: 711932,
    pid: 7119,
    deep: 2,
    name: "民雄",
  },
  {
    id: 711933,
    pid: 7119,
    deep: 2,
    name: "大林",
  },
  {
    id: 711934,
    pid: 7119,
    deep: 2,
    name: "溪口",
  },
  {
    id: 711935,
    pid: 7119,
    deep: 2,
    name: "义竹",
  },
  {
    id: 711936,
    pid: 7119,
    deep: 2,
    name: "布袋",
  },
  {
    id: 712121,
    pid: 7121,
    deep: 2,
    name: "斗南",
  },
  {
    id: 712122,
    pid: 7121,
    deep: 2,
    name: "大埤",
  },
  {
    id: 712123,
    pid: 7121,
    deep: 2,
    name: "虎尾",
  },
  {
    id: 712124,
    pid: 7121,
    deep: 2,
    name: "土库",
  },
  {
    id: 712125,
    pid: 7121,
    deep: 2,
    name: "褒忠",
  },
  {
    id: 712126,
    pid: 7121,
    deep: 2,
    name: "东势",
  },
  {
    id: 712127,
    pid: 7121,
    deep: 2,
    name: "台西",
  },
  {
    id: 712128,
    pid: 7121,
    deep: 2,
    name: "仑背",
  },
  {
    id: 712129,
    pid: 7121,
    deep: 2,
    name: "麦寮",
  },
  {
    id: 712130,
    pid: 7121,
    deep: 2,
    name: "斗六",
  },
  {
    id: 712131,
    pid: 7121,
    deep: 2,
    name: "林内",
  },
  {
    id: 712132,
    pid: 7121,
    deep: 2,
    name: "古坑",
  },
  {
    id: 712133,
    pid: 7121,
    deep: 2,
    name: "莿桐",
  },
  {
    id: 712134,
    pid: 7121,
    deep: 2,
    name: "西螺",
  },
  {
    id: 712135,
    pid: 7121,
    deep: 2,
    name: "二仑",
  },
  {
    id: 712136,
    pid: 7121,
    deep: 2,
    name: "北港",
  },
  {
    id: 712137,
    pid: 7121,
    deep: 2,
    name: "水林",
  },
  {
    id: 712138,
    pid: 7121,
    deep: 2,
    name: "口湖",
  },
  {
    id: 712139,
    pid: 7121,
    deep: 2,
    name: "四湖",
  },
  {
    id: 712140,
    pid: 7121,
    deep: 2,
    name: "元长",
  },
  {
    id: 712434,
    pid: 7124,
    deep: 2,
    name: "屏东市",
  },
  {
    id: 712435,
    pid: 7124,
    deep: 2,
    name: "三地门",
  },
  {
    id: 712436,
    pid: 7124,
    deep: 2,
    name: "雾台",
  },
  {
    id: 712437,
    pid: 7124,
    deep: 2,
    name: "玛家",
  },
  {
    id: 712438,
    pid: 7124,
    deep: 2,
    name: "九如",
  },
  {
    id: 712439,
    pid: 7124,
    deep: 2,
    name: "里港",
  },
  {
    id: 712440,
    pid: 7124,
    deep: 2,
    name: "高树",
  },
  {
    id: 712441,
    pid: 7124,
    deep: 2,
    name: "盐埔",
  },
  {
    id: 712442,
    pid: 7124,
    deep: 2,
    name: "长治",
  },
  {
    id: 712443,
    pid: 7124,
    deep: 2,
    name: "麟洛",
  },
  {
    id: 712444,
    pid: 7124,
    deep: 2,
    name: "竹田",
  },
  {
    id: 712445,
    pid: 7124,
    deep: 2,
    name: "内埔",
  },
  {
    id: 712446,
    pid: 7124,
    deep: 2,
    name: "万丹",
  },
  {
    id: 712447,
    pid: 7124,
    deep: 2,
    name: "潮州",
  },
  {
    id: 712448,
    pid: 7124,
    deep: 2,
    name: "泰武",
  },
  {
    id: 712449,
    pid: 7124,
    deep: 2,
    name: "来义",
  },
  {
    id: 712450,
    pid: 7124,
    deep: 2,
    name: "万峦",
  },
  {
    id: 712451,
    pid: 7124,
    deep: 2,
    name: "崁顶",
  },
  {
    id: 712452,
    pid: 7124,
    deep: 2,
    name: "新埤",
  },
  {
    id: 712453,
    pid: 7124,
    deep: 2,
    name: "南州",
  },
  {
    id: 712454,
    pid: 7124,
    deep: 2,
    name: "林边",
  },
  {
    id: 712455,
    pid: 7124,
    deep: 2,
    name: "东港",
  },
  {
    id: 712456,
    pid: 7124,
    deep: 2,
    name: "琉球",
  },
  {
    id: 712457,
    pid: 7124,
    deep: 2,
    name: "佳冬",
  },
  {
    id: 712458,
    pid: 7124,
    deep: 2,
    name: "新园",
  },
  {
    id: 712459,
    pid: 7124,
    deep: 2,
    name: "枋寮",
  },
  {
    id: 712460,
    pid: 7124,
    deep: 2,
    name: "枋山",
  },
  {
    id: 712461,
    pid: 7124,
    deep: 2,
    name: "春日",
  },
  {
    id: 712462,
    pid: 7124,
    deep: 2,
    name: "狮子",
  },
  {
    id: 712463,
    pid: 7124,
    deep: 2,
    name: "车城",
  },
  {
    id: 712464,
    pid: 7124,
    deep: 2,
    name: "牡丹",
  },
  {
    id: 712465,
    pid: 7124,
    deep: 2,
    name: "恒春",
  },
  {
    id: 712466,
    pid: 7124,
    deep: 2,
    name: "满州",
  },
  {
    id: 712517,
    pid: 7125,
    deep: 2,
    name: "台东市",
  },
  {
    id: 712518,
    pid: 7125,
    deep: 2,
    name: "绿岛",
  },
  {
    id: 712519,
    pid: 7125,
    deep: 2,
    name: "兰屿",
  },
  {
    id: 712520,
    pid: 7125,
    deep: 2,
    name: "延平",
  },
  {
    id: 712521,
    pid: 7125,
    deep: 2,
    name: "卑南",
  },
  {
    id: 712522,
    pid: 7125,
    deep: 2,
    name: "鹿野",
  },
  {
    id: 712523,
    pid: 7125,
    deep: 2,
    name: "关山",
  },
  {
    id: 712524,
    pid: 7125,
    deep: 2,
    name: "海端",
  },
  {
    id: 712525,
    pid: 7125,
    deep: 2,
    name: "池上",
  },
  {
    id: 712526,
    pid: 7125,
    deep: 2,
    name: "东河",
  },
  {
    id: 712527,
    pid: 7125,
    deep: 2,
    name: "成功",
  },
  {
    id: 712528,
    pid: 7125,
    deep: 2,
    name: "长滨",
  },
  {
    id: 712529,
    pid: 7125,
    deep: 2,
    name: "金峰",
  },
  {
    id: 712530,
    pid: 7125,
    deep: 2,
    name: "大武",
  },
  {
    id: 712531,
    pid: 7125,
    deep: 2,
    name: "达仁",
  },
  {
    id: 712532,
    pid: 7125,
    deep: 2,
    name: "太麻里",
  },
  {
    id: 712615,
    pid: 7126,
    deep: 2,
    name: "花莲市",
  },
  {
    id: 712616,
    pid: 7126,
    deep: 2,
    name: "新城",
  },
  {
    id: 712618,
    pid: 7126,
    deep: 2,
    name: "秀林",
  },
  {
    id: 712619,
    pid: 7126,
    deep: 2,
    name: "吉安",
  },
  {
    id: 712620,
    pid: 7126,
    deep: 2,
    name: "寿丰",
  },
  {
    id: 712621,
    pid: 7126,
    deep: 2,
    name: "凤林",
  },
  {
    id: 712622,
    pid: 7126,
    deep: 2,
    name: "光复",
  },
  {
    id: 712623,
    pid: 7126,
    deep: 2,
    name: "丰滨",
  },
  {
    id: 712624,
    pid: 7126,
    deep: 2,
    name: "瑞穗",
  },
  {
    id: 712625,
    pid: 7126,
    deep: 2,
    name: "万荣",
  },
  {
    id: 712626,
    pid: 7126,
    deep: 2,
    name: "玉里",
  },
  {
    id: 712627,
    pid: 7126,
    deep: 2,
    name: "卓溪",
  },
  {
    id: 712628,
    pid: 7126,
    deep: 2,
    name: "富里",
  },
  {
    id: 712707,
    pid: 7127,
    deep: 2,
    name: "马公",
  },
  {
    id: 712708,
    pid: 7127,
    deep: 2,
    name: "西屿",
  },
  {
    id: 712709,
    pid: 7127,
    deep: 2,
    name: "望安",
  },
  {
    id: 712710,
    pid: 7127,
    deep: 2,
    name: "七美",
  },
  {
    id: 712711,
    pid: 7127,
    deep: 2,
    name: "白沙",
  },
  {
    id: 712712,
    pid: 7127,
    deep: 2,
    name: "湖西",
  },
  {
    id: 810000,
    pid: 8100,
    deep: 2,
    name: "香港",
  },
  {
    id: 820000,
    pid: 8200,
    deep: 2,
    name: "澳门",
  },
  {
    id: 910000,
    pid: 9100,
    deep: 2,
    name: "国外",
  },
  {
    id: 419001000,
    pid: 419001,
    deep: 2,
    name: "济源",
  },
  {
    id: 429004000,
    pid: 429004,
    deep: 2,
    name: "仙桃",
  },
  {
    id: 429005000,
    pid: 429005,
    deep: 2,
    name: "潜江",
  },
  {
    id: 429006000,
    pid: 429006,
    deep: 2,
    name: "天门",
  },
  {
    id: 429021000,
    pid: 429021,
    deep: 2,
    name: "神农架林区",
  },
  {
    id: 469001000,
    pid: 469001,
    deep: 2,
    name: "五指山",
  },
  {
    id: 469002000,
    pid: 469002,
    deep: 2,
    name: "琼海",
  },
  {
    id: 469005000,
    pid: 469005,
    deep: 2,
    name: "文昌",
  },
  {
    id: 469006000,
    pid: 469006,
    deep: 2,
    name: "万宁",
  },
  {
    id: 469007000,
    pid: 469007,
    deep: 2,
    name: "东方",
  },
  {
    id: 469021000,
    pid: 469021,
    deep: 2,
    name: "定安",
  },
  {
    id: 469022000,
    pid: 469022,
    deep: 2,
    name: "屯昌",
  },
  {
    id: 469023000,
    pid: 469023,
    deep: 2,
    name: "澄迈",
  },
  {
    id: 469024000,
    pid: 469024,
    deep: 2,
    name: "临高",
  },
  {
    id: 469025000,
    pid: 469025,
    deep: 2,
    name: "白沙",
  },
  {
    id: 469026000,
    pid: 469026,
    deep: 2,
    name: "昌江",
  },
  {
    id: 469027000,
    pid: 469027,
    deep: 2,
    name: "乐东",
  },
  {
    id: 469028000,
    pid: 469028,
    deep: 2,
    name: "陵水",
  },
  {
    id: 469029000,
    pid: 469029,
    deep: 2,
    name: "保亭",
  },
  {
    id: 469030000,
    pid: 469030,
    deep: 2,
    name: "琼中",
  },
  {
    id: 659001000,
    pid: 659001,
    deep: 2,
    name: "石河子",
  },
  {
    id: 659002000,
    pid: 659002,
    deep: 2,
    name: "阿拉尔",
  },
  {
    id: 659003000,
    pid: 659003,
    deep: 2,
    name: "图木舒克",
  },
  {
    id: 659004000,
    pid: 659004,
    deep: 2,
    name: "五家渠",
  },
  {
    id: 659005000,
    pid: 659005,
    deep: 2,
    name: "北屯",
  },
  {
    id: 659006000,
    pid: 659006,
    deep: 2,
    name: "铁门关",
  },
  {
    id: 659007000,
    pid: 659007,
    deep: 2,
    name: "双河",
  },
  {
    id: 659008000,
    pid: 659008,
    deep: 2,
    name: "可克达拉",
  },
  {
    id: 659009000,
    pid: 659009,
    deep: 2,
    name: "昆玉",
  },
];

const cate = [
  {
    id: 1,
    pid: 0,
    name: "生活服务",
    status: 1,
  },
  {
    id: 2,
    pid: 1,
    name: "快递速运",
    status: 1,
  },
  {
    id: 3,
    pid: 1,
    name: "电脑服务",
    status: 1,
  },
  {
    id: 4,
    pid: 1,
    name: "搬家",
    status: 1,
  },
  {
    id: 5,
    pid: 1,
    name: "家政",
    status: 1,
  },
  {
    id: 6,
    pid: 1,
    name: "征婚交友",
    status: 1,
  },
  {
    id: 7,
    pid: 1,
    name: "仪式典礼",
    status: 1,
  },
  {
    id: 8,
    pid: 1,
    name: "摄影",
    status: 1,
  },
  {
    id: 9,
    pid: 1,
    name: "汽车租赁",
    status: 1,
  },
  {
    id: 10,
    pid: 1,
    name: "家电维修",
    status: 1,
  },
  {
    id: 11,
    pid: 1,
    name: "居民服务",
    status: 1,
  },
  {
    id: 12,
    pid: 1,
    name: "宠物服务",
    status: 1,
  },
  {
    id: 13,
    pid: 1,
    name: "房屋租赁",
    status: 1,
  },
  {
    id: 14,
    pid: 0,
    name: "商务服务",
    status: 1,
  },
  {
    id: 15,
    pid: 14,
    name: "物流运输",
    status: 1,
  },
  {
    id: 16,
    pid: 14,
    name: "出国",
    status: 1,
  },
  {
    id: 17,
    pid: 14,
    name: "招聘",
    status: 1,
  },
  {
    id: 18,
    pid: 14,
    name: "翻译",
    status: 1,
  },
  {
    id: 19,
    pid: 14,
    name: "设计",
    status: 1,
  },
  {
    id: 20,
    pid: 14,
    name: "广告",
    status: 1,
  },
  {
    id: 21,
    pid: 14,
    name: "公关策划",
    status: 1,
  },
  {
    id: 22,
    pid: 14,
    name: "咨询",
    status: 1,
  },
  {
    id: 23,
    pid: 14,
    name: "拍卖",
    status: 1,
  },
  {
    id: 24,
    pid: 14,
    name: "代理",
    status: 1,
  },
  {
    id: 25,
    pid: 14,
    name: "调查",
    status: 1,
  },
  {
    id: 26,
    pid: 14,
    name: "法律服务",
    status: 1,
  },
  {
    id: 27,
    pid: 14,
    name: "会计审计",
    status: 1,
  },
  {
    id: 28,
    pid: 14,
    name: "铃声短信",
    status: 1,
  },
  {
    id: 29,
    pid: 14,
    name: "出版印刷",
    status: 1,
  },
  {
    id: 30,
    pid: 0,
    name: "招商加盟",
    status: 1,
  },
  {
    id: 31,
    pid: 30,
    name: "服装鞋帽",
    status: 1,
  },
  {
    id: 32,
    pid: 30,
    name: "美容保养",
    status: 1,
  },
  {
    id: 33,
    pid: 30,
    name: "礼品饰品",
    status: 1,
  },
  {
    id: 34,
    pid: 30,
    name: "餐饮美食",
    status: 1,
  },
  {
    id: 35,
    pid: 30,
    name: "生活服务",
    status: 1,
  },
  {
    id: 36,
    pid: 30,
    name: "教育培训",
    status: 1,
  },
  {
    id: 37,
    pid: 30,
    name: "环保机械",
    status: 1,
  },
  {
    id: 38,
    pid: 30,
    name: "汽车电子",
    status: 1,
  },
  {
    id: 39,
    pid: 30,
    name: "母婴用品",
    status: 1,
  },
  {
    id: 40,
    pid: 30,
    name: "家居用品",
    status: 1,
  },
  {
    id: 41,
    pid: 30,
    name: "综合招商",
    status: 1,
  },
  {
    id: 42,
    pid: 30,
    name: "养殖种植",
    status: 1,
  },
  {
    id: 43,
    pid: 0,
    name: "金融服务",
    status: 1,
  },
  {
    id: 44,
    pid: 43,
    name: "理财",
    status: 1,
  },
  {
    id: 45,
    pid: 43,
    name: "银行",
    status: 1,
  },
  {
    id: 46,
    pid: 43,
    name: "保险",
    status: 1,
  },
  {
    id: 47,
    pid: 43,
    name: "投资担保",
    status: 1,
  },
  {
    id: 48,
    pid: 43,
    name: "典当",
    status: 1,
  },
  {
    id: 49,
    pid: 0,
    name: "教育培训",
    status: 1,
  },
  {
    id: 50,
    pid: 49,
    name: "学前教育",
    status: 1,
  },
  {
    id: 51,
    pid: 49,
    name: "小初高教育",
    status: 1,
  },
  {
    id: 52,
    pid: 49,
    name: "高教自考",
    status: 1,
  },
  {
    id: 53,
    pid: 49,
    name: "留学",
    status: 1,
  },
  {
    id: 54,
    pid: 49,
    name: "IT培训",
    status: 1,
  },
  {
    id: 55,
    pid: 49,
    name: "语言培训",
    status: 1,
  },
  {
    id: 56,
    pid: 49,
    name: "文体培训",
    status: 1,
  },
  {
    id: 57,
    pid: 49,
    name: "企业培训拓展",
    status: 1,
  },
  {
    id: 58,
    pid: 49,
    name: "特殊人群教育",
    status: 1,
  },
  {
    id: 59,
    pid: 0,
    name: "医疗服务",
    status: 1,
  },
  {
    id: 60,
    pid: 59,
    name: "综合医院",
    status: 1,
  },
  {
    id: 61,
    pid: 59,
    name: "专科医院",
    status: 1,
  },
  {
    id: 62,
    pid: 59,
    name: "中医医院",
    status: 1,
  },
  {
    id: 63,
    pid: 59,
    name: "中西医结合医院",
    status: 1,
  },
  {
    id: 64,
    pid: 59,
    name: "民族医医院",
    status: 1,
  },
  {
    id: 65,
    pid: 59,
    name: "妇幼保健医院",
    status: 1,
  },
  {
    id: 66,
    pid: 59,
    name: "社区、乡镇卫生院",
    status: 1,
  },
  {
    id: 67,
    pid: 59,
    name: "门诊部",
    status: 1,
  },
  {
    id: 68,
    pid: 59,
    name: "诊所",
    status: 1,
  },
  {
    id: 69,
    pid: 59,
    name: "急救中心",
    status: 1,
  },
  {
    id: 70,
    pid: 59,
    name: "体检机构",
    status: 1,
  },
  {
    id: 71,
    pid: 59,
    name: "药品",
    status: 1,
  },
  {
    id: 72,
    pid: 59,
    name: "医疗器械",
    status: 1,
  },
  {
    id: 73,
    pid: 59,
    name: "其他",
    status: 1,
  },
  {
    id: 74,
    pid: 0,
    name: "旅游住宿",
    status: 1,
  },
  {
    id: 75,
    pid: 74,
    name: "旅游",
    status: 1,
  },
  {
    id: 76,
    pid: 74,
    name: "宾馆酒店",
    status: 1,
  },
  {
    id: 77,
    pid: 74,
    name: "交通票务",
    status: 1,
  },
  {
    id: 78,
    pid: 0,
    name: "日用百货",
    status: 1,
  },
  {
    id: 79,
    pid: 78,
    name: "教具",
    status: 1,
  },
  {
    id: 80,
    pid: 78,
    name: "文具",
    status: 1,
  },
  {
    id: 81,
    pid: 78,
    name: "服装",
    status: 1,
  },
  {
    id: 82,
    pid: 78,
    name: "鞋帽",
    status: 1,
  },
  {
    id: 83,
    pid: 78,
    name: "纺织原料",
    status: 1,
  },
  {
    id: 84,
    pid: 78,
    name: "箱包",
    status: 1,
  },
  {
    id: 85,
    pid: 78,
    name: "饰品",
    status: 1,
  },
  {
    id: 86,
    pid: 78,
    name: "日化用品",
    status: 1,
  },
  {
    id: 87,
    pid: 78,
    name: "化妆品",
    status: 1,
  },
  {
    id: 88,
    pid: 78,
    name: "美容",
    status: 1,
  },
  {
    id: 89,
    pid: 78,
    name: "母婴护理",
    status: 1,
  },
  {
    id: 90,
    pid: 78,
    name: "家具",
    status: 1,
  },
  {
    id: 91,
    pid: 78,
    name: "家纺家饰",
    status: 1,
  },
  {
    id: 92,
    pid: 78,
    name: "厨具餐具",
    status: 1,
  },
  {
    id: 93,
    pid: 78,
    name: "大型家电",
    status: 1,
  },
  {
    id: 94,
    pid: 78,
    name: "厨用电器",
    status: 1,
  },
  {
    id: 95,
    pid: 78,
    name: "卫浴家电",
    status: 1,
  },
  {
    id: 96,
    pid: 78,
    name: "健康电器",
    status: 1,
  },
  {
    id: 97,
    pid: 78,
    name: "生活小家电",
    status: 1,
  },
  {
    id: 98,
    pid: 78,
    name: "成人用品",
    status: 1,
  },
  {
    id: 99,
    pid: 78,
    name: "礼品",
    status: 1,
  },
  {
    id: 100,
    pid: 0,
    name: "食品餐饮",
    status: 1,
  },
  {
    id: 101,
    pid: 100,
    name: "生活食材",
    status: 1,
  },
  {
    id: 102,
    pid: 100,
    name: "休闲零食",
    status: 1,
  },
  {
    id: 103,
    pid: 100,
    name: "饮料饮品",
    status: 1,
  },
  {
    id: 104,
    pid: 100,
    name: "保健食品",
    status: 1,
  },
  {
    id: 105,
    pid: 100,
    name: "烟酒",
    status: 1,
  },
  {
    id: 106,
    pid: 100,
    name: "餐馆",
    status: 1,
  },
  {
    id: 107,
    pid: 0,
    name: "数码科技",
    status: 1,
  },
  {
    id: 108,
    pid: 107,
    name: "电脑整机",
    status: 1,
  },
  {
    id: 109,
    pid: 107,
    name: "电脑配件",
    status: 1,
  },
  {
    id: 110,
    pid: 107,
    name: "网络设备",
    status: 1,
  },
  {
    id: 111,
    pid: 107,
    name: "手机",
    status: 1,
  },
  {
    id: 112,
    pid: 107,
    name: "数码产品",
    status: 1,
  },
  {
    id: 113,
    pid: 0,
    name: "信息服务",
    status: 1,
  },
  {
    id: 114,
    pid: 113,
    name: "平台网站",
    status: 1,
  },
  {
    id: 115,
    pid: 113,
    name: "操作系统",
    status: 1,
  },
  {
    id: 116,
    pid: 113,
    name: "中间件软件",
    status: 1,
  },
  {
    id: 117,
    pid: 113,
    name: "应用软件",
    status: 1,
  },
  {
    id: 118,
    pid: 113,
    name: "杀毒软件",
    status: 1,
  },
  {
    id: 119,
    pid: 113,
    name: "监控安全软件",
    status: 1,
  },
  {
    id: 120,
    pid: 113,
    name: "数据库软件",
    status: 1,
  },
  {
    id: 121,
    pid: 113,
    name: "企业软件",
    status: 1,
  },
  {
    id: 122,
    pid: 113,
    name: "行业专用软件",
    status: 1,
  },
  {
    id: 123,
    pid: 113,
    name: "支付结算软件",
    status: 1,
  },
  {
    id: 124,
    pid: 113,
    name: "教学软件",
    status: 1,
  },
  {
    id: 125,
    pid: 113,
    name: "通讯服务",
    status: 1,
  },
  {
    id: 126,
    pid: 113,
    name: "网站建设",
    status: 1,
  },
  {
    id: 127,
    pid: 113,
    name: "域名空间",
    status: 1,
  },
  {
    id: 128,
    pid: 0,
    name: "文体娱乐",
    status: 1,
  },
  {
    id: 129,
    pid: 128,
    name: "广场舞",
    status: 1,
  },
  {
    id: 130,
    pid: 128,
    name: "影视传媒",
    status: 1,
  },
  {
    id: 131,
    pid: 128,
    name: "游戏",
    status: 1,
  },
  {
    id: 132,
    pid: 128,
    name: "体育器械",
    status: 1,
  },
  {
    id: 133,
    pid: 128,
    name: "休闲活动",
    status: 1,
  },
  {
    id: 134,
    pid: 128,
    name: "运势测算",
    status: 1,
  },
  {
    id: 135,
    pid: 128,
    name: "玩具模型",
    status: 1,
  },
  {
    id: 136,
    pid: 128,
    name: "乐器",
    status: 1,
  },
  {
    id: 137,
    pid: 0,
    name: "房产地产",
    status: 1,
  },
  {
    id: 138,
    pid: 137,
    name: "安保服务",
    status: 1,
  },
  {
    id: 139,
    pid: 137,
    name: "物业管理",
    status: 1,
  },
  {
    id: 140,
    pid: 0,
    name: "农林牧渔",
    status: 1,
  },
  {
    id: 141,
    pid: 140,
    name: "兽医兽药",
    status: 1,
  },
  {
    id: 142,
    pid: 140,
    name: "农药",
    status: 1,
  },
  {
    id: 143,
    pid: 140,
    name: "化肥",
    status: 1,
  },
  {
    id: 144,
    pid: 140,
    name: "养殖",
    status: 1,
  },
  {
    id: 145,
    pid: 140,
    name: "种植",
    status: 1,
  },
  {
    id: 146,
    pid: 140,
    name: "园林景观",
    status: 1,
  },
  {
    id: 147,
    pid: 0,
    name: "建筑装修",
    status: 1,
  },
  {
    id: 148,
    pid: 147,
    name: "建筑工程",
    status: 1,
  },
  {
    id: 149,
    pid: 147,
    name: "装修服务",
    status: 1,
  },
  {
    id: 150,
    pid: 147,
    name: "建筑装修材料",
    status: 1,
  },
  {
    id: 151,
    pid: 0,
    name: "机械设备",
    status: 1,
  },
  {
    id: 152,
    pid: 151,
    name: "安全防伪",
    status: 1,
  },
  {
    id: 153,
    pid: 151,
    name: "安保器材",
    status: 1,
  },
  {
    id: 154,
    pid: 151,
    name: "防盗报警",
    status: 1,
  },
  {
    id: 155,
    pid: 151,
    name: "消防交通",
    status: 1,
  },
  {
    id: 156,
    pid: 151,
    name: "智能楼宇",
    status: 1,
  },
  {
    id: 157,
    pid: 151,
    name: "办公设备",
    status: 1,
  },
  {
    id: 158,
    pid: 151,
    name: "通用机械设备",
    status: 1,
  },
  {
    id: 159,
    pid: 151,
    name: "通用零配件",
    status: 1,
  },
  {
    id: 160,
    pid: 151,
    name: "建筑工程机械",
    status: 1,
  },
  {
    id: 161,
    pid: 151,
    name: "勘探机械",
    status: 1,
  },
  {
    id: 162,
    pid: 151,
    name: "化工机械",
    status: 1,
  },
  {
    id: 163,
    pid: 151,
    name: "木材石材加工机械",
    status: 1,
  },
  {
    id: 164,
    pid: 151,
    name: "印刷机械",
    status: 1,
  },
  {
    id: 165,
    pid: 151,
    name: "模具",
    status: 1,
  },
  {
    id: 166,
    pid: 151,
    name: "食品机械",
    status: 1,
  },
  {
    id: 167,
    pid: 151,
    name: "农林机械",
    status: 1,
  },
  {
    id: 168,
    pid: 151,
    name: "纸制造加工设备",
    status: 1,
  },
  {
    id: 169,
    pid: 151,
    name: "制鞋纺织机械",
    status: 1,
  },
  {
    id: 170,
    pid: 151,
    name: "商业设备",
    status: 1,
  },
  {
    id: 171,
    pid: 151,
    name: "制药设备",
    status: 1,
  },
  {
    id: 172,
    pid: 151,
    name: "冶炼铸造设备",
    status: 1,
  },
  {
    id: 173,
    pid: 151,
    name: "机床机械",
    status: 1,
  },
  {
    id: 174,
    pid: 151,
    name: "五金工具",
    status: 1,
  },
  {
    id: 175,
    pid: 151,
    name: "物流设备",
    status: 1,
  },
  {
    id: 176,
    pid: 151,
    name: "清洁通风设备",
    status: 1,
  },
  {
    id: 177,
    pid: 151,
    name: "焊接材料设备",
    status: 1,
  },
  {
    id: 178,
    pid: 151,
    name: "玻璃橡塑设备",
    status: 1,
  },
  {
    id: 179,
    pid: 151,
    name: "电子产品制造设备",
    status: 1,
  },
  {
    id: 180,
    pid: 151,
    name: "通讯设备",
    status: 1,
  },
  {
    id: 181,
    pid: 151,
    name: "交通工具",
    status: 1,
  },
  {
    id: 182,
    pid: 0,
    name: "电子电工",
    status: 1,
  },
  {
    id: 183,
    pid: 182,
    name: "电子元器件",
    status: 1,
  },
  {
    id: 184,
    pid: 182,
    name: "电机设备",
    status: 1,
  },
  {
    id: 185,
    pid: 182,
    name: "电线电缆",
    status: 1,
  },
  {
    id: 186,
    pid: 182,
    name: "供电设备",
    status: 1,
  },
  {
    id: 187,
    pid: 182,
    name: "照明设备",
    status: 1,
  },
  {
    id: 188,
    pid: 182,
    name: "仪器仪表",
    status: 1,
  },
  {
    id: 189,
    pid: 0,
    name: "资源材料",
    status: 1,
  },
  {
    id: 190,
    pid: 189,
    name: "涂料",
    status: 1,
  },
  {
    id: 191,
    pid: 189,
    name: "化工原料",
    status: 1,
  },
  {
    id: 192,
    pid: 189,
    name: "橡胶",
    status: 1,
  },
  {
    id: 193,
    pid: 189,
    name: "塑料",
    status: 1,
  },
  {
    id: 194,
    pid: 189,
    name: "能源",
    status: 1,
  },
  {
    id: 195,
    pid: 189,
    name: "冶金",
    status: 1,
  },
  {
    id: 196,
    pid: 189,
    name: "包装材料",
    status: 1,
  },
  {
    id: 197,
    pid: 189,
    name: "金属材料",
    status: 1,
  },
  {
    id: 198,
    pid: 0,
    name: "环境管理",
    status: 1,
  },
  {
    id: 199,
    pid: 198,
    name: "污染处理",
    status: 1,
  },
  {
    id: 200,
    pid: 198,
    name: "废旧回收",
    status: 1,
  },
  {
    id: 201,
    pid: 198,
    name: "节能",
    status: 1,
  },
  {
    id: 202,
    pid: 0,
    name: "其他",
    status: 1,
  },
  {
    id: 203,
    pid: 202,
    name: "组织",
    status: 1,
  },
  {
    id: 204,
    pid: 202,
    name: "其他",
    status: 1,
  },
  {
    id: 30205,
    pid: 30,
    name: "酒水饮料",
    status: 1,
  },
  {
    id: 30206,
    pid: 30,
    name: "建材装饰",
    status: 1,
  },
];

let areaFunc = (pid) => {
  let r = [];
  area.forEach((v) => {
    if (v.pid === pid) {
      let s = { label: v.name, value: v.id };
      let c = areaFunc(v.id);
      if (c.length > 0) {
        s.children = c;
      }
      r.push(s);
    }
  });
  return r;
};

let cateFunc = (pid) => {
  let r = [];
  cate.forEach((v) => {
    if (v.pid === pid) {
      let s = { label: v.name, value: v.id };
      let c = cateFunc(v.id);
      if (c.length > 0) {
        s.children = c;
      }
      r.push(s);
    }
  });
  return r;
};

const areaTree = areaFunc(0);
const cateTree = cateFunc(0);

export { areaTree, cateTree };
