import React, { useState, useEffect, Fragment } from "react";

import {
  Form,
  Input,
  Button,
  Popconfirm,
  message,
  Table,
  Tag,
  Select,
  Space,
  Modal,
  Tooltip,
  Drawer,
} from "antd";

import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";

import Ky from "ky";
import { Http } from "../../Http";
import queryString from "query-string";
import dayjs from "dayjs";

import styles from "./UserList.module.less";

const { Option } = Select;
const { TextArea } = Input;

function UserList({ location, navigate }) {
  const { state } = location;

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState({});
  const [search, setSearch] = useState(state?.search ? state.search : {});
  const [pagination, setPagination] = useState(
    state?.pagination
      ? state.pagination
      : {
          current: 1,
          pageSize: 10,
          total: 0,
        }
  );

  const [remarks, setRemarks] = useState({
    show: false,
    value: {},
    list: [],
    text: "",
  });

  const [visible, setVisible] = useState(false);

  const [formSearch] = Form.useForm();
  const [formUser] = Form.useForm();

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
      fixed: "left",
    },
    {
      title: "用户名",
      dataIndex: "username",
      key: "username",
      ellipsis: true,
      width: 120,
      fixed: "left",
    },
    {
      title: "手机号",
      dataIndex: "cellphone",
      key: "cellphone",
      ellipsis: true,
      width: 120,
    },
    {
      title: "注册来源",
      dataIndex: "ref",
      key: "ref",
      ellipsis: true,
      width: 200,
      render: (value) => (
        <a href={value} target="_blank">
          {value}
        </a>
      ),
    },
    {
      title: "备注",
      dataIndex: "mark",
      key: "mark",
      width: 120,
      render: (value) => {
        if (value) return <Tag color="green">已备注</Tag>;
        return <Tag color="red">未备注</Tag>;
      },
    },
    {
      title: "短信提醒",
      dataIndex: "tipsTime",
      key: "tipsTime",
      ellipsis: true,
      width: 180,
    },
    {
      title: "认证状态",
      dataIndex: ["real", "status"],
      key: ["real", "status"],
      width: 100,
      render: (value, data) => {
        if (value === 0) return <Tag color="blue">待审核</Tag>;
        if (value === 1) return <Tag color="green">已通过</Tag>;
        if (value === 2)
          return (
            <Tooltip title={data.real.reviewMsg}>
              <Tag color="red">已驳回</Tag>
            </Tooltip>
          );
        return <Tag color="cyan">未填写</Tag>;
      },
    },
    {
      title: "认证类型",
      dataIndex: ["real", "type"],
      key: ["real", "type"],
      width: 120,
      render: (value) => {
        if (value === 1) return <Tag color="blue">个人认证</Tag>;
        if (value === 2) return <Tag color="cyan">企业认证</Tag>;
      },
    },
    {
      title: "支付状态",
      dataIndex: ["real", "pay"],
      key: ["real", "pay"],
      width: 120,
      render: (value) => {
        if (value === 1) return <Tag color="green">已支付</Tag>;
        if (value === 0) return <Tag color="red">未支付</Tag>;
      },
    },
    {
      title: "支付费用",
      dataIndex: ["real", "amount"],
      key: ["real", "amount"],
      width: 120,
      render: (value) => {
        if (value) {
          return `￥${value}`;
        } else {
          return ``;
        }
      },
    },
    {
      title: "认证名称",
      dataIndex: ["real", "name"],
      key: ["real", "name"],
      ellipsis: true,
      width: 120,
    },
    {
      title: "认证时间",
      dataIndex: "realTime",
      key: "realTime",
      ellipsis: true,
      width: 180,
    },
    {
      title: "注册地址",
      dataIndex: "ip",
      key: "ip",
      ellipsis: true,
      width: 240,
      render: (value, data) => (
        <span title={value + "; " + data.ua}>{value}</span>
      ),
    },
    {
      title: "邮箱",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: 200,
    },
    {
      title: "来源类型",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      width: 120,
    },
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      ellipsis: true,
      width: 120,
      render: (value, data) => (
        <img style={{ maxWidth: 20, display: "block" }} src={value} />
      ),
    },
    {
      title: "性别",
      dataIndex: "gender",
      key: "gender",
      width: 80,
      render: (value) => {
        if (value === 1) return <Tag color="blue">女士</Tag>;
        if (value === 0) return <Tag color="orange">先生</Tag>;
        return <Tag color="red">未知</Tag>;
      },
    },
    {
      title: "注册时间",
      dataIndex: "createdTime",
      key: "createdTime",
      ellipsis: true,
      width: 180,
    },
    {
      title: "短信",
      dataIndex: "tipsTime",
      key: "tipsTime",
      ellipsis: true,
      width: 80,
      fixed: "right",
      render: (value, data) => {
        return (
          <Popconfirm
            title="是否确认发送?"
            placement="bottomLeft"
            onConfirm={() => {
              onSmstips({ id: data.id });
            }}
          >
            <Tag
              color={value == null ? "#f50c0c" : "#87d068"}
              style={{ cursor: "pointer" }}
            >
              {value == null ? "未发送" : "已发送"}
            </Tag>
          </Popconfirm>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 80,
      fixed: "right",
      render: (value, data) => {
        if (value === 1)
          return (
            <Popconfirm
              title="是否确认禁止?"
              placement="bottomLeft"
              onConfirm={() => {
                onStatus({ id: data.id, value: 0 });
              }}
            >
              <Tag color="#87d068" style={{ cursor: "pointer" }}>
                开启
              </Tag>
            </Popconfirm>
          );
        if (value === 0)
          return (
            <Popconfirm
              title="是否确认开启?"
              placement="bottomLeft"
              onConfirm={() => {
                onStatus({ id: data.id, value: 1 });
              }}
            >
              <Tag color="#f50" style={{ cursor: "pointer" }}>
                禁止
              </Tag>
            </Popconfirm>
          );
        return "未知";
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      width: 240,
      fixed: "right",
      render: (_, data) => (
        <Fragment>
          <Button
            type="link"
            size="small"
            onClick={() => {
              navigate(`save`, { state: { data, search, pagination } });
            }}
          >
            详细
          </Button>
          <Popconfirm
            title="是否确认重置密码?"
            onConfirm={() => onPassword(data.id)}
          >
            <Button type="link" size="small">
              重置密码
            </Button>
          </Popconfirm>
          <Button
            type="link"
            size="small"
            onClick={() => {
              navigate(`real`, { state: { data, search, pagination } });
            }}
          >
            认证
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              let list = [];
              if (data.mark) {
                data.mark.split("|").forEach((value) => {
                  list.push(value.split("*"));
                });
              }
              setRemarks({
                show: true,
                value: data,
                list: list.reverse(),
                text: "",
              });
            }}
          >
            备注
          </Button>
        </Fragment>
      ),
    },
  ];

  const getIp = async (ip) => {
    let para = queryString.stringify({ ip });
    try {
      let parsed = await Ky.get(`https://ip.chinaqyz.com/`, {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        let { country_name, region_name, city_name } = parsed.result;
        return [country_name, region_name, city_name]
          .filter((val) => val.length > 0)
          .join("-");
      } else {
        await message.warn(parsed.message ? parsed.message : "获取失败！");
      }
    } catch (error) {
      await message.error("接口出现错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onSearch = (values) => {
    if (values.kw) {
      values.kw = values.kw.replace(/\s+/g, "");
    }
    setSearch(values);
    onLoading({
      search: values,
      pagination: { current: 1, pageSize: 10, total: 0 },
    });
  };

  const onStatus = async (values) => {
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/status`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success(parsed.message ? parsed.message : "提交成功");
        onLoading({ search, pagination });
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onSmstips = async (values) => {
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/smstips`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success(parsed.message ? parsed.message : "提交成功");
        onLoading({ search, pagination });
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onPassword = async (id) => {
    let para = queryString.stringify({ id });
    try {
      let parsed = await Http.post(`user/reset`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success(parsed.message ? parsed.message : "提交成功");
        onLoading({ search, pagination });
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const getUserList = async (params) => {
    let para = queryString.stringify(params);
    let parsed = await Http.get(`user`, {
      searchParams: para,
    }).json();
    if (parsed.state === "ok") {
      for (let i = 0; i < parsed.result.list.length; i++) {
        let v = parsed.result.list[i];
        if (v.ip) {
          v.ip = await getIp(v.ip);
        }
      }
      setData(parsed.result);
      setPagination({
        current: parsed.result.pageNumber,
        pageSize: parsed.result.pageSize,
        total: parsed.result.totalRow,
      });
    }
  };

  const onLoading = async (params) => {
    setLoading(true);
    try {
      await getUserList({
        ...params.search,
        pageNumber: params.pagination.current,
        pageSize: params.pagination.pageSize,
      });
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onUser = async (params) => {
    let para = queryString.stringify(params);
    try {
      let parsed = await Http.post(`user/save`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("提交成功");
        onLoading({ search, pagination });
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onRemarksSubmit = async () => {
    if (remarks.text.length !== 0) {
      if (remarks.value.mark) {
        remarks.value.mark = `${remarks.value.mark}|${
          remarks.text
        }*${dayjs().format("YYYY-MM-DD HH:mm")}`;
      } else {
        remarks.value.mark = `${remarks.text}*${dayjs().format(
          "YYYY-MM-DD HH:mm"
        )}`;
      }
      let para = queryString.stringify(remarks.value);
      try {
        let parsed = await Http.post(`user/save`, {
          body: new URLSearchParams(para),
        }).json();
        if (parsed.state === "ok") {
          await message.success("提交成功");
          setRemarks({
            show: false,
            value: { ...remarks.value, mark: [] },
            text: "",
          });
          onLoading({ search, pagination });
        } else {
          await message.warn(parsed.message ? parsed.message : "提交失败");
        }
      } catch (error) {
        await message.error("接口调用错误, 错误原因:" + error.message);
      }
    } else {
      message.success("请输入备注！");
    }
  };

  useEffect(() => {
    onLoading({ search, pagination });
  }, []);

  return (
    <div className={styles.UserList}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Space>
            {selected.length > 0 ? (
              <Popconfirm
                title="是否确认开启?"
                placement="bottomLeft"
                onConfirm={() => {
                  onStatus({ id: selected, value: 1 });
                }}
              >
                <Button type="primary" icon={<CheckCircleFilled />}>
                  开启
                </Button>
              </Popconfirm>
            ) : null}
            {selected.length > 0 ? (
              <Popconfirm
                title="是否确认禁止?"
                placement="bottomLeft"
                onConfirm={() => {
                  onStatus({ id: selected, value: 0 });
                }}
              >
                <Button type="primary" danger icon={<CloseCircleFilled />}>
                  禁止
                </Button>
              </Popconfirm>
            ) : null}
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                formUser.resetFields();
                setVisible(true);
              }}
            >
              新增
            </Button>
          </Space>
        </div>
        <div className={styles.headerRight}>
          <Form
            form={formSearch}
            layout="inline"
            initialValues={search}
            onFinish={onSearch}
          >
            <Form.Item name="kw" style={{ width: 240 }}>
              <Input placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item name="status">
              <Select
                placeholder="请选择状态"
                style={{ width: 120 }}
                allowClear
              >
                <Option value={1}>正常</Option>
                <Option value={0}>禁止</Option>
              </Select>
            </Form.Item>
            <Form.Item name="sort">
              <Select
                placeholder="请选择排序"
                style={{ width: 150 }}
                allowClear
              >
                <Option value="createdTime desc">按创建时间排序</Option>
                <Option value="tipsTime desc">按短信提醒排序</Option>
                <Option value="realTime desc">按认证时间排序</Option>
                <Option value="payTime desc">按支付时间排序</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="reset"
                onClick={() => {
                  formSearch.resetFields();
                }}
              >
                重置
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.content}>
        <Table
          loading={loading}
          rowKey="id"
          scroll={{ x: 1660 }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selected,
            onChange: (srk) => {
              setSelected(srk);
            },
          }}
          onChange={(p, f, s) => {
            let sort = search.sort;
            if (s.order) {
              sort = `${s.field} ${s.order.replace("end", "")}`;
            }
            onLoading({ search: { ...search, sort }, pagination: p });
          }}
          dataSource={data.list}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showTotal: () => {
              return `总共 ${pagination.total} 条数据`;
            },
          }}
        />
      </div>
      <Drawer
        title="备注"
        placement="right"
        width={500}
        onClose={() => setRemarks({ ...remarks, show: false })}
        visible={remarks.show}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setRemarks({ ...remarks, show: false })}
              style={{ marginRight: 8 }}
            >
              返回
            </Button>
            <Button onClick={onRemarksSubmit} type="primary">
              提交
            </Button>
          </div>
        }
      >
        <div className={styles.remarks}>
          <div className={styles.tit}>历史记录：</div>
          {remarks.list ? (
            <div className={styles.his}>
              {remarks.list.map((value) => (
                <p>
                  <span>{value[0]}</span>
                  <span>{value[1]}</span>
                </p>
              ))}
            </div>
          ) : null}
          <div className={styles.now}>
            <TextArea
              rows={4}
              value={remarks.text}
              onChange={(e) => {
                setRemarks({
                  ...remarks,
                  text: e.target.value,
                });
              }}
              placeholder="请输入备注"
            />
          </div>
        </div>
      </Drawer>
      <Modal
        title="新增用户"
        visible={visible}
        onOk={() => {
          setVisible(false);
          formUser.submit();
        }}
        onCancel={() => setVisible(false)}
        centered
      >
        <Form
          form={formUser}
          initialValues={{ role: "client" }}
          onFinish={onUser}
        >
          <Form.Item name="username">
            <Input placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item name="role" style={{ marginBottom: 0 }}>
            <Select>
              <Option value="client">用户</Option>
              <Option value="manage">管理员</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default UserList;
