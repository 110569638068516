import React from "react";
import { createPortal } from "react-dom";

function Portal({ visible, children, ...rest }) {
  let portal = null;
  if (visible) {
    portal = createPortal(children, document.body);
  }
  return portal;
}

export default Portal;
