import React, { useState, useEffect, Fragment } from "react";

import { Router, Link, Match, Redirect, useNavigate } from "@reach/router";

import { Http } from "./Http";

import { Spin, message } from "antd";

import Container from "./pages/Container";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Report from "./pages/Report";

import styles from "./App.module.less";

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <Signup path="signup" />
        <Signin path="signin" />
        <Report path="report" />
        <PrivateRoute as={Container} path="/*" />
      </Router>
    </div>
  );
}

const PrivateRoute = ({ navigate, as: Component, ...props }) => {
  const [auth, setAuth] = useState(false);

  const onVerify = async () => {
    try {
      let parsed = await Http.get("verify", {}).json();
      if (parsed.state === "ok") {
        setAuth(true);
      } else {
        navigate(`/signin`);
      }
    } catch (error) {
      await message.error("权限认证错误, 错误原因: " + error.message);
      navigate(`/signin`);
    }
  };

  useEffect(() => {
    onVerify();
  }, []);

  return (
    <Fragment>
      {auth ? (
        <Component {...props} />
      ) : (
        <Spin
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size="large"
        />
      )}
    </Fragment>
  );
};

export default App;
