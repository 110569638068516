import React, { useState, useEffect } from "react";

import { Space, Button, Select, Modal, Spin, message } from "antd";

import { CheckCircleFilled } from "@ant-design/icons";

import ky from "ky";
import queryString from "query-string";

import nlpImg from "../../../images/nlp_img.jpg";
import nlpIntro from "../../../images/nlp_intro.jpg";
import wxPay from "../../../images/wx_pay.png";

import styles from "./ShopNlp.module.less";

function ShopNlp({ data, company }) {
  const [visible, setVisible] = useState(false);
  const [edition, setEdition] = useState(data.edition[0]);
  const [initialPrice, setInitialPrice] = useState(data.edition[0].price);
  const [finalPrice, setFinalPrice] = useState(null);
  const [num, setNum] = useState(1);
  const [sid, setSid] = useState(0);

  const { Option } = Select;

  const onSubmit = async () => {
    let amount = finalPrice ? finalPrice : initialPrice;
    if (amount === 0) {
      message.error("此版本已赠送，请勿重复购买！");
    } else {
      let para = queryString.stringify({
        name: "智能写作购买",
        amount: amount,
        attach: `sso,order,${company.userId},${data.id},${edition.id},${
          num * 12
        }`,
      });
      let parsed = await ky
        .post("https://pay.chinaapus.com/order/submit", {
          body: new URLSearchParams(para),
        })
        .json();
      setSid(parsed.data);
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "autocloseload") {
        message.success("购买成功，如有问题请联系客服！");
        setVisible(false);
      }
    });
  }, []);

  return (
    <div className={styles.ShopNlp}>
      <div className={styles.info}>
        <div className={styles.col}>
          <div className={styles.pic}>
            <img src={nlpImg} alt="智能写作" />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.title}>
            智能写作
            {data.edition.map((value) =>
              value.id === edition.id ? (
                <span key={value.name}> / {value.name}</span>
              ) : null
            )}
          </div>
          <div className={styles.desc}>
            AI伪原创文章检测工具,内容在线伪原创工具,采集批量伪原创采用自然语言分析器SyntaxNet(语法分析程序)+自然语言处理(NLP)+SEO行业数据词库。
          </div>
          <div className={styles.price}>
            总价格：<span>{finalPrice ? finalPrice : initialPrice}</span>/元
          </div>
          <div className={styles.edition}>
            <i>产品版本：</i>
            <Space>
              {data.edition.map((value) => (
                <Button
                  type={value.id === edition.id ? "primary" : "dashed"}
                  key={value.id}
                  size="small"
                  onClick={() => {
                    setEdition(value);
                    setNum(1);
                    setInitialPrice(value.price);
                    setFinalPrice(null);
                  }}
                >
                  {value.name}
                </Button>
              ))}
            </Space>
          </div>
          <div className={styles.term}>
            <i>产品期限：</i>
            <Select
              value={num}
              style={{ width: 120 }}
              size="small"
              onChange={(value) => {
                setNum(value);
                setFinalPrice(initialPrice * value);
              }}
            >
              <Option value={1}>1年</Option>
              <Option value={2}>2年</Option>
              <Option value={3}>3年</Option>
            </Select>
          </div>
          <div className={styles.buy}>
            <Button
              type="primary"
              danger
              icon={<CheckCircleFilled />}
              onClick={onSubmit}
            >
              立即购买
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          <span>产品介绍</span>
        </div>
        <div className={styles.content}>
          <p>
            智能写作是一款互联网内容创作服务产品，旨在为SEOER、内容运营、小说写作、论文写作打造更新快、覆盖全、质量高
            的内容生态圈，基于你提供内容帮助你持续高效的进行写作、优雅表达。
          </p>
          <p>
            1、采用语义分析，深度学习等计算技术，自动学习，及时形成知识积累。独立审核算法，自动把关，保证输出文章符合要求；
          </p>
          <p>
            2、机器自动生成，速度快，可迅速输出大量文章，基础AI情感分析 /
            中文分词 / 语言模型，极简的操作使你可以专注、更快的深度写作；
          </p>
          <p>
            3、深度AI文章生成，凭借其强大的NLP、深度学习等技术轻松过原创度检测,目前已通过国内百度、360、搜狗等搜索引擎，今日头条、大鱼号等自媒体平台检测。
          </p>
          <img src={nlpIntro} alt="产品介绍" />
        </div>
      </div>
      <Modal
        title="微信扫码支付"
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        footer={null}
        centered
      >
        <div className={styles.order}>
          <div className={styles.ifo}>
            <p>
              购买产品：
              <span>
                智能写作（
                {data.edition.map((value) =>
                  value.id === edition.id ? value.name : null
                )}
                ）
              </span>
            </p>
            <p>
              购买期限：<span>{num}年</span>
            </p>
            <p>
              支付金额：<span>{finalPrice ? finalPrice : initialPrice}元</span>
            </p>
            <p>申请发票，付款成功后联系客服(13065554315)处理</p>
          </div>
          <div className={styles.ewm}>
            <p>
              {sid !== 0 ? (
                <iframe
                  src={`https://pay.chinaapus.com/?serial_id=${sid}&size=120`}
                  width="120px"
                  height="120px"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              ) : (
                <Spin tip="付款码生成中..." />
              )}
            </p>
            <p>
              <img src={wxPay} />
              <span>微信支付</span>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ShopNlp;
