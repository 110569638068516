import React from "react";

import { Router } from "@reach/router";

import UserList from "./UserList";
import UserSave from "./UserSave";
import UserReal from "./UserReal";

import styles from "./User.module.less";

function User() {
  return (
    <div className={styles.User}>
      <Router>
        <UserList path="/" />
        <UserSave path="save" />
        <UserReal path="real" />
      </Router>
    </div>
  );
}

export default User;
