import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Cascader,
  Space,
  Spin,
  Select,
  Radio,
  message,
  Row,
  Col,
} from "antd";

import { FormOutlined } from "@ant-design/icons";

import { Http } from "../../Http";
import queryString from "query-string";

import { areaTree, cateTree } from "../../Tree.js";

import Thumb from "../../components/Thumb";

import styles from "./UserReal.module.less";

const { Option } = Select;

function UserReal({ navigate, location }) {
  const { state } = location;
  const { data, search, pagination } = state;

  const [submit, setSubmit] = useState(false);
  const [type, setType] = useState(data?.real?.type ? data.real.type : 1);
  const [local, setLocal] = useState(data?.real?.locate ? data.real.locate : 1);
  const [status, setStatus] = useState(2);
  const [reason, setReason] = useState(null);

  const [form] = Form.useForm();

  const onBack = () => {
    navigate("../", { state: { search, pagination } });
  };

  const onTypeChange = (value) => {
    setType(value);
  };

  const onlocalChange = (value) => {
    data.real ? message.warn("用户自行修改！") : setLocal(value);
  };

  const onStatusChange = (e) => {
    if (e.target.value === 1) {
      if (data.real.pay === 0) {
        message.error("未支付，无法审核通过！");
      } else {
        setStatus(e.target.value);
      }
    } else {
      setStatus(e.target.value);
    }
  };

  const onSmstips = async (values) => {
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/smstips`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success(
          parsed.message ? parsed.message : "发送短信提醒成功"
        );
      } else {
        await message.warn(
          parsed.message ? parsed.message : "发送短信提醒失败"
        );
      }
    } catch (error) {
      await message.error("短信接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onFinish = async (values) => {
    setSubmit(true);
    if (data.real) {
      if (type === 1) {
        values.front = data.real.front;
        values.back = data.real.back;
      } else {
        values.image = data.real.image;
      }
      if (!values.attr) {
        values.attr = data.real.attr;
      }
      values.type = type;
      values.locate = local;
      values.status = status;
      values.id = data.real.id;
      values.userId = data.real.userId;
      if (status === 2) {
        values.reviewMsg = reason;
      }
    } else {
      if (type === 1) {
        values.front = "real/sfz_01.jpg";
        values.back = "real/sfz_02.jpg";
      } else {
        values.image = "real/yyzz.jpg";
      }
      if (!values.attr) {
        values.attr = 1;
      }
      values.type = type;
      values.locate = local;
      values.userId = data.id;
    }
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`real/save`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("提交成功");
        await onSmstips({ id: values.userId });
        onBack();
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (data?.real) {
      data.real.cate = data.real.parentId
        ? [data.real.parentId, data.real.childId]
        : [];
      data.real.area = data.real.provinceId
        ? [data.real.provinceId, data.real.cityId, data.real.regionId]
        : [];
      data.real.status === 0 ? setStatus(2) : setStatus(data.real.status);
      data.real.status === 2 ? setReason(data.real.reviewMsg) : setReason(null);
      form.setFieldsValue(data.real);
    }
  }, [data?.real]);

  return (
    <div className={styles.UserReal}>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormOutlined style={{ fontSize: 16, color: "#1890ff" }} />
          <i>{type === 1 ? "个人认证" : "企业认证"}</i>
        </div>
      </div>
      <div className={styles.content}>
        {data?.real ? null : (
          <Row style={{ marginBottom: 24 }}>
            <Col
              span={2}
              style={{ textAlign: "right", fontSize: 14, lineHeight: "32px" }}
            >
              认证类型：
            </Col>
            <Col span={8}>
              <Radio.Group
                value={type}
                onChange={(e) => onTypeChange(e.target.value)}
              >
                <Radio.Button value={1}>个人认证</Radio.Button>
                <Radio.Button value={2}>企业认证</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        )}
        {type === 1 ? (
          <div className={styles.personal}>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 8 }}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item label="所在地">
                <Radio.Group
                  value={local}
                  onChange={(e) => onlocalChange(e.target.value)}
                >
                  <Radio.Button value={1}>大陆</Radio.Button>
                  <Radio.Button value={2}>非大陆</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="name"
                label="真实姓名"
                rules={[{ required: true, message: "请填写真实姓名！" }]}
              >
                <Input placeholder="请填写真实姓名" />
              </Form.Item>
              {local === 1 ? (
                <Form.Item
                  name="code"
                  label="证件号"
                  rules={[{ required: true, message: "请填写身份证号！" }]}
                >
                  <Input placeholder="请填写身份证号" />
                </Form.Item>
              ) : (
                <Form.Item
                  label="证件类型及号码"
                  style={{ marginBottom: 0 }}
                  required
                >
                  <Form.Item
                    name="attr"
                    rules={[{ required: true, message: "请选择证件类型！" }]}
                    style={{
                      display: "inline-block",
                      width: 160,
                    }}
                  >
                    <Select placeholder="请选择证件类型">
                      <Option value={2}>回乡证/台胞证</Option>
                      <Option value={3}>护照</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="code"
                    rules={[{ required: true, message: "请填写证件号码！" }]}
                    style={{
                      display: "inline-block",
                      width: "calc(100% - 176px)",
                      marginLeft: 16,
                    }}
                  >
                    <Input placeholder="请填写证件号码" />
                  </Form.Item>
                </Form.Item>
              )}
              <Form.Item
                name="cate"
                label="行业"
                rules={[{ required: true, message: "请选择行业！" }]}
              >
                <Cascader options={cateTree} placeholder="请选择行业" />
              </Form.Item>
              <Form.Item
                name="area"
                label="区域"
                rules={[{ required: true, message: "请选择区域！" }]}
              >
                <Cascader options={areaTree} placeholder="请选择区域" />
              </Form.Item>
              <Form.Item
                name="address"
                label="通讯地址"
                rules={[{ required: true, message: "请输入详细地址！" }]}
              >
                <Input placeholder="请输入详细地址" />
              </Form.Item>
              {data.real ? (
                <Form.Item label="证件照" style={{ marginBottom: 0 }} required>
                  <Form.Item
                    name="tempFront"
                    style={{
                      display: "inline-block",
                      width: 124,
                    }}
                  >
                    <Thumb onClick={() => message.warn("用户自行修改！")} />
                  </Form.Item>
                  <Form.Item
                    name="tempBack"
                    style={{
                      display: "inline-block",
                      width: 124,
                      marginLeft: 10,
                    }}
                  >
                    <Thumb onClick={() => message.warn("用户自行修改！")} />
                  </Form.Item>
                </Form.Item>
              ) : null}
              {data.real ? (
                <Form.Item label="审核" required>
                  <Radio.Group value={status} onChange={onStatusChange}>
                    <Radio value={1}>通过</Radio>
                    <Radio value={2}>驳回</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : null}
              {data.real && status === 2 ? (
                <Form.Item label="驳回原因" required>
                  <Input.TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="请输入驳回原因"
                    rows={3}
                  />
                </Form.Item>
              ) : null}
              <Form.Item wrapperCol={{ offset: 2 }}>
                <Space>
                  <Button
                    type="primary"
                    disabled={submit}
                    loading={submit}
                    htmlType="submit"
                  >
                    提交
                  </Button>
                  <Button htmlType="button" onClick={onBack}>
                    返回
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div className={styles.enterprise}>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 8 }}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item label="所在地">
                <Radio.Group
                  value={local}
                  onChange={(e) => onlocalChange(e.target.value)}
                >
                  <Radio.Button value={1}>中国大陆</Radio.Button>
                  <Radio.Button value={2}>港澳台</Radio.Button>
                  <Radio.Button value={3}>海外</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="name"
                label="公司名称"
                rules={[{ required: true, message: "请填写公司名称！" }]}
              >
                <Input placeholder="请填写公司名称" />
              </Form.Item>
              {local === 1 ? (
                <Form.Item
                  name="code"
                  label="社会信用代码"
                  rules={[{ required: true, message: "请填写社会信用代码！" }]}
                >
                  <Input placeholder="请填写社会信用代码" />
                </Form.Item>
              ) : null}
              <Form.Item
                name="cate"
                label="行业"
                rules={[{ required: true, message: "请选择行业！" }]}
              >
                <Cascader options={cateTree} placeholder="请选择行业" />
              </Form.Item>
              <Form.Item
                name="area"
                label="区域"
                rules={[{ required: true, message: "请选择区域！" }]}
              >
                <Cascader options={areaTree} placeholder="请选择区域" />
              </Form.Item>
              <Form.Item
                name="address"
                label="通讯地址"
                rules={[{ required: true, message: "请输入通讯地址！" }]}
              >
                <Input placeholder="请输入通讯地址" />
              </Form.Item>
              <Form.Item
                name="person"
                label="联系人姓名"
                rules={[{ required: true, message: "请填写联系人姓名！" }]}
              >
                <Input placeholder="请填写联系人姓名" />
              </Form.Item>
              <Form.Item
                name="cellphone"
                label="联系人手机"
                rules={[{ required: true, message: "请填写联系人手机！" }]}
              >
                <Input placeholder="请填写联系人手机" />
              </Form.Item>
              {data.real ? (
                <Form.Item
                  name="tempImage"
                  label="营业执照"
                  rules={[{ required: true, message: "请上传营业执照！" }]}
                >
                  <Thumb onClick={() => message.warn("用户自行修改！")} />
                </Form.Item>
              ) : null}
              {data.real ? (
                <Form.Item label="审核" required>
                  <Radio.Group value={status} onChange={onStatusChange}>
                    <Radio value={1}>通过</Radio>
                    <Radio value={2}>驳回</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : null}
              {data.real && status === 2 ? (
                <Form.Item label="驳回原因" required>
                  <Input.TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="请输入驳回原因"
                    rows={3}
                  />
                </Form.Item>
              ) : null}
              <Form.Item wrapperCol={{ offset: 2 }}>
                <Space>
                  <Button
                    type="primary"
                    disabled={submit}
                    loading={submit}
                    htmlType="submit"
                  >
                    提交
                  </Button>
                  <Button htmlType="button" onClick={onBack}>
                    返回
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserReal;
