import React, { useState, useEffect } from "react";

import { Form, Input, Button, Popconfirm, message, Table, Tag } from "antd";

import { DeleteFilled } from "@ant-design/icons";

import { Http } from "../../Http";
import queryString from "query-string";

import styles from "./Order.module.less";

function Order({ location }) {
  const { state } = location;

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState({});
  const [search, setSearch] = useState(state?.search ? state.search : {});
  const [pagination, setPagination] = useState(
    state?.pagination
      ? state.pagination
      : {
          current: 1,
          pageSize: 10,
          total: 0,
        }
  );

  const [form] = Form.useForm();

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 120,
    },
    {
      title: "订单号",
      dataIndex: "serialNo",
      key: "serialNo",
      ellipsis: true,
    },
    {
      title: "产品",
      dataIndex: ["shop", "name"],
      key: ["shop", "name"],
      ellipsis: true,
      width: 100,
    },
    {
      title: "套餐",
      dataIndex: ["edition", "name"],
      key: ["edition", "name"],
      ellipsis: true,
      width: 100,
    },
    {
      title: "价格",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      width: 100,
      render: (value) => {
        return `￥${value}`;
      },
    },
    {
      title: "开始日期",
      dataIndex: "startTime",
      key: "startTime",
      ellipsis: true,
      width: 180,
      sorter: true,
    },
    {
      title: "结束日期",
      dataIndex: "endTime",
      key: "endTime",
      ellipsis: true,
      width: 180,
      sorter: true,
      render: (value) => {
        return value ? value : "无限期";
      },
    },
    {
      title: "交易状态",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 100,
      render: (value) => {
        if (value === 0) return <Tag color="green">已支付</Tag>;
        return "未知";
      },
      sorter: true,
    },
    {
      title: "创建日期",
      dataIndex: "createdTime",
      key: "createdTime",
      ellipsis: true,
      width: 180,
      sorter: true,
    },
  ];

  const onSearch = (values) => {
    if (values.kw) {
      values.kw = values.kw.replace(/\s+/g, "");
    }
    setSearch(values);
    onLoading({
      search: values,
      pagination: { current: 1, pageSize: 10, total: 0 },
    });
  };

  const onDelete = async (id) => {
    let para = queryString.stringify({ id });
    try {
      let parsed = await Http.post(`order/delete`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success(parsed.message ? parsed.message : "提交成功");
        onLoading({ search, pagination });
        setSelected([]);
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const getOrderList = async (params) => {
    let para = queryString.stringify(params);
    let parsed = await Http.get(`order`, {
      searchParams: para,
    }).json();
    if (parsed.state === "ok") {
      setData(parsed.result);
      setPagination({
        current: parsed.result.pageNumber,
        pageSize: parsed.result.pageSize,
        total: parsed.result.totalRow,
      });
    }
  };

  const onLoading = async (params) => {
    setLoading(true);
    try {
      await getOrderList({
        ...params.search,
        pageNumber: params.pagination.current,
        pageSize: params.pagination.pageSize,
      });
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoading({ search, pagination });
  }, []);

  return (
    <div className={styles.Order}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {selected.length > 0 ? (
            <Popconfirm
              title="是否确认删除?"
              placement="bottomLeft"
              onConfirm={() => {
                onDelete(selected);
              }}
            >
              <Button
                type="primary"
                danger
                icon={<DeleteFilled />}
                style={{ marginRight: 16 }}
              >
                删除
              </Button>
            </Popconfirm>
          ) : null}
        </div>
        <div className={styles.headerRight}>
          <Form
            form={form}
            layout="inline"
            initialValues={search}
            onFinish={onSearch}
          >
            <Form.Item name="kw" style={{ width: 240 }}>
              <Input placeholder="请输入订单号" />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="reset"
                onClick={() => {
                  form.resetFields();
                }}
              >
                重置
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.content}>
        <Table
          loading={loading}
          rowKey="id"
          rowSelection={{
            type: "checkbox",
            onChange: (srk) => {
              setSelected(srk);
            },
          }}
          onChange={(p, f, s) => {
            let sort = null;
            if (s.order) {
              sort = `${s.field} ${s.order.replace("end", "")}`;
            }
            onLoading({ search: { ...search, sort }, pagination: p });
          }}
          dataSource={data.list}
          columns={columns}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

export default Order;
