import React, { useState, useEffect } from "react";

import { useNavigate, useLocation, Link } from "@reach/router";

import { Button, Input, Form, message, Checkbox, Space, Alert } from "antd";

import { Server, Http } from "../Http";
import queryString from "query-string";

import {
  UserOutlined,
  UnlockOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

import styles from "./Signin.module.less";

function Signin() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    let search = queryString.parse(location.search);
    let para = queryString.stringify({ ...values, ...search });
    try {
      let parsed = await Http.post("signin", {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("登陆成功, 即将跳转");
        if (parsed.role === "manager") {
          navigate(`/`);
        } else {
          navigate(`/`);
        }
      } else {
        if (parsed.captchaMsg) {
          await message.warn(parsed.captchaMsg);
          form.setFieldsValue({ captcha: "" });
        } else {
          await message.warn(parsed.message ? parsed.message : "登陆出现错误");
        }
        onCaptcha();
      }
    } catch (error) {
      await message.error("登陆出现错误");
      onCaptcha();
    } finally {
      setLoading(false);
    }
  };

  const onCaptcha = () => {
    let code = Server + "/captcha?_=" + new Date().getTime();
    setCaptcha(code);
  };

  useEffect(() => {
    onCaptcha();
  }, []);

  return (
    <div className={styles.Signin}>
      <div className={styles.bigBox}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>用户登录</h2>
            <h3>USER LOGIN</h3>
          </div>
          <Form
            onFinish={onFinish}
            form={form}
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入用户名！" }]}
              style={{ flex: "1" }}
            >
              <Input
                style={{ height: "46px" }}
                prefix={
                  <UserOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请输入用户名"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码！" }]}
              style={{ flex: "1" }}
            >
              <Input.Password
                style={{ height: "46px" }}
                prefix={
                  <UnlockOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="captcha"
                rules={[{ required: true, message: "请输入验证码！" }]}
                style={{ display: "inline-block", width: "calc(100% - 140px)" }}
              >
                <Input
                  style={{ height: "46px" }}
                  prefix={
                    <SafetyCertificateOutlined
                      style={{ fontSize: 16, color: "#999" }}
                    />
                  }
                  placeholder="请输入验证码"
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", marginLeft: 15 }}>
                <img
                  src={captcha}
                  alt=""
                  title="获取验证码"
                  onClick={onCaptcha}
                  style={{ height: 46, display: "block" }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ display: "inline-block", width: "calc(100% - 56px)" }}
              >
                <Checkbox>记住密码</Checkbox>
              </Form.Item>
              <Form.Item style={{ display: "inline-block" }}>
                <Link to={"/signup" + location.search}>立即注册</Link>
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "46px" }}
                disabled={loading}
                loading={loading}
                block
              >
                登录
              </Button>
            </Form.Item>
          </Form>
          <Alert
            style={{ marginTop: 24 }}
            message="国家法律规定，在网络平台发布信息需进行身份备案。请注册后完成实名认证。"
            type="warning"
            showIcon
          />
        </div>
      </div>
    </div>
  );
}

export default Signin;
