import React, { useState, useEffect } from "react";

import { Router, Link } from "@reach/router";

import {
  Layout,
  Menu,
  Avatar,
  Badge,
  Dropdown,
  Popover,
  Popconfirm,
  Empty,
  message,
  PageHeader,
  Breadcrumb,
} from "antd";

import {
  AppstoreOutlined,
  ShopOutlined,
  BlockOutlined,
  FundProjectionScreenOutlined,
  UserOutlined,
  BellOutlined,
  ExportOutlined,
  UnlockOutlined,
  HomeOutlined,
  CaretDownOutlined,
  FieldNumberOutlined,
} from "@ant-design/icons";

import { Http } from "../../Http";

import Home from "../website/Home";
import Shop from "../website/Shop";
import Order from "../website/Order";
import Real from "../website/Real";
import User from "../website/User";
import Password from "../website/Password";

import LogoLong from "../../images/logo_long.png";
import LogoShort from "../../images/logo_short.png";
import Guide from "../../images/guide.png";

import styles from "./Website.module.less";

function Website({ location, navigate, user }) {
  const [page, setPage] = useState({ open: [], selected: [] });
  const [collapsed, setCollapsed] = useState(false);
  const [company, setCompany] = useState({});
  const [guide, setGuide] = useState(false);
  const [visible, setVisible] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [position, setPosition] = useState({
    name: null,
    url: null,
    sub: {},
  });

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const menu = [
    {
      name: "服务中心",
      url: "/",
      icon: AppstoreOutlined,
      sub: [],
    },
    {
      name: "商城服务",
      url: "x2",
      icon: ShopOutlined,
      sub: [
        {
          name: "商品列表",
          url: "shop",
        },
        {
          name: "我的订单",
          url: "order",
        },
      ],
    },
    {
      name: "认证管理",
      url: "x3",
      icon: BlockOutlined,
      sub: [
        {
          name: "实名认证",
          url: "real",
        },
      ],
    },
    {
      name: "信息中心",
      url: "x4",
      icon: UserOutlined,
      sub: [
        {
          name: "个人资料",
          url: "user",
        },
        {
          name: "修改密码",
          url: "password",
        },
      ],
    },
    {
      name: "统计报表",
      url: "report",
      icon: FundProjectionScreenOutlined,
      sub: [],
    },
  ];

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onLogout = async () => {
    try {
      let parsed = await Http.get(`logout`, {}).json();
      if (parsed.state === "ok") {
        navigate("/signin");
      } else {
        await message.warn("退出失败");
      }
    } catch (error) {
      await message.error("接口调用错误");
    } finally {
    }
  };

  const tipsPopover = (
    <div className={styles.tipsDrop}>
      <div className={styles.content}>
        {infoData.length > 0 ? (
          infoData.map((value) => (
            <div key={value.id} className={styles.row}>
              <h2>
                <a>{value.title}</a>
              </h2>
              <p>{value.time}</p>
            </div>
          ))
        ) : (
          <Empty style={{ paddingBottom: 20 }} />
        )}
      </div>
      <div className={styles.footer}>
        <Popconfirm title="是否确认清空？" okText="确定" cancelText="取消">
          <Link to="#">清空信息</Link>
        </Popconfirm>
        <a onClick={() => {}}>查看更多</a>
      </div>
    </div>
  );

  const userDrop = (
    <Menu className={styles.userDrop}>
      <Menu.Item>
        <a>
          <FieldNumberOutlined />
          <span>{company.userId}</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            navigate("/user");
            setPage({ open: ["x4"], selected: ["user"] });
          }}
        >
          <UserOutlined />
          <span>个人资料</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            navigate("/password");
            setPage({ open: ["x4"], selected: ["password"] });
          }}
        >
          <UnlockOutlined />
          <span>修改密码</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={onLogout}>
          <ExportOutlined />
          <span>退出登录</span>
        </a>
      </Menu.Item>
    </Menu>
  );

  const onMuenLocal = () => {
    menu.forEach((value) => {
      if (location.pathname.includes(value.url)) {
        setPage({ open: [], selected: [value.url] });
        setPosition({
          ...position,
          name: value.name,
          url: `/${value.url}`,
          sub: {},
        });
      } else {
        value.sub.forEach((val) => {
          if (location.pathname.includes(val.url)) {
            setPage({ open: [value.url], selected: [val.url] });
            setPosition({
              name: value.name,
              url: `/${value.url}`,
              sub: { name: val.name, url: val.url },
            });
          }
        });
      }
    });
  };

  const onGetReal = async () => {
    try {
      let parsed = await Http.get(`real/self`, {}).json();
      if (parsed.state === "ok") {
        setCompany(parsed.result);
        if (parsed.result.status !== 2) {
          if (parsed.result.pay === 1) {
            setGuide(false);
          } else {
            navigate(`/real`);
          }
        } else {
          setGuide(true);
        }
      } else {
        setGuide(true);
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  useEffect(() => {
    onMuenLocal();
    if (
      location.pathname.includes("real") ||
      location.pathname.includes("report")
    ) {
      setGuide(false);
    } else {
      onGetReal();
    }
  }, [location.pathname]);

  return (
    <div className={styles.Website}>
      <Layout style={{ height: "100vh" }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className={styles.logo}>
            <img src={collapsed ? LogoShort : LogoLong} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            openKeys={page.open}
            selectedKeys={page.selected}
            onOpenChange={(e) => {
              setPage({ ...page, open: e });
            }}
          >
            {menu.map((value) =>
              value.sub.length > 0 ? (
                <SubMenu
                  key={value.url}
                  icon={<value.icon />}
                  title={value.name}
                >
                  {value.sub.map((val) => (
                    <Menu.Item
                      key={val.url}
                      onClick={() => {
                        navigate(val.url === "/" ? `/` : `/${val.url}`);
                        setPage({ ...page, selected: [val.url] });
                      }}
                    >
                      {val.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={value.url}
                  icon={<value.icon />}
                  onClick={() => {
                    if (value.url === "report") {
                      window.open(`${location.origin}/report`, "_blank");
                    } else {
                      navigate(value.url === "/" ? `/` : `/${value.url}`);
                      setPage({ ...page, selected: [value.url] });
                    }
                  }}
                >
                  {value.name}
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{ height: "100vh" }}>
          <div className={styles.header}>
            <div className={styles.headerAttach}>
              <div className={styles.attachLeft}>
                <PageHeader title={position.name} />
              </div>
              <div className={styles.attachRight}>
                <Popover
                  content={tipsPopover}
                  trigger="click"
                  placement="bottomRight"
                  visible={visible}
                  onVisibleChange={() => setVisible(!visible)}
                >
                  <div className={styles.col}>
                    <Badge count={0}>
                      <BellOutlined style={{ fontSize: "20px" }} />
                    </Badge>
                  </div>
                </Popover>
                <Dropdown overlay={userDrop} placement="bottomRight" arrow>
                  <div className={styles.welcome}>
                    <Avatar
                      size={32}
                      style={{ backgroundColor: "#fde3cf" }}
                      src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    />
                    <span>{user?.username}</span>
                    <CaretDownOutlined />
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className={styles.headerPosition}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>控制台</Breadcrumb.Item>
                <Breadcrumb.Item href={position.url}>
                  {position.name}
                </Breadcrumb.Item>
                {position.sub ? (
                  <Breadcrumb.Item href={position.sub.url}>
                    {position.sub.name}
                  </Breadcrumb.Item>
                ) : null}
              </Breadcrumb>
            </div>
          </div>
          <div className={styles.content}>
            <Router>
              <Home path="/" user={user} company={company} />
              <Shop path="shop/*" company={company} />
              <Order path="order/*" />
              <Real path="real/*" user={user} />
              <User path="user/*" />
              <Password path="password/*" />
            </Router>
          </div>
        </Layout>
      </Layout>
      {guide ? (
        <div className={styles.guide} onClick={() => navigate("/real")}>
          <div className={styles.image}>
            <img src={Guide} alt="" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Website;
