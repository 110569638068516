import React, { useState, useEffect } from "react";

import { Avatar, Tag, Button, Spin, Alert, message } from "antd";

import {
  CloseCircleFilled,
  IdcardOutlined,
  VerifiedOutlined,
  CheckCircleFilled,
  CommentOutlined,
} from "@ant-design/icons";

import { Http } from "../../Http";
import queryString from "query-string";

import ewm from "../../images/ewm.png";

import styles from "./RealSelect.module.less";

function RealSelect({ navigate, location, user }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getReal = async () => {
    let parsed = await Http.get(`real/self`, {}).json();
    if (parsed.state === "ok") {
      if (parsed.result.status === 0) {
        if (parsed.result.pay === 0) {
          navigate("pay");
        }
      } else {
        let code = [];
        if (parsed.result.code) {
          for (let i = 0; i < parsed.result.code.length; i++) {
            if (i > 5) {
              code.push("*");
            } else {
              code.push(parsed.result.code[i]);
            }
          }
        }
        parsed.result.newCode = code;
        setData(parsed.result);
      }
    }
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getReal();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoading();
  }, []);

  return (
    <div className={styles.RealSelect}>
      {loading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.avatar}>
            <Avatar
              size={80}
              style={{ backgroundColor: "#fde3cf" }}
              src={
                user?.avatar
                  ? user?.avatar
                  : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              }
            />
            <h2>{user?.username}</h2>
            <h3>
              {data.name ? null : (
                <Tag icon={<CloseCircleFilled />} color="red">
                  未实名认证
                </Tag>
              )}
              {data.status === 0 ? (
                <Tag icon={<CheckCircleFilled />} color="blue">
                  审核中
                </Tag>
              ) : null}
              {data.status === 1 ? (
                <Tag icon={<CheckCircleFilled />} color="green">
                  已实名认证
                </Tag>
              ) : null}
            </h3>
          </div>
          {data.status === 2 ? (
            <Alert
              style={{ width: 628, marginTop: 15 }}
              message={"驳回原因：" + data.reviewMsg}
              type="warning"
              showIcon
            />
          ) : null}
          {!data.name ? (
            <Alert
              style={{ width: 628, marginTop: 15 }}
              message={
                <div>
                  根据《中国人民共和国网络安全法》关于实名制的要求，您需要进行账户实名认证，请尽快完成账户实名认证。
                  <a
                    target="_blank"
                    href="http://www.gjbmj.gov.cn/n1/2017/0119/c409088-29036593.html"
                  >
                    【详细】
                  </a>
                </div>
              }
              type="warning"
              showIcon
            />
          ) : null}
          {!data.name || data.status === 2 ? (
            <div className={styles.select}>
              <div className={styles.col}>
                <div className={styles.icon}>
                  <IdcardOutlined />
                </div>
                <div className={styles.text}>
                  <p>个人认证</p>
                  <p>通过身份证照</p>
                </div>
                <div className={styles.list}>
                  <p>
                    <CheckCircleFilled
                      style={{ color: "#1890ff", marginRight: 5 }}
                    />
                    全自动审核，即时通过
                  </p>
                  <p>
                    <CheckCircleFilled
                      style={{ color: "#1890ff", marginRight: 5 }}
                    />
                    支持升级为企业用户
                  </p>
                </div>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginTop: 35 }}
                  onClick={() =>
                    navigate("save", {
                      state:
                        data.name && data.type === 1
                          ? data
                          : { type: 1, attr: 1 },
                    })
                  }
                >
                  前往认证
                </Button>
              </div>
              <div className={styles.col}>
                <div className={styles.icon}>
                  <VerifiedOutlined />
                </div>
                <div className={styles.text}>
                  <p>企业认证</p>
                  <p>通过营业执照</p>
                </div>
                <div className={styles.list}>
                  <p>
                    <CheckCircleFilled
                      style={{ color: "#1890ff", marginRight: 5 }}
                    />
                    全自动审核，即时通过
                  </p>
                  <p>
                    <CheckCircleFilled
                      style={{ color: "#1890ff", marginRight: 5 }}
                    />
                    支持申请企业备案
                  </p>
                </div>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginTop: 35 }}
                  onClick={() =>
                    navigate("save", {
                      state:
                        data.name && data.type === 2
                          ? data
                          : { type: 2, attr: 4 },
                    })
                  }
                >
                  前往认证
                </Button>
              </div>
              <div className={styles.col}>
                <div className={styles.icon}>
                  <CommentOutlined />
                </div>
                <div className={styles.text}>
                  <p>专属客服</p>
                  <p>客服电话：13065554315</p>
                </div>
                <div className={styles.wewm}>
                  <img src={ewm} alt="" />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.infos}>
              <p>
                <i>{data.type === 1 ? "个人名称" : "企业名称"}：</i>
                <i>{data.name}</i>
              </p>
              <p>
                <i>认证类型：</i>
                <i>
                  {data.type === 1 ? "个人认证" : "企业认证"}{" "}
                  {data.pay === 1 ? "（已支付）" : "（未支付）"}
                </i>
              </p>
              <p>
                <i>用户类型：</i>
                <i>
                  <CheckCircleFilled
                    style={{ color: "#52c41a", marginRight: 5 }}
                  />
                  {data.type === 1 ? "个人用户" : "企业用户"}
                </i>
              </p>
              <p>
                <i>通讯地址：</i>
                <i>{data.address}</i>
              </p>
              {data.code ? (
                <p>
                  <i>{data.type === 1 ? "身份证号" : "社会信用代码"}：</i>
                  <i>{data.newCode.map((val) => val)}</i>
                </p>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RealSelect;
