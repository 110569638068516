import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "@reach/router";

import { message, Empty, Avatar, Tooltip, Spin } from "antd";

import {
  ScheduleFilled,
  MobileFilled,
  MailFilled,
  EditOutlined,
  PhoneFilled,
  ShopFilled,
  MessageFilled,
  PlusSquareFilled,
  FileExcelFilled,
  FileAddFilled,
  FileFilled,
  AppstoreFilled,
} from "@ant-design/icons";

import { Http } from "../../Http";
//import queryString from "query-string";

import ewm from "../../images/ewm.png";

import styles from "./Home.module.less";

function Home({ user, company }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [help, setHelp] = useState([]);
  const [mpb, setMpb] = useState("");

  const getUnitList = async () => {
    let parsed = await Http.get(`unit`, {}).json();
    if (parsed.state === "ok") {
      setData(parsed.result);
      console.log(parsed.result);
      parsed.result.forEach((value) => {
        if (value.alias === "b2b") {
          setMpb(value.url);
        }
      });
    }
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getUnitList();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const UnitWeb = ({ value }) => {
    return (
      <div className={`${styles.li} ${styles.web}`}>
        <h2>{value.name}</h2>
        <p>产品介绍：自助建站系统是面向中小企业用户推...</p>
        {/* <p>到期时间：2020-10-10 23:23:23</p> */}
        <div className={styles.router}>
          <a target="_blank" href={value.url}>
            进入
          </a>
        </div>
      </div>
    );
  };

  const UnitNlp = ({ value }) => {
    return (
      <div className={`${styles.li} ${styles.nlp}`}>
        <h2>{value.name}</h2>
        <p>产品介绍： AI伪原创文章检测工具,内容在线伪原...</p>
        {/* <p>到期时间：2020-10-10 23:23:23</p> */}
        <div className={styles.router}>
          <a target="_blank" href={value.url}>
            进入
          </a>
        </div>
      </div>
    );
  };

  const UnitB2b = ({ value }) => {
    return (
      <div className={`${styles.li} ${styles.b2b}`}>
        <h2>{value.name}</h2>
        <p>
          可轻松实现上万个关键词排名在各大搜索引擎前三页，收录快，排名快，大面积的曝光展示。...
        </p>
        {/* <p>到期时间：2020-10-10 23:23:23</p> */}
        <div className={styles.router}>
          <a target="_blank" href={value.url}>
            进入
          </a>
        </div>
      </div>
    );
  };

  const UnitSts = ({ value }) => {
    return (
      <div className={`${styles.li} ${styles.sts}`}>
        <h2>{value.name}</h2>
        <p>产品介绍：企业网站群解决方案能够帮助企业构建...</p>
        {/* <p>到期时间：2020-10-10 23:23:23</p> */}
        <div className={styles.router}>
          <a target="_blank" href={value.url}>
            进入
          </a>
        </div>
      </div>
    );
  };

  const Mixin = (value) => {
    if (value.alias === "web") return <UnitWeb value={value} key={1} />;
    if (value.alias === "nlp") return <UnitNlp value={value} key={2} />;
    if (value.alias === "b2b") return <UnitB2b value={value} key={3} />;
    if (value.alias === "sts") return <UnitSts value={value} key={4} />;
    return null;
  };

  useEffect(() => {
    if (user) {
      onLoading();
    }
  }, [user]);

  return (
    <div className={styles.Home}>
      <div className={styles.homeLeft}>
        <div className={styles.col}>
          {loading ? (
            <div className={styles.loading}>
              <Spin size="large" />
            </div>
          ) : (
            <div className={styles.avatar}>
              <Avatar
                size={140}
                src={
                  user?.avatar
                    ? user?.avatar
                    : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                }
              />
              <div className={styles.edit}>
                <EditOutlined onClick={() => navigate("/user")} />
              </div>
            </div>
          )}

          {loading ? null : (
            <div className={styles.infos}>
              <div className={styles.text}>
                {company?.name ? (
                  <h2 style={{ color: "#1890ff" }}>{company.name}</h2>
                ) : (
                  <h2 style={{ color: "red" }}>
                    还未进行实名认证，无法获取企业信息!
                  </h2>
                )}
                <h3>
                  您好！
                  <span>{user?.username ? user.username : "-----"}</span> ,
                  愿您的生意天天上升，情绪时时开心！
                </h3>
              </div>
              <div className={styles.auth}>
                <Tooltip title="营业执照" placement="topLeft">
                  <div className={styles.col} onClick={() => navigate("/real")}>
                    <ScheduleFilled
                      style={{ color: company?.name ? "#1890ff" : null }}
                    />
                    {company?.name ? (
                      <i style={{ color: "#1890ff" }}>已填写</i>
                    ) : (
                      <i>未填写</i>
                    )}
                  </div>
                </Tooltip>
                <Tooltip title="手机号" placement="topLeft">
                  <div className={styles.col} onClick={() => navigate("/user")}>
                    <MobileFilled
                      style={{ color: user?.cellphone ? "#1890ff" : null }}
                    />
                    {user?.cellphone ? (
                      <i style={{ color: "#1890ff" }}>已填写</i>
                    ) : (
                      <i>未填写</i>
                    )}
                  </div>
                </Tooltip>
                <Tooltip title="邮箱" placement="topLeft">
                  <div className={styles.col} onClick={() => navigate("/user")}>
                    <MailFilled
                      style={{ color: user?.email ? "#1890ff" : null }}
                    />
                    {user?.email ? (
                      <i style={{ color: "#1890ff" }}>已填写</i>
                    ) : (
                      <i>未填写</i>
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.title}>常用功能</div>
          <div className={styles.box}>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <ShopFilled style={{ color: "#ffffff", fontSize: "24px" }} />
                </div>
                <div className={styles.tit}>店铺装修</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <FileAddFilled
                    style={{ color: "#ffffff", fontSize: "24px" }}
                  />
                </div>
                <div className={styles.tit}>新增文章</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <FileFilled style={{ color: "#ffffff", fontSize: "24px" }} />
                </div>
                <div className={styles.tit}>文章管理</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <PlusSquareFilled
                    style={{ color: "#ffffff", fontSize: "24px" }}
                  />
                </div>
                <div className={styles.tit}>新增产品</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <AppstoreFilled
                    style={{ color: "#ffffff", fontSize: "24px" }}
                  />
                </div>
                <div className={styles.tit}>产品管理</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <MessageFilled
                    style={{ color: "#ffffff", fontSize: "24px" }}
                  />
                </div>
                <div className={styles.tit}>留言管理</div>
              </a>
            </div>
            <div className={styles.row}>
              <a target="_blank" href={mpb}>
                <div className={styles.ico}>
                  <FileExcelFilled
                    style={{ color: "#ffffff", fontSize: "24px" }}
                  />
                </div>
                <div className={styles.tit}>报表下载</div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {loading ? (
            <div className={styles.loading}>
              <Spin size="large" />
            </div>
          ) : (
            <div className={styles.title}>已开通服务</div>
          )}
          {loading ? null : (
            <div className={styles.box}>
              {data.map((value) => Mixin(value))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.homeRight}>
        <div className={styles.agency}>
          <div className={styles.title}>待办事项</div>
          <div className={styles.box}>
            {company?.name ? null : (
              <h2>
                <Link to="real">企业未认证，立即认证！</Link>
              </h2>
            )}
            {user?.cellphone ? null : (
              <h2>
                <Link to="user">手机号未填写，立即填写！</Link>
              </h2>
            )}
            {user?.email ? null : (
              <h2>
                <Link to="user">邮箱未填写，立即填写！</Link>
              </h2>
            )}
            {user?.email && user?.cellphone && company?.name ? (
              <Empty style={{ paddingTop: 24 }} />
            ) : null}
          </div>
        </div>
        <div className={styles.service}>
          <div className={styles.title}>专属客服</div>
          <div className={styles.box}>
            <h2>
              <PhoneFilled />
              <i>客服专线：13065554315</i>
            </h2>
            <h2>
              <img src={ewm} alt="" />
            </h2>
          </div>
        </div>
        <div className={styles.help}>
          <div className={styles.title}>系统帮助</div>
          <div className={styles.box}>
            {help.length > 0 ? (
              help.map((value) => <h2 key={value.id}>{value.title}</h2>)
            ) : (
              <Empty style={{ paddingTop: 24 }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
