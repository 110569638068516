import React, { useState, useEffect, useRef } from "react";

import {
  Button,
  message,
  Spin,
  Upload,
  Modal,
  Checkbox,
  notification,
  Space,
} from "antd";

import {
  CloseOutlined,
  CheckCircleFilled,
  PlusCircleFilled,
  InboxOutlined,
} from "@ant-design/icons";

import { Server, Http } from "../Http";
import queryString from "query-string";

import Portal from "./Portal";

import styles from "./Album.module.less";

const { Dragger } = Upload;

function Album({ visible, limit, onOk, onCancel }) {
  const [loading, setLoading] = useState(true);
  const [upload, setUpload] = useState(false);
  const [data, setData] = useState([]);
  const [thumbArray, setThumbArray] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 16,
    total: 0,
  });

  const containerEl = useRef(null);

  const props = {
    data: {},
    accept: ".jpg, .jpeg, .png, .bmp, .gif",
    multiple: true,
    action: Server + "/album/upload",
    method: "post",
    onChange(info) {
      if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败,请检查图片格式和大小！`);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} 上传成功.`);
      }
    },
  };

  const onSelect = (value) => {
    setThumbArray(() => {
      return thumbArray.includes(value)
        ? thumbArray.filter((item) => item !== value)
        : [...thumbArray, value];
    });
  };

  const onAllSelect = (e) => {
    let array = [];
    if (e.target.checked) {
      data.forEach((v) => {
        array.push(v);
      });
    } else {
      array = [];
    }
    setThumbArray(array);
  };

  const handleAlbumOk = () => {
    if (limit && limit !== thumbArray.length) {
      message.warning("需选择 " + limit + " 张图片，请重新选择!");
    } else {
      let thumbs = [];
      thumbArray.forEach((value) => {
        thumbs.push(value.src);
      });
      onOk(thumbs);
    }
  };

  const handleAlbumCancel = () => {
    onCancel();
  };

  const handleAlbumScroll = () => {
    if (
      containerEl.current.scrollTop + containerEl.current.offsetHeight >=
      containerEl.current.scrollHeight
    ) {
      if (
        pagination.current < Math.ceil(pagination.total / pagination.pageSize)
      ) {
        onLoading({
          ...pagination,
          current: pagination.current + 1,
          bare: false,
        });
      }
    }
  };

  const handleAlbumDelete = async () => {
    let ids = [];
    thumbArray.forEach((value) => {
      ids.push(value.id);
    });
    let para = queryString.stringify({ id: ids });
    try {
      let parsed = await Http.post("album/delete", {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("删除成功");
        onLoading({ current: 1, pageSize: 16, total: 0, bare: true });
      } else {
        await message.warn("删除失败");
      }
    } catch (error) {
      await message.error("接口调用错误");
    } finally {
    }
  };

  const handleAlbumExport = () => {
    let thumbs = [];
    thumbArray.forEach((v) => {
      thumbs.push(v.src);
    });
    notification.open({
      message: "图片链接",
      description: thumbs.map((value) => <p>{value}</p>),
      duration: null,
      className: "thumbUrlList",
    });
  };

  const handleModalOk = () => {
    setUpload(false);
    onLoading({
      ...pagination,
      current: 1,
      bare: true,
    });
  };

  const handleModalCancel = () => {
    setUpload(false);
  };

  const onLoading = async (params) => {
    setLoading(true);
    try {
      let para = queryString.stringify({
        pageNumber: params.current,
        pageSize: params.pageSize,
      });
      let parsed = await Http.get("album", {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        if (params.bare) {
          setData(parsed.result.list);
          setPagination({
            current: 1,
            pageSize: parsed.result.pageSize,
            total: parsed.result.totalRow,
          });
        } else {
          setData([...data, ...parsed.result.list]);
          setPagination({
            current: parsed.result.pageNumber,
            pageSize: parsed.result.pageSize,
            total: parsed.result.totalRow,
          });
        }
      }
    } catch (error) {
      await message.error("接口调用错误");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      onLoading({ ...pagination, bare: true });
    } else {
      setData([]);
      setThumbArray([]);
      setPagination({
        current: 1,
        pageSize: 16,
        total: 0,
      });
    }
  }, [visible]);
  return (
    <Portal visible={visible}>
      <div className={styles.Album}>
        <div className={styles.bigbox}>
          <div className={styles.header}>
            <div className={styles.name}>图片库</div>
            <div className={styles.close}>
              <CloseOutlined onClick={() => handleAlbumCancel()} />
            </div>
          </div>
          <div
            className={styles.content}
            ref={containerEl}
            onScroll={() => handleAlbumScroll()}
          >
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
              <div className={styles.box}>
                <div className={styles.col} onClick={() => setUpload(true)}>
                  <div className={styles.upload}>
                    <PlusCircleFilled />
                  </div>
                </div>
                {data.map((value) => (
                  <div
                    className={styles.col}
                    key={value.id}
                    onClick={() => onSelect(value)}
                  >
                    <img src={value.src} alt="" />
                    {thumbArray.includes(value) ? (
                      <div className={styles.selected}>
                        <CheckCircleFilled />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <Space>
              <Checkbox onChange={onAllSelect}>全选</Checkbox>
              {thumbArray.length > 0 ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => handleAlbumDelete()}
                >
                  删除
                </Button>
              ) : null}
              {thumbArray.length > 0 ? (
                <Button type="primary" onClick={() => handleAlbumExport()}>
                  导出
                </Button>
              ) : null}
            </Space>
            <Space>
              <Button onClick={() => handleAlbumCancel()}>返回</Button>
              <Button type="primary" onClick={() => handleAlbumOk()}>
                确定
              </Button>
            </Space>
          </div>
        </div>
        <Modal
          title="上传图片"
          visible={upload}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          cancelText="返回"
          centered
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className={styles.modalUpload}>
            <Dragger {...props}>
              <p
                className="ant-upload-drag-icon"
                style={{ margin: "0 0 5px 0" }}
              >
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ fontSize: "14px", color: "#666" }}
              >
                单击或拖动图片到该区域上传
              </p>
              <p className="ant-upload-hint" style={{ fontSize: "12px" }}>
                只允许上传.jpeg/.jpg/.gif/.png格式，图片体积小于2MB的图片！
              </p>
            </Dragger>
          </div>
        </Modal>
      </div>
    </Portal>
  );
}

export default Album;
