import React, { useState, useEffect } from "react";

import { Tabs, Spin, message } from "antd";

import ky from "ky";
import { Http } from "../../Http";
import queryString from "query-string";

import ewm from "../../images/ewm.png";
import wxpay from "../../images/pay.jpg";

import styles from "./RealPay.module.less";

function RealPay({ navigate, location, user }) {
  const [loading, setLoading] = useState(true);
  console.log(loading);
  const [data, setData] = useState({});
  const [sid, setSid] = useState(0);

  const { TabPane } = Tabs;

  const getReal = async () => {
    let parsed = await Http.get(`real/self`, {}).json();
    if (parsed.state === "ok") {
      setData(parsed.result);
      if (parsed.result.userId) {
        onSubmit(parsed.result);
      }
    }
  };

  const onSubmit = async (value) => {
    let para = queryString.stringify({
      name: value.type === 1 ? "个人认证费用" : "企业认证费用",
      amount: value.amount,
      attach:
        value.type === 1
          ? `sso,individual,${value.userId}`
          : `sso,enterprise,${value.userId}`,
    });
    let parsed = await ky
      .post("https://pay.chinaapus.com/order/submit", {
        body: new URLSearchParams(para),
      })
      .json();
    setSid(parsed.data);
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getReal();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReal();
    //onLoading();
  });

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "autocloseload") {
        navigate("/");
      }
    });
  });

  return (
    <div className={styles.RealPay}>
      <div className={styles.bigbox}>
        <div class={styles.tip}>您还差最后一步了：微信支付实名认证费用！</div>
        <div class={styles.box}>
          <div className={styles.col}>
            <div className={styles.tit}>
              <span>认证费用</span>
            </div>
            <div className={styles.exp}>
              <p>您已提交认证资料，请支付认证费用，以便后续操作。</p>
              <p>1. 认证费用：不退，不开发票。</p>
              <p>2. 认证成功后，赠送200家B2B联盟供求网站同时发布。</p>
              <p>3. 认证成功后，赠送智能写作系统。</p>
              <p>4. 认证成功后，赠送500元小程序制作代金券。</p>
              <p>5. 认证成功后，有专属一对一客服指导发布。</p>
            </div>
            <div className={styles.pay}>
              <Tabs tabPosition="left" type="card">
                <TabPane tab="微信扫码支付" key="1">
                  <div className={styles.box}>
                    <div className={styles.ewm}>
                     <img src={wxpay} alt="" /> 
                    </div>
                    <div className={styles.txt}>
                      <p>
                        认证费用：<span>￥{data.amount}</span>元
                      </p>
                      <p>支付完成后，第一时间加右边微信客服开通功能！</p>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tit}>
              <span>在线客服</span>
            </div>
            <div className={styles.exp}>
              <p>用户ID：{data.userId}</p>
              <p>工作时间：AM 8:00-12:00</p>
              <p>工作时间：PM 14:00-18:00</p>
              <p>客服电话：13065554315</p>
              <p>扫码加微信在线沟通</p>
            </div>
            <div className={styles.ewm}>
              <img src={ewm} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealPay;
