import React from "react";

import { Router } from "@reach/router";

import RealSelect from "./RealSelect";
import RealSave from "./RealSave";
import RealPay from "./RealPay";

import styles from "./Real.module.less";

function Real({ user }) {
  return (
    <div className={styles.Real}>
      <Router>
        <RealSelect path="/" user={user} />
        <RealSave path="save" />
        <RealPay path="pay" />
      </Router>
    </div>
  );
}

export default Real;
