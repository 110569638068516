import React, { useState, useEffect, useRef } from "react";

import { Router } from "@reach/router";

import { Statistic, Space, Tabs, DatePicker } from "antd";

import moment from "moment";

import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";

import sl1 from "../../images/baidu.png";
import sl2 from "../../images/so.png";
import sl3 from "../../images/sogou.png";
import sl4 from "../../images/sm.png";

import styles from "./Home.module.less";

const { RangePicker } = DatePicker;

function Home() {
  const { TabPane } = Tabs;

  const [timeSelect, setTimeSelect] = useState({
    key: 1,
    value: [moment(), moment()],
  });

  const time = [
    {
      key: 1,
      name: "今日",
      value: [moment(), moment()],
    },
    {
      key: 2,
      name: "本周",
      value: [
        moment().week(moment().week()).startOf("week"),
        moment().week(moment().week()).endOf("week"),
      ],
    },
    {
      key: 3,
      name: "本月",
      value: [
        moment().month(moment().month()).startOf("month"),
        moment().month(moment().month()).endOf("month"),
      ],
    },
    {
      key: 4,
      name: "全年",
      value: [
        moment().year(moment().year()).startOf("year"),
        moment().year(moment().year()).endOf("year"),
      ],
    },
  ];

  const Chart = useRef();

  const getChart = async () => {
    // let para = queryString.stringify({});
    // let parsed = await Http.get(`hour`, {
    //   searchParams: para,
    // }).json();
    // if (parsed.state === "ok") {

    // }
    let eChart = echarts.init(Chart.current);
    eChart.setOption({
      color: ["#3398DB"],
      tooltip: {
        trigger: "axis",
      },
      grid: {
        top: 10,
        left: 40,
        right: 40,
        bottom: 20,
      },
      xAxis: [
        {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],

      series: [
        {
          name: "访问人数",
          type: "bar",
          data: [10, 52, 200, 334, 390, 330, 220],
          barWidth: "50%",
        },
      ],
    });
  };

  useEffect(() => {
    getChart();
  }, []);

  return (
    <div className={styles.Home}>
      <div className={styles.section}>
        <div className={styles.col}>
          <div className={styles.tit}>
            <div className={styles.name}>注册用户</div>
            <div className={styles.state}>
              <span>已实名</span>
              <span>未实名</span>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.li}>
              <h2>今日注册</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  value={20000}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
              </Space>
            </div>
            <div className={styles.li}>
              <h2>昨日注册</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={1000}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={1000}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  value={2000}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
              </Space>
            </div>
            <div className={styles.li}>
              <h2>总注册数</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={100}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={100}
                  suffix={<span style={{ fontSize: 12 }}>/ 人</span>}
                />
                <Statistic
                  value={200}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
              </Space>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.tit}>
            <div className={styles.name}>秒排宝</div>
            <div className={styles.state}>
              <span>已审核</span>
              <span>未审核</span>
              <span>
                <img src={sl1} />
                百度收录
              </span>
              <span>
                <img src={sl2} />
                360收录
              </span>
              <span>
                <img src={sl3} />
                搜狗收录
              </span>
              <span>
                <img src={sl4} />
                神马收录
              </span>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.li}>
              <h2>今日发布</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl1} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl2} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl3} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl4} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
              </Space>
            </div>
            <div className={styles.li}>
              <h2>昨日发布</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl1} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl2} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl3} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl4} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
              </Space>
            </div>
            <div className={styles.li}>
              <h2>总发布数</h2>
              <Space size={24}>
                <Statistic
                  valueStyle={{ color: "#3f8600" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  valueStyle={{ color: "#cf1322" }}
                  value={10000}
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  suffix={<span style={{ fontSize: 12 }}>/ 总计</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl1} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={10000}
                  prefix={
                    <img src={sl2} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl3} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
                <Statistic
                  value={20000}
                  prefix={
                    <img src={sl4} style={{ width: 20, marginBottom: 6 }} />
                  }
                  suffix={<span style={{ fontSize: 12 }}>/ 篇</span>}
                />
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <Tabs
          defaultActiveKey="1"
          size="large"
          tabBarExtraContent={
            <div className={styles.timeSelect}>
              {time.map((value) => (
                <a
                  key={value.key}
                  style={{
                    color: value.key === timeSelect.key ? " #1890ff" : null,
                  }}
                  onClick={() =>
                    setTimeSelect({ key: value.key, value: value.value })
                  }
                >
                  {value.name}
                </a>
              ))}
              <RangePicker value={timeSelect.value} />
            </div>
          }
        >
          <TabPane tab="流量分析" key="1">
            <div className={styles.analysis}>
              <div className={styles.trend}>
                <div className={styles.title}>今日流量趋势</div>
                <div className={styles.Chart} ref={Chart}></div>
              </div>
              <div className={styles.rank}>
                <div className={styles.title}>品牌流量排名</div>
                <ul>
                  <li>
                    <span>1</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>2</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>3</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>4</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>5</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>6</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>7</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                  <li>
                    <span>8</span>
                    <span>南齐井盖</span>
                    <span>100000</span>
                  </li>
                </ul>
              </div>
            </div>
          </TabPane>
          <TabPane tab="地域分析" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="品牌分析" key="3">
            Content of Tab Pane 3
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Home;
