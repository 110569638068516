import ky from "ky";

const Server = "/api";

const Http = ky.extend({
  prefixUrl: Server,
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        //console.log(request, options, response);
      },
    ],
  },
});

export { Server, Http };
