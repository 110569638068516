import React, { useState, useEffect } from "react";

import { Router } from "@reach/router";

import { Layout, Menu, Avatar, Dropdown, message } from "antd";

import {
  ClusterOutlined,
  TeamOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { Http } from "../../Http";

import Home from "../webmaster/Home";
import User from "../webmaster/User";

import LogoLong from "../../images/logo_long.png";
import LogoShort from "../../images/logo_short.png";

import styles from "./Webmaster.module.less";

function Webmaster({ location, navigate, user }) {
  const [page, setPage] = useState({ open: [], selected: [] });
  const [collapsed, setCollapsed] = useState(false);

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const menu = [
    {
      name: "数据总览",
      url: "webmaster",
      icon: ClusterOutlined,
      sub: [],
    },
    {
      name: "用户中心",
      url: "webmaster/user",
      icon: TeamOutlined,
      sub: [],
    },
  ];

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onLogout = async () => {
    try {
      let parsed = await Http.get(`logout`, {}).json();
      if (parsed.state === "ok") {
        navigate("/signin");
      } else {
        await message.warn("退出失败");
      }
    } catch (error) {
      await message.error("接口调用错误");
    } finally {
    }
  };

  const userDrop = (
    <Menu className={styles.userDrop}>
      <Menu.Item>
        <a onClick={onLogout}>
          <ExportOutlined />
          <span>退出登录</span>
        </a>
      </Menu.Item>
    </Menu>
  );

  const onMuenLocal = () => {
    menu.forEach((value) => {
      if (location.pathname.includes(value.url)) {
        setPage({ open: [], selected: [value.url] });
      } else {
        value.sub.forEach((val) => {
          if (location.pathname.includes(val.url)) {
            setPage({ open: [value.url], selected: [val.url] });
          }
        });
      }
    });
  };

  useEffect(() => {
    onMuenLocal();
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      if (user.role !== "manager") {
        navigate("/");
        message.warn("您无权访问该地址！");
      }
    }
  }, [user]);

  return (
    <div className={styles.Webmaster}>
      <Layout style={{ height: "100vh" }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className={styles.logo}>
            <img src={collapsed ? LogoShort : LogoLong} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            openKeys={page.open}
            selectedKeys={page.selected}
            onOpenChange={(e) => {
              setPage({ ...page, open: e });
            }}
          >
            {menu.map((value) =>
              value.sub.length > 0 ? (
                <SubMenu
                  key={value.url}
                  icon={<value.icon />}
                  title={value.name}
                >
                  {value.sub.map((val) => (
                    <Menu.Item
                      key={val.url}
                      onClick={() => {
                        navigate(val.url === "/" ? `/` : `/${val.url}`);
                        setPage({ ...page, selected: [val.url] });
                      }}
                    >
                      {val.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={value.url}
                  icon={<value.icon />}
                  onClick={() => {
                    navigate(value.url === "/" ? `/` : `/${value.url}`);
                    setPage({ ...page, selected: [value.url] });
                  }}
                >
                  {value.name}
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{ height: "100vh" }}>
          <div className={styles.header}>
            <div className={styles.HeaderLeft}></div>
            <div className={styles.HeaderRight}>
              <Dropdown overlay={userDrop} placement="bottomRight" arrow>
                <div className={styles.welcome}>
                  <Avatar
                    size={32}
                    style={{ backgroundColor: "#fde3cf" }}
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  />
                  <i>{user?.username}</i>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className={styles.content}>
            {user ? (
              <Router>
                <Home path="/" />
                <User path="user/*" />
              </Router>
            ) : null}
          </div>
        </Layout>
      </Layout>
    </div>
  );
}

export default Webmaster;
