import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Select,
  Radio,
  Cascader,
  Upload,
  Space,
  message,
  Spin,
  Popover,
  Modal,
} from "antd";

import {
  FormOutlined,
  PlusOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Server, Http } from "../../Http";
import queryString from "query-string";

import { areaTree, cateTree } from "../../Tree.js";

import personalCase1 from "../../images/personal_case_01.png";
import personalCase2 from "../../images/personal_case_02.png";
import personalCase3 from "../../images/personal_case_03.png";
import personalCase4 from "../../images/personal_case_04.png";
import enterpriseCase1 from "../../images/enterprise_case_01.png";
import enterpriseCase2 from "../../images/enterprise_case_02.jpg";

import styles from "./RealSave.module.less";

const { Option } = Select;

function RealSave({ navigate, location }) {
  const { state } = location;

  const [submit, setSubmit] = useState(false);
  const [local, setLocal] = useState(1);
  const [personal, setPersonal] = useState({
    front: {
      loading: false,
    },
    back: {
      loading: false,
    },
  });
  const [enterprise, setEnterprise] = useState({
    loading: false,
  });
  const [modal, setModal] = useState({
    visible: false,
    img: null,
  });

  const [form] = Form.useForm();

  const handlePersonalFrontChange = (info) => {
    if (info.file.status === "uploading") {
      setPersonal({
        ...personal,
        front: {
          loading: true,
        },
      });
      return;
    }
    if (info.file.status === "done") {
      if (local === 1) {
        if (info.file.response.result) {
          setPersonal({
            ...personal,
            front: {
              loading: false,
              url: info.file.response.result.url,
              object: info.file.response.result.object,
            },
          });
          form.setFieldsValue({
            name: info.file.response.result.name,
            code: info.file.response.result.code,
          });
        } else {
          message.warn("未识别出相关信息，请重新点击上传！");
          setPersonal({
            ...personal,
            front: {
              loading: false,
            },
          });
        }
      } else {
        setPersonal({
          ...personal,
          front: {
            loading: false,
            url: info.file.response.result.url,
            object: info.file.response.result.object,
          },
        });
      }
    }
  };

  const handlePersonalBackChange = (info) => {
    if (info.file.status === "uploading") {
      setPersonal({
        ...personal,
        back: {
          loading: true,
        },
      });
      return;
    }
    if (info.file.status === "done") {
      if (local === 1) {
        if (info.file.response.result) {
          setPersonal({
            ...personal,
            back: {
              loading: false,
              url: info.file.response.result.url,
              object: info.file.response.result.object,
            },
          });
        } else {
          message.warn("未识别出相关信息，请重新点击上传！");
          setPersonal({
            ...personal,
            back: {
              loading: false,
            },
          });
        }
      } else {
        setPersonal({
          ...personal,
          back: {
            loading: false,
            url: info.file.response.result.url,
            object: info.file.response.result.object,
          },
        });
      }
    }
  };

  const handleEnterpriseChange = (info) => {
    if (info.file.status === "uploading") {
      setEnterprise({
        loading: true,
      });
      return;
    }
    if (info.file.status === "done") {
      if (local === 1) {
        if (info.file.response.result) {
          setEnterprise({
            loading: false,
            url: info.file.response.result.url,
            object: info.file.response.result.object,
          });
          form.setFieldsValue({
            name: info.file.response.result.name,
            code: info.file.response.result.code,
          });
        } else {
          message.warn("未识别出相关信息，请重新点击上传！");
          setEnterprise({
            loading: false,
          });
        }
      } else {
        setEnterprise({
          loading: false,
          url: info.file.response.result.url,
          object: info.file.response.result.object,
        });
      }
    }
  };

  const onlocalChange = (value) => {
    setLocal(value);
    if (value === state.locate) {
      state.type === 1
        ? setPersonal({
            front: {
              loading: false,
              url: state.tempFront,
              object: state.front,
            },
            back: {
              loading: false,
              url: state.tempBack,
              object: state.back,
            },
          })
        : setEnterprise({
            loading: false,
            url: state.tempImage,
            object: state.image,
          });
      state.cate = state.parentId ? [state.parentId, state.childId] : [];
      state.area = state.provinceId
        ? [state.provinceId, state.cityId, state.regionId]
        : [];
      form.setFieldsValue(state);
    } else {
      state.type === 1
        ? setPersonal({
            front: {
              loading: false,
            },
            back: {
              loading: false,
            },
          })
        : setEnterprise({
            loading: false,
          });
      form.resetFields();
    }
  };

  const onFinish = async (values) => {
    if (state.type === 1) {
      if (personal.front.object && personal.back.object) {
        setSubmit(true);
        values.front = personal.front.object;
        values.back = personal.back.object;
        if (state.id) {
          values.id = state.id;
        }
        if (!values.attr) {
          values.attr = state.attr;
        }
        values.locate = local;
        values.type = state.type;
        values.status = 0;
        let para = queryString.stringify(values);
        try {
          let parsed = await Http.post(`real/save`, {
            body: new URLSearchParams(para),
          }).json();
          if (parsed.state === "ok") {
            await message.success("提交成功");
            navigate("../pay");
          } else {
            await message.warn(parsed.message ? parsed.message : "提交失败");
          }
        } catch (error) {
          await message.error("接口调用错误, 错误原因:" + error.message);
        } finally {
          setSubmit(false);
        }
      } else {
        if (local === 1) {
          message.warn("请上传正确的身份证正反面图片！");
        } else {
          message.warn("请上传正确证件正反面图片！");
        }
      }
    } else {
      if (enterprise.object) {
        setSubmit(true);
        values.image = enterprise.object;
        if (state.id) {
          values.id = state.id;
        }
        if (!values.attr) {
          values.attr = state.attr;
        }
        values.locate = local;
        values.type = state.type;
        values.status = 0;
        let para = queryString.stringify(values);
        try {
          let parsed = await Http.post(`real/save`, {
            body: new URLSearchParams(para),
          }).json();
          if (parsed.state === "ok") {
            await message.success("提交成功");
            navigate("../pay");
          } else {
            await message.warn(parsed.message ? parsed.message : "提交失败");
          }
        } catch (error) {
          await message.error("接口调用错误, 错误原因:" + error.message);
        } finally {
          setSubmit(false);
        }
      } else {
        if (local === 1) {
          message.warn("请上传正确的营业执照图片！");
        } else {
          message.warn("请上传正确的营业许可证图片！");
        }
      }
    }
  };

  useEffect(() => {
    if (state) {
      if (state.status) {
        state.locate ? setLocal(state.locate) : setLocal(1);
        state.type === 1
          ? setPersonal({
              front: {
                loading: false,
                url: state.tempFront,
                object: state.front,
              },
              back: {
                loading: false,
                url: state.tempBack,
                object: state.back,
              },
            })
          : setEnterprise({
              loading: false,
              url: state.tempImage,
              object: state.image,
            });
        state.cate = state.parentId ? [state.parentId, state.childId] : [];
        state.area = state.provinceId
          ? [state.provinceId, state.cityId, state.regionId]
          : [];
        form.setFieldsValue(state);
      }
    } else {
      navigate("../");
    }
  }, [state]);

  return (
    <div className={styles.RealSave}>
      <div className={styles.bigBox}>
        <div className={styles.header}>
          <div className={styles.title}>
            <FormOutlined style={{ fontSize: 16, color: "#1890ff" }} />
            <i>{state?.type === 1 ? "个人认证" : "企业认证"}</i>
          </div>
        </div>
        <div className={styles.content}>
          {state?.type === 1 ? (
            <div className={styles.personal}>
              <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item label="所在地">
                  <Radio.Group
                    value={local}
                    onChange={(e) => onlocalChange(e.target.value)}
                  >
                    <Radio.Button value={1}>大陆</Radio.Button>
                    <Radio.Button value={2}>非大陆</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {local === 1 ? (
                  <Form.Item
                    label="身份证"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="front"
                      rules={[{ required: true, message: "请上传正面！" }]}
                      style={{
                        display: "inline-block",
                        width: 104,
                      }}
                    >
                      <Upload
                        data={{ side: "front", locate: local }}
                        action={Server + "/ocrID"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handlePersonalFrontChange}
                      >
                        {personal.front.url ? (
                          <div className={styles.pic}>
                            <img
                              src={personal.front.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: personal.front.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setPersonal({
                                      ...personal,
                                      front: {
                                        loading: false,
                                      },
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <Popover
                            placement="top"
                            content={
                              <img src={personalCase1} alt="身份证正面" />
                            }
                          >
                            <div className={styles.status}>
                              {personal.front.loading ? (
                                <LoadingOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                              <div style={{ marginTop: 8, fontSize: 12 }}>
                                身份证人像面
                              </div>
                            </div>
                          </Popover>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="back"
                      rules={[{ required: true, message: "请上传反面！" }]}
                      style={{
                        display: "inline-block",
                        width: 104,
                        marginLeft: 10,
                      }}
                    >
                      <Upload
                        data={{ side: "back", locate: local }}
                        action={Server + "/ocrID"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handlePersonalBackChange}
                        multiple
                      >
                        {personal.back.url ? (
                          <div className={styles.pic}>
                            <img
                              src={personal.back.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: personal.back.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setPersonal({
                                      ...personal,
                                      back: {
                                        loading: false,
                                      },
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <Popover
                            placement="top"
                            content={
                              <img src={personalCase2} alt="身份证反面" />
                            }
                          >
                            <div className={styles.status}>
                              {personal.back.loading ? (
                                <LoadingOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                              <div style={{ marginTop: 8, fontSize: 12 }}>
                                身份证国徽面
                              </div>
                            </div>
                          </Popover>
                        )}
                      </Upload>
                    </Form.Item>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="证件上传"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="front"
                      rules={[{ required: true, message: "请上传正面！" }]}
                      style={{
                        display: "inline-block",
                        width: 104,
                      }}
                    >
                      <Upload
                        data={{ side: "front", locate: local }}
                        action={Server + "/ocrID"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handlePersonalFrontChange}
                      >
                        {personal.front.url ? (
                          <div className={styles.pic}>
                            <img
                              src={personal.front.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: personal.front.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setPersonal({
                                      ...personal,
                                      front: {
                                        loading: false,
                                      },
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <Popover
                            placement="left"
                            content={<img src={personalCase3} alt="证件正面" />}
                          >
                            <div className={styles.status}>
                              {personal.front.loading ? (
                                <LoadingOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                              <div style={{ marginTop: 8, fontSize: 12 }}>
                                证件人像面
                              </div>
                            </div>
                          </Popover>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="back"
                      rules={[{ required: true, message: "请上传反面！" }]}
                      style={{
                        display: "inline-block",
                        width: 104,
                        marginLeft: 10,
                      }}
                    >
                      <Upload
                        data={{ side: "back", locate: local }}
                        action={Server + "/ocrID"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handlePersonalBackChange}
                        multiple
                      >
                        {personal.back.url ? (
                          <div className={styles.pic}>
                            <img
                              src={personal.back.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: personal.back.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setPersonal({
                                      ...personal,
                                      back: {
                                        loading: false,
                                      },
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <Popover
                            placement="right"
                            content={<img src={personalCase4} alt="证件反面" />}
                          >
                            <div className={styles.status}>
                              {personal.back.loading ? (
                                <LoadingOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                              <div style={{ marginTop: 8, fontSize: 12 }}>
                                证件国徽面
                              </div>
                            </div>
                          </Popover>
                        )}
                      </Upload>
                    </Form.Item>
                  </Form.Item>
                )}
                {local === 1 ? (
                  personal.front.url ? (
                    <Form.Item
                      name="name"
                      label="真实姓名"
                      rules={[{ required: true, message: "请填写真实姓名！" }]}
                    >
                      <Input placeholder="请填写真实姓名" />
                    </Form.Item>
                  ) : null
                ) : (
                  <Form.Item
                    name="name"
                    label="真实姓名"
                    rules={[{ required: true, message: "请填写真实姓名！" }]}
                  >
                    <Input placeholder="请填写真实姓名" />
                  </Form.Item>
                )}
                {local === 1 ? (
                  personal.front.url ? (
                    <Form.Item
                      name="code"
                      label="身份证号"
                      rules={[{ required: true, message: "请填写身份证号！" }]}
                    >
                      <Input placeholder="请填写身份证号" />
                    </Form.Item>
                  ) : null
                ) : (
                  <Form.Item
                    label="证件类型及号码"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="attr"
                      rules={[{ required: true, message: "请选择证件类型！" }]}
                      style={{
                        display: "inline-block",
                        width: 200,
                      }}
                    >
                      <Select placeholder="请选择证件类型">
                        <Option value={2}>回乡证/台胞证</Option>
                        <Option value={3}>护照</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="code"
                      rules={[{ required: true, message: "请填写证件号码！" }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 216px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input placeholder="请填写证件号码" />
                    </Form.Item>
                  </Form.Item>
                )}
                <Form.Item
                  name="cate"
                  label="行业"
                  rules={[{ required: true, message: "请选择行业！" }]}
                >
                  <Cascader options={cateTree} placeholder="请选择行业" />
                </Form.Item>
                <Form.Item
                  name="area"
                  label="区域"
                  rules={[{ required: true, message: "请选择区域！" }]}
                >
                  <Cascader options={areaTree} placeholder="请选择区域" />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="通讯地址"
                  rules={[{ required: true, message: "请输入详细地址！" }]}
                >
                  <Input placeholder="请输入详细地址" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5 }}>
                  <Space>
                    <Button
                      type="primary"
                      disabled={submit}
                      loading={submit}
                      htmlType="submit"
                    >
                      提交
                    </Button>
                    <Button htmlType="button" onClick={() => navigate("../")}>
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className={styles.enterprise}>
              <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item label="所在地">
                  <Radio.Group
                    value={local}
                    onChange={(e) => onlocalChange(e.target.value)}
                  >
                    <Radio.Button value={1}>中国大陆</Radio.Button>
                    <Radio.Button value={2}>港澳台</Radio.Button>
                    <Radio.Button value={3}>海外</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {local === 1 ? (
                  <Form.Item
                    label="营业执照"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="image"
                      rules={[{ required: true, message: "请上传营业执照！" }]}
                      style={{
                        display: "inline-block",
                        width: 124,
                      }}
                    >
                      <Upload
                        data={{ locate: local }}
                        action={Server + "/ocrBS"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handleEnterpriseChange}
                        multiple
                      >
                        {enterprise.url ? (
                          <div className={styles.pic}>
                            <img
                              src={enterprise.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: enterprise.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setEnterprise({
                                      loading: false,
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {enterprise.loading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div style={{ marginTop: 8, fontSize: 12 }}>
                              营业执照
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: 200,
                      }}
                    >
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 13,
                        }}
                        target="_blank"
                        href={enterpriseCase1}
                      >
                        <PaperClipOutlined style={{ marginRight: 5 }} />
                        模板图片
                      </a>
                      <p style={{ fontSize: 12, color: "#999", marginTop: 5 }}>
                        请上传清晰、端正、无水印的图片 加速您的审核
                      </p>
                    </Form.Item>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="营业许可证"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="image"
                      rules={[
                        { required: true, message: "请上传营业许可证！" },
                      ]}
                      style={{
                        display: "inline-block",
                        width: 124,
                      }}
                    >
                      <Upload
                        data={{ locate: local }}
                        action={Server + "/ocrBS"}
                        accept=".jpg,.jpeg,.png"
                        name="file"
                        listType="picture-card"
                        method="post"
                        showUploadList={false}
                        onChange={handleEnterpriseChange}
                        multiple
                      >
                        {enterprise.url ? (
                          <div className={styles.pic}>
                            <img
                              src={enterprise.url}
                              alt="file"
                              style={{ width: "100%" }}
                            />
                            <div
                              className={styles.see}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  onClick={() =>
                                    setModal({
                                      visible: true,
                                      img: enterprise.url,
                                    })
                                  }
                                />
                                <DeleteOutlined
                                  onClick={() =>
                                    setEnterprise({
                                      loading: false,
                                    })
                                  }
                                />
                              </Space>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {enterprise.loading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div style={{ marginTop: 8, fontSize: 12 }}>
                              营业许可证
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: 200,
                      }}
                    >
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 13,
                        }}
                        target="_blank"
                        href={enterpriseCase2}
                      >
                        <PaperClipOutlined style={{ marginRight: 5 }} />
                        模板图片
                      </a>
                      <p style={{ fontSize: 12, color: "#999", marginTop: 5 }}>
                        请上传清晰、端正、无水印的图片 加速您的审核
                      </p>
                    </Form.Item>
                  </Form.Item>
                )}
                <Form.Item
                  name="name"
                  label="公司名称"
                  rules={[{ required: true, message: "请填写公司名称！" }]}
                >
                  <Input placeholder="请填写公司名称" />
                </Form.Item>
                {local === 1 ? (
                  <Form.Item
                    name="code"
                    label="社会信用代码"
                    rules={[
                      { required: true, message: "请填写社会信用代码！" },
                    ]}
                  >
                    <Input placeholder="请填写社会信用代码" />
                  </Form.Item>
                ) : null}
                <Form.Item
                  name="cate"
                  label="行业"
                  rules={[{ required: true, message: "请选择行业！" }]}
                >
                  <Cascader options={cateTree} placeholder="请选择行业" />
                </Form.Item>
                <Form.Item
                  name="area"
                  label="区域"
                  rules={[{ required: true, message: "请选择区域！" }]}
                >
                  <Cascader options={areaTree} placeholder="请选择区域" />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="通讯地址"
                  rules={[{ required: true, message: "请输入通讯地址！" }]}
                >
                  <Input placeholder="请输入通讯地址" />
                </Form.Item>
                <Form.Item
                  name="person"
                  label="联系人姓名"
                  rules={[{ required: true, message: "请填写联系人姓名！" }]}
                >
                  <Input placeholder="请填写联系人姓名" />
                </Form.Item>
                <Form.Item
                  name="cellphone"
                  label="联系人手机"
                  rules={[
                    {
                      required: true,
                      pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                      message: "请输入正确的手机号！",
                    },
                  ]}
                >
                  <Input placeholder="请填写联系人手机" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5 }}>
                  <Space>
                    <Button
                      type="primary"
                      disabled={submit}
                      loading={submit}
                      htmlType="submit"
                    >
                      提交
                    </Button>
                    <Button htmlType="button" onClick={() => navigate("../")}>
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="图片查看"
        visible={modal.visible}
        onCancel={() => setModal({ visible: false })}
        footer={null}
        width={850}
        centered
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img
            style={{ maxWidth: "100%", maxHeight: 640, display: "block" }}
            src={modal.img}
            alt="图片查看"
          />
        </div>
      </Modal>
    </div>
  );
}

export default RealSave;
