import React, { useState, useEffect } from "react";

import { Space, Button, Select, Modal, Spin, message } from "antd";

import {
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import ky from "ky";
import queryString from "query-string";

import { ReactComponent as MedalsSvg } from "../../../icon/medals.svg";
import { ReactComponent as SketchSvg } from "../../../icon/sketch.svg";

import viptime from "../../../images/vip-time.png";
import b2bImg from "../../../images/b2b_img.jpg";
import wxPay from "../../../images/wx_pay.png";

import styles from "./ShopB2b.module.less";

function ShopB2b({ data, company }) {
  const [visible, setVisible] = useState(false);
  const [edition, setEdition] = useState(data.edition[0]);
  const [initialPrice, setInitialPrice] = useState(data.edition[0].price);
  const [finalPrice, setFinalPrice] = useState(null);
  const [num, setNum] = useState(1);
  const [sid, setSid] = useState(0);

  const { Option } = Select;

  const onSubmit = async () => {
    let amount = finalPrice ? finalPrice : initialPrice;
    if (amount === 0) {
      message.error("此版本已赠送，请勿重复购买！");
    } else {
      let para = queryString.stringify({
        name: "秒排宝购买",
        amount: amount,
        attach: `sso,order,${company.userId},${data.id},${edition.id},${
          num * 12
        }`,
      });
      let parsed = await ky
        .post("https://pay.chinaapus.com/order/submit", {
          body: new URLSearchParams(para),
        })
        .json();
      setSid(parsed.data);
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "autocloseload") {
        message.success("购买成功，如有问题请联系客服！");
        setVisible(false);
      }
    });
  }, []);

  return (
    <div className={styles.ShopB2b}>
      <div className={styles.info}>
        <div className={styles.col}>
          <div className={styles.pic}>
            <img src={b2bImg} alt="秒排宝" />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.title}>
            秒排宝
            {data.edition.map((value) =>
              value.id === edition.id ? (
                <span key={value.name}> / {value.name}</span>
              ) : null
            )}
          </div>
          <div className={styles.desc}>
            以云计算和搜索引擎优化为基础，高质量行业平台为支撑的新-代快速排名覆盖系统。三至七天万词首页，无需官网制作简单，自动发布缩减人工，页面美观询盘绑定。
          </div>
          <div className={styles.price}>
            总价格：<span>{finalPrice ? finalPrice : initialPrice}</span>/元
          </div>
          <div className={styles.edition}>
            <i>产品版本：</i>
            <Space>
              {data.edition.map((value) => (
                <Button
                  type={value.id === edition.id ? "primary" : "dashed"}
                  key={value.id}
                  size="small"
                  onClick={() => {
                    setEdition(value);
                    setNum(1);
                    setInitialPrice(value.price);
                    setFinalPrice(null);
                  }}
                >
                  {value.name}
                </Button>
              ))}
            </Space>
          </div>
          <div className={styles.term}>
            <i>产品期限：</i>
            <Select
              value={num}
              style={{ width: 120 }}
              size="small"
              onChange={(value) => {
                setNum(value);
                setFinalPrice(initialPrice * value);
              }}
            >
              <Option value={1}>1年</Option>
              <Option value={2}>2年</Option>
              <Option value={3}>3年</Option>
            </Select>
          </div>
          <div className={styles.buy}>
            <Button
              type="primary"
              danger
              icon={<CheckCircleFilled />}
              onClick={onSubmit}
            >
              立即购买
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          <span>版本介绍</span>
        </div>
        <div className={styles.content}>
          <div className={styles.col}>
            <div className={styles.colBox}>
              <h2>
                <span>体验会员</span>
              </h2>
              <h3>功能比较齐全，操作简单快捷，适合新手使用</h3>
            </div>
            <div className={styles.colInfo}>
              <h2>
                店铺尊贵标识
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺BANNER可修改
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺/产品去广告
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线客服
                <span>( 不可自定义 )</span>
              </h2>
              <h2>
                品牌
                <span>( 1个 )</span>
              </h2>
              <h2>
                产品发布
                <span>( 50条 )</span>
              </h2>
              <h2>
                商机发布
                <span>( 50条 )</span>
              </h2>
              <h2>
                公司相册
                <span>( 50M )</span>
              </h2>
              <h2>
                产品刷新
                <span>( 单条刷新 )</span>
              </h2>
              <h2>
                超级名片
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                超级写稿
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                平台双端旺铺
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                200家合作站
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                朋友圈推广神器
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                口令营销
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                发信息无需验证码
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                自定义公司模板
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                SEO助手
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                留言板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                流量统计
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线地图
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.colBox}>
              <h2>
                <MedalsSvg />
                <span>铜牌会员</span>
                <img src={viptime} />
              </h2>
              <h3>买两年送一年，信息总量翻倍，仅限一个帐号内</h3>
            </div>
            <div className={styles.colInfo}>
              <h2>
                店铺尊贵标识
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺BANNER可修改
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺/产品去广告
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线客服
                <span>( 可接入任何客服系统 )</span>
              </h2>
              <h2>
                品牌
                <span>( 2个 )</span>
              </h2>
              <h2>
                产品发布
                <span>( 5000条 )</span>
              </h2>
              <h2>
                商机发布
                <span>( 5000条 )</span>
              </h2>
              <h2>
                公司相册
                <span>( 500M )</span>
              </h2>
              <h2>
                产品刷新
                <span>( 批量刷新 )</span>
              </h2>
              <h2>
                超级名片
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                超级写稿
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                平台双端旺铺
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                200家合作站
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                朋友圈推广神器
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                口令营销
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                发信息无需验证码
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                自定义公司模板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                SEO助手
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                留言板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                流量统计
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线地图
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.colBox}>
              <h2>
                <MedalsSvg />
                <span>银牌会员</span>
                <img src={viptime} />
              </h2>
              <h3>买两年送一年，信息总量翻倍，仅限一个帐号内</h3>
            </div>
            <div className={styles.colInfo}>
              <h2>
                店铺尊贵标识
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺BANNER可修改
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺/产品去广告
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线客服
                <span>( 可接入任何客服系统 )</span>
              </h2>
              <h2>
                品牌
                <span>( 5个 )</span>
              </h2>
              <h2>
                产品发布
                <span>( 8000条 )</span>
              </h2>
              <h2>
                商机发布
                <span>( 8000条 )</span>
              </h2>
              <h2>
                公司相册
                <span>( 800M )</span>
              </h2>
              <h2>
                产品刷新
                <span>( 批量刷新 )</span>
              </h2>
              <h2>
                超级名片
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                超级写稿
                <span>
                  <CloseOutlined style={{ color: "red", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                平台双端旺铺
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                200家合作站
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                朋友圈推广神器
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                口令营销
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                发信息无需验证码
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                自定义公司模板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                SEO助手
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                留言板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                流量统计
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线地图
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.colBox}>
              <h2>
                <MedalsSvg />
                <span>金牌会员</span>
                <img src={viptime} />
              </h2>
              <h3>买两年送一年，信息总量翻倍，仅限一个帐号内</h3>
            </div>
            <div className={styles.colInfo}>
              <h2>
                店铺尊贵标识
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺BANNER可修改
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺/产品去广告
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线客服
                <span>( 可接入任何客服系统 )</span>
              </h2>
              <h2>
                品牌
                <span>( 10个 )</span>
              </h2>
              <h2>
                产品发布
                <span>( 10000条 )</span>
              </h2>
              <h2>
                商机发布
                <span>( 10000条 )</span>
              </h2>
              <h2>
                公司相册
                <span>( 1000M )</span>
              </h2>
              <h2>
                产品刷新
                <span>( 批量刷新 )</span>
              </h2>
              <h2>
                超级名片
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                超级写稿
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                平台双端旺铺
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                200家合作站
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                朋友圈推广神器
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                口令营销
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                发信息无需验证码
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                自定义公司模板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                SEO助手
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                留言板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                流量统计
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线地图
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.colBox}>
              <h2>
                <SketchSvg />
                <span>钻石会员</span>
                <img src={viptime} />
              </h2>
              <h3>买两年送一年，信息总量翻倍，仅限一个帐号内</h3>
            </div>
            <div className={styles.colInfo}>
              <h2>
                店铺尊贵标识
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺BANNER可修改
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                商铺/产品去广告
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线客服
                <span>( 可接入任何客服系统 )</span>
              </h2>
              <h2>
                品牌
                <span>( 20个 )</span>
              </h2>
              <h2>
                产品发布
                <span>( 20000条 )</span>
              </h2>
              <h2>
                商机发布
                <span>( 20000条 )</span>
              </h2>
              <h2>
                公司相册
                <span>( 2000M )</span>
              </h2>
              <h2>
                产品刷新
                <span>( 批量刷新 )</span>
              </h2>
              <h2>
                超级名片
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                超级写稿
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                平台双端旺铺
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                200家合作站
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                朋友圈推广神器
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                口令营销
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                发信息无需验证码
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                自定义公司模板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                SEO助手
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                留言板
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                流量统计
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
              <h2>
                在线地图
                <span>
                  <CheckOutlined style={{ color: "green", fontSize: 14 }} />
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="微信扫码支付"
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        footer={null}
        centered
      >
        <div className={styles.order}>
          <div className={styles.ifo}>
            <p>
              购买产品：
              <span>
                秒排宝（
                {data.edition.map((value) =>
                  value.id === edition.id ? value.name : null
                )}
                ）
              </span>
            </p>
            <p>
              购买期限：<span>{num}年</span>
            </p>
            <p>
              支付金额：<span>{finalPrice ? finalPrice : initialPrice}元</span>
            </p>
            <p>申请发票，付款成功后联系客服(13065554315)处理</p>
          </div>
          <div className={styles.ewm}>
            <p>
              {sid !== 0 ? (
                <iframe
                  src={`https://pay.chinaapus.com/?serial_id=${sid}&size=120`}
                  width="120px"
                  height="120px"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              ) : (
                <Spin tip="付款码生成中..." />
              )}
            </p>
            <p>
              <img src={wxPay} />
              <span>微信支付</span>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ShopB2b;
