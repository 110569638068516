import React, { useState, useEffect } from "react";

import { Tabs, message, Spin } from "antd";

import { Http } from "../../Http";
import queryString from "query-string";

import ShopSbw from "./shop/ShopSbw";
import ShopNlp from "./shop/ShopNlp";
import ShopDns from "./shop/ShopDns";
import ShopApp from "./shop/ShopApp";
import ShopPom from "./shop/ShopPom";
import ShopB2b from "./shop/ShopB2b";
import ShopMdd from "./shop/ShopMdd";
import ShopDpd from "./shop/ShopDpd";
import ShopSts from "./shop/ShopSts";

import styles from "./Shop.module.less";

function Shop({ location, company }) {
  let search = queryString.parse(location.search);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(search.active ? search.active : "1");

  const { TabPane } = Tabs;

  const getEdition = async (id) => {
    let para = queryString.stringify({ shopId: id });
    let parsed = await Http.get(`shop/edition/list`, {
      searchParams: para,
    }).json();
    if (parsed.state === "ok") {
      return parsed.result;
    }
  };

  const getShopList = async () => {
    let parsed = await Http.get(`shop/list`, {}).json();
    if (parsed.state === "ok") {
      for (let i = 0; i < parsed.result.length; i++) {
        let v = parsed.result[i];
        v.edition = await getEdition(v.id);
        if (parsed.result[i].id === 9) {
          parsed.result[i].alias = ShopSts;
        }
        if (parsed.result[i].id === 8) {
          parsed.result[i].alias = ShopDpd;
        }
        if (parsed.result[i].id === 7) {
          parsed.result[i].alias = ShopMdd;
        }
        if (parsed.result[i].id === 6) {
          parsed.result[i].alias = ShopB2b;
        }
        if (parsed.result[i].id === 5) {
          parsed.result[i].alias = ShopPom;
        }
        if (parsed.result[i].id === 4) {
          parsed.result[i].alias = ShopApp;
        }
        if (parsed.result[i].id === 3) {
          parsed.result[i].alias = ShopDns;
        }
        if (parsed.result[i].id === 2) {
          parsed.result[i].alias = ShopNlp;
        }
        if (parsed.result[i].id === 1) {
          parsed.result[i].alias = ShopSbw;
        }
      }
      setData(parsed.result);
    }
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getShopList();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoading();
  }, []);

  return (
    <div className={styles.Shop}>
      {loading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <Tabs
          type="card"
          activeKey={current}
          onChange={(value) => setCurrent(value)}
        >
          {data.map((value) => (
            <TabPane tab={value.name} key={value.id} forceRender={true}>
              <value.alias data={value} company={company} />
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
}

export default Shop;
