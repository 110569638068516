import React, { useState, useEffect } from "react";

import { Form, Input, Button, message, Spin, Radio } from "antd";

import { FormOutlined } from "@ant-design/icons";

import { Http } from "../../Http";
import queryString from "query-string";

import Thumb from "../../components/Thumb";
import Album from "../../components/Album";

import styles from "./User.module.less";

function User() {
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [album, setAlbum] = useState({
    visible: false,
    limit: 1,
    field: "",
  });

  const [form] = Form.useForm();

  const getUser = async () => {
    let parsed = await Http.get(`user/self`, {}).json();
    if (parsed.state === "ok") {
      form.setFieldsValue(parsed.result);
    }
  };

  const onAlbumOk = (values) => {
    setAlbum({ ...album, visible: false });
    let val = {};
    val[album.field] = values[0];
    form.setFieldsValue(val);
  };

  const onLoading = async () => {
    setLoading(true);
    try {
      await getUser();
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setSubmit(true);
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/self`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("提交成功");
        window.location.reload();
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setSubmit(false);
    }
  };

  useEffect(() => {
    onLoading();
  }, []);

  return (
    <div className={styles.User}>
      <div className={styles.header}>
        <FormOutlined style={{ fontSize: 16, color: "#1890ff" }} />
        <i>个人资料</i>
      </div>
      <div className={styles.content}>
        {loading ? (
          <Spin size="large" tip="加载中..." style={{ width: "100%" }} />
        ) : (
          <Form
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="用户名"
              rules={[{ required: true, message: "请输入用户名!" }]}
            >
              <Input disabled placeholder="请输入用户名" />
            </Form.Item>

            <Form.Item
              name="cellphone"
              label="手机号"
              rules={[
                {
                  required: true,
                  pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                  message: "请输入正确的手机号！",
                },
              ]}
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item
              name="email"
              label="电子邮箱"
              rules={[{ required: true, message: "请输入电子邮箱!" }]}
            >
              <Input placeholder="请输入电子邮箱" />
            </Form.Item>

            <Form.Item
              name="gender"
              label="性别"
              rules={[{ required: true, message: "请选择状态!" }]}
            >
              <Radio.Group>
                <Radio value={0}>男</Radio>
                <Radio value={1}>女</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="avatar" label="头像">
              <Thumb
                title="头像"
                onClick={() =>
                  setAlbum({ ...album, visible: true, field: "avatar" })
                }
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 2 }}>
              <Button
                type="primary"
                disabled={submit}
                loading={submit}
                htmlType="submit"
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
      <Album
        visible={album.visible}
        limit={album.limit}
        onOk={onAlbumOk}
        onCancel={() => setAlbum({ ...album, visible: false })}
      />
    </div>
  );
}

export default User;
