import React, { useState } from "react";

import { Modal } from "antd";

import { CloudUploadOutlined, EyeFilled, EditFilled } from "@ant-design/icons";

import styles from "./Thumb.module.less";

function Thumb(value) {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.Thumb}>
      <div className={styles.content}>
        {value.value ? (
          <div className={styles.picture}>
            <img src={value.value} alt="" />
            <div className={styles.handle}>
              <EyeFilled onClick={() => setVisible(true)} />
              <EditFilled onClick={value.onClick} />
            </div>
          </div>
        ) : (
          <div className={styles.upload} onClick={value.onClick}>
            <CloudUploadOutlined style={{ color: "#cccccc", fontSize: 36 }} />
            <i>{value.title}</i>
          </div>
        )}
      </div>
      <Modal
        title="图片查看"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={850}
        centered
      >
        <div className={styles.SeePic}>
          <img src={value.value} alt="图片查看" />
        </div>
      </Modal>
    </div>
  );
}

export default Thumb;
