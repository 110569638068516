import React, { useState, useEffect, Fragment } from "react";

import {
  Button,
  Card,
  Radio,
  Table,
  Tag,
  message,
  Tabs,
  Skeleton,
  Alert,
} from "antd";

import { ShareAltOutlined } from "@ant-design/icons";

import Ky from "ky";
import { Http } from "../Http";
import queryString from "query-string";

import { truncate } from "../Utils";

import moment from "moment";

import styles from "./Report.module.less";

function Report({ location }) {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(null);
  const [real, setReal] = useState({});
  const [link, setLink] = useState(null);
  const [count, setCount] = useState({
    news: 0,
    product: 0,
    media: 0,
    tieba: 0,
    zhidao: 0,
    group: 0,
    scan: 0,
  });
  const [brandActive, setBrandActive] = useState(0);
  const [brands, setBrands] = useState([]);

  const [b2bLoading, setB2bLoading] = useState(true);
  const [b2bNewsData, setB2bNewsData] = useState({});
  const [b2bProductData, setB2bProductData] = useState({});
  const [b2bNewsPagination, setB2bNewsPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [b2bProductPagination, setB2bProductPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [mediaLoading, setMediaLoading] = useState(true);
  const [mediaData, setMediaData] = useState({});
  const [mediaPagination, setMediaPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [tiebaLoading, setTiebaLoading] = useState(true);
  const [tiebaData, setTiebaData] = useState({});
  const [tiebaPagination, setTiebaPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [groupLoading, setGroupLoading] = useState(true);
  const [groupData, setGroupData] = useState({});
  const [groupPagination, setGroupPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [scanLoading, setScanLoading] = useState(true);
  const [scanData, setScanData] = useState({});
  const [scanPagination, setScanPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [optimizeLoading, setOptimizeLoading] = useState(true);
  const [optimizeData, setOptimizeData] = useState({});
  const [optimizePagination, setOptimizePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { TabPane } = Tabs;

  const convertTime = (value) => {
    return moment(value).format("YYYY-MM-DD");
  };

  const mpbNewsColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 100,
      ellipsis: true,
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (value, data) => (
        <a target="_blank" href={data.url}>
          {value}
        </a>
      ),
    },
    {
      title: "时间",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 180,
      ellipsis: true,
      render: (value) => {
        return convertTime(value);
      },
    },
    {
      title: "收录（检测功能暂未开通）",
      dataIndex: "included",
      key: "included",
      ellipsis: true,
      width: 220,
      render: (_, data) => {
        return (
          <Fragment>
            <Tag
              style={{
                color: data.baidu !== 0 ? null : "#999",
              }}
              color={data.baidu !== 0 ? "#108ee9" : null}
              title={data.baidu !== 0 ? "已收录" : "未收录"}
            >
              百度
            </Tag>
            <Tag
              style={{
                color: data.so !== 0 ? null : "#999",
              }}
              color={data.so !== 0 ? "#87d068" : null}
              title={data.so !== 0 ? "已收录" : "未收录"}
            >
              360
            </Tag>
            <Tag
              style={{
                color: data.sogou !== 0 ? null : "#999",
              }}
              color={data.sogou !== 0 ? "#2db7f5" : null}
              title={data.sogou !== 0 ? "已收录" : "未收录"}
            >
              搜狗
            </Tag>
            <Tag
              style={{
                color: data.sm !== 0 ? null : "#999",
              }}
              color={data.sm !== 0 ? "#f50" : null}
              title={data.sm !== 0 ? "已收录" : "未收录"}
            >
              神马
            </Tag>
          </Fragment>
        );
      },
    },
  ];

  const mpbProdcutColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 100,
      ellipsis: true,
    },
    {
      title: "产品",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (value, data) => (
        <a target="_blank" href={data.url}>
          {value}
        </a>
      ),
    },
    {
      title: "时间",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 180,
      ellipsis: true,
    },
    {
      title: "收录（检测功能暂未开通）",
      dataIndex: "included",
      key: "included",
      ellipsis: true,
      width: 220,
      render: (_, data) => {
        return (
          <Fragment>
            <Tag
              style={{
                color: data.baidu !== 0 ? null : "#999",
              }}
              color={data.baidu !== 0 ? "#108ee9" : null}
              title={data.baidu !== 0 ? "已收录" : "未收录"}
            >
              百度
            </Tag>
            <Tag
              style={{
                color: data.so !== 0 ? null : "#999",
              }}
              color={data.so !== 0 ? "#87d068" : null}
              title={data.so !== 0 ? "已收录" : "未收录"}
            >
              360
            </Tag>
            <Tag
              style={{
                color: data.sogou !== 0 ? null : "#999",
              }}
              color={data.sogou !== 0 ? "#2db7f5" : null}
              title={data.sogou !== 0 ? "已收录" : "未收录"}
            >
              搜狗
            </Tag>
            <Tag
              style={{
                color: data.sm !== 0 ? null : "#999",
              }}
              color={data.sm !== 0 ? "#f50" : null}
              title={data.sm !== 0 ? "已收录" : "未收录"}
            >
              神马
            </Tag>
          </Fragment>
        );
      },
    },
  ];

  const mediaColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "链接",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
    },
    {
      title: "计划",
      dataIndex: ["brand", "name"],
      key: ["brand", "name"],
      ellipsis: true,
      width: 160,
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      width: 160,
      render: (value, data) => (
        <a href={data.url} target="_blank">
          详情
        </a>
      ),
    },
  ];

  const tiebaColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "标题",
      dataIndex: "x1",
      key: "x1",
      ellipsis: true,
    },
    {
      title: "链接",
      dataIndex: "x2",
      key: "x2",
      ellipsis: true,
    },
    {
      title: "平台",
      dataIndex: "x3",
      key: "x3",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      render: (value) => <a>详情</a>,
    },
  ];

  const groupColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "标题",
      dataIndex: "x1",
      key: "x1",
      ellipsis: true,
    },
    {
      title: "链接",
      dataIndex: "x2",
      key: "x2",
      ellipsis: true,
    },
    {
      title: "发布网站",
      dataIndex: "x3",
      key: "x3",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      render: (value) => <a>详情</a>,
    },
  ];

  const ScanColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "搜索词",
      dataIndex: "word",
      key: "word",
      ellipsis: true,
    },
    {
      title: "链接/内容",
      dataIndex: "str",
      key: "str",
      ellipsis: true,
    },
    {
      title: "计划",
      dataIndex: ["brand", "name"],
      key: ["brand", "name"],
      ellipsis: true,
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      render: (value) => {
        if (value === 1) return <Tag color="magenta">相关下来</Tag>;
        if (value === 2) return <Tag color="volcano">快照屏蔽</Tag>;
        if (value === 3) return <Tag color="geekblue">负面删除</Tag>;
        if (value === 4) return <Tag color="purple">评论删除</Tag>;
        return null;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      render: (value) => {
        if (value === 0) return <Tag color="blue">待处理</Tag>;
        if (value === 1) return <Tag color="orange">处理中</Tag>;
        if (value === 2) return <Tag color="green">已处理</Tag>;
        if (value === 3) return <Tag color="red">死单</Tag>;
        return null;
      },
    },
  ];

  const optimizeColumns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "关键词",
      dataIndex: "x1",
      key: "x1",
      ellipsis: true,
    },
    {
      title: "搜索引擎",
      dataIndex: "x2",
      key: "x2",
      ellipsis: true,
      render: (value) => {
        if (value === 1) return <Tag color="blue">百度</Tag>;
        if (value === 2) return <Tag color="green">360</Tag>;
        if (value === 3) return <Tag color="red">搜狗</Tag>;
        if (value === 4) return <Tag color="orange">神马</Tag>;
        return null;
      },
    },
    {
      title: "当前排名",
      dataIndex: "x3",
      key: "x3",
      ellipsis: true,
      render: (value) => <span>第{value}名</span>,
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      render: (value) => <a>查看排名</a>,
    },
  ];

  const onLoading = async () => {
    setLoading(true);
    let search = queryString.parse(location.search);
    if (search.token) {
      try {
        //let x = await Ky.get(`https://httpbin.org/ip`, {
        //  searchParams: { token: search.token },
        //}).json();
        let parsed = await Http.get("report/token", {
          searchParams: { token: search.token },
        }).json();
        if (parsed.state === "ok") {
          await message.success("登陆成功");
          setAuthenticated(parsed.authenticated);
        } else {
          await message.warn(parsed.message ? parsed.message : "登陆出现错误");
        }
      } catch (error) {
        await message.error("接口出现错误, 错误原因:" + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        let parsed = await Http.get("report", {
          searchParams: {},
        }).json();
        if (parsed.state === "ok") {
          await message.success("登陆成功");
          setAuthenticated(parsed.authenticated);
        } else {
          await message.warn(parsed.message ? parsed.message : "登陆出现错误");
        }
      } catch (error) {
        await message.error("接口出现错误, 错误原因:" + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const onReal = async () => {
    try {
      let parsed = await Http.get("real/self", {
        searchParams: { authenticated },
      }).json();
      if (parsed.state === "ok") {
        setReal(parsed.result);
      }
    } catch (error) {
      await message.error("接口出现错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onShare = async () => {
    let para = queryString.stringify({ authenticated });
    try {
      let parsed = await Http.post(`report/share`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("链接获取成功！");
        setLink(parsed.result);
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const shareLink = (value) => {
    let message = `请复制此链接：${location.href}?token=${value}`;
    return (
      <div
        className={styles.shareLink}
        style={{ height: value ? 56 : 0, paddingBottom: value ? 16 : 0 }}
      >
        {value ? (
          <Alert
            message={message}
            type="info"
            afterClose={() => setLink(null)}
            closable
          />
        ) : null}
      </div>
    );
  };

  const onB2bCount = async () => {
    try {
      let para = queryString.stringify({ authenticated });
      let parsed = await Ky.get(
        `https://b2b.chinaqyz.com/api/webmaster/report/count`,
        {
          searchParams: para,
        }
      ).json();
      if (parsed.state === "ok") {
        setCount({ ...count, ...parsed.result });
      }
    } catch (error) {
      await message.error("接口出现错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onB2bBrandLoading = async () => {
    setLoading(true);
    try {
      let para = queryString.stringify({ authenticated });
      let parsed = await Ky.get(`https://b2b.chinaqyz.com/api/webmaster/report`, {
        searchParams: para,
      }).json();
      if (parsed.state === "ok") {
        setBrands(parsed.result);
        parsed.result.forEach((value, key) => {
          if (key === 0) {
            setBrandActive(value.id);
          }
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onB2bNewsLoading = async (params) => {
    setB2bLoading(true);
    try {
      let para = queryString.stringify({
        authenticated: authenticated,
        brandId: brandActive,
        pageNumber: params.b2bNewsPagination.current,
        pageSize: params.b2bNewsPagination.pageSize,
      });
      let parsed = await Ky.get(
        `https://b2b.chinaqyz.com/api/webmaster/report/news`,
        {
          searchParams: para,
        }
      ).json();
      if (parsed.state === "ok") {
        setB2bNewsData(parsed.result);
        setB2bNewsPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setB2bLoading(false);
    }
  };

  const onB2bProductLoading = async (params) => {
    try {
      let para = queryString.stringify({
        authenticated: authenticated,
        brandId: brandActive,
        pageNumber: params.b2bProductPagination.current,
        pageSize: params.b2bProductPagination.pageSize,
      });
      let parsed = await Ky.get(
        `https://b2b.chinaqyz.com/api/webmaster/report/product`,
        {
          searchParams: para,
        }
      ).json();
      if (parsed.state === "ok") {
        setB2bProductData(parsed.result);
        setB2bProductPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
    }
  };

  const onMediaLoading = async (params) => {
    setMediaLoading(true);
    try {
      let para = queryString.stringify({
        authenticated: authenticated,
        pageNumber: params.mediaPagination.current,
        pageSize: params.mediaPagination.pageSize,
      });
      let parsed = await Ky.get(
        `https://b2b.chinaqyz.com/api/webmaster/report/media`,
        {
          searchParams: para,
        }
      ).json();
      if (parsed.state === "ok") {
        setMediaData(parsed.result);
        setMediaPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setMediaLoading(false);
    }
  };

  const onTiebaLoading = async () => {
    setTiebaLoading(false);
    setTiebaData({
      list: [],
    });
  };

  const onGroupLoading = async () => {
    setGroupLoading(false);
    setGroupData({
      list: [],
    });
  };

  const onScanLoading = async (params) => {
    setScanLoading(true);
    try {
      let para = queryString.stringify({
        authenticated: authenticated,
        pageNumber: params.scanPagination.current,
        pageSize: params.scanPagination.pageSize,
      });
      let parsed = await Ky.get(
        `https://b2b.chinaqyz.com/api/webmaster/report/scan`,
        {
          searchParams: para,
        }
      ).json();
      if (parsed.state === "ok") {
        setScanData(parsed.result);
        setScanPagination({
          current: parsed.result.pageNumber,
          pageSize: parsed.result.pageSize,
          total: parsed.result.totalRow,
        });
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setScanLoading(false);
    }
  };

  const onOptimizeLoading = async () => {
    setOptimizeLoading(false);
    setOptimizeData({
      list: [],
    });
  };

  useEffect(() => {
    onLoading();
  }, []);

  useEffect(() => {
    if (authenticated) {
      onReal();
      onB2bCount();
      onB2bBrandLoading();
      onMediaLoading({ mediaPagination });
      onTiebaLoading({ tiebaPagination });
      onGroupLoading({ groupPagination });
      onScanLoading({ scanPagination });
      onOptimizeLoading({ optimizePagination });
    }
  }, [authenticated]);

  useEffect(() => {
    if (brandActive !== 0) {
      onB2bNewsLoading({ b2bNewsPagination });
      onB2bProductLoading({ b2bProductPagination });
    }
  }, [brandActive]);

  return (
    <div className={styles.Report}>
      <div className={styles.header}>效果统计</div>
      <div className={styles.content}>
        {shareLink(link)}
        <div className={styles.share}>
          <Skeleton
            loading={loading}
            title={false}
            paragraph={{ rows: 2, width: "100%" }}
            active
          >
            <div className={styles.name}>
              <h2>{real?.name ? real.name : "未进行企业实名认证"}</h2>
              <h3>
                开通日期：{real?.createdTime ? real.createdTime : "-----------"}
              </h3>
            </div>
            {location.search ? null : (
              <Button
                type="primary"
                icon={<ShareAltOutlined />}
                onClick={() => onShare()}
              >
                链接分享
              </Button>
            )}
          </Skeleton>
        </div>
        <div className={styles.info}>
          <div className={styles.col}>
            <span>
              秒排宝<i>(条)</i>
            </span>
            <span>
              {count.news + count.product > 0
                ? count.news + count.product
                : "-"}
            </span>
          </div>
          {/* <div className={styles.col}>
            <span>
              新闻发布<i>(条)</i>
            </span>
            <span>{count.media > 0 ? count.media : "-"}</span>
          </div>
          <div className={styles.col}>
            <span>
              贴吧问答<i>(条)</i>
            </span>
            <span>
              {count.tieba + count.zhidao > 0
                ? count.tieba + count.zhidao
                : "-"}
            </span>
          </div>
          <div className={styles.col}>
            <span>
              企业群站<i>(条)</i>
            </span>
            <span>{count.group > 0 ? count.group : "-"}</span>
          </div>
          <div className={styles.col}>
            <span>
              舆情监控<i>(条)</i>
            </span>
            <span>{count.scan > 0 ? count.scan : "-"}</span>
          </div>
          <div className={styles.col}>
            <span>
              已优化<i>(条)</i>
            </span>
            <span>-</span>
          </div> */}
        </div>
        <Card
          title="秒排宝"
          bordered={false}
          extra={
            <Radio.Group
              value={brandActive}
              onChange={(e) => setBrandActive(e.target.value)}
            >
              {brands.map((value) => (
                <Radio.Button value={value.id} key={value.id}>
                  {truncate(value.name, 8, "...")}
                </Radio.Button>
              ))}
            </Radio.Group>
          }
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="商机" key="1" forceRender={true}>
              <Table
                loading={b2bLoading}
                rowKey="id"
                onChange={(p, f, s) => {
                  onB2bNewsLoading({
                    b2bNewsPagination: p,
                  });
                }}
                dataSource={b2bNewsData.list}
                columns={mpbNewsColumns}
                pagination={{
                  current: b2bNewsPagination.current,
                  pageSize: b2bNewsPagination.pageSize,
                  total: b2bNewsPagination.total,
                  showTotal: () => {
                    return `总共 ${b2bNewsPagination.total} 条数据`;
                  },
                }}
              />
            </TabPane>
            <TabPane tab="产品" key="2" forceRender={true}>
              <Table
                loading={b2bLoading}
                rowKey="id"
                onChange={(p, f, s) => {
                  onB2bProductLoading({
                    b2bProductPagination: p,
                  });
                }}
                dataSource={b2bProductData.list}
                columns={mpbProdcutColumns}
                pagination={{
                  current: b2bProductPagination.current,
                  pageSize: b2bProductPagination.pageSize,
                  total: b2bProductPagination.total,
                  showTotal: () => {
                    return `总共 ${b2bProductPagination.total} 条数据`;
                  },
                }}
              />
            </TabPane>
          </Tabs>
        </Card>
        {/* <Card title="新闻发布" bordered={false}>
          <Table
            loading={mediaLoading}
            rowKey="id"
            onChange={(p, f, s) => {
              onMediaLoading({
                mediaPagination: p,
              });
            }}
            dataSource={mediaData.list}
            columns={mediaColumns}
            pagination={mediaPagination}
          />
        </Card>
        <Card title="贴吧问答" bordered={false}>
          <Table
            loading={tiebaLoading}
            rowKey="id"
            onChange={(p, f, s) => {
              onTiebaLoading({
                tiebaPagination: p,
              });
            }}
            dataSource={tiebaData.list}
            columns={tiebaColumns}
            pagination={tiebaPagination}
          />
        </Card>
        <Card title="企业群站" bordered={false}>
          <Table
            loading={groupLoading}
            rowKey="id"
            onChange={(p, f, s) => {
              onGroupLoading({
                groupPagination: p,
              });
            }}
            dataSource={groupData.list}
            columns={groupColumns}
            pagination={groupPagination}
          />
        </Card>
        <Card title="舆情监控" bordered={false}>
          <Table
            loading={scanLoading}
            rowKey="id"
            onChange={(p, f, s) => {
              onScanLoading({
                scanPagination: p,
              });
            }}
            dataSource={scanData.list}
            columns={ScanColumns}
            pagination={scanPagination}
          />
        </Card>
        <Card title="总优化" bordered={false}>
          <Table
            loading={optimizeLoading}
            rowKey="id"
            onChange={(p, f, s) => {
              onOptimizeLoading({
                optimizePagination: p,
              });
            }}
            dataSource={optimizeData.list}
            columns={optimizeColumns}
            pagination={optimizePagination}
          />
        </Card> */}
      </div>
    </div>
  );
}

export default Report;
