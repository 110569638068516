import React, { useState } from "react";

import { Form, Input, Button, message } from "antd";

import { FormOutlined } from "@ant-design/icons";

import { Http } from "../../Http";
import queryString from "query-string";

import styles from "./Password.module.less";

function Password() {
  const [submit, setSubmit] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setSubmit(true);
    let para = queryString.stringify(values);
    try {
      let parsed = await Http.post(`user/password`, {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        await message.success("提交成功");
      } else {
        await message.warn(parsed.message ? parsed.message : "提交失败");
      }
    } catch (error) {
      await message.error("接口调用错误, 错误原因:" + error.message);
    } finally {
      setSubmit(false);
    }
  };

  return (
    <div className={styles.Password}>
      <div className={styles.header}>
        <FormOutlined style={{ fontSize: 16, color: "#1890ff" }} />
        <i>修改密码</i>
      </div>
      <div className={styles.content}>
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 8 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="oldPwd"
            label="旧密码"
            rules={[{ required: true, message: "请输入旧密码！" }]}
          >
            <Input.Password placeholder="请输入旧密码" />
          </Form.Item>
          <Form.Item
            name="newPwd"
            label="新密码"
            rules={[{ required: true, message: "请输入新密码！" }]}
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item
            name="repPwd"
            label="确认密码"
            rules={[{ required: true, message: "请再次输入密码!" }]}
          >
            <Input.Password placeholder="请再次输入密码" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 2 }}>
            <Button
              type="primary"
              disabled={submit}
              loading={submit}
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Password;
