import React, { useState, useEffect } from "react";

import { Space, Button, Select, Modal, Spin, message } from "antd";

import { CheckCircleFilled } from "@ant-design/icons";

import ky from "ky";
import queryString from "query-string";

import mddImg from "../../../images/mdd_img.jpg";
import mddIntro from "../../../images/mdd_intro.jpg";
import wxPay from "../../../images/wx_pay.png";

import styles from "./ShopMdd.module.less";

function ShopMdd({ data, company }) {
  const [visible, setVisible] = useState(false);
  const [edition, setEdition] = useState(data.edition[0]);
  const [initialPrice, setInitialPrice] = useState(data.edition[0].price);
  const [finalPrice, setFinalPrice] = useState(null);
  const [num, setNum] = useState(1);
  const [sid, setSid] = useState(0);

  const { Option } = Select;

  const onSubmit = async () => {
    let amount = finalPrice ? finalPrice : initialPrice;
    if (amount === 0) {
      message.error("此版本已赠送，请勿重复购买！");
    } else {
      let para = queryString.stringify({
        name: "主图设计购买",
        amount: amount,
        attach: `sso,order,${company.userId},${data.id},${edition.id},${
          num * 12
        }`,
      });
      let parsed = await ky
        .post("https://pay.chinaapus.com/order/submit", {
          body: new URLSearchParams(para),
        })
        .json();
      setSid(parsed.data);
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "autocloseload") {
        message.success("购买成功，如有问题请联系客服！");
        setVisible(false);
      }
    });
  }, []);

  return (
    <div className={styles.ShopMdd}>
      <div className={styles.info}>
        <div className={styles.col}>
          <div className={styles.pic}>
            <img src={mddImg} alt="主图设计" />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.title}>
            主图设计
            {data.edition.map((value) =>
              value.id === edition.id ? (
                <span key={value.name}> / {value.name}</span>
              ) : null
            )}
          </div>
          <div className={styles.desc}>
            ★卖点提炼 ★吸引买家点击 ★提升客户转化
            ★引爆搜索流量，一个高点击率的产品，不仅要有高颜值的产品展示图，还需要文案与顾客产生共鸣才会让他从万千产品中挑中你的产品
          </div>
          <div className={styles.price}>
            总价格：<span>{finalPrice ? finalPrice : initialPrice}</span>/元
          </div>
          <div className={styles.edition}>
            <i>产品版本：</i>
            <Space>
              {data.edition.map((value) => (
                <Button
                  type={value.id === edition.id ? "primary" : "dashed"}
                  key={value.id}
                  size="small"
                  onClick={() => {
                    setEdition(value);
                    setNum(1);
                    setInitialPrice(value.price);
                    setFinalPrice(null);
                  }}
                >
                  {value.name}
                </Button>
              ))}
            </Space>
          </div>
          <div className={styles.term}>
            <i>产品期限：</i>
            <Select
              value={num}
              style={{ width: 120 }}
              size="small"
              onChange={(value) => {
                setNum(value);
                setFinalPrice(initialPrice * value);
              }}
            >
              <Option value={1}>1年</Option>
              <Option value={2}>2年</Option>
              <Option value={3}>3年</Option>
            </Select>
          </div>
          <div className={styles.buy}>
            <Button
              type="primary"
              danger
              icon={<CheckCircleFilled />}
              onClick={onSubmit}
            >
              立即购买
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          <span>产品介绍</span>
        </div>
        <div className={styles.title}>
          <img src={mddIntro} alt="产品介绍" />
        </div>
      </div>
      <Modal
        title="微信扫码支付"
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        footer={null}
        centered
      >
        <div className={styles.order}>
          <div className={styles.ifo}>
            <p>
              购买产品：
              <span>
                主图设计（
                {data.edition.map((value) =>
                  value.id === edition.id ? value.name : null
                )}
                ）
              </span>
            </p>
            <p>
              购买期限：<span>{num}年</span>
            </p>
            <p>
              支付金额：<span>{finalPrice ? finalPrice : initialPrice}元</span>
            </p>
            <p>主图设计与申请发票，付款成功后联系客服(13065554315)处理</p>
          </div>
          <div className={styles.ewm}>
            <p>
              {sid !== 0 ? (
                <iframe
                  src={`https://pay.chinaapus.com/?serial_id=${sid}&size=120`}
                  width="120px"
                  height="120px"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              ) : (
                <Spin tip="付款码生成中..." />
              )}
            </p>
            <p>
              <img src={wxPay} />
              <span>微信支付</span>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ShopMdd;
