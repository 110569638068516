import React, { useState, useEffect } from "react";

import { useNavigate, useLocation, Link } from "@reach/router";

import { Button, Input, Form, message, Checkbox, Modal } from "antd";

import { Server, Http } from "../Http";
import queryString from "query-string";

import {
  UserOutlined,
  CheckCircleFilled,
  UnlockOutlined,
  SafetyCertificateOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import styles from "./Signup.module.less";

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const [form] = Form.useForm();

  const onSms = async () => {
    let captcha = form.getFieldValue("captcha");
    let cellphone = form.getFieldValue("cellphone");
    let para = queryString.stringify({ captcha, cellphone });
    try {
      let parsed = await Http.post("sms", {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        setCountdown(60);
        await message.success("短信已发出,注意查收");
      } else {
        await message.warn(
          parsed.message ? parsed.message : "发送短信出现错误"
        );
      }
    } catch (error) {
      await message.error("注册出现错误, 错误原因:" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let search = queryString.parse(location.search);
    let para = queryString.stringify({ ...values, ...search });
    try {
      let parsed = await Http.post("signup", {
        body: new URLSearchParams(para),
      }).json();
      if (parsed.state === "ok") {
        setSuccess(true);
      } else {
        if (parsed.captchaMsg) {
          await message.warn(parsed.captchaMsg);
          form.setFieldsValue({ captcha: "" });
        } else if (parsed.smsMsg) {
          await message.warn(parsed.smsMsg);
          form.setFieldsValue({ captcha: "", code: "" });
        } else {
          await message.warn(parsed.message ? parsed.message : "注册出现错误");
          form.setFieldsValue({ captcha: "", code: "" });
        }
        onCaptcha();
      }
    } catch (error) {
      await message.error("注册出现错误, 错误原因:" + error.message);
      onCaptcha();
      form.setFieldsValue({ captcha: "", code: "" });
    } finally {
      setLoading(false);
    }
  };

  const onCaptcha = () => {
    let code = Server + "/captcha?_=" + new Date().getTime();
    setCaptcha(code);
  };

  useEffect(() => {
    onCaptcha();
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      let handle = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => {
        clearInterval(handle);
      };
    }
  }, [countdown]);

  return (
    <div className={styles.Signup}>
      <div className={styles.bigBox}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>用户注册</h2>
            <h3>USER REGISTRATION</h3>
          </div>
          <Form
            onFinish={onFinish}
            form={form}
            initialValues={{ agreement: true }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入用户名！" }]}
              style={{ flex: "1" }}
            >
              <Input
                style={{ height: "36px" }}
                prefix={
                  <UserOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请输入用户名"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码！" }]}
              style={{ flex: "1" }}
            >
              <Input.Password
                style={{ height: "36px" }}
                prefix={
                  <UnlockOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item
              name="repassword"
              rules={[
                { required: true, message: "请再次输入密码！" },
                {
                  validator(rule, value) {
                    if (!value || form.getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("两次输入的密码不一致，请重新输入！");
                  },
                },
              ]}
            >
              <Input.Password
                style={{ height: "36px" }}
                prefix={
                  <UnlockOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请再次输入密码"
              />
            </Form.Item>
            <Form.Item
              name="cellphone"
              rules={[
                {
                  required: true,
                  pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                  message: "请输入正确的手机号！",
                },
              ]}
            >
              <Input
                style={{ height: "36px" }}
                prefix={
                  <PhoneOutlined style={{ fontSize: 16, color: "#999" }} />
                }
                placeholder="请输入手机号"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="captcha"
                rules={[{ required: true, message: "请输入图形验证码！" }]}
                style={{ display: "inline-block", width: "calc(100% - 113px)" }}
              >
                <Input
                  style={{ height: "36px" }}
                  prefix={
                    <SafetyCertificateOutlined
                      style={{ fontSize: 16, color: "#999" }}
                    />
                  }
                  placeholder="请输入图形验证码"
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", marginLeft: 15 }}>
                <img
                  src={captcha}
                  alt=""
                  title="获取验证码"
                  onClick={onCaptcha}
                  style={{ height: 36, display: "block" }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="code"
                rules={[{ required: true, message: "请输入短信验证码！" }]}
                style={{ display: "inline-block", width: "calc(100% - 117px)" }}
              >
                <Input
                  style={{ height: "36px" }}
                  prefix={
                    <SafetyCertificateOutlined
                      style={{ fontSize: 16, color: "#999" }}
                    />
                  }
                  placeholder="请输入短信验证码"
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", marginLeft: 15 }}>
                <Button
                  style={{ height: 36, width: 102 }}
                  type="primary"
                  disabled={countdown > 0}
                  onClick={() => {
                    onSms();
                  }}
                >
                  {countdown > 0 ? "(" + countdown + ")" : "获取验证码"}
                </Button>
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                style={{ display: "inline-block", margin: "0 0 20px 0" }}
              >
                <Checkbox>同意用户协议</Checkbox>
              </Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(100% - 242px)",
                  margin: "0 0 20px 0",
                }}
              >
                <a onClick={() => setVisible(true)}>《服务条款》</a>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", margin: "0 0 20px 0" }}
              >
                <Link to={"/signin" + location.search}>我有账号，立即登录</Link>
              </Form.Item>
            </Form.Item>
            <div className={styles.row}>
              <Form.Item style={{ flex: "1", margin: "0" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ height: "36px" }}
                  disabled={loading}
                  loading={loading}
                  block
                >
                  注册
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        title="服务条款"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        centered
      >
        <div className={styles.terms}>
          <p>
            本条款阐述之条款和条件适用于您使用本公司的各种产品或服务。当您使用本公司某一特定服务时，如该服务经本公司的授权另有单独的协议、指引或规则，您还应遵守&ldquo;本条款&rdquo;及本公司随时公布的相关的服务条款、指引或规则等。该指引和规则，均构成&ldquo;本条款&rdquo;的一部分。
          </p>

          <p>1、接受条款。</p>

          <p>
            进入本公司或客户端软件注册为会员过程中，请详细阅读&ldquo;本条款&rdquo;。如果注册，您就已经与本公司订立&ldquo;本条款&rdquo;，且您将受本条款和相关条件约束。本公司有权随时更改&ldquo;本条款&rdquo;，若&ldquo;本条款&rdquo;内容有所更改，更改后的内容将在本公司官网的&ldquo;服务条款&rdquo;版块公告，公告即生效，您可随时查阅。如您不同意相关变更，必须停止使用接受本公司的&ldquo;服务&rdquo;。您应在次登录后仔细阅读修订后的&ldquo;本条款&rdquo;，并有权选择停止继续使用&ldquo;服务&rdquo;；一旦您继续使用&ldquo;服务&rdquo;，则表示您已接受经修订的&ldquo;本条款&rdquo;，当您与本公司发生争议时，应以最新的服务协议为准。除另行明确声明外，任何使&ldquo;服务&rdquo;范围扩大或功能增强的新内容均受&ldquo;本条款&rdquo;约束。
          </p>

          <p>2、谁可接受本公司&ldquo;服务&rdquo;？</p>

          <p>
            &ldquo;服务&rdquo;仅供能够根据相关法律订立具有法律约束力的合约的个人或公司使用。因此，您的年龄必须在十八周岁或以上，才可使用本公司服务。如不符合本项条件，请勿使用&ldquo;服务&rdquo;。本公司可随时自行全权决定拒绝向任何人士提供&ldquo;服务&rdquo;。&ldquo;服务&rdquo;不会提供给被暂时或永久中止资格的本公司会员。
          </p>

          <p>3、收费。</p>

          <p>
            本公司保留在根据第1条通知您后，收取&ldquo;服务&rdquo;费用的权利。您因进行交易、向本公司获取有偿服务或接触本公司服务器而发生的所有应纳税赋。本公司保留在无须发出书面通知，仅在本公司官网公示的情况下，暂时或永久地更改或停止部分或全部&ldquo;服务&rdquo;的权利。
          </p>

          <p>4、您的资料和供买卖的产品。</p>

          <p>
            &ldquo;公司资料&rdquo;包括您在注册、发布或列举产品过程中、在任何公开信息场合或通过任何电子邮件形式，向本公司或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对&ldquo;公司资料&rdquo;负全部责任，而本公司仅作为您在网上发布和刊登&ldquo;公司资料&rdquo;的被动渠道。但是，倘若本公司认为&ldquo;公司资料&rdquo;可能使其承担任何法律或道义上的责任，则本公司可自行全权决定对&ldquo;公司资料&rdquo;采取其认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给本公司的&ldquo;公司资料&rdquo;拥有全部权利，包括全部版权。您确认，本公司没有责任去认定或决定您提交的资料哪些是应当受到保护的，对享有&ldquo;服务&rdquo;的其他用户使用&ldquo;您的资料&rdquo;，本公司也不必负责。
          </p>

          <p>4.1 注册义务。</p>

          <p>
            如您在本公司络营销服务平台网站或客户端软件注册，您同意：（a）提供关于您或您所经营企业的真实、准确、完整和反映当前情况的资料；(b)
            维持并及时更新会员资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或本公司有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，本公司有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来对&ldquo;服务&rdquo;(或其任何部份)
            以任何形式使用。如您代表企业或其他法律主体在本公司登记，则您声明和保证，您有权使该企业或其他法律主体受
            &ldquo;本条款&rdquo;约束。
          </p>

          <p>4.2 会员注册名、密码和保密。</p>

          <p>
            在登记过程中，您将选择会员注册名和密码。您须自行负责对您的会员注册名和密码保密，且须对您在会员注册名和密码下发生的所有活动承担责任。您同意：(a)
            如发现任何人未经授权使用您的会员注册名或密码，或发生违反保密规定的任何其他情况，您会立即通知本公司；及
            (b)
            确保您在每个上网时段结束时，以正确步骤离开网站。本公司不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
          </p>

          <p>4.3 关于您的资料的规则。</p>

          <p>
            您同意，&ldquo;公司资料&rdquo;和您提供在本公司网站上交易的任何&ldquo;产品&rdquo;（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。&ldquo;本条款&rdquo;中&ldquo;产品&rdquo;一词均含此义）
          </p>

          <p>
            a. 不会有欺诈成份，与售卖伪造或盗窃无涉；
            <br />
            b.
            不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权；
            <br />
            c. 不会违反任何法律、法规、条例或规章
            (包括但不限于关于规范出口管理、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；
            <br />
            d. 不会含有诽谤（包括商业诽谤）、非法恐吓或非法骚扰的内容；
            <br />
            e. 不会含有淫秽、或包含任何儿童色情内容；
            <br />
            f.
            不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；
            <br />
            g.
            不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：(i)
            &ldquo;本条款&rdquo;项下禁止的货物或服务；或 (ii)
            您无权连接或包含的货物或服务。此外，您同意不会：(h)
            在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用&ldquo;服务&rdquo;；(i)
            未经其他人士同意，利用&ldquo;服务&rdquo;收集其他人士的电子邮件地址及其他资料；或
            (j)
            利用&ldquo;服务&rdquo;制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士。
            <br />
            4.4 被禁止物品。
          </p>

          <p>
            您不得在本公司的网站公布：(a)
            可能使本公司违反任何相关法律、法规、条例或规章的任何物品；或 (b)
            本公司认为应禁止或不适合通过本网站发布的任何物品。
          </p>

          <p>5、您授予本公司的许可使用权。</p>

          <p>
            您授予本公司独家的、全球通用的、永久的、免费的许可使用权利
            (并有权在多个曾面对该权利进行再授权)，使本公司有权(全部或部份地)
            使用、复制、修订、改写、发布、翻译、分发、执行和展示&quot;您的资料&quot;或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将&quot;您的资料&quot;纳入其他作品内。
          </p>

          <p>6、隐私。</p>

          <p>
            尽管有第5条所规定的许可使用权，天助网将仅根据天助网的隐私声明使用&ldquo;公司资料&rdquo;。本公司隐私声明的全部条款属于&rdquo;本条款&rdquo;的一部份，因此，您必须仔细阅读。请注意，您一旦自愿地在天助网交易地点披露&ldquo;公司资料&rdquo;，该等资料即可能被其他人士获取和使用。
          </p>

          <p>7、终止或访问限制。</p>

          <p>
            您同意，在本公司未向您收费的情况下，本公司可自行全权决定以任何理由
            (包括但不限于本公司认为您已违反&ldquo;本条款&rdquo;的字面意义和精神，或您以不符合&ldquo;本条款&rdquo;的字面意义和精神的方式行事，您同意，在本公司向您收费的情况下，本公司应基于合理的怀疑且经电子邮件通知的情况下实施上述终止服务的行为。本公司同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供&ldquo;服务&rdquo;或其任何部份。您同意，根据&rdquo;本条款&rdquo;的任何规定终止您使用&ldquo;服务&rdquo;之措施可在不发出事先通知的情况下实施，并承认和同意，本公司可立即使您的帐户无效，或撤销您的帐户以及在您的帐户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或&ldquo;服务&rdquo;。帐号终止后，本公司没有义务为您保留原帐号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，您同意，本公司不会就终止您接入&ldquo;服务&rdquo;而对您或任何第三者承担任何责任。
          </p>

          <p>8、违反规则会有什么后果？</p>

          <p>
            在不限制其他补救措施的前提下，发生下述任一情况，本公司可立即发出警告，暂时中止、永久中止或终止您的会员资格，删除您的任何现有产品信息，以及您在网站上展示的任何其他资料：(i
            ) 您违反&rdquo;本条款&rdquo;；(ii)
            本公司无法核实或鉴定您提供的任何资料；或 (iii)
            本公司相信您的行为可能会使您、本公司用户或通过本公司或其网站。
          </p>
        </div>
      </Modal>
      <Modal
        title="提示"
        visible={success}
        footer={null}
        onOk={() => setSuccess(false)}
        onCancel={() => {
          navigate(`/`);
        }}
        maskClosable={false}
        centered
      >
        <div className={styles.tips}>
          <div className={styles.ico}>
            <CheckCircleFilled style={{ fontSize: 72, color: "#52c41a" }} />
          </div>
          <div className={styles.nam}>提交成功</div>
          <div className={styles.txt}>
            工作人员正在为您开通免费店铺，请保持电话畅通
          </div>
          <div className={styles.btn}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/`);
              }}
            >
              我知道了
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Signup;
